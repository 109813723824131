import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SpinnerService {
  private showSpinnerSubject$: Subject<boolean> = new Subject<boolean>();

  public spinnerObservable = this.showSpinnerSubject$.asObservable();

  public showSpinner(): void {
    this.showSpinnerSubject$.next(true);
  }

  public hideSpinner(): void {
    this.showSpinnerSubject$.next(false);
  }
}
