import { MixedRender } from './mixed-render';
import { MoustacheRender } from './moustache.render';
import { StandardRender } from './standard.render';
import { NoopRender } from './noop.render';
import { RendererInterface, RenderType } from '@jump-tech-frontend/domain';

export class RenderFactory {
  public createRenderer(renderType: RenderType): RendererInterface {
    switch (renderType) {
      case 'mixed':
        return new MixedRender();
      case 'moustache':
        return new MoustacheRender();
      case 'standard':
        return new StandardRender();
      case 'noop':
        return new NoopRender();
    }
  }
}
