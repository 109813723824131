import { MOUSTACHE_RENDER_REGEX, RenderOptions, RenderType, STANDARD_RENDER_REGEX } from '@jump-tech-frontend/domain';

import { RenderFactory } from './render.factory';

export class RenderHelper {
  public constructor(private readonly renderFactory: RenderFactory = new RenderFactory()) {}

  public renderTemplate(template: string, context: Record<string, unknown>, options?: RenderOptions): string {
    const renderType = this.getRenderType(template);
    const renderer = this.renderFactory.createRenderer(renderType);

    return renderer.render(template, context, options);
  }

  private getRenderType(template: string): RenderType {
    const moustacheRenderer = MOUSTACHE_RENDER_REGEX.test(template);
    const standardRenderer = STANDARD_RENDER_REGEX.test(template);

    if (moustacheRenderer && standardRenderer) {
      return 'mixed';
    }

    if (moustacheRenderer) {
      return 'moustache';
    }

    if (standardRenderer) {
      return 'standard';
    }

    return 'noop';
  }
}
