import { Component, Input } from '@angular/core';
import { I18nKeys } from '@jump-tech-frontend/app-config';
import { QuestionBase } from '@jump-tech-frontend/domain';

@Component({
  selector: 'question-form-error',
  template: `<span class="form-error">{{ message || i18ns.required }}</span>`,
  standalone: true
})
export class QuestionFormErrorComponent {
  @Input() message: string;
  @Input() question: QuestionBase<string>;
  @Input() i18ns: I18nKeys;
}
