import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from './error-modal.component';
import { ErrorPresenter } from './error.presenter';

import { ErrorViewModel } from './error.view.model';

@Component({
  selector: 'error-component',
  template: `<div [attr.data-qa]="'errorPlaceholder'"></div>`,
  standalone: true,
  providers: [ErrorPresenter]
})
export class ErrorComponent implements OnInit {
  private modalRef?: NgbModalRef;

  constructor(private errorPresenter: ErrorPresenter, private modalService: NgbModal) {}
  ngOnInit() {
    this.errorPresenter.load((vm: ErrorViewModel) => {
      if (vm && !this.modalRef) {
        this.modalRef = this.modalService.open(ErrorModalComponent, { modalDialogClass: 'bottom-modal' });
        this.modalRef.componentInstance.error = vm.error;
        this.modalRef.result
          .then(value => {
            const action = vm.error.action;
            this.errorPresenter.clearError();
            this.modalRef = null;
            if (value && action) {
              action();
            }
          })
          .catch(() => {
            this.errorPresenter.clearError();
          });
      }
    });
  }
}
