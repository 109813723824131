import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { JumptechDate } from '@jump-tech-frontend/domain';

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = JumptechDate.from(value, { sourceFormat: 'd MMMM yyyy' }).toDateObject();
      return {
        day: date.day,
        month: date.month,
        year: date.year
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if (date) {
      return JumptechDate.from(date).toDateFormat();
    }
    return '';
  }
}
