<div class="project-auto-assignment-modal modal-wrapper" *ngIf="vm$ | async as vm">
  <div class="modal-header-wrapper">
    <div class="modal-banner">{{ vm.disableProjectAutoAssignment }}</div>
  </div>
  <div class="modal-body">
    <p class="project-auto-assignment-modal__message">{{ vm.confirmationMessage }}</p>
  </div>
  <div class="modal-footer project-auto-assignment-modal__footer">
    <jui-button
      class="project-auto-assignment-modal__footer__cancel"
      color="secondary"
      size="sm"
      [attr.data-qa]="'cancelButton'"
      (click)="dismiss()"
    >
      {{ vm.cancel }}
    </jui-button>
    <jui-button size="sm" [attr.data-qa]="'saveButton'" (click)="submit()">
      {{ vm.submit }}
    </jui-button>
  </div>
</div>
