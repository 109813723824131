import { Injectable } from '@angular/core';
import { AlertModalComponent } from './modals/alert-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AcceptedFileType } from '../core/domain/project-configuration';
import { MimeTypeService } from '@jump-tech-frontend/cards';
import { S3Service } from '../core/s3.service';
import { TranslocoService } from '@ngneat/transloco';
import { ApiService } from '../core/api.service';
import { last, tap } from 'rxjs';
import { HttpDownloadProgressEvent, HttpEventType, HttpUploadProgressEvent } from '@angular/common/http';
import { PresignedPost } from 'aws-sdk/clients/s3';

@Injectable({ providedIn: 'root' })
export class AttachmentsHelper {
  static readonly maxFileSizeBytes = 20000000;
  static readonly defaultFileTypes = '.doc,.docx,.xls,.xlsx,.pdf,image/*';

  constructor(
    private modalService: NgbModal,
    private mimeTypeService: MimeTypeService,
    public s3: S3Service,
    private translocoService: TranslocoService,
    private apiService: ApiService
  ) {}

  public getFileFormatsString(acceptedFileTypes: AcceptedFileType[]) {
    if (!acceptedFileTypes || !acceptedFileTypes.length) {
      return ` (${this.translocoService.translate('common.formats')} ${AttachmentsHelper.defaultFileTypes})`;
    }
    const fileTypes = acceptedFileTypes.map(acceptedFileType => acceptedFileType.label).join(', ');
    return ` (${this.translocoService.translate('common.formats')} ${fileTypes})`;
  }

  onInvalidFileFormat(acceptedFileTypes: AcceptedFileType[]) {
    const modalRef = this.modalService.open(AlertModalComponent);
    modalRef.componentInstance.title = this.translocoService.translate('common.modals.invalidFile.title');
    modalRef.componentInstance.messages = [
      this.translocoService.translate('common.modals.invalidFile.messages.supportedFile'),
      `${this.getFileFormatsString(acceptedFileTypes)}`
    ];
  }

  public onExceededMaxFileSizeEvent() {
    const modalRef = this.modalService.open(AlertModalComponent);
    modalRef.componentInstance.title = this.translocoService.translate('common.modals.maximumFileSize.title');
    modalRef.componentInstance.messages = [
      this.translocoService.translate('common.modals.maximumFileSize.messages.uploadsLimited')
    ];
  }

  public onPostToS3ErrorEvent(error: string) {
    const modalRef = this.modalService.open(AlertModalComponent);
    modalRef.componentInstance.title = this.translocoService.translate('common.modals.invalidGeneralPostFile.title');
    modalRef.componentInstance.messages = [error];
  }

  public checkIsFileTypeAndSizeOK(file: File, acceptedFileTypes?: AcceptedFileType[]) {
    if (file.size > AttachmentsHelper.maxFileSizeBytes) {
      this.onExceededMaxFileSizeEvent();
      return false;
    }
    if (!this.mimeTypeService.isAllowedFileType(file, this.getAcceptedMimeTypes(acceptedFileTypes))) {
      this.onInvalidFileFormat(acceptedFileTypes);
      return false;
    }
    return true;
  }

  getFileUploadDetail(file, projectId: string, attachmentName?: string) {
    const fileType = file.type;
    let fileExtension = fileType.split('/')[1];
    fileExtension =
      fileExtension === 'vnd.openxmlformats-officedocument.wordprocessingml.document' ? 'docx' : fileExtension;
    const fileName = attachmentName ? `${attachmentName.trim()}.${fileExtension}` : file.name.trim();
    const uploadDate = new Date().getTime();
    const key = `${projectId}/${fileName}`;
    return { uploadDate, key, fileName, fileType };
  }

  getAcceptedMimeTypes(acceptedFileTypes?: AcceptedFileType[]) {
    return acceptedFileTypes ? acceptedFileTypes.map(a => a.type).join(',') : AttachmentsHelper.defaultFileTypes;
  }

  getSanitisedFilename(fileName: string) {
    return fileName.replace(/_\d{13}(\..*)$/, (match, p1) => {
      return p1;
    });
  }

  postAndTrackProgress(
    presignedPost: PresignedPost,
    file: File,
    uploadInProgress?: { fileName: string; progress: number },
    updateCallback?: any
  ) {
    return this.apiService.postToS3(presignedPost, file).pipe(
      tap((event: HttpDownloadProgressEvent | HttpUploadProgressEvent) => {
        let currentProgress;
        switch (event?.type) {
          case HttpEventType.UploadProgress:
            currentProgress = (event.loaded / event.total) * 100;
            if (uploadInProgress) {
              uploadInProgress.progress = currentProgress;
            }
            if (updateCallback && updateCallback instanceof Function) {
              updateCallback(currentProgress);
            }
            break;
          case HttpEventType.DownloadProgress:
            if (event?.partialText) {
              this.onPostToS3ErrorEvent(
                event?.partialText ??
                  this.translocoService.translate('common.modals.invalidGeneralPostFile.messages.unknownError')
              );
            }
        }
      }),
      last()
    );
  }
}
