<div class="job-entry" *ngFor="let item of 10 | numberToArray">
  <span class="job-entry__icon-container">
    <i class="material-icons job-entry__icon">drag_handle</i>
  </span>
  <div class="job-entry__details">
    <span class="job-entry__details__title">{{ vm.headerDetails }}</span>
    <div class="job-entry__details__content">
      <jui-skeleton-loader width="110px"></jui-skeleton-loader>
      <jui-skeleton-loader count="2" random="true"></jui-skeleton-loader>
    </div>
  </div>
  <div class="job-entry__details">
    <span class="job-entry__details__title">{{ vm.headerPostcode }}</span>
    <div class="job-entry__details__content">
      <jui-skeleton-loader width="70px" color="high"></jui-skeleton-loader>
    </div>
  </div>
  <div class="job-entry__details button-column">
    <jui-skeleton-loader color="secondary" height="30px" width="100px"></jui-skeleton-loader>
    <jui-skeleton-loader color="primary" height="30px" width="100px"></jui-skeleton-loader>
  </div>
</div>
