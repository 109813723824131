<div [formGroup]="changePasswordForm">
  <label class="login-card__lbl" for="old-password">{{ 'auth.formFields.oldPassword.label' | transloco }}</label>
  <div class="login-card__row" [class.login-card__row-slim]="changePasswordForm.controls['oldPassword'].dirty">
    <input
      #oldPasswordInput
      class="form-control login-card__ctrl login-card__ctrl--has-icon"
      [type]="showPassword ? 'text' : 'password'"
      placeholder="{{ 'auth.formFields.oldPassword.placeholder' | transloco }}"
      formControlName="oldPassword"
      autocomplete="old-password"
      id="old-password"
      data-qa="oldPasswordInput"
    />
    <button class="login-card__toggle" (click)="togglePassword()" [attr.data-qa]="'toggleShowOldPassword'">
      <span *ngIf="!showPassword" class="material-icons login-card__toggle-icon"> visibility </span>
      <span *ngIf="showPassword" class="material-icons login-card__toggle-icon"> visibility_off </span>
    </button>
  </div>
  <br />
  <label class="login-card__lbl" for="new-password">{{ 'auth.formFields.newPassword.label' | transloco }}</label>
  <div class="login-card__row" [class.login-card__row-slim]="changePasswordForm.controls['newPassword'].dirty">
    <input
      class="form-control login-card__ctrl login-card__ctrl--has-icon"
      [type]="showPassword ? 'text' : 'password'"
      placeholder="{{ 'auth.formFields.newPassword.placeholder' | transloco }}"
      formControlName="newPassword"
      autocomplete="new-password"
      id="new-password"
      data-qa="newPasswordInput"
    />
    <button class="login-card__toggle" (click)="togglePassword()" [attr.data-qa]="'toggleShowNewPassword'">
      <span *ngIf="!showPassword" class="material-icons login-card__toggle-icon"> visibility </span>
      <span *ngIf="showPassword" class="material-icons login-card__toggle-icon"> visibility_off </span>
    </button>
  </div>
  <app-password-strength-checker [form]="changePasswordForm"></app-password-strength-checker>
  <label class="login-card__lbl" for="new-password-confirm">{{
    'auth.formFields.newPasswordConfirm.label' | transloco
  }}</label>
  <div class="login-card__row">
    <input
      class="form-control login-card__ctrl login-card__ctrl--has-icon"
      [type]="showPassword ? 'text' : 'password'"
      placeholder="{{ 'auth.formFields.newPasswordConfirm.placeholder' | transloco }}"
      formControlName="newPasswordConfirm"
      id="new-password-confirm"
      data-qa="newPasswordConfirmInput"
      autocomplete="new-password"
      (keyup.enter)="changePassword()"
    />
    <button class="login-card__toggle" (click)="togglePassword()" [attr.data-qa]="'toggleShowConfirmPassword'">
      <span *ngIf="!showPassword" class="material-icons login-card__toggle-icon"> visibility </span>
      <span *ngIf="showPassword" class="material-icons login-card__toggle-icon"> visibility_off </span>
    </button>
  </div>
  <div
    class="login-card__row"
    *ngIf="
      changePasswordForm.errors?.mismatch &&
      (changePasswordForm.controls['newPasswordConfirm'].dirty || changePasswordForm.controls['newPassword'].touched)
    "
  >
    <div class="login-card__error a-fade-in-fast">
      {{ 'auth.error.passwordMisMatch' | transloco }}
    </div>
  </div>
  <div class="login-card__row">
    <jui-button
      size="xl"
      expand
      class="login-card__btn"
      data-qa="newPasswordRequiredButton"
      [class.login-card__btn--disabled]="changePasswordForm.invalid"
      [disabled]="changePasswordForm.invalid || submitInProgress"
      (click)="changePassword()"
      [loading]="submitInProgress"
    >
      <span *ngIf="!submitInProgress">{{ 'Buttons.changePassword.text' | transloco }}</span>
      <span *ngIf="submitInProgress">{{ 'Buttons.settingNewPassword.text' | transloco }}</span>
    </jui-button>
  </div>
</div>
