import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { from, Observable, catchError, switchMap } from 'rxjs';
import * as XRegExp from 'xregexp';

import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { LoggerService } from '../../error/logger.service';
import { EnvironmentService } from '../../../environments/environment.service';
import { PathwayApiEnvironment } from '../../../environments/pathway-api-environment';

@Injectable({
  providedIn: 'root'
})
export class HttpAuthInterceptor implements HttpInterceptor {
  private apiInterceptPattern: string;
  private environment: PathwayApiEnvironment;

  constructor(
    private authenticationService: AuthenticationService,
    private loggerService: LoggerService,
    environmentService: EnvironmentService
  ) {
    this.environment = environmentService.getCurrentApiEnvironment();
    this.apiInterceptPattern = `https://api\\.${
      this.environment.production ? '' : this.environment.name + '\\.'
    }jumptech\\.co\\.uk|https://.*.jumpte\\.ch|https://api\\.local\\.jumptech\\.co\\.uk`;
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    /**
     * Only add Authorization header if we are pointing to the Jumptech API
     */
    if (!XRegExp(this.apiInterceptPattern).test(req.url)) {
      return next.handle(req);
    }

    return from(this.authenticationService.getSession()).pipe(
      switchMap((auth: CognitoUserSession) => {
        let authReq = req;
        if (auth) {
          const token = auth.getIdToken().getJwtToken();
          authReq = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
        }

        return next.handle(authReq);
      }),
      catchError(err => {
        this.loggerService.log(err);
        // catch error do nothing, continue to error interceptor
        if (err !== 'No current user') {
          throw err;
        }
        return next.handle(req);
      })
    );
  }
}
