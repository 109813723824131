import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AssignedTradesPeopleVm, DateTimeChangeEvent } from '../../../../schedule.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgForOf, NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReactiveFormsModule } from '@angular/forms';
import { JobAssignment } from '../../../../../../core/domain/job';
import { ScheduleEventSelectedJobDetailsSlotTimeFormComponent } from '../schedule-event-selected-job-details-slot-time-form/schedule-event-selected-job-details-slot-time-form.component';
import { ScheduleEventSelectedJobDetailsTradespersonComponent } from '../schedule-event-selected-job-details-tradesperson/schedule-event-selected-job-details-tradesperson.component';

@Component({
  selector: 'schedule-event-selected-job-details-assigned-engineers',
  templateUrl: 'schedule-event-selected-job-details-assigned-engineers.component.html',
  styleUrls: ['../../../schedule-event-selected-job-details/schedule-event-selected-job-details.component.scss'],
  standalone: true,
  imports: [
    CoreComponentsAngularModule,
    NgIf,
    NgForOf,
    NgSelectModule,
    NgbDatepickerModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    ScheduleEventSelectedJobDetailsSlotTimeFormComponent,
    ScheduleEventSelectedJobDetailsTradespersonComponent
  ]
})
export class ScheduleEventSelectedJobDetailsAssignedEngineersComponent {
  @Input() vm: AssignedTradesPeopleVm;
  @Output() setLeadEngineer: EventEmitter<JobAssignment> = new EventEmitter<JobAssignment>();
  @Output() removeEngineer: EventEmitter<JobAssignment> = new EventEmitter<JobAssignment>();
  @Output() slotDateTimeChange: EventEmitter<DateTimeChangeEvent> = new EventEmitter<DateTimeChangeEvent>();
}
