<div class="modal-wrapper modal-wrapper--jumbo">
  <div class="modal-header"></div>
  <div class="modal-body">
    <h1 class="modal-heading u-text-primary">Pathway</h1>
    <p class="modal-lead" [attr.data-qa]="'pathwayError'">{{ 'common.errors.pathwayUnavailable' | transloco }}</p>
    <hr class="my-4" />
    <p class="modal-lead">
      <jui-button expand size="xl" [attr.data-qa]="'closeError'" (click)="close()">
        {{ 'common.ok' | transloco }}
      </jui-button>
    </p>
  </div>
</div>
