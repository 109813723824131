import { Directive, Input, ViewChild } from '@angular/core';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { ScrollService } from '../scroll.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Datasource } from '../../domain/datasource';
import * as JmesPath from 'jmespath';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { BaseQuestionComponent } from '../question.component';

@Directive()
export class AbstractDropdownQuestionComponent extends BaseQuestionComponent {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<any>;
  @ViewChild('dropDownInput') input;
  type: string;
  initialised = false;

  /**
   * The original options which may need to be used as a base
   * to filter if this question is subscribed to the value of another question.
   */
  currentFilter: any[] = [];

  constructor(protected scrollService: ScrollService, protected spinnerService: NgxSpinnerService) {
    super();
  }

  override get value() {
    return this.form?.get(this.question.key)?.value || null;
  }

  override set value(value: any) {
    this.form?.get(this.question.key)?.patchValue(value);
  }

  public mapData(result: any[], datasource: Datasource, question: QuestionBase<string>, form: FormGroup) {
    const options: { key: string; value: string; type: string; order?: number }[] = [];
    const transformed = JmesPath.search(result, datasource?.transform ?? '');
    transformed.forEach(item => {
      const initialText = item.text;
      const initialValue = item.value;
      const initialType = item.type || 'text';
      const order = item.order || null;
      if (Array.isArray(initialValue)) {
        initialValue.forEach(child => {
          options.push({
            key: `${initialText} - ${child}`,
            value: `${initialText} - ${child}`,
            type: initialType
          });
        });
      } else {
        options.push({
          key: initialValue,
          value: initialText,
          type: initialType,
          order: order
        });
      }
    });
    if (question && form) {
      if (options.length) {
        question.options = options.sort((a, b) => {
          if (typeof a.order === 'number' && typeof b.order === 'number') {
            return a.order - b.order;
          }
          return a.key?.localeCompare(b.key) ?? 0;
        });

        // If no option is selected and there's no placeholder,
        // then select the first available option.
        if (!question.placeholder && !form?.get(question.key)?.value) {
          form?.get(question.key)?.setValue(options[0].key);
        }
      } else if (!question.options || !question.options.length) {
        question.options = [];
      }
    }
  }
}
