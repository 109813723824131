<div class="button-wrapper" *ngIf="vm$ | async as vm">
  <div class="survey-actions js-survey-actions" *ngIf="vm.actions.length">
    <div class="survey-actions__action" *ngFor="let action of vm.actions">
      <jui-button
        class="action-{{ action.action }}"
        size="xl"
        title="{{ action.label }}"
        expand
        [display]="action.buttonDisplay"
        [color]="action.buttonVariant"
        [disabled]="action.disabled"
        (click)="executeAction(action)"
        [attr.data-qa]="action.qaAttribute"
      >
        {{ action.label }}
      </jui-button>
    </div>
  </div>

  <div class="survey-actions survey-actions--helper" *ngIf="vm.hasReset">
    <div class="survey-actions__action survey-actions__action--reset">
      <jui-button
        class="action-reset"
        size="xl"
        title="Reset"
        expand
        color="low"
        (click)="resetCards()"
        [attr.data-qa]="'resetButton'"
      >
        Reset
      </jui-button>
    </div>
  </div>
</div>
