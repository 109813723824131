import { IfDefinition } from './expression';

export interface ActionEvent {
  eventName: string;
  executeIf?: IfDefinition;
}

export interface AsyncAction extends ActionEvent {
  asyncActions?: ActionEvent[];
}

export interface ProjectAction<T extends ActionEvent> {
  id: string;
  label: string;
  action?: string;
  asyncActions?: T[];
  transition?: string;
  confirmation?: any;
  requiredResource?: string;
  showResult?: boolean;
  doc?: any;
  dataIncludes?: string[];
  show?: { taskId: string; key: string; value: string | boolean };
  requiredData?: [{ key: string; label: string }];
  infoMessage?: string;
  tenant?: string;
}
