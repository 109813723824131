import { NgModule } from '@angular/core';
import { PostCodeLookupComponent } from './post-code-lookup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

@NgModule({
  declarations: [PostCodeLookupComponent],
  exports: [PostCodeLookupComponent],
  imports: [CommonModule, FormsModule, NgbButtonsModule, ReactiveFormsModule, CoreComponentsAngularModule],
  providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class PostCodeLookupModule {}
