@if (vm$ | async; as vm) {
<div class="ena-application">
  @if (vm.showLoader) {
    <div class="ena-application__spinner">
      <ngx-spinner
        color="var(--jds-theme-color-primary)"
        bdColor="transparent"
        size="medium"
        [fullScreen]="false"
        type="ball-clip-rotate"
        name="enaApplicationSpinner"
        [attr.data-qa]="'enaApplicationSpinner'"
      >
      </ngx-spinner>
    </div>
  }
  @else {
    @if (vm.showRetry) {
      <div class="ena-application__retry">
        <span class="ena-application__message">{{ vm.i18nRetryMessage }}</span>
        <jui-button size="sm" (click)="retryFormLoad()">{{ vm.i18nRetryLabel }}</jui-button>
      </div>
    } @else {
      <div class="ena-application__main-header">
        <div class="ena-application__main-header-details">
          <div class="ena-application__status" [class.ena-application__status--rejected]="vm.showRejectedStatus">
            <span class="ena-application__status-label">{{ vm.i18nTitle }}</span>
            <div class="ena-application__status-badge" [attr.data-qa]="vm.qaEnaApplicationStatus">{{ vm.status }}</div>
            @if (vm.externalStatus) {
              <span class="ena-application__ext-status" [attr.data-qa]="vm.qaEnaApplicationExternalStatus">{{vm.externalStatus}}</span>
            }
          </div>
          @if (vm.externalDno) {
            <div>
              <span class="ena-application__ext-dno-label">{{ vm.i18nExternalDnoLabel }}</span>
              <span [attr.data-qa]="vm.qaEnaApplicationExternalDno">{{ vm.externalDno }}</span>
            </div>
          }
        </div>
        <ena-application-actions></ena-application-actions>
      </div>
      <ena-application-groups [vm]="vm"></ena-application-groups>
    }
  }

</div>
}
