import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { NgxSpinnerService } from 'ngx-spinner';
import { ImageAsset, ImageAssetFactory, ImageAssetType } from '../../domain/card';
import { I18nKeys } from '../../domain/i18n-keys';
import { ImageManipulationService } from '../image-manipulation.service';
import { BaseQuestionComponent } from '../question.component';
import { ScrollService } from '../scroll.service';
import { untilDestroyed } from '@jump-tech-frontend/angular-common';
import { HttpImageComponent } from '../http-image.component';
import { ImageAssetComponent } from '../image-asset.component';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { QuestionHintComponent } from '@jump-tech-frontend/question-components';
import { MultiImageUploadQuestionInputComponent } from './multi-image-upload-question/multi-image-upload-question-input.component';
import { FileUploadQuestionInputComponent } from './file-upload-question/file-upload-question-input.component';
import { QuestionLabelContentComponent } from '@jump-tech-frontend/question-components';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: `crds-question-image-or-file-upload`,
  template: `
    <div *ngIf="show && form" [formGroup]="form">
      <label *ngIf="question.label" class="form-label" [attr.for]="question.key" [attr.data-qa]="question.key">
        <question-label-content
          [question]="question"
          [hideHint]="hasUploadedFiles"
          [isInvalid]="isInvalid"
          [i18ns]="i18ns"
        ></question-label-content>
      </label>
      <!-- QUESTION INPUT -->
      <div class="inline-buttons">
        <crds-question-file-upload-input
          (fileUploaded)="onFileUploaded($event)"
          [hasUploadedFiles]="hasUploadedFiles"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
          [attr.data-qa]="'fileUploadInput'"
        ></crds-question-file-upload-input>
        <crds-question-multi-image-upload-input
          (imageUploaded)="onImageUploaded($event)"
          [hasUploadedImages]="hasUploadedImages"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
          [attr.data-qa]="'multiImageUploadInput'"
        ></crds-question-multi-image-upload-input>
      </div>
      <div class="file-wrapper">
        <question-hint *ngIf="!hasUploadedFiles" [question]="question"></question-hint>
        <div class="file-placeholder-container pt-1" *ngFor="let uploadedFile of files; index as i">
          <div class="file-placeholder-icon">
            <i class="material-icons">article</i>
          </div>
          <div class="file-placeholder">
            <span [id]="i + '_temp'">{{ uploadedFile.name }}</span>
          </div>
          <jui-icon (click)="clearFile(i)" isButton="true" name="delete_forever" #fileUploadButton></jui-icon>
        </div>
      </div>
      <div class="image-wrapper">
        <div *ngIf="!hasUploadedImages && images && images.length">
          <crds-image-asset [images]="images" [key]="question.key"></crds-image-asset>
        </div>
        <div class="file-placeholder-container pt-1" *ngFor="let uploadedImage of uploadedImages; index as i">
          <div class="file-placeholder-icon"></div>
          <div class="file-placeholder">
            <crds-http-image [id]="i + '_temp'" [src]="uploadedImage"></crds-http-image>
          </div>
          <button (click)="clearImage(i)" type="button" class="btn btn-primary file-upload-button" #imageUploadButton>
            <i class="material-icons">delete_forever</i>
          </button>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .material-icons {
        color: #ffffff;
      }
      .inline-buttons {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
        grid-row-gap: var(--jds-space-sm);
        grid-column-gap: var(--jds-space-sm);
        margin: var(--jds-space-stack-sm);
      }
      .file-placeholder-container {
        padding-right: 1em;
        margin-bottom: 0.5em;
        display: grid;
        grid-template-columns: 1fr 8fr 1fr;
        align-items: center;
        border-bottom-style: solid;
        border-width: thin;
      }

      .file-placeholder-icon {
        padding: 0;
      }

      .file-placeholder-icon i {
        font-size: 3rem;
        color: var(--primary-color) !important;
      }

      .file-placeholder {
        word-break: break-all;
      }

      .file-wrapper {
        text-align: left;
      }

      .image-wrapper {
        text-align: left;
      }

      .image-upload-button,
      .file-upload-button {
        max-width: 100% !important;
        padding-top: 0.4em;
        line-height: 1.5em;
        width: auto;
        font-size: 0.8rem;
        display: inline-flex;
        margin-bottom: 0.75rem;
        width: max-content;
      }

      .image-wrapper {
        max-height: 200px;
        overflow-y: scroll;
      }
    `
  ],
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    QuestionLabelContentComponent,
    FileUploadQuestionInputComponent,
    MultiImageUploadQuestionInputComponent,
    QuestionHintComponent,
    NgFor,
    CoreComponentsAngularModule,
    ImageAssetComponent,
    HttpImageComponent
  ]
})
export class ImageOrFileUploadQuestionComponent extends BaseQuestionComponent implements OnChanges {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<any>;
  @Input() i18ns: I18nKeys;
  @ViewChild('imageUploadButton') input;
  type: string;
  images: ImageAsset[];
  imageAssetType = ImageAssetType;
  files: any[] = [];
  uploadedImages: any[] = [];

  private untilDestroyed = untilDestroyed();

  constructor(
    private spinnerService: NgxSpinnerService,
    private imageService: ImageManipulationService,
    private imageAssetFactory: ImageAssetFactory,
    private scrollService: ScrollService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    /**
     * Check for a non-array value
     */
    if (this.value && !(this.value instanceof Array)) {
      setTimeout(() => {
        this.form?.get(this.question.key)?.patchValue([this.value]);
      });
    }
    this.images = this.imageAssetFactory.create(this.question.image, this.form);
    this.files =
      this.form.get(this.question.key) && this.form?.get(this.question.key)?.value
        ? this.form?.get(this.question.key)?.value.filter(v => typeof v !== 'string')
        : [];
    this.uploadedImages =
      this.form.get(this.question.key) && this.form?.get(this.question.key)?.value
        ? this.form?.get(this.question.key)?.value.filter(v => typeof v === 'string')
        : [];
    this.scrollService.scrollObservable.pipe(this.untilDestroyed()).subscribe(documentId => {
      if (documentId === this.question.key) {
        this.input.nativeElement.scrollIntoView();
      }
    });
  }

  get isInvalid() {
    return this.form?.get(this.question.key)?.touched && this.form?.get(this.question.key)?.invalid;
  }

  get value() {
    return this.form?.get(this.question.key)?.value;
  }

  get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.form?.get(this.question.key)?.value) &&
      showIfQuestionPopulated &&
      this.question.show !== false
    );
  }

  get hasUploadedImages() {
    return this.uploadedImages.length > 0;
  }

  get hasUploadedFiles() {
    return this.files.length > 0;
  }

  async onImageUploaded($event: string) {
    if (this.question.sort && this.question.sort === 'desc') {
      this.uploadedImages.unshift($event);
    } else {
      this.uploadedImages.push($event);
    }
    this.form?.get(this.question.key)?.patchValue([...this.files, ...this.uploadedImages]);
    await this.spinnerService.hide();
  }

  async onFileUploaded($event: { name: string; data: string }) {
    const { name, data } = $event;
    const index = this.files.indexOf(x => x.name === name);
    if (index > -1) {
      this.files.splice(index, 1);
    }

    if (this.question.sort && this.question.sort === 'desc') {
      this.files.unshift($event);
    } else {
      this.files.push($event);
    }
    this.form?.get(this.question.key)?.patchValue([...this.files, ...this.uploadedImages]);
    await this.spinnerService.hide();
  }

  clearImage(index: number) {
    this.uploadedImages.splice(index, 1);
    this.form?.get(this.question.key)?.patchValue([...this.files, ...this.uploadedImages]);
  }

  clearFile(index: number) {
    this.files.splice(index, 1);
    this.form?.get(this.question.key)?.patchValue([...this.files, ...this.uploadedImages]);
  }
}
