import { BehaviorSubject } from 'rxjs';
import { DestroyRef, EventEmitter, inject, Injectable } from '@angular/core';
import { ModalQuestionParams, ModalQuestionViewModel } from './modal.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormUpdate } from '../question.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class ModalPresenter {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  load(
    vm: BehaviorSubject<ModalQuestionViewModel | null>,
    params: ModalQuestionParams,
    formChange: EventEmitter<FormUpdate>
  ) {
    const form = this.getForm(params);
    this.subscribeToFormChanges(form, formChange, params);
    vm.next({
      ...params,
      form
    });
  }

  private getForm(params: ModalQuestionParams): FormGroup {
    const { key, value, required } = params;
    return new FormGroup({
      [key]: new FormControl(value, required ? [Validators.required] : [])
    });
  }

  private subscribeToFormChanges(form: FormGroup, formChange: EventEmitter<FormUpdate>, params: ModalQuestionParams) {
    form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      formChange?.emit({ key: params.key, value: value[params.key] });
    });
  }
}
