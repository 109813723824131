<jui-button
  [class.doc-manager__btn--progress]="inProgress"
  [disabled]="isDisabled"
  size="xs"
  color="secondary"
  class="doc-manager__btn"
  [loading]="inProgress"
  (click)="actionClick.emit()"
  [attr.data-qa]="qaHook"
  readOnlyDisable
>
  <span>{{ label }}</span>
</jui-button>
