import { Injectable } from '@angular/core';
import { SupportBarRepository } from './support-bar.repository';
import { SupportBarDataDm, SupportBarDataVm } from './support-bar.model';
import jsonview from '@pgrabovets/json-view';
import { ProjectStructureSupportModalComponent } from './project-structure-support-modal/project-structure-support-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Project } from '../../core/domain/project';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SupportBarPresenter {
  constructor(private repository: SupportBarRepository, private modalService: NgbModal) {}

  load(vmSubject$: BehaviorSubject<SupportBarDataVm>): void {
    this.repository.load((dm: SupportBarDataDm): void => {
      if (!dm) {
        vmSubject$.next(null);
      } else {
        vmSubject$.next({
          show: dm.show,
          instanceInformation: dm.instanceInformation,
          isAdminIcon: dm.instanceInformation?.isAdmin ? 'check': 'close',
          isSuperAdminIcon: dm.instanceInformation?.isSuperAdmin ? 'check': 'close',
          loading: dm.loading,
          projectStructure: dm.projectStructure,
          projectStructureButtonText: dm.projectStructureButtonText,
          changeProjectStatusButtonText: dm.changeProjectStatusButtonText,
          statusChangeInfoVm: {
            statusOptions: dm.statusOptions,
            statusChangeForm: dm.statusChangeForm,
            statusOptionsDropdownPlaceholder: dm.statusOptionsDropdownPlaceholder,
            statusChangeButtonText: dm.statusChangeButtonText,
            errorMessage: dm.errorMessage,
            changeStatusButtonDisabled:
              !dm.statusChangeForm.valid || !dm.statusChangeForm.get('selectedStatus').value || dm.state === 'transient'
          }
        });
      }
    });
  }

  openProjectStructureModal(projectStructure: Project): void {
    this.modalService.open(ProjectStructureSupportModalComponent, {
      backdrop: 'static',
      modalDialogClass: 'full-screen-modal'
    });
    try {
      const tree = jsonview.create(projectStructure);
      jsonview.render(tree, document.querySelector('.jsonPreview'));
      jsonview.toggleNode(tree);
    } catch (error) {
      console.error(error);
    }
  }

  handleProjectStatusChange(): void {
    this.repository.handleStatusFormUpdate();
  }

  async updateProjectStatus(projectId: string): Promise<void> {
    await this.repository.updateProjectStatus(projectId);
  }
}
