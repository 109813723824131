import { TitleCasePipe } from '@angular/common';
import { JumptechDate, JumptechDateSettings } from '@jump-tech-frontend/domain';
import { TranslocoService } from '@ngneat/transloco';
import { ProjectStatus } from '../admin/user-management/domain/types';
import { User } from '../core/domain/user';
import { checkIfExpression } from '../core/utils/filter';
import { Note } from '../project-detail/notes/note';
import { SearchCriteria } from './search-context';
import { get as _get } from 'lodash';
import { createRenderer } from '@jump-tech/lib-mustache-renderer';

export class SearchResult {
  isExport = false;
  params: {
    assignedTo?: string;
    assignedToDisplayName?: string;
    created_by?: string;
    created_by_name?: string;
    created_by_roles?: string[];
    created_by_team?: string;
    created_by_team_name?: string;
    created_by_teams?: string[];
    created_on?: string;
    delegate?: string;
    delegationChain?: string;
    email?: string;
    firstName?: string;
    id?: string;
    jobStatus?: string;
    jobs?: any[];
    lastName?: string;
    name?: string;
    owner?: string;
    owner_name?: string;
    phoneNumber?: string;
    address?: {
      postCode?: string;
    };
    postCode?: string;
    projectType?: string;
    relayLastProgress?: {
      progress?: string;
      progressDate?: string;
      cardLabel?: string;
      updateType?: string;
      isForm?: boolean;
    };
    scheduledDate?: string;
    scheduledDisplayDate?: string;
    subTenantIds?: string[];
    subTenantNames?: string[];
    status?: string;
    type?: string;
    tenant?: string;
    updated_on?: string;
    assigned_to_dates?: { date: string; from: string; to: string }[];
    assigned_to_team?: string;
    assigned_to_team_name?: string;
    notes?: Note[];
    dnoApprovedDate?: string;
    dnoRejectedDate?: string;
    applicationSentDate?: string;
    notificationSentDate?: string;
    dnoReasonForRejected?: string;
    dnoStatus?: string;
    documents?: string[];
    externalSources?: any;
  } = {};
  user?: User;
  states?: { status: string; label: string; showProgress: boolean }[];

  valueCache: Record<string, string> = {};

  constructor(
    private translocoService: TranslocoService,
    private criteria: SearchCriteria[],
    isExport: boolean,
    params: any,
    user?: User,
    states?: { status: string; label: string; showProgress: boolean }[]
  ) {
    this.isExport = isExport;
    this.params = params;
    Object.assign(this, { user, states });
  }

  getField(field: any, context?: any, maxLength?: number) {
    if (!Object.hasOwnProperty.call(this.valueCache, field)) {
      let value = '';
      for (const f of field.split('||')) {
        value = this.processFieldForDisplay(f.trim(), context);
        // Return the first matching item (if exists)
        if (value) {
          break;
        }
      }
      this.valueCache[field] = value;
    }

    if (maxLength && this.valueCache[field] && typeof this.valueCache[field] === 'string') {
      return `${this.valueCache[field].substring(0, maxLength)}...`;
    }

    return this.valueCache[field];
  }

  checkMagicGetter(paramsKey: string) {
    return this[paramsKey];
  }

  get(paramsKey: string) {
    return _get(this, paramsKey) || _get(this.params, paramsKey) || null;
  }

  /**
   * Returns specific targeted values from within an object which contains array(s).
   * Above example - Within the jobs array, get me the job of 'type' Site Visit, and then output the scheduledDate
   * @param paramsKey - Key of the field to be displayed - e.g. jobs[1].scheduledDate
   * @param context - Context from where to retrieve a property from within an array - e.g.
   * "context": {
   *  "1": {
   *    "type": {
   *      "$eq": "Site Visit"
   *    }
   *  }
   *}
   * Within the jobs array, get the job which is of type 'Site Visit' and output the scheduledDate.
   */
  getWithContext(paramsKey: string, context: any) {
    let target = this.params;
    const keyParts = paramsKey.split('.');

    for (const key of keyParts) {
      const properties = key.match(/(.+)\[([A-z0-9]+)\]/);
      // No properties means that this specific key is an object, NOT an array
      if (!properties) {
        target = target[key];
        continue;
      }

      const arrayKey = properties[1];
      const arrayItem = target[arrayKey];

      if (arrayItem === undefined) {
        return null;
      }

      // Look in the context object to find the matching checkIfExpression expression
      const expression = context[properties[2]];
      if (!expression) {
        return null;
      }
      const newTarget = arrayItem.find(item => {
        return checkIfExpression(expression, item);
      });
      if (!newTarget) {
        return null;
      }
      // Assign the new target and loop the next key of the keyParts
      target = newTarget;
    }
    return target;
  }

  /**
   * Retrieve and render (if necessary) the final outputted value
   * @param field - field 'key' to be displayed
   * @param context - optional context, required if the 'field' is targeting a value within an array
   */
  processFieldForDisplay(field: any, context?: any) {
    const renderer = createRenderer({
      timezone: JumptechDateSettings.defaultTimeZone,
      locale: JumptechDateSettings.defaultLocale
    });
    // Conditional mustache rendering
    if (field.match(/{{.}}/)) {
      return renderer.render(`{${field}}`, this.params);
    }
    const fieldToRetrieve = SearchResult.getFieldToRetrieve(field);

    // TODO: Refactor and remove
    const magicGetterValue = this.checkMagicGetter(fieldToRetrieve);
    if (magicGetterValue) {
      return magicGetterValue;
    }
    // Regex to check whether the field is targeting a- property within an array item
    const valueToDisplay = fieldToRetrieve.match(/(.+)\[([A-z0-9]+)]/)
      ? this.getWithContext(fieldToRetrieve, context)
      : this.get(fieldToRetrieve);
    if (!field.match(/^{/)) {
      // Field isn't a mustache template, or we used magic getter - just output the value
      return valueToDisplay;
    }
    let rendererView: any = this.params;
    if (valueToDisplay) {
      rendererView = { field: valueToDisplay };
      field = field.replace(fieldToRetrieve, 'field');
    }
    return renderer.render(`{${field}}`, rendererView).trim();
  }

  /**
   * Removes mustache formatting for a field
   * @param field - unformatted field which may be a mustache template variable
   * @private
   * @return - Field 'key' to be displayed, without any mustache formatting
   */
  private static getFieldToRetrieve(field: any): string {
    const fieldWithoutBraces = field.replace(/[{}]/g, '');
    return fieldWithoutBraces.split('|')[0].trim();
  }

  get id() {
    return this.params.id || '';
  }

  get tenant() {
    return this.params.tenant || '';
  }

  get name() {
    return [this.params.firstName, this.params.lastName, '-', this.params.email].filter(a => !!a).join(' ');
  }

  get postCode() {
    return this.params.postCode || this.params.address?.postCode || '';
  }

  get type() {
    return this.params.type || '';
  }

  get status() {
    let status = this.params.status;
    const state = (this.states || []).find(s => s.status === status);
    if (!state && status !== ProjectStatus.ARCHIVED) {
      return `${status} (${this.translocoService.translate('common.invalid')})`;
    }
    status = state?.label ?? status;
    let progressPercent;
    let progressStep;

    if (this.params.relayLastProgress) {
      const progressParts = this.params.relayLastProgress.progress.split('|');
      progressPercent = progressParts[0];
      progressStep = progressParts.length > 1 ? progressParts[1] : null;
    }

    return (
      new TitleCasePipe().transform(status) +
      (state?.showProgress && this.params.relayLastProgress
        ? progressStep
          ? ` ${this.translocoService.translate('common.step')} ${progressStep}`
          : ` (${progressPercent}% ${this.translocoService.translate('common.complete')})`
        : '')
    );
  }

  get created_by_name() {
    if (this.params.delegationChain && this.user) {
      const delegationParts = this.params.delegationChain.split('|');
      const userParentTenant = delegationParts[delegationParts.indexOf(this.user.tenant) - 1];
      if (userParentTenant) {
        return `${this.params.created_by_name} (${userParentTenant})`;
      }
    }
    return this.params.created_by_name || this.translocoService.translate('common.unknown');
  }

  get created_on() {
    const toAssignedTo = (this.params?.assigned_to_dates || []).find(atd => atd.to === this?.user?.tenant || null);
    return toAssignedTo?.date && toAssignedTo.date !== ''
      ? this.toDateTimeFormat(toAssignedTo.date)
      : this.toDateTimeFormat(this.params.created_on);
  }

  get assigned_to_date() {
    if (!this.params?.assigned_to_dates?.length) {
      return '';
    }
    const toAssignedTo = this.params.assigned_to_dates.find(atd => atd.from === this?.user?.tenant || null);
    return toAssignedTo?.date && toAssignedTo.date !== '' ? this.toDateTimeFormat(toAssignedTo.date) : '';
  }

  get updated_on() {
    return this.toDateTimeFormat(this.params.updated_on) || '';
  }

  get installer() {
    const subTenantNames = this.get('subTenantNames');
    const currentSubTenant = subTenantNames && subTenantNames.length && subTenantNames[0];
    if (this.get('delegationChain')) {
      const delegationParts = this.get('delegationChain').split('|');
      const userChildTenant = delegationParts[delegationParts.indexOf(this.user.tenant) + 1];
      if (userChildTenant) {
        return userChildTenant.toUpperCase() + (currentSubTenant ? ` (${currentSubTenant})` : '');
      }
    }
    return this.translocoService.translate('common.unassigned');
  }

  get isDelegated() {
    if (this.get('delegationChain')) {
      const delegationParts = this.get('delegationChain').split('|');
      const userChildTenant = delegationParts[delegationParts.indexOf(this.user.tenant) + 1];
      if (userChildTenant) {
        return userChildTenant;
      }
    }
    return null;
  }

  get scheduledDate() {
    if (!this.params.scheduledDate || this.params.scheduledDate === '') {
      return '';
    }
    return this.toDateTimeFormat(this.params.scheduledDate);
  }

  get installationDate() {
    if (!this.params?.jobs?.length) {
      return '';
    }
    if (this.params.jobs.length === 1) {
      return this.params.jobs[0]?.scheduledDate ? this.toDateTimeFormat(this.params.jobs[0]?.scheduledDate) : '';
    }

    const job = this.params.jobs.find(job => job.jobTypeCategory === 'Installation');
    return job?.scheduledDate ? this.toDateTimeFormat(job?.scheduledDate) : '';
  }

  /**
   * Return the scheduled date only if the project status is SCHEDULED.
   */
  get scheduledDateIfScheduledStatus() {
    if (this.params.status !== ProjectStatus.SCHEDULED) {
      return '';
    }
    return this.scheduledDate;
  }

  get lastNote() {
    const note = this.params?.notes?.[this.params.notes.length - 1]?.note || '';
    return note.replace(/[\n,]/g, '');
  }

  get lastNoteDate() {
    return this.params?.notes?.[this.params.notes.length - 1]?.created_on
      ? this.toDateTimeFormat(this.params.notes[this.params.notes.length - 1].created_on)
      : '';
  }

  get lastNoteUser() {
    return this.params?.notes?.[this.params.notes.length - 1]?.created_by_name || '';
  }

  get documents() {
    return (
      this.params?.externalSources?.documentPack?.documents
        .map(d => d.name)
        .sort((a, b) => this._documentRelevanceSort(this.criteria || [], a, b))
        .join(', ') || []
    );
  }

  /**
   *    dnoApprovedDate?: string;
   *    dnoRejectedDate?: string;
   *    applicationSentDate?: string;
   *    notificationSentDate?: string;
   *    dnoReasonForRejected?: string;
   *    dnoStatus?: string;
   */
  get dnoStatus() {
    const {
      dnoApprovedDate,
      dnoRejectedDate,
      applicationSentDate,
      notificationSentDate,
      dnoReasonForRejected,
      dnoStatus
    } = this.params;

    if (notificationSentDate) {
      return this.translocoService.translate('dashboard.search.enaNotificationSubmitted');
    }
    if (dnoStatus === 'APPROVED' && (!dnoRejectedDate || dnoApprovedDate > dnoRejectedDate)) {
      return this.translocoService.translate('dashboard.search.dnoApproved');
    }
    if (dnoStatus === 'REJECTED' && (!dnoApprovedDate || dnoRejectedDate > dnoApprovedDate)) {
      return `${this.translocoService.translate('dashboard.search.dnoRejected')} (${dnoReasonForRejected})`;
    }
    return (applicationSentDate && this.translocoService.translate('dashboard.search.enaApplicationSubmitted')) || '';
  }

  private _documentRelevanceSort(criteria, a, b): number {
    for (const c of criteria) {
      if (
        (c?.['key'] !== 'externalSources.documentPack.documents.name' &&
          c?.['key'] !== 'externalSources.documentPack.documents.searchableKey') ||
        !c?.['value'] ||
        !Array.isArray(c?.['value'])
      ) {
        continue;
      }

      if (c?.['value'].includes(a)) {
        return -1;
      }
      if (c?.['value'].includes(b)) {
        return 1;
      }
    }

    return 0;
  }

  toDateTimeFormat(date: string): string {
    const dateTime = JumptechDate.from(date);
    if (!dateTime.isValid) {
      return '';
    }
    if (this.isExport) {
      return dateTime.toExportDateTimeFormat();
    }
    return dateTime.toDateTimeFormat();
  }
}
