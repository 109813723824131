import { DestroyRef, inject, Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { LOGIN_PATH } from '../../app.routes';
import { AuthenticationService } from './authentication.service';
import { ApiService } from '../../core/api.service';
import { User } from '../../core/domain/user';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private destroyRef: DestroyRef = inject(DestroyRef);
  private userSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public userObservable: Observable<User> = this.userSubject.asObservable();

  get currentUser() {
    return this.userSubject.value;
  }

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.authenticationService.authObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async user => {
      if (!user) {
        this.userSubject.next(null);
      }
      const userName = user?.username;
      if (userName != null) {
        await this.updateUser(userName);
      }
    });
  }

  async updateUser(userName?: string) {
    const user: User = await firstValueFrom(
      this.apiService.getUser(userName || this.authenticationService.user.username, false)
    );
    if (!user) {
      await this.router.navigate([LOGIN_PATH], { replaceUrl: true });
    } else {
      this.userSubject.next(user);
    }
  }

  resetPassword() {
    const userName = this.userSubject.getValue().userName;
    return this.apiService.resetPassword(userName);
  }

  /**
   * @returns null if user not found or error, otherwise the user
   */
  async findUserById(id: string): Promise<User | null> {
    try {
      return await firstValueFrom(this.apiService.findUser(id));
    } catch (e) {
      return null;
    }
  }
}
