export interface ElasticSearchAuditLog {
  tenant: string;
  subTenant?: string;
  id: string;
  projectId: string;
  projectType: string;
  status: string;
  eventName: string;
  created_on: string;
  created_by: string;
  timestamp: string;
  type: string;
  jobId?: string;
  jobType?: string;
  resourceId?: string;
  resourceName?: string;
  oldValue?: string;
  newValue?: string;
  data: any;
}
