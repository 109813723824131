<div class="schedule-more-details__heading">{{ vm.jobType }}</div>

<div class="schedule-more-details__row-split">
  <div class="schedule-more-details__row-item">
    <span class="schedule-more-details__icon">
      <jui-icon name="house" color="secondary" size="sm"></jui-icon>
    </span>
    <span>{{ vm.addressDisplay }}</span>
  </div>
  <div class="schedule-more-details__row-item">
    <span class="schedule-more-details__icon">
      <jui-icon name="phone" color="secondary" size="sm"></jui-icon>
    </span>
    <span>{{ vm.phoneNumber }}</span>
  </div>
  <div class="schedule-more-details__row-item">
    <span class="schedule-more-details__icon">
      <jui-icon name="email" color="secondary" size="sm"></jui-icon>
    </span>
    <span>{{ vm.email }}</span>
  </div>
</div>

<div class="schedule-more-details__divider"></div>
