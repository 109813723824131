@if (vm$ | async; as vm) {
  @if (!vm.hidden) {
    <div class="schedule-event-selected-job-details">
      <schedule-event-selected-job-details-actions
        (close)="handleClose()"
        (hide)="handleHide()"
        (goToProject)="handleGoToProject()"
        (scheduleNow)="handleScheduleNow()"
        (checkCollisions)="handleCheckCollisions()"
        [vm]="vm.actions"
      >
      </schedule-event-selected-job-details-actions>
      <schedule-event-selected-job-details-job-info [vm]="vm.info"></schedule-event-selected-job-details-job-info>
      <div class="schedule-event-selected-job-details__content">
        <schedule-event-selected-job-details-date-time-form
          [vm]="vm.mainJobTime"
          (handleDateTimeChange)="handleDateTimeChange($event)"
        >
        </schedule-event-selected-job-details-date-time-form>
        <div class="schedule-event-selected-job-details__sub-heading">
          {{ vm.i18nTradesPeopleSlotHeader }}
        </div>
        <schedule-event-selected-job-details-assigned-engineers
          [vm]="vm.assignedTradesPeople"
          (removeEngineer)="removeEngineer($event)"
          (setLeadEngineer)="setLeadEngineer($event)"
          (slotDateTimeChange)="handleSlotDateTimeChange($event)"
        >
        </schedule-event-selected-job-details-assigned-engineers>
      </div>
    </div>
  }
}
