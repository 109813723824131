<input
  class="upload-file-input"
  id="generalUpload"
  style="display: none"
  type="file"
  (change)="onFileUploadEvent($event)"
  [accept]="attachmentsHelper.getAcceptedMimeTypes()"
/>

<table class="styled-table">
  <thead>
    <tr>
      <td class="no-wrap">{{ 'common.fileName' | transloco }}</td>
      <td class="no-wrap">{{ 'common.uploadedBy' | transloco }}</td>
      <td class="no-wrap">{{ 'common.uploadDate' | transloco }}</td>
      <td class="action-row-header">{{ 'common.actions' | transloco }}</td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let attachment of attachments | orderBy: 'uploadDate':'asc'">
      <td>{{ attachmentsHelper.getSanitisedFilename(attachment.key) }}</td>
      <td>
        {{
          (attachment.uploadedByLabel || ('common.unknown' | transloco)) +
            (attachment.uploadedByTenant && attachment.uploadedByTenant !== tenant
              ? ' (' + attachment.uploadedByTenant + ')'
              : '')
        }}
      </td>
      <td class="no-wrap">{{ millisToDate(attachment.uploadDate) }}</td>
      <td class="no-wrap">
        <div class="row-actions">
          <jui-button
            class="row-actions__item"
            color="secondary"
            size="xs"
            *ngIf="attachment.url"
            (click)="onFileDownloadEvent(attachment)"
            title="{{ 'common.download' | transloco }}"
            [attr.data-qa]="'downloadAttachmentButton'"
            >{{ 'common.download' | transloco }}</jui-button
          >

          <jui-button
            readOnlyDisable
            class="row-actions__item"
            size="xs"
            color="danger"
            display="ghost"
            *ngIf="hasEditFileAccess(attachment)"
            (click)="onFileDeleteEvent(attachment)"
            title="{{ 'common.delete' | transloco }}"
            [attr.data-qa]="'deleteAttachmentButton'"
            >{{ 'common.delete' | transloco }}</jui-button
          >
        </div>
      </td>
    </tr>
  </tbody>
</table>
<div class="main-action">
  <jui-button
    readOnlyDisable
    size="sm"
    [attr.data-qa]="'uploadAttachmentButton'"
    *ngIf="hasUploadFileAccess()"
    (click)="clickFile('generalUpload')"
    ><span class="material-icons" slot="icon">cloud_upload</span>{{ 'common.upload' | transloco }}</jui-button
  >
</div>
<div class="attachments-progress" *ngIf="uploadInProgress">
  <app-progress-bar
    class="pb-2"
    [fileName]="attachmentsHelper.getSanitisedFilename(uploadInProgress.fileName)"
    [progress]="uploadInProgress.progress"
  >
  </app-progress-bar>
</div>

<div *ngIf="customAttachments.length">
  <hr />
  <div class="mb-2">{{ 'projectDetail.projectSpecificAttachments' | transloco }}</div>
  <div *ngFor="let customAttachment of customAttachments">
    <div class="custom-attachment-wrapper">
      <jui-button size="sm" (click)="projectAttachmentElement.click()"
        ><span class="material-icons" slot="icon">upload</span>{{ 'common.upload' | transloco }}</jui-button
      >
      <div><i class="material-icons material-icons-md" (click)="projectAttachmentElement.click()">cloud_upload</i></div>
      <div>
        {{ 'common.upload' | transloco }} {{ customAttachment.label }}
        {{ attachmentsHelper.getFileFormatsString(customAttachment.acceptedFileTypes) }}
      </div>
    </div>
    <input
      class="upload-file-input"
      [id]="customAttachment.id"
      style="display: none"
      type="file"
      (change)="onFileUploadEvent($event, customAttachment)"
      [accept]="attachmentsHelper.getAcceptedMimeTypes()"
      #projectAttachmentElement
    />
  </div>
</div>
