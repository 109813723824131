@if (supportBarVm$ | async; as vm) { @if (vm.show) {
<div class="support-bar">
  <span class="support-bar__item"
    >Support User:<span class="support-bar__data">{{ vm.instanceInformation.userName }}</span></span
  >
  <span class="support-bar__item"
    >Tenant:<span class="support-bar__data">{{ vm.instanceInformation.tenant }}</span></span
  >
  <span class="support-bar__item"
    >Team:<span class="support-bar__data">{{ vm.instanceInformation.team }}</span></span
  >
  <span class="support-bar__item"
    >isAdmin:
    <span class="support-bar__data">
      <jui-icon size="xs" color="current" [name]="vm.isAdminIcon"></jui-icon>
    </span>
  </span>
  <span class="support-bar__item"
    >isSuperAdmin:
    <span class="support-bar__data">
      <jui-icon size="xs" color="current" [name]="vm.isSuperAdminIcon"></jui-icon>
    </span>
  </span>
  @if (vm.loading) {
  <span class="support-bar__item--loading">
    <jui-skeleton-loader color="primary" height="14px" width="200px"></jui-skeleton-loader>
  </span>
  } @if (vm.projectStructure) {
  <span class="support-bar__item">
    <jui-button size="xs" color="primary" (click)="openProjectStructureModal(vm.projectStructure)">
      <span slot="icon" class="material-icons">code</span>
      {{ vm.projectStructureButtonText }}</jui-button
    >
  </span>
  @if (vm.instanceInformation.isSuperAdmin) {
  <span class="support-bar__item">
    <jui-button size="xs" color="primary" [ngbPopover]="statusChangePopover" [autoClose]="'outside'">{{
      vm.changeProjectStatusButtonText
    }}</jui-button>
  </span>
  } }
  <ng-template #statusChangePopover>
    <status-change-popover
      [vm]="vm.statusChangeInfoVm"
      (projectStatusChanged)="handleProjectStatusChange()"
      (changeStatusButtonClicked)="updateProjectStatus()"
    ></status-change-popover>
  </ng-template>
</div>
} }
