import { DateTime, Interval, Settings } from 'luxon';
import { JumptechDateFormats } from './jumptech-date-formats';
import { JumptechDateSettings } from './jumptech-date-settings';
import {
  DateObject,
  isDate,
  isDateObject,
  JumptechDateArithmeticOptions,
  JumptechDateDiffOptions,
  JumptechDateEqualityOptions,
  JumptechDateFromFormatOptions,
  JumptechDateOfUnit,
  JumptechDateUnit,
  JumptechDateUntilOptions,
  JumptechDuration,
  JumptechDurationExtended,
  SupportedDateFormats,
  SupportedDates,
  SupportedExportDateFormats
} from './types';

export class JumptechDate {
  date: DateTime;

  private constructor(date: DateTime) {
    this.date = date;
  }

  public static from(
    date: SupportedDates | undefined | null,
    options?: JumptechDateFromFormatOptions
  ): JumptechDate | null {
    if (!date) {
      return new JumptechDate(DateTime.invalid('No valid date passed', '`date` must have a value'));
    }

    let subject: DateTime | undefined;

    if (isDateObject(date)) {
      const now = DateTime.utc();
      subject = DateTime.fromObject(
        {
          day: date.day || now.day,
          month: date.month || now.month,
          year: date.year || now.year,
          hour: date.hour || 0,
          minute: date.minute || 0,
          second: date.second || 0,
          millisecond: date.millisecond || 0
        },
        {
          locale: options?.sourceLocale,
          zone: options?.sourceZone
        }
      );
    }

    if (isDate(date)) {
      subject = DateTime.fromJSDate(date, { zone: options?.sourceZone });
    }

    if (typeof date === 'number') {
      if (date.toString().length === 13) {
        subject = DateTime.fromMillis(date, { locale: options?.sourceLocale, zone: options?.sourceZone ?? 'utc' });
      } else {
        subject = DateTime.fromSeconds(date, { locale: options?.sourceLocale, zone: options?.sourceZone ?? 'utc' });
      }
    }

    if (typeof date === 'string') {
      if (options?.sourceFormat) {
        subject = DateTime.fromFormat(date, options.sourceFormat, {
          locale: options?.sourceLocale,
          zone: options?.sourceZone ?? 'utc'
        });
      } else {
        subject = DateTime.fromISO(date, { locale: options?.sourceLocale, zone: options?.sourceZone ?? 'utc' });
      }
    }

    subject = subject
      ?.setZone(options?.targetZone || Settings.defaultZone)
      .setLocale(options?.targetLocale || Settings.defaultLocale);

    return new JumptechDate(subject);
  }

  /*
   Best Guess Only
  */
  public static asFormat(format: SupportedDateFormats | SupportedExportDateFormats): string {
    let dateFormat = '';

    const options = JumptechDateFormats.getFormatOptions(format);

    const zone = JumptechDateSettings.defaultTimeZone;
    const locale = JumptechDateSettings.defaultLocale;
    const dateTimeFormat = new Intl.DateTimeFormat(locale, {
      ...options,
      timeZone: zone
    });

    const dateParts = dateTimeFormat.formatToParts();
    for (let i = 0; i < dateParts.length; i++) {
      let replacement = '';
      switch (dateParts[i].type) {
        case 'year':
          replacement = 'Y';
          break;
        case 'month':
          replacement = 'M';
          break;
        case 'day':
          replacement = 'D';
          break;
        case 'hour':
          replacement = 'h';
          break;
        case 'minute':
          replacement = 'm';
          break;
        case 'second':
          replacement = 's';
          break;
        case 'dayPeriod':
          replacement = 'a';
          dateParts[i].value = 'a';
          break;
        case 'weekday':
          replacement = 'd';
          break;
        case 'timeZoneName':
          replacement = 'Z';
          break;
        case 'literal':
          replacement = dateParts[i].value;
          break;
      }

      const part = dateParts[i].value;
      dateFormat += part.replace(/./gi, replacement).slice(0, part.length > 4 ? 4 : part.length);
    }
    return dateFormat;
  }

  public static now(zone?: string): JumptechDate {
    if (zone) {
      return new JumptechDate(DateTime.utc().setZone(zone));
    }
    return new JumptechDate(DateTime.now());
  }

  /*
    Varies per locale but in general is 21, March 2024
  */
  public toDateFormat(): string {
    if (!this.isValid()) {
      return '!!!!';
    }
    const options = JumptechDateFormats.getFormatOptions('Date');
    return this.date.toLocaleString(options, { locale: JumptechDateSettings.defaultLocale });
  }

  /*
    Varies per locale but in general is 12:00 GMT
  */
  public toTimeFormat(includeTimeZone = true): string {
    if (!this.isValid()) {
      return '!!!!';
    }

    const options = JumptechDateFormats.getFormatOptions('Time');
    if (!includeTimeZone) {
      delete options.timeZoneName;
    }

    return this.date.toLocaleString(options, { locale: JumptechDateSettings.defaultLocale });
  }

  /*
    Varies per locale but in general is 21, March 2024 at 12:00 GMT
  */
  public toDateTimeFormat(): string {
    if (!this.isValid()) {
      return '!!!!';
    }
    const options = JumptechDateFormats.getFormatOptions('DateTime');
    return this.date.toLocaleString(options, { locale: JumptechDateSettings.defaultLocale });
  }

  /*
  Varies per locale but in general is 21, March 2024 at 12:00 GMT
*/
  public toTimestampFormat(): string {
    if (!this.isValid()) {
      return '!!!!';
    }
    const options = JumptechDateFormats.getFormatOptions('Timestamp');
    return this.date.toLocaleString(options, { locale: JumptechDateSettings.defaultLocale });
  }

  public toExportDateFormat(): string {
    if (!this.isValid()) {
      return '!!!!';
    }
    const options = JumptechDateFormats.getFormatOptions('ExportDate');
    return this.date.toLocaleString(options, { locale: JumptechDateSettings.defaultLocale });
  }

  public toExportDateTimeFormat(): string {
    if (!this.isValid()) {
      return '!!!!';
    }
    const options = JumptechDateFormats.getFormatOptions('ExportDateTime');
    const result = this.date.toLocaleString(options, { locale: JumptechDateSettings.defaultLocale });
    return result.replace(/, /g, ' ');
  }

  /**
   * @deprecated - avoid usage - prefer to{ExactFormats} for consistency
   * @see JumptechDate.toDateFormat
   * @see JumptechDate.toTimeFormat
   * @see JumptechDate.toDateTimeFormat
   * @see JumptechDate.toExportDateFormat
   * @see JumptechDate.toExportDateTimeFormat
   */
  public toCustomFormat(options: Intl.DateTimeFormatOptions) {
    if (!this.isValid()) {
      return '!!!!';
    }
    return this.date.toLocaleString(options);
  }

  public toJsDate(): Date | null {
    if (!this.isValid()) {
      return null;
    }
    return this.date.toJSDate();
  }

  public toIso(): string | null {
    if (!this.isValid()) {
      return '!!!!';
    }
    return this.date.toUTC().toISO();
  }

  public toDateObject(): DateObject {
    if (!this.isValid()) {
      return {};
    }
    return {
      year: this.date.year,
      month: this.date.month,
      day: this.date.day,
      hour: this.date.hour,
      minute: this.date.minute,
      second: this.date.second,
      millisecond: this.date.millisecond
    };
  }

  public toMillis(): number {
    if (!this.isValid()) {
      return NaN;
    }
    return this.date.toMillis();
  }

  public toSeconds(): number {
    if (!this.isValid()) {
      return NaN;
    }
    return this.date.toSeconds();
  }

  public isValid(): boolean {
    if (!this.date.isValid) {
      console.log('Invalid Date', this.date.invalidReason, this.date.invalidExplanation);
    }
    return this.date?.isValid;
  }

  public isWeekend(): boolean {
    if (!this.isValid()) {
      return false;
    }
    return this.date.isWeekend;
  }

  public plus(duration: JumptechDurationExtended, options?: JumptechDateArithmeticOptions): JumptechDate | null {
    if (!this.isValid()) {
      return this;
    }

    if (options?.businessDays) {
      let businessDaysLeft = Math.round(duration?.days ?? 0);
      let dateTime: DateTime = this.date;
      while (businessDaysLeft > 0) {
        dateTime = dateTime.plus({ days: 1 });

        if (!dateTime.isWeekend) {
          businessDaysLeft--;
        }
      }
      return dateTime ? new JumptechDate(dateTime) : null;
    }

    return new JumptechDate(this.date.plus(duration));
  }

  public minus(duration: JumptechDurationExtended, options?: JumptechDateArithmeticOptions): JumptechDate | null {
    if (!this.isValid()) {
      return this;
    }

    if (options?.businessDays) {
      let businessDaysLeft = Math.round(duration?.days ?? 0);
      let dateTime: DateTime = this.date;
      while (businessDaysLeft > 0) {
        dateTime = dateTime.minus({ days: 1 });

        if (!dateTime.isWeekend) {
          businessDaysLeft--;
        }
      }
      return dateTime ? new JumptechDate(dateTime) : null;
    }

    return new JumptechDate(this.date.minus(duration));
  }

  public startOf(unit: JumptechDateOfUnit): JumptechDate {
    if (!this.isValid()) {
      return this;
    }
    return new JumptechDate(this.date.startOf(unit));
  }

  public endOf(unit: JumptechDateOfUnit): JumptechDate {
    if (!this.isValid()) {
      return this;
    }
    return new JumptechDate(this.date.endOf(unit));
  }

  public diff(other: JumptechDate, options: JumptechDateDiffOptions): JumptechDuration {
    if (!this.date?.isValid || !other?.isValid) {
      return {};
    }

    const duration = this.date.diff(other.toDateTime(), options.units);
    return {
      years: duration.years,
      months: duration.months,
      days: duration.days,
      hours: duration.hours,
      minutes: duration.minutes,
      seconds: duration.seconds,
      milliseconds: duration.milliseconds
    };
  }

  public between(start: JumptechDate, end: JumptechDate): boolean {
    if (!this.isValid() || !start.isValid() || !end.isValid()) {
      return false;
    }
    const interval = Interval.fromDateTimes(start.toDateTime(), end.toDateTime());
    return interval.contains(this.date);
  }

  public until(end: JumptechDate, options: JumptechDateUntilOptions): JumptechDuration {
    if (!this.isValid() || !end.isValid()) {
      return {};
    }
    const interval = this.date.until(end.toDateTime()) as Interval;
    const duration = interval.toDuration(options.units);
    return {
      years: duration.years,
      quarters: duration.quarters,
      months: duration.months,
      weeks: duration.weeks,
      days: duration.days,
      hours: duration.hours,
      minutes: duration.minutes,
      seconds: duration.seconds,
      milliseconds: duration.milliseconds
    };
  }

  public equals(compare: JumptechDate, options?: JumptechDateEqualityOptions): boolean {
    if (!this.isValid() || !compare.isValid()) {
      return false;
    }

    if (!options?.unit) {
      return this.date === compare.toDateTime();
    }

    if (options?.unit === 'timestamp') {
      return this.date.toMillis() === compare.toMillis();
    }

    return this.get(options.unit) === compare.get(options.unit);
  }

  public get(unit: JumptechDateUnit): number {
    if (!this.isValid()) {
      return NaN;
    }
    return this.date.get(unit);
  }

  public set(values: DateObject): JumptechDate {
    if (!this.isValid()) {
      return this;
    }

    return new JumptechDate(this.date.set(values));
  }

  /*
    Do not expose underlying date lib
   */
  private toDateTime(): DateTime {
    return this.date;
  }
}
