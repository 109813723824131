import { QuestionViewModel } from '../question.model';
import { I18nKeys } from '@jump-tech-frontend/app-config';

export interface TextboxQuestionViewModel extends QuestionViewModel {
  hint?: string;
  type?:
    | 'text'
    | 'number'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'password'
    | 'tel'
    | 'url'
    | 'time'
    | 'week'
    | 'month';
  forceUpper?: boolean;
  translations?: Partial<I18nKeys>;
  formClass: string;
}

export interface TextboxQuestionParams {
  key: string;
  label: string;
  required?: boolean;
  inline?: boolean;
  type?:
    | 'text'
    | 'number'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'password'
    | 'tel'
    | 'url'
    | 'time'
    | 'week'
    | 'month';
  value?: string;
}
