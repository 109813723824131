import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScheduleMoreInfoVm } from '../../schedule.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgForOf, NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReactiveFormsModule } from '@angular/forms';
import { JobAssignment } from '../../../core/domain/job';

@Component({
  selector: 'schedule-event-more-details-assigned-engineers',
  templateUrl: 'schedule-event-more-details-assigned-engineers.component.html',
  styleUrls: ['../schedule-event-more-details/schedule-event-more-details.component.scss'],
  standalone: true,
  imports: [
    CoreComponentsAngularModule,
    NgIf,
    NgForOf,
    NgSelectModule,
    NgbDatepickerModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule
  ]
})
export class ScheduleEventMoreDetailsAssignedEngineersComponent {
  @Input() vm: ScheduleMoreInfoVm;
  @Output() onSetLeadEngineer: EventEmitter<JobAssignment> = new EventEmitter<JobAssignment>();
  @Output() onRemoveEngineer: EventEmitter<JobAssignment> = new EventEmitter<JobAssignment>();

  constructor() {}
}
