import { Component, Input, OnInit } from '@angular/core';
import { BasePathwayLayout } from './base-pathway.layout';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-now-date-question',
  template: `
    <div *ngIf="show">
      <label class="form-label" [for]="question.key">{{ question.label }}</label>
      <input class="form-control" [id]="question.key" [value]="value" [readOnly]="true" type="date" />
    </div>
  `,
  styles: [``],
  standalone: true,
  imports: [NgIf]
})
export class NowDateQuestionComponent extends BasePathwayLayout implements OnInit {
  @Input() form;
  @Input() question: any;
  @Input() setDefault?: boolean;

  value: any = null;

  ngOnInit(): void {
    if (this.setDefault) {
      const now = new Date().toJSON();
      this.value = now.slice(0, 10);
      this.form.get(this.question.key).patchValue(now);
    }
  }

  get show() {
    const show = super.show;
    if (!show && !this.question.readOnly) {
      this.form.get(this.question.key).disable();
    } else {
      this.form.get(this.question.key).enable();
    }
    return show;
  }
}
