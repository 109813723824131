import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ImageAsset, ImageAssetFactory, ImageAssetType } from '../../domain/card';
import { OcrActionRegex } from '../../domain/card-actions/ocr.action';
import { ImageError, ImageManipulationService } from '../image-manipulation.service';
import { OcrService, TextDetection } from '../ocr.service';
import { BaseQuestionComponent } from '../question.component';
import { ScrollService } from '../scroll.service';
import { untilDestroyed } from '@jump-tech-frontend/angular-common';
import { QuestionFormErrorComponent } from '@jump-tech-frontend/question-components';
import { HttpImageComponent } from '../http-image.component';
import { ImageAssetComponent } from '../image-asset.component';
import { QuestionHintComponent } from '@jump-tech-frontend/question-components';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { QuestionLabelContentComponent } from '@jump-tech-frontend/question-components';
import { NgIf } from '@angular/common';
import { I18nKeys } from '@jump-tech-frontend/app-config';

@Component({
  selector: `crds-question-ocr-image-upload`,
  template: `
    <div *ngIf="show && form" [formGroup]="form">
      <label *ngIf="question.label" class="form-label" [attr.for]="question.key" [attr.data-qa]="question.key">
        <question-label-content [question]="question" [isInvalid]="isInvalid" [i18ns]="i18ns"></question-label-content>
      </label>

      <!-- QUESTION INPUT -->
      <input
        id="ocr"
        class="imageCapture"
        type="file"
        style="display:none;"
        (change)="setFromOcr($event)"
        accept="image/*"
      />
      <jui-button (click)="clickFile()" size="lg" expand #ocrImageUploadButton>
        {{ i18ns.uploadImage }}<i slot="icon" class="material-icons button-icon">linked_camera</i>
      </jui-button>

      <!-- QUESTION EXAMPLE IMAGE OR USER UPLOADED -->
      <div class="image-wrapper">
        <question-hint [question]="question"></question-hint>
        <div *ngIf="form.get(question.key).value === null">
          <crds-image-asset [images]="images" [key]="question.key"></crds-image-asset>
        </div>
        <div *ngIf="form.get(question.key).value !== null">
          <crds-http-image [id]="question.key + '_temp'" [src]="getImageValue()"></crds-http-image>
        </div>
      </div>

      <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" type="line-scale"></ngx-spinner>
      <question-form-error
        *ngIf="uploadError"
        [message]="uploadError"
        [i18ns]="i18ns"
        class="file-upload-error"
      ></question-form-error>
    </div>
  `,
  styles: [
    `
      img[id$='_temp'] {
        width: 100%;
        max-height: 300px;
      }
    `
  ],
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    QuestionLabelContentComponent,
    CoreComponentsAngularModule,
    QuestionHintComponent,
    ImageAssetComponent,
    HttpImageComponent,
    NgxSpinnerModule,
    QuestionFormErrorComponent
  ]
})
export class OcrImageUploadQuestionComponent extends BaseQuestionComponent implements OnChanges {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<any>;
  @Input() i18ns: I18nKeys;
  @ViewChild('ocrImageUploadButton') input;
  type: string;
  images: ImageAsset[];
  imageAssetType = ImageAssetType;
  uploadError: string;
  imageError: ImageError | null;

  private untilDestroyed = untilDestroyed();

  constructor(
    private spinnerService: NgxSpinnerService,
    private imageService: ImageManipulationService,
    private imageAssetFactory: ImageAssetFactory,
    private ocrService: OcrService,
    private scrollService: ScrollService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    this.images = this.imageAssetFactory.create(this.question.image, this.form);
    this.scrollService.scrollObservable.pipe(this.untilDestroyed()).subscribe(documentId => {
      if (documentId === this.question.key) {
        this.input.nativeElement.scrollIntoView();
      }
    });
  }

  get isInvalid() {
    return this.form?.get(this.question.key)?.touched && this.form?.get(this.question.key)?.invalid;
  }

  get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }

  clickFile() {
    (document.getElementsByClassName('imageCapture')[0] as HTMLElement).click();
  }

  async setFromOcr(event: any) {
    this.clearOcrTarget();
    const file: File = event.target.files[0];
    if (!file) {
      return; // User cancelled
    }
    this.imageError = this.imageService.checkFileTypeAndSize(file);
    if (this.imageError) {
      this.uploadError = `${this.imageError.fileName}: ${this.i18ns[this.imageError.type]}`;
      return;
    }

    const targetFormControl = this.form.get(this.question.targetOcrField);

    await this.spinnerService.show();
    try {
      const tempImage = await this.imageService.resizeImageFromFile(file);
      const results = await this.ocrService.recognise(tempImage);
      const filteredResult = this.filterResults(results);
      this.patchOcrTarget(filteredResult);
      if (!filteredResult?.Geometry) {
        return Promise.resolve(tempImage);
      }
      const croppedImage = await this.imageService.cropImage(tempImage, filteredResult);
      targetFormControl.patchValue([croppedImage]);
      this.resetFileInput();
    } catch (error) {
      console.log('Failed image upload', error);
      // Cannot use instanceof
      if (error.name === 'ImageUploadError') {
        this.uploadError = this.i18ns.uploadImageError;
      }
    } finally {
      await this.spinnerService.hide();
    }
  }

  private filterResults(results: TextDetection[]): TextDetection {
    let returnedResult: TextDetection = {};

    this.question?.regexs?.forEach((regex: OcrActionRegex) => {
      switch (regex.type) {
        case 'standard': {
          const standardFilteredResults = results.filter(result => result.Type === 'WORD');
          const standardResult = standardFilter(standardFilteredResults, regex);
          if (standardResult) {
            returnedResult = standardResult;
          }
          break;
        }
        case 'split': {
          const splitFilteredResults = results.filter(result => result.Type === 'LINE');
          const splitResult = splitFilter(splitFilteredResults, regex);
          if (splitResult) {
            returnedResult = splitResult;
          }
          break;
        }
      }
    });

    if (!returnedResult.DetectedText) {
      // Check for "S"
      const containsSIndex = results.findIndex(result => result.DetectedText === 'S');
      if (containsSIndex > -1) {
        let lastResultIndex = 4;
        returnedResult = {
          DetectedText: ''
        };
        let targetResults = results.slice(containsSIndex + 1, containsSIndex + 5);
        targetResults = targetResults
          .sort((a, b) => {
            const aX = a.Geometry?.Polygon[0].X ?? 0;
            const bX = b.Geometry?.Polygon[0].X ?? 0;
            return aX - bX;
          })
          .filter(result => /^[0-9 ]+$/.test(result.DetectedText ?? ''));
        for (let i = 0; i < targetResults.length; i++) {
          returnedResult.DetectedText += targetResults[i]?.DetectedText?.replace(/\s/g, '') ?? '';
          if (returnedResult?.DetectedText?.length && returnedResult.DetectedText.length > 12) {
            lastResultIndex = i;
            break;
          }
        }
        returnedResult.Geometry = {
          Polygon: [
            {
              X: targetResults[0].Geometry?.Polygon[0].X ?? 0,
              Y: targetResults[0].Geometry?.Polygon[0].Y ?? 0
            },
            {
              X: targetResults[lastResultIndex].Geometry?.Polygon[1].X ?? 0,
              Y: targetResults[lastResultIndex].Geometry?.Polygon[1].Y ?? 0
            },
            {
              X: targetResults[lastResultIndex].Geometry?.Polygon[2].X ?? 0,
              Y: targetResults[lastResultIndex].Geometry?.Polygon[2].Y ?? 0
            },
            {
              X: targetResults[0].Geometry?.Polygon[3].X ?? 0,
              Y: targetResults[0].Geometry?.Polygon[3].Y ?? 0
            }
          ]
        };
      }
    }

    return returnedResult;
  }

  private patchOcrTarget(result: TextDetection) {
    const target = this.form.get(this.question.targetOcr);
    target?.patchValue(result?.DetectedText?.replace(/ /g, '') || '');
  }

  private clearOcrTarget() {
    this.form.get(this.question.targetOcr)?.reset('');
  }

  resetFileInput() {
    const input = document.getElementById('ocr');
    input['value'] = '';

    if (!/safari/i.test(navigator.userAgent)) {
      input['type'] = '';
      input['type'] = 'file';
    }
  }

  getImageValue(): string {
    const value = this.form?.get(this.question.key)?.value;
    return Array.isArray(value) ? value[0] : value;
  }
}

/**
 * Attempt to find the index of the first pattern in the results then try to match the subsequent
 * results against the subsequent patterns.
 * @returns TextDetection | null
 * @param results
 * @param regex
 */
const splitFilter = (results: TextDetection[], regex: OcrActionRegex): TextDetection | null => {
  let match: TextDetection = null;

  results.forEach(result => {
    if (result.DetectedText.match(regex.patterns[0]) !== null) {
      match = result;
    }
  });

  return match;
};

const standardFilter = (results: TextDetection[], regex: OcrActionRegex): TextDetection | null => {
  const filteredResults = results.filter(result => result.DetectedText.match(regex.patterns[0]) !== null);
  return filteredResults.length ? filteredResults[0] : null;
};
