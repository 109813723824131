import { Component, Input } from '@angular/core';
import { ReadOnlyDisableDirective } from '@jump-tech-frontend/angular-common';
import { ScheduleV2Component } from '../../schedule/schedule-v2/schedule.component';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { ProjectScheduleActionsPresenter } from './project-schedule-actions.presenter';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleJobActionVm } from '../../schedule/utils/schedule-types';

@Component({
  selector: 'project-schedule-action-modal',
  styleUrls: ['project-schedule-actions.component.scss'],
  standalone: true,
  imports: [ScheduleV2Component, CoreComponentsAngularModule, CommonModule, ReadOnlyDisableDirective],
  providers: [ProjectScheduleActionsPresenter],
  template: `
    <div class="modal-header">
      <div>
        <span
          class="modal-header__action-label"
          [class.modal-header__action-label--reschedule]="this.data.isReschedule"
        >
          {{ this.data.modalTitleActionLabel }}
        </span>
        <span>{{ this.data.modalTitle }}</span>
      </div>
      <jui-icon name="close" color="high" size="sm" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      </jui-icon>
    </div>
    <div class="modal-content">
      <schedule-v2
        [selectedJob]="this.data.selectedJob"
        [actionId]="this.data.actionId"
        context="project"
        (scheduleSuccess)="onSuccess($event)"
      ></schedule-v2>
    </div>
  `
})
export class ProjectScheduleActionsModalComponent {
  @Input() data: ScheduleJobActionVm;

  constructor(public activeModal: NgbActiveModal) {}

  onSuccess(data): void {
    this.activeModal.close(data);
  }
}
