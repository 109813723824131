export type IfCheck = {
  $and?: IfDefinition[];
  $or?: IfDefinition[];
  $eq?: string;
  $ne?: string;
  $gt?: string;
  $lt?: string;
  $gte?: string;
  $lte?: string;
  $mr?: string;
  $nmr?: string;
  $in?: string;
  $nin?: string;
};

export type IfDefinition = {
  [key: string]: string | IfCheck | IfDefinition[];
};
