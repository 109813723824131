/** Regex to match against Moustache parameters.
 * @example
 * {{ installDate }}
 * {{{ installDate }}}
 * {{ installDate | data: 'DD-MM-YYYY HH:mm' }}
 * */
export const MOUSTACHE_RENDER_REGEX = /\{\{.+\}\}/;

/** Regex to match against Standard parameters.
 * @example
 * { installDate }
 * { installDate.nested }
 * { installDate.nested.value }
 * */
export const STANDARD_RENDER_REGEX = /(?<!\{)\{[A-Za-z0-9._]+\}(?!\})/;

/** Regex to globally match against Standard parameters using {@link STANDARD_RENDER_REGEX} */
export const STANDARD_RENDER_REGEX_GLOBAL = new RegExp(STANDARD_RENDER_REGEX, 'g');

export type RenderType = 'mixed' | 'moustache' | 'standard' | 'noop';

export type RenderOptions = {
  returnAsList: boolean;
};

export type RendererInterface = {
  render: (template: string, context: Record<string, unknown>, options?: RenderOptions) => string;
};
