<div class="schedule-event-selected-job-details__assigned">
  <div class="schedule-event-selected-job-details__assigned-row schedule-event-selected-job-details__assigned-row--heading">
    <div class="schedule-event-selected-job-details__assigned-data">{{ vm.tradesPeopleDisplay }}</div>
  </div>
</div>
<div class="schedule-event-selected-job-details__assigned-engineers" [formGroup]="vm.form">
  @for (tradesPersonVm of vm.tradesPeople; track tradesPersonVm.assignedTo) {
    <div class="schedule-event-selected-job-details__assigned-row" [formGroupName]="tradesPersonVm.assignedTo">
      <schedule-event-selected-job-details-tradesperson
        [vm]="tradesPersonVm"
        (removeEngineer)="removeEngineer.emit($event)"
        (setLeadEngineer)="setLeadEngineer.emit($event)"
      >
      </schedule-event-selected-job-details-tradesperson>
      <div class="schedule-event-selected-job-details__slots">
        @for (slot of tradesPersonVm.slots; let idx = $index; track slot.assignmentId) {
        <schedule-event-selected-job-details-slot-time-form
          [index]="idx"
          [tradespersonId]="tradesPersonVm.assignedTo"
          [vm]="slot"
          (slotDateTimeChange)="slotDateTimeChange.emit($event)">
        </schedule-event-selected-job-details-slot-time-form>
        }
        <jui-button size="sm" color="secondary" display="ghost">{{ vm.i18nAddSpecificTimeBtn }}</jui-button>
      </div>
    </div>
  }
  <div class="schedule-event-selected-job-details__divider"></div>
  <div class="schedule-event-selected-job-details__action">
    <jui-button color="secondary" size="sm">{{ vm.i18nAddTradesPersonBtn }}</jui-button>
  </div>
</div>
