import { Job, JobAssignment } from '../core/domain/job';
import { FormGroup } from '@angular/forms';
import { JobInformation, JobInformationEventContext } from './utils/schedule-types';
import { DropDownElement } from '../shared/form-components/multiple-selection-dropdown.component';

export interface ScheduleJobsDisplayVm {
  showLoader: boolean;
  jobs: JobVm[];
  filtersVm: ScheduleJobsDisplayFilterInfoVm;
  numberOfJobs: string;
  headerDetails: string;
  headerPostcode: string;
  buttonAssign: string;
  buttonProject: string;
  headerJobsReadyToSchedule: string;
  qaButtonProject: string;
  qaButtonAssign: string;
  qaButtonFilters: string;
}

export interface ScheduleJobViewDm extends JobInformationEventContext {
  labelJob: string;
  labelLead: string;
  labelStartDate: string;
  labelStartTime: string;
  labelEndDate: string;
  labelEndTime: string;
  labelName: string;
  labelContactInfo: string;
  labelAddress: string;
  labelAssignedTradesperson: string;
  btnGoToProject: string;
  btnMoreDetails: string;
  startDateDisplay: string;
  endDateDisplay: string;
  startTimeDisplay: string;
  endTimeDisplay: string;
}

export interface ScheduleJobViewVm extends JobInformationEventContext {
  titleJob: string;
  labelJob: string;
  labelStartDate: string;
  labelStartTime: string;
  labelEndDate: string;
  labelEndTime: string;
  labelName: string;
  labelContactInfo: string;
  labelAddress: string;
  labelAssignedTradesperson: string;
  titleAssignedTradesperson: string;
  btnGoToProject: string;
  btnMoreDetails: string;
  formattedAddress: string;
  assignedEngineerDisplay: string;
  assignedEngineerTooltip: string;
  startDateDisplay: string;
  endDateDisplay: string;
  startTimeDisplay: string;
  endTimeDisplay: string;
  qaCloseBtn: string;
  qaGoToProjectsBtn: string;
  qaMoreDetailsBtn: string;
}

export interface ScheduleJobsDisplayDm {
  loading: boolean;
  jobs: Job[];
  selectedJob?: Job;
  jobTypes: DropDownElement[];
  filtersForm: FormGroup;
  i18ns: ScheduleI18n;
}

export interface JobVm {
  id: string;
  projectId: string;
  jobTypeDetails: string;
  jobCustomerNameDetails: string;
  postcode: string;
  jobInformation?: JobInformation;
  qaJobEntry: string;
}

export interface ScheduleJobsDisplayFilterInfoVm {
  filtersForm: FormGroup;
  jobTypes: DropDownElement[];
  jobTypeLabel: string;
  jobTypesDropdownPlaceholder: string;
  freeTextFilterLabel: string;
  freeTextFilterPlaceholder: string;
  qaCloseFilterButton: string;
  qaFilterJobTypesDropdown: string;
  qaFilterFreeTextInput: string;
}

export interface ScheduleI18n {
  [key: string]: string;
}

export interface ScheduleError {
  message: string;
  qaClearErrorsButton: string;
  qaErrorMessage: string;
}

export enum ErrorType {
  fetchReadyToScheduleJobs = 'schedule.errors.fetchReadyToScheduleJobs',
  fetchEngineers = 'schedule.errors.fetchEngineers',
  unknown = 'schedule.errors.unknown',
  scheduleJob = 'schedule.errors.scheduleJob',
  selectRescheduleJob = 'schedule.errors.selectRescheduleJob'
}

export type ScheduleMoreInfoDm = Required<
  Pick<
    Job,
    | 'id'
    | 'type'
    | 'projectId'
    | 'jobAssignments'
    | 'firstName'
    | 'lastName'
    | 'phoneNumber'
    | 'email'
    | 'address'
    | 'startDate'
    | 'endDate'
  >
> & {
  engineers: any[];
  form: FormGroup;
  addEngineerForm: FormGroup;
  isAddingEngineer: boolean;
  scheduleInProgress: boolean;
  isInitialSchedule: boolean;
  scheduleSuccess: boolean;
  isReadonlyAggregator: boolean;

  //i18ns
  i18nLead: string;
  i18nStartDatePlaceholder: string;
  i18nTradesPeopleHeader: string;
  i18nStartDateLabel: string;
  i18nScheduleNowBtn: string;
  i18nGoToProjectBtn: string;
  i18nStartDateRequired: string;
  i18nInvalidDateFormat: string;
  i18nStartDateAfterEnd: string;
  i18nStartTimeLabel: string;
  i18nEndDatePlaceholder: string;
  i18nEndDateLabel: string;
  i18nEndDateRequired: string;
  i18nEndDateBeforeStart: string;
  i18nEndTimeLabel: string;
  i18nDurationLabel: string;
  i18nDayLabel: string;
  i18nDaysLabel: string;
  i18nHourLabel: string;
  i18nHoursLabel: string;
  i18nMinutesLabel: string;
  i18nAddTradesPersonLabel: string;
  i18nSelectEngineerPlaceholder: string;
  i18nConfirmBtn: string;
  i18nCancelBtn: string;
  i18nCloseBtn: string;
  i18nSetLeadEngineerBtn: string;
  i18nRemoveEngineerBtn: string;
  i18nAddTradesPersonBtn: string;
  i18nProvisionallyScheduleBtn: string;
  i18nRescheduleReasonInputLabel: string;
  i18nRescheduleReasonInputPlaceholder: string;
  i18nEngineerRequiredError: string;
  i18nTimeIsInvalid: string;
};

export interface ScheduleMoreInfoVm {
  projectId: string;
  jobId: string;
  jobType: string;
  tenantType?: string;
  customerName: string;
  addressDisplay: string;
  phoneNumber: string;
  email: string;
  selectedEngineers: JobAssignment[];
  allEngineers: {
    name: string;
    id: string;
  }[];
  isScheduleButtonDisabled: boolean;
  showEngineerRequiredError: boolean;
  i18nStartDateLabel: string;
  i18nStartTimeLabel: string;
  i18nEndDateLabel: string;
  i18nEndTimeLabel: string;
  i18nTradesPeopleHeader: string;
  i18nGoToProjectBtn: string;
  i18nProvisionallyScheduleBtn: string;
  i18nScheduleNowBtn: string;
  form: FormGroup;
  addEngineerForm: FormGroup;
  isAddingEngineer: boolean;
  tradesPeopleDisplay: string;
  durationDisplay: string;
  scheduleInProgress: boolean;
  scheduleValid: boolean;
  isInitialSchedule: boolean;
  isTimeInvalid: boolean;
  //i18n Localization Keys
  i18nLead: string;
  i18nStartDateRequired: string;
  i18nEndDateRequired: string;
  i18nStartDateAfterEnd: string;
  i18nTimeIsInvalid: string;
  i18nEndDateBeforeStart: string;
  i18nInvalidDateFormat: string;
  i18nAddTradesPersonBtn: string;
  i18nSelectEngineerPlaceholder: string;
  i18nStartDatePlaceholder: string;
  i18nEndDatePlaceholder: string;
  i18nConfirmBtn: string;
  i18nCloseBtn: string;
  i18nCancelBtn: string;
  i18nSetLeadEngineerBtn: string;
  i18nRemoveEngineerBtn: string;
  i18nRescheduleReasonInputLabel: string;
  i18nRescheduleReasonInputPlaceholder: string;
  i18nEngineerRequiredError: string;
  //QA hooks
  qaDragHandle: string;
  qaCloseBtn: string;
  qaGoToProjectBtn: string;
  qaScheduleNowBtn: string;
  qaStartDateInput: string;
  qaStartDateBtn: string;
  qaStartTimeSelect: string;
  qaEndDateInput: string;
  qaEndDateBtn: string;
  qaEndTimeSelect: string;
  qaSetLeadEngineerBtn: string;
  qaRemoveEngineerBtn: string;
  qaAddEngineerBtn: string;
  qaEngineerSelect: string;
  qaConfirmAddEngineerBtn: string;
  qaCancelAddEngineerBtn: string;
  qaRescheduleReasonInput: string;
}

export interface SchedulePayloadPostDto {
  id?: string;
  start: string;
  end: string;
  jobType: string;
  jobAssignments: JobAssignment[];
  rescheduleReason?: string;
}
