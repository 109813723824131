import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractLayout } from '../abstract.layout';
import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormGroup } from '@angular/forms';
import { ApiService } from '../../api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccessService } from '../../../auth/services/access.service';
import { TranslocoModule } from '@ngneat/transloco';
import { EditLayoutQuestionsComponent } from '../edit-layout-questions.component';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { HttpImageComponent } from '@jump-tech-frontend/cards';
import { ReadOnlyDisableDirective } from '@jump-tech-frontend/angular-common';
import { MultiImageUploadComponent } from '../multi-image-upload.component';
import { CardLayoutItem } from '../../domain/card-layout-item';
import { NumberToArrayPipe } from '../../../shared/pipes/number-to-array.pipe';
import { GalleryV2LayoutPresenter } from './gallery-v2-layout.presenter';
import { BehaviorSubject } from 'rxjs';
import { GalleryV2LayoutRepository } from './gallery-v2-layout.repository';
import { GalleryVm } from './gallery-v2.model';

@Component({
  selector: 'app-gallery-v2-layout',
  template: `
    <ng-container *ngIf="vm$ | async as vm">
      <div class="gallery-v2__card" [class.gallery-v2__card--inline]="isInline">
        <!-- default pristine state -->
        <ng-container *ngIf="vm.isPristine">
          <ng-container *ngTemplateOutlet="multiImageUploader; context: { vm }"></ng-container>
        </ng-container>

        <!-- has data state -->
        <ng-container *ngIf="vm.showMainDisplay">
          <div class="gallery-v2__header">
            <div class="gallery-v2__title">
              <span>{{ vm.item.key }}</span>
              <jui-badge *ngIf="vm.imageCount" type="label" outlined="true" position="topLeft">
                {{ vm.imageCount }}
              </jui-badge>
            </div>
            <div class="gallery-v2__actions">
              <jui-icon
                *ngIf="!vm.isEditing"
                name="edit"
                size="xs"
                (click)="setEditing()"
                [disabled]="vm.isWorking"
              ></jui-icon>
              <ng-container *ngIf="vm.isEditing">
                <jui-button
                  *ngIf="vm.showSaveButton"
                  class="gallery-v2__action"
                  size="xs"
                  (click)="save(vm.form)"
                  [disabled]="vm.isSaveDisabled"
                  [loading]="vm.isSaving"
                  >{{ 'common.save' | transloco }}</jui-button
                >
                <jui-button
                  class="gallery-v2__action"
                  size="xs"
                  color="low"
                  (click)="cancelEdit()"
                  [disabled]="vm.isWorking"
                  >{{ 'common.cancel' | transloco }}</jui-button
                >
              </ng-container>
            </div>
          </div>

          <ng-container *ngIf="vm.isEditing">
            <ng-container *ngTemplateOutlet="multiImageUploader; context: { vm }"></ng-container>
          </ng-container>

          <div class="gallery-v2__images" *ngIf="vm.showUploadedImages || !!vm.activelyProcessing">
            <ng-container *ngFor="let projectData of vm.data[vm.item.editConfig.key]; index as i">
              <div class="gallery-v2__img a-fade-in">
                <div class="gallery-v2__remove" *ngIf="vm.isEditing">
                  <jui-icon isButton="true" name="delete" size="sm" (click)="removeImage(i)" color="high"></jui-icon>
                </div>

                <!-- todo - simplify this mess -->
                <crds-http-image
                  *ngIf="!isAttachment(projectData)"
                  [src]="getImageSrc(projectData)"
                  [downloadUrl]="getImageSrc(projectData, 'o')"
                  [fileName]="vm.item.editConfig.key + '_' + i"
                  imageClass="card-img-bottom"
                  skeletonLoaderHeight="180"
                  style="cursor: pointer"
                  (clicked)="openImagesModal(vm.data, vm.item.editConfig.key + '_' + i, true)"
                  [qaHook]="'galleryImg_' + vm.item.editConfig.key"
                ></crds-http-image>

                <!-- todo  check this with attachments?? add jui-icon here -->
                <div *ngIf="isAttachment(projectData)" class="attachment-filename" style="display: flex">
                  <i class="material-icons material-icons-md">article</i>
                  <span class="pt-1">{{ getAttachmentName(vm.item.editConfig.key, projectData) }}</span>
                </div>
              </div>
            </ng-container>

            @if (vm.activelyProcessing && isInline) {
            <jui-skeleton-loader
              height="150px"
              width="180px"
              inline="true"
              [count]="vm.activelyProcessing"
              [attr.data-qa]="'skeletonLoader'"
            ></jui-skeleton-loader>
            }
          </div>
        </ng-container>

        <!--  todo - move this logic out from the view  -->

        @if (vm.activelyProcessing && !isInline) {
        <jui-skeleton-loader
          *ngFor="let i of vm.activelyProcessing | numberToArray"
          height="180px"
          width="100%"
          [attr.data-qa]="'skeletonLoader'"
        ></jui-skeleton-loader>
        } @else if ((vm.activelyProcessing && isInline && !vm.data[vm.item.editConfig.key]?.length)) {
        <!-- handles the case where an inline gallery has no uploaded items so the loader needs to render here          -->
        <div class="gallery-v2__loader-inline">
          <jui-skeleton-loader
            height="150px"
            width="180px"
            inline="true"
            [count]="vm.activelyProcessing"
            [attr.data-qa]="'skeletonLoader'"
          ></jui-skeleton-loader>
        </div>
        }
      </div>

      <ng-template #multiImageUploader let-vm="vm">
        <app-multi-image-upload-component
          *ngIf="vm.item.editConfig && vm.form"
          [item]="vm.item"
          [project]="vm.project"
          [data]="vm.data"
          [question]="vm.item.editConfig"
          [existingFiles]="vm.existingImages"
          [form]="vm.form"
          [showButton]="true"
          [i18ns]="vm.i18ns"
          (processingFiles)="onProcessingFiles($event)"
          (saveSuccess)="onSaveSuccess($event)"
        ></app-multi-image-upload-component>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ['../../../project-detail/gallery/gallery-v2.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NgFor,
    NgIf,
    CoreComponentsAngularModule,
    EditLayoutQuestionsComponent,
    TranslocoModule,
    HttpImageComponent,
    ReadOnlyDisableDirective,
    MultiImageUploadComponent,
    NumberToArrayPipe
  ],
  providers: [GalleryV2LayoutPresenter, GalleryV2LayoutRepository]
})
export class GalleryV2LayoutComponent extends AbstractLayout implements OnInit, OnDestroy {
  @Input() readOnly: boolean;
  @Input() useLayoutGroups: boolean;
  @Input() galleryItem: CardLayoutItem;
  @Input() data: any;
  @Input() isInline = false;

  vm$: BehaviorSubject<GalleryVm> = new BehaviorSubject<GalleryVm>(null);
  forms: UntypedFormGroup[] = [];

  constructor(
    sanitizer: DomSanitizer,
    apiService: ApiService,
    featureAccessService: AccessService,
    modalService: NgbModal,
    private presenter: GalleryV2LayoutPresenter
  ) {
    super(sanitizer, apiService, featureAccessService, modalService);
  }

  ngOnInit(): void {
    this.presenter.load(this.vm$, this.project, this.galleryItem);
  }

  ngOnDestroy(): void {
    this.presenter.cancelEdit();
  }

  setEditing(): void {
    this.presenter.setEditing();
  }

  cancelEdit(): void {
    this.presenter.cancelEdit();
  }

  removeImage(idx): void {
    this.presenter.removeImage(idx);
  }

  save(form: UntypedFormGroup): void {
    this.presenter.save(form);
  }

  getAttachmentName(key, projectData) {
    return projectData && projectData.name;
  }

  onProcessingFiles(numberProcessing: number): void {
    // this.isProcessing = isProcessing;
    this.presenter.setProcessing(numberProcessing);
    if (!numberProcessing) {
      this.presenter.handleProcessingComplete();
    }
  }

  onSaveSuccess(payload): void {
    this.presenter.handleSaveSuccess(payload);
  }
}
