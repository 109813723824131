import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { AbstractLayout } from './abstract.layout';
import { DomSanitizer } from '@angular/platform-browser';
import { CardControlService } from '../card-control.service';
import { UntypedFormGroup } from '@angular/forms';
import { get } from 'lodash';
import { CardLayoutItem } from '../domain/card-layout-item';
import { ApiService } from '../api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccessService } from '../../auth/services/access.service';
import { ProjectCardLayout } from '../domain/project-card-layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EditLayoutQuestionsComponent } from './edit-layout-questions.component';
import { NgIf, NgFor } from '@angular/common';
import { HttpImageComponent } from '@jump-tech-frontend/cards';

@Component({
  selector: 'app-modal-layout',
  template: `
    <div *ngIf="filteredLayoutItems.length" class="card" [class.card--full-screen]="this.fullScreen">
      <div class="card-body">
        <div *ngFor="let item of filteredLayoutItems">
          <div *ngIf="item.editConfig?.key">
            <app-edit-layout-questions
              *ngIf="item.editConfig?.key && form"
              [project]="data"
              [question]="item.editConfig"
              [form]="form"
            >
            </app-edit-layout-questions>
          </div>

          <div *ngIf="!item.editConfig?.key">
            <div *ngIf="item.icon" class="row card-row">
              <div class="col-2 card-detail-image">
                <i class="material-icons">{{ item.icon }}</i>
              </div>
              <div class="col-10 card-detail-content" [innerHtml]="replaceVariables(item.content)"></div>
            </div>
            <div *ngIf="!item.icon" class="row card-row">
              <div *ngIf="item.key" class="col-7 card-detail-content strong">{{ item.key }}</div>
              <div
                *ngIf="item.content"
                class="col-5 card-detail-content"
                [innerHtml]="replaceVariables(item.content)"
              ></div>
              <div *ngIf="item.src && data[item.src]" class="col-5 card-detail-content">
                <crds-http-image [src]="item.src" [alt]="item.label"></crds-http-image>
              </div>
            </div>
          </div>
        </div>
      </div>
      <crds-http-image
        *ngIf="layout.image && layout.image.key && data[layout.image.key] && layout.image.position === 'bottom'"
        class="card-img-bottom"
        [src]="layout.image.src"
        alt=""
      ></crds-http-image>
    </div>
  `,
  styleUrls: ['../../project-detail/project-detail.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, EditLayoutQuestionsComponent, HttpImageComponent]
})
export class ModalLayoutComponent extends AbstractLayout implements OnInit {
  @Input() layout: ProjectCardLayout;
  @Input() data: any;
  @Input() mode: 'edit' | 'readonly';
  @Input() fullScreen: boolean;
  @Output() formUpdate: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  form: UntypedFormGroup;
  filteredLayoutItems: CardLayoutItem[] = [];

  constructor(
    sanitizer: DomSanitizer,
    apiService: ApiService,
    featureAccessService: AccessService,
    modalService: NgbModal,
    private cardControlService: CardControlService
  ) {
    super(sanitizer, apiService, featureAccessService, modalService);
  }

  ngOnInit() {
    const layoutItems = this.layout['items'] || [];
    this.filteredLayoutItems = layoutItems.filter((item: CardLayoutItem) => {
      if (!('showIf' in item)) {
        return true;
      }
      try {
        const showIfResolvedValue = get(this.data, item.showIf.key);
        return !!showIfResolvedValue && showIfResolvedValue == item.showIf.value;
      } catch (e) {
        return false;
      }
    });
    if (this.editMode) {
      this.setForm();
    }
  }

  get editMode() {
    return this.mode && this.mode === 'edit';
  }

  async setForm() {
    this.form = await this.cardControlService.toFormGroup(this.filteredLayoutItems, this.data);
    this.formUpdate.emit(this.form);
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.formUpdate.emit(this.form);
    });
  }

  replaceVariables(item) {
    return this.replaceTemplateVariables(item, this.data) + ' ';
  }
}
