<ng-container *ngIf="vm?.item">
  <div class="modal-wrapper">
    <div class="modal-header-wrapper">
      <div class="modal-header">
        <button type="button" class="close" aria-describedby="modal-title" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-banner">
        {{ vm.title }}
      </div>
    </div>
    <div class="modal-body" *ngIf="vm.item">
      <div class="standard-quote__top">
        <div class="standard-quote__field">
          <label>{{ vm.nameLabel }}</label>
          <app-form-error *ngIf="!vm.nameValid"></app-form-error>
          <input
            name="name"
            class="form-control form-control-sm name"
            (focusout)="changeName(standardDataName.value)"
            value="{{ vm.item.name }}"
            #standardDataName
            [attr.data-qa]="'standardQuoteNameInput'"
          />
        </div>
        <div class="standard-quote__field">
          <label>{{ vm.projectTypeLabel }}</label>
          <app-form-error *ngIf="!vm.projectTypeValid"></app-form-error>
          <app-multi-select
            [singleSelection]="true"
            [selectedItems]="[vm.item.projectType]"
            [items]="vm.projectTypes"
            (itemsChanged)="changeProjectType($event)"
            [allowSearchFilter]="false"
            [attr.data-qa]="'standardQuoteProjectTypeSelect'"
            [alphaSort]="true"
            appendTo="app-standard-quote-component"
          ></app-multi-select>
        </div>
      </div>
      <div class="standard-quote__section" *ngIf="vm.item">
        <div class="standard-quote__field standard-quote__field--full">
          <div class="standard-quote__section" *ngIf="vm.item.layout">
            <app-list-layout
              [layout]="vm.item.layout"
              [showSave]="false"
              (formUpdated)="changeData($event)"
              [data]="vm.item.data"
            ></app-list-layout>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <jui-button
        class="modal-footer__action-btn"
        size="sm"
        color="low"
        (click)="closeModal()"
        [attr.data-qa]="'standardQuoteCancel'"
      >
        {{ vm.closeLabel }}
      </jui-button>
      <jui-button
        class="standard-quote__action-bt"
        [disabled]="vm.saveDisabled"
        size="sm"
        (click)="actionClick()"
        [attr.data-qa]="'standardQuoteSave'"
      >
        {{ vm.saveLabel }}
      </jui-button>
    </div>
    <ngx-spinner
      bdColor="var(--jds-theme-spinner-bd-rgba)"
      size="large"
      [fullScreen]="false"
      type="line-scale"
      name="standardQuoteSpinner"
      [attr.data-qa]="'standardQuoteSpinner'"
    ></ngx-spinner>
  </div>
</ng-container>
