export interface Address {
  line1: string;
  line2?: string;
  line3?: string;
  line4?: string;
  locality?: string;
  town?: string;
  county?: string;
  country?: string;
  postCode: string;
}
