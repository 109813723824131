import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgFor } from '@angular/common';

@Component({
  selector: 'invalid-items-modal-component',
  template: `<div class="modal-wrapper modal--confirm">
    <div class="modal-header">
      <div>{{ title }}</div>
    </div>
    <div class="modal-body">
      <div *ngFor="let message of messages">{{ message }}</div>
    </div>
    <div class="modal-footer text-center">
      <jui-button size="sm" [attr.data-qa]="'submitButton'" (click)="close($event)">
        {{ 'common.ok' | transloco }}
      </jui-button>
    </div>
  </div>`,
  standalone: true,
  imports: [NgFor, CoreComponentsAngularModule, TranslocoModule]
})
export class InvalidItemsModalComponent {
  @Input() title: string;
  @Input() messages: string[];

  constructor(public activeModal: NgbActiveModal) {}

  close($event) {
    $event.preventDefault();
    this.activeModal.close();
  }
}
