import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
  standalone: true
})
export class OrderByPipe implements PipeTransform {
  transform(array: any[], sortBy: string, order: 'asc' | 'desc'): any[] {
    if (order === 'asc') {
      return array.sort((a, b) => a[sortBy] - b[sortBy]);
    }
    return array.sort((a, b) => b[sortBy] - a[sortBy]);
  }
}
