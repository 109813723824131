<div class="schedule-more-details__assigned">
  <div class="schedule-more-details__assigned-row schedule-more-details__assigned-row--heading">
    <div class="schedule-more-details__assigned-data">{{ vm.tradesPeopleDisplay }}</div>
  </div>
</div>
<div class="schedule-more-details__assigned-engineers">
  <div class="schedule-more-details__assigned-row" *ngFor="let engineer of vm.selectedEngineers">
    <div class="schedule-more-details__assigned-data">{{ engineer.assignedToDisplayName }}</div>
    <div class="schedule-more-details__assigned-data">
      <span *ngIf="engineer.assignmentType === 'LEAD'" class="schedule-more-details__lead">{{vm.i18nLead}}</span>
      <span class="schedule-more-details__assigned-icon"
        ><jui-icon
          name="person"
          [type]="engineer.type"
          [color]="engineer.color"
          size="xs"
          [attr.data-qa]="vm.qaSetLeadEngineerBtn"
          [title]="engineer.titleText"
          (click)="onSetLeadEngineer.emit(engineer)"
        ></jui-icon
      ></span>
      <span class="schedule-more-details__assigned-icon"
        ><jui-icon
          name="delete"
          color="high"
          size="xs"
          [title]="vm.i18nRemoveEngineerBtn"
          [attr.data-qa]="vm.qaRemoveEngineerBtn"
          (click)="onRemoveEngineer.emit(engineer)"
        ></jui-icon
      ></span>
    </div>
  </div>
</div>
