import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { JumptechDate } from '@jump-tech-frontend/domain';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../core/api.service';

import { CustomAttachment } from '../../core/domain/project-configuration';
import { ConfirmModalComponent } from '../../shared/modals/confirm-modal.component';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../../error/logger.service';
import { Project } from '../../core/domain/project';
import { UploadFileInput, uploadFileSeparator } from '../../core/directives/upload-file-input';
import { User } from '../../core/domain/user';
import { AttachmentsHelper } from '../../shared/attachments.helper';
import { AccessService, PathwayFeature } from '../../auth/services/access.service';
import { UserService } from '../../auth/services/user.service';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { firstValueFrom } from 'rxjs';
import { PresignedPost } from 'aws-sdk/clients/s3';
import { OrderByPipe } from './order-by.pipe';
import { ProgressBarComponent } from '../../progress-bar/progress-bar.component';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgFor, NgIf } from '@angular/common';
import { ReadOnlyDisableDirective } from '@jump-tech-frontend/angular-common';

export interface S3FileInterface {
  maxFileSizeBytes: number;

  onFileUploadEvent(fileInput: any);

  onFileDownloadEvent(attachment: ProjectAttachment);

  onFileDeleteEvent(attachment: ProjectAttachment);
}

export interface ProjectAttachment {
  key: string;
  uploadDate: number;
  uploadedBy: string;
  uploadedByLabel?: string;
  uploadedByTenant?: string;
  url?: string;
}

@Component({
  selector: 'app-project-attachments',
  templateUrl: './project-attachments.component.html',
  styleUrls: ['./project-attachments.component.scss', '../styled-table.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    UploadFileInput,
    NgFor,
    NgIf,
    CoreComponentsAngularModule,
    ProgressBarComponent,
    TranslocoModule,
    OrderByPipe,
    ReadOnlyDisableDirective
  ]
})
export class ProjectAttachmentsComponent implements S3FileInterface, OnInit, AfterViewInit {
  @ViewChildren(UploadFileInput) fileInputs!: QueryList<UploadFileInput>;
  @Output() onUpdateAttachments: EventEmitter<ProjectAttachment[]> = new EventEmitter<ProjectAttachment[]>();
  @Input() attachments: ProjectAttachment[] = [];
  @Input() customAttachments?: CustomAttachment[] = [];
  @Input() tenant: string;
  @Input() readOnly: boolean;
  @Input() project: Project;
  user: User;
  bucketName = `pathway-attachments-${environment.bucketSuffix}`;
  maxFileSizeBytes = 20000000;
  uploadInProgress: { fileName: string; progress: number } = null;
  hasRoleOrTeamPermission = false;

  constructor(
    private featureAccessService: AccessService,
    private loggerService: LoggerService,
    private modalService: NgbModal,
    private userService: UserService,
    public attachmentsHelper: AttachmentsHelper,
    private translocoService: TranslocoService,
    private apiService: ApiService
  ) {}

  async ngOnInit() {
    this.hasRoleOrTeamPermission = this.featureAccessService.isFeatureAccessAllowed(
      PathwayFeature.AttachmentsAccess,
      true
    );
    this.userService.userObservable.subscribe(user => {
      this.user = user;
    });
  }

  ngAfterViewInit() {
    // Reset immediately to add the separator to the file id. This avoids partial match conflicts.
    this.fileInputs.forEach(item => item.reset());
  }

  clickFile(id) {
    (document.querySelector(`[id^="${id}${uploadFileSeparator}"]`) as HTMLElement).click();
  }

  millisToDate(millis: number) {
    return JumptechDate.from(millis).toDateTimeFormat();
  }

  async onFileUploadEvent(fileInput: any, customAttachment?: CustomAttachment) {
    const file = fileInput.target.files[0];
    if (!this.attachmentsHelper.checkIsFileTypeAndSizeOK(file, customAttachment?.acceptedFileTypes)) {
      return false;
    }

    const { uploadDate, key, fileName, fileType } = this.attachmentsHelper.getFileUploadDetail(
      file,
      this.project.id,
      customAttachment?.uploadAsFilename
    );
    this.uploadInProgress = { fileName: fileName, progress: 0 };
    try {
      const presignedPost: PresignedPost = await firstValueFrom(
        this.apiService.getSignedPostUrl(key, this.bucketName, fileType)
      );
      this.attachmentsHelper.postAndTrackProgress(presignedPost, file, this.uploadInProgress).subscribe(() => {
        this.addOrUpdateAttachment(fileName, uploadDate);
        this.onUpdateAttachments.next(this.attachments);
        this.uploadInProgress = null;
        return true;
      });
    } catch (e) {
      this.attachmentsHelper.onPostToS3ErrorEvent(
        this.translocoService.translate('common.modals.invalidGeneralPostFile.messages.unknownError')
      );
    } finally {
      this.fileInputs.forEach(item => item.reset());
    }
  }

  private addOrUpdateAttachment(fileName, uploadDate) {
    const index = this.attachments.findIndex(attachment => attachment.key === fileName);
    const projectAttachment = {
      key: fileName,
      uploadDate: uploadDate,
      uploadedBy: this.user.id,
      uploadedByLabel: this.user.label,
      uploadedByTenant: this.tenant
    };
    if (index > -1) {
      this.attachments[index] = projectAttachment;
    } else {
      this.attachments.push(projectAttachment);
    }
  }

  async onFileDownloadEvent(attachment: ProjectAttachment) {
    const result = await this.apiService.getAttachmentOrUrl(attachment.url).toPromise();
    const downloadHelper = document.createElement('a');
    downloadHelper.href = result;
    downloadHelper.setAttribute('download', attachment.key);
    downloadHelper.setAttribute('target', '_blank');
    document.body.appendChild(downloadHelper);
    downloadHelper.click();
  }

  onFileDeleteEvent(attachment: ProjectAttachment) {
    const key = `${this.project.id}/${attachment.key}`;
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.config = {
      title: this.translocoService.translate('project.modals.deleteFile.title'),
      messages: [this.translocoService.translate('project.modals.deleteFile.messages.confirmation')],
      confirm: this.translocoService.translate('common.yes'),
      cancel: this.translocoService.translate('common.no')
    };
    modalRef.result
      .then(() => {
        this.attachmentsHelper.s3
          .remove(key, this.bucketName)
          .then(() => {
            this.attachments = this.attachments.filter(a => a.key !== attachment.key);
            this.onUpdateAttachments.next(this.attachments);
          })
          .catch(this.loggerService.error);
      })
      .catch(this.loggerService.log);
  }

  isUserUploadedFile(fileUploadedBy: string) {
    if (!this.user) {
      return false;
    }

    return this.user.isAdmin || (fileUploadedBy && fileUploadedBy === this.user.id);
  }

  hasEditFileAccess(attachment: ProjectAttachment) {
    return !this.readOnly && this.hasRoleOrTeamPermission && this.isUserUploadedFile(attachment.uploadedBy);
  }

  hasUploadFileAccess() {
    return !this.readOnly && this.hasRoleOrTeamPermission;
  }

  showFile(blob: Blob, fileName: string) {
    if (window.navigator && window.navigator?.['msSaveOrOpenBlob']) {
      window.navigator?.['msSaveOrOpenBlob'](blob);
      return;
    }

    const data = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = data;
    link.target = '_blank';
    link.click();

    setTimeout(function () {
      window.URL.revokeObjectURL(data);
    }, 100);
  }
}
