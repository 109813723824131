import { RendererInterface } from '@jump-tech-frontend/domain';
import { MoustacheRender } from './moustache.render';
import { StandardRender } from './standard.render';

export class MixedRender implements RendererInterface {
  public constructor(
    private readonly moustacheRenderer: MoustacheRender = new MoustacheRender(),
    private readonly standardRenderer: StandardRender = new StandardRender()
  ) {}

  public render(template: string, context: unknown): string {
    const moustacheRenderedTemplate = this.moustacheRenderer.render(template, context);
    return this.standardRenderer.render(moustacheRenderedTemplate, context);
  }
}
