import { BehaviorSubject } from 'rxjs';
import { EventEmitter, inject, Injectable } from '@angular/core';
import {
  VideoPlayerDataModel,
  VideoPlayerParams,
  VideoPlayerTranslations,
  VideoPlayerViewModel
} from './video-player.model';
import { VideoPlayerRepository } from './video-player.repository';
import { AppName } from '@jump-tech-frontend/app-config';
import { TRANSLATIONS_PROVIDER } from '../../../translations.token';

@Injectable()
export class VideoPlayerPresenter {
  private readonly appName = inject(AppName);
  public readonly translationsProvider = inject(TRANSLATIONS_PROVIDER);
  private readonly videoPlayerRepository = inject(VideoPlayerRepository);

  async load(
    vm: BehaviorSubject<VideoPlayerViewModel | null>,
    videoPlayerParams: VideoPlayerParams & { id: string },
    updateEmitter: EventEmitter<string | null>
  ) {
    await this.videoPlayerRepository.load(videoPlayerParams, (dataModel: VideoPlayerDataModel) => {
      const videoPlayerViewModel: VideoPlayerViewModel = {
        canRecord: dataModel.canRecord,
        canDelete: dataModel.canDelete,
        showRecordControls: this.appName === 'RELAY',
        showVideo: dataModel.showVideo || !!dataModel.videoSource,
        id: dataModel.id,
        videoSource: dataModel.videoSource,
        readOnly: videoPlayerParams.readOnly ?? false,
        translations: this.getTranslations()
      };
      vm.next(videoPlayerViewModel);
      updateEmitter.next(dataModel.videoSource ?? null);
    });
  }

  private getTranslations(): VideoPlayerTranslations {
    return {
      maximumLengthHint: this.translationsProvider.getTranslation('video.maximumLengthHint')
    };
  }

  public async startRecording() {
    await this.videoPlayerRepository.startRecording();
  }

  public stopRecording() {
    this.videoPlayerRepository.stopRecording();
  }

  public pauseRecording() {
    this.videoPlayerRepository.pauseRecording();
  }

  public resumeRecording() {
    this.videoPlayerRepository.resumeRecording();
  }

  public async removeRecording() {
    await this.videoPlayerRepository.removeRecording();
  }
}
