import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationExtras, Router } from '@angular/router';
import { JumptechDate } from '@jump-tech-frontend/domain';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { UserService } from '../../auth/services/user.service';
import { User } from '../domain/user';
import { HttpGateway } from '../http-gateway.service';
import { LocalStorageGateway } from '../local-storage-gateway.service';
import { SearchBarService } from './search-bar/search-bar.service';
import { ISearchDataVm, SearchTarget } from './search.model';

const lastSearchKey = `lastSavedSearch-${environment.name}`;

@Injectable({
  providedIn: 'root'
})
export class SearchRepository {
  private user: User;
  searchTerm = null;
  topResults$ = null;
  mainResults$ = null;
  searchTerm$ = null;
  topResultsObserver = false;
  topResultsSubscriber: Subscription;
  mainResultsSubscriber: Subscription;

  resetResult: ISearchDataVm = {
    searchTerm: null,
    tenant: null,
    results: [],
    showResults: false,
    showNoResults: false,
    resultFoundText: '',
    isMaxResultsLimit: false,
    maxResultText: ''
  };

  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    private httpGateway: HttpGateway,
    private localStorageGateway: LocalStorageGateway,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private searchBarService: SearchBarService,
    private spinnerService: NgxSpinnerService,
    private transloco: TranslocoService,
    private router: Router
  ) {
    this.topResults$ = new Subject();
    this.mainResults$ = new Subject();
    this.searchTerm$ = new Subject();

    this.userService.userObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user: User) => {
      if (user) {
        this.user = user;
      }
    });
    this.searchBarService.closeEvent$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(close => {
      if (close) {
        this.clearResults(SearchTarget.inline);
      }
    });
    this.authenticationService.signOutObservable
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.clearLastSearchResults());
  }

  public isSignedIn() {
    return this.authenticationService.isUserSignedIn;
  }

  public getSearchTermUpdates(callback, target) {
    target === SearchTarget.inline
      ? this.searchTerm$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(callback)
      : null;
  }

  public async viewProject(id: string) {
    const extras: NavigationExtras = {
      replaceUrl: true
    };
    await this.router.navigate(['project', id], extras);
    this.searchTerm$.next('');
  }

  public async searchProjects(searchTerm: string, target: SearchTarget, max: number) {
    await this.spinnerService.show('searchBtnSpinner');
    if (target === SearchTarget.main) {
      await this.spinnerService.show('mainSearchResultsSpinner');
    }
    const searchTermSafe = this.cleanSearchString(searchTerm);
    if (searchTermSafe) {
      await this.loadApiData(searchTermSafe, searchTerm, target, max);
    }
  }

  public async loadApiData(searchTermSafe: string, searchTerm: string, target: SearchTarget, max: number) {
    const params = { max: max.toString() };
    const dto = await this.httpGateway.get(`${environment.apiProjectsSearchUrl}/${searchTermSafe}`, params);
    const resultsPm = this.setModelFromDto(dto, searchTerm, max);
    await this.notifyResults(target, resultsPm);
  }

  private async notifyResults(target: SearchTarget, results) {
    if (target === SearchTarget.inline) {
      this.searchTerm$.next(results.searchTerm);
      this.topResults$.next(results);
    } else {
      this.mainResults$.next(results);
      this.localStorageGateway.setItem(lastSearchKey, JSON.stringify(results));
      await this.spinnerService.hide('mainSearchResultsSpinner');
    }
  }

  public clearLastSearchResults() {
    this.localStorageGateway.removeItem(lastSearchKey);
  }

  public unsubscribeMainResults() {
    if (this.mainResultsSubscriber) {
      this.mainResultsSubscriber.unsubscribe();
    }
  }

  public async getSearchResults(target: SearchTarget, callback) {
    if (!this.topResultsObserver && target === SearchTarget.inline) {
      this.topResultsSubscriber = this.topResults$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(callback);
      this.topResultsObserver = true;
    }
    if (target === SearchTarget.main) {
      this.mainResultsSubscriber = this.mainResults$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(callback);
      this.getSavedResults();
    }
  }

  public getSavedResults() {
    const savedResults = this.localStorageGateway.getItem(lastSearchKey);
    if (savedResults) {
      const savedSearch = JSON.parse(savedResults);
      this.mainResults$.next(savedSearch);
    } else {
      this.mainResults$.next(this.resetResult);
    }
  }

  public clearResults(target: SearchTarget) {
    if (target === SearchTarget.main) {
      this.clearMainResults();
    } else {
      this.topResults$.next(this.resetResult);
      this.searchTerm$.next('');
    }
  }

  public clearMainResults() {
    this.mainResults$.next(this.resetResult);
  }

  public cleanSearchString(term: string) {
    return encodeURIComponent(term);
  }

  private setModelFromDto(dto: any, searchTerm: string, max: number): ISearchDataVm {
    return {
      searchTerm,
      tenant: this.user.tenant,
      results: dto.results.map(res => {
        return {
          id: res.id,
          name: `${res.firstName} ${res.lastName}`,
          ref: res.externalReference || res.id,
          address: res.postCode ? `${res.line1}  ${res.town}  ${res.postCode}` : '',
          email: res.email,
          projectType: res.type,
          status: res.statusName || res.status,
          phone: res.phoneNumber,
          type: res.type,
          owner: res.owner ? res.owner.split('|')[0] : this.transloco.translate('common.unassigned'),
          assignedTo: res.assignedToDisplayName
            ? res.assignedToDisplayName
            : this.transloco.translate('common.unassigned'),
          scheduledDisplayDate: res.scheduledDate ? JumptechDate.from(res.scheduledDate).toDateTimeFormat() : null
        };
      }),
      isMaxResultsLimit: dto.results.length >= max,
      maxResultText: this.transloco.translate('common.maximumResultsExceeded'),
      showResults: !!dto.results.length,
      showNoResults: !dto.results.length,
      resultFoundText: !dto.results.length
        ? this.transloco.translate('common.noResultsFound')
        : dto.results.length === 1
        ? this.transloco.translate('common.singleResultFound')
        : this.transloco.translate('common.multiResultsFound')
    };
  }
}
