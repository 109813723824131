import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UploadProgressModalComponent } from './upload-progress-modal.component';
import { UploadProgressPresenter } from './upload-progress.presenter';
import { UploadProgressViewModel } from './upload-progress.view.model';

@Component({
  selector: 'upload-progress-component',
  template: `<div [attr.data-qa]="'uploadProgressPlaceHolder'"></div>`,
  standalone: true,
  providers: [UploadProgressPresenter]
})
export class UploadProgressComponent implements OnInit {
  private modalRef?: NgbModalRef;

  constructor(private uploadProgressPresenter: UploadProgressPresenter, private modalService: NgbModal) {}
  ngOnInit() {
    this.uploadProgressPresenter.load((vm: UploadProgressViewModel) => {
      if (vm && !this.modalRef) {
        const options: NgbModalOptions = {
          backdrop: 'static',
          centered: true
        };
        this.modalRef = this.modalService.open(UploadProgressModalComponent, options);
        this.modalRef.componentInstance.vm = vm;
        this.modalRef.result
          .then(value => {
            this.uploadProgressPresenter.clearProgress();
            this.modalRef = null;
          })
          .catch(() => {
            this.uploadProgressPresenter.clearProgress();
          });
      } else if (vm && this.modalRef) {
        this.modalRef.componentInstance.vm = vm;
      } else {
        if (this.modalRef) {
          this.modalRef.dismiss();
          this.modalRef = null;
        }
      }
    });
  }
}
