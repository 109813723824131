import { Injectable } from '@angular/core';
import { DropDownElement } from '../shared/form-components/multiple-selection-dropdown.component';
import { JobTypeConfiguration } from './domain/project-configuration';
import { ProjectConfigurationService } from './project-configuration.service';
import { PathwayFeature } from '../auth/services/access.service';

interface PartialProjectConfiguration {
  projectType: string;
  configuration: {
    jobTypes: JobTypeConfiguration[];
  };
}

@Injectable({
  providedIn: 'root' // Ensures we get a singleton even with lazy-loading
})
export class JobTypeService {
  jobTypes: DropDownElement[] = [];
  private jobTypeToProjectType = {};
  private selectedJobTypes: string[] = [];

  constructor(protected projectConfigurationService: ProjectConfigurationService) {}

  async fetchJobTypes(): Promise<void> {
    if (this.jobTypes.length) {
      return; // we've already got them
    }
    const projectTypes = (await this.projectConfigurationService.getProjectTypes(
      PathwayFeature.MainDashboard,
      'projectType, configuration.jobTypes'
    )) as PartialProjectConfiguration[];
    for (const projectType of projectTypes) {
      for (const jobType of projectType.configuration?.jobTypes || []) {
        // Store the project type against the job type so we can do a reverse
        // look up. WARNING if we ever allow projects to share job types, this
        // will stop working.
        this.jobTypeToProjectType[jobType.jobType] = { projectType };
      }
    }
    // convert the array and map it for the dropdown
    const jobTypesList = Object.keys(this.jobTypeToProjectType);
    this.jobTypes = jobTypesList.map(jt => ({ id: jt, name: jt }));
  }

  isSelected(jobType: string): boolean {
    return this.selectedJobTypes.length === 0 || this.selectedJobTypes.includes(jobType);
  }

  setSelectedJobTypes(selected: string[]) {
    this.selectedJobTypes = selected;
  }

  getSelectedJobTypes(): string[] {
    return this.selectedJobTypes;
  }
}
