import { Injectable } from '@angular/core';
import { ScheduleRepository } from '../../schedule.repository';
import { ScheduleJobViewDm, ScheduleJobViewVm } from '../../schedule.model';
import { JobInformationEventContext } from '../../utils/schedule-types';

@Injectable({ providedIn: 'root' })
export class ScheduleJobViewPresenter {
  constructor(private repository: ScheduleRepository) {}

  public load(vm$): void {
    this.repository.loadJobView((dm: ScheduleJobViewDm): void => {
      let viewModel: ScheduleJobViewVm = null;
      if (dm) {
        const initials = dm.jobInformation.jobAssignments.reduce((output, cur, idx, arr) => {
          const first = cur.assignedToDisplayName.charAt(0)?.toUpperCase();
          const last = cur.assignedToDisplayName.split(' ')[1]?.charAt(0)?.toUpperCase();
          output = output + (idx < arr.length - 1 ? `${first}${last} & ` : `${first}${last}`);
          return output;
        }, '');

        viewModel = {
          ...dm,
          titleJob: `${dm.labelJob} - ${dm.jobInformation.type}`,
          titleAssignedTradesperson: `${dm.labelAssignedTradesperson} (${dm.jobInformation.jobAssignments.length})`,
          formattedAddress: dm.jobInformation.address
            ? `${dm.jobInformation.address.line1}, ${dm.jobInformation.address.town}, ${dm.jobInformation.address.postCode}`
            : '',
          assignedEngineerDisplay: initials,
          assignedEngineerTooltip: dm.jobInformation.jobAssignments
            .map(x => {
              return x.assignmentType === 'LEAD'
                ? `${x.assignedToDisplayName} (${dm.labelLead})`
                : x.assignedToDisplayName;
            })
            .join(', '),
          qaCloseBtn: 'sjvCloseCardBtn',
          qaGoToProjectsBtn: 'sjvGoToProjectBtn',
          qaMoreDetailsBtn: 'sjvMoreDetailsBtn'
        };
      }
      vm$.next(viewModel);
    });
  }

  public close(): void {
    this.repository.closeViewingJob();
  }

  public setViewingJobInfo(jobInformationEventContext: JobInformationEventContext): void {
    this.repository.setViewingJobInfo(jobInformationEventContext);
  }

  public openProject(id: string): void {
    this.repository.goToProject(id);
  }

  public openMoreDetails(jobInformationEventContext: JobInformationEventContext): void {
    this.repository.openMoreDetails(
      jobInformationEventContext.jobInformation,
      jobInformationEventContext.callback,
      jobInformationEventContext.event
    );
  }
}
