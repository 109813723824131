import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import {NgbDatepickerModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@ngneat/transloco';
import { JobInformationEventContext } from '../../utils/schedule-types';
import { ScheduleJobViewPresenter } from './schedule-job-view.presenter';
import { BehaviorSubject } from 'rxjs';
import { ScheduleJobViewVm } from '../../schedule.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'schedule-job-view',
  templateUrl: 'schedule-job-view.component.html',
  styleUrls: ['schedule-job-view.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    CommonModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgSelectModule,
    CoreComponentsAngularModule,
    NgbTooltipModule
  ],
  providers: [ScheduleJobViewPresenter]
})
export class ScheduleJobViewComponent implements OnInit {
  vm$: BehaviorSubject<ScheduleJobViewVm> = new BehaviorSubject<ScheduleJobViewVm>(null); // todo type

  public constructor(private presenter: ScheduleJobViewPresenter) {}

  @Input() phase2Enabled: boolean = false;
  @Output() cardClosed: EventEmitter<null> = new EventEmitter<null>();

  // todo we can hook in from the more details to new output
  public closeCard(): void {
    this.cardClosed.emit();
    this.presenter.close();
  }

  public openProject(vm: ScheduleJobViewVm): void {
    this.presenter.openProject(vm.jobInformation.projectId);
  }

  public openMoreDetails(jobInformationContext: JobInformationEventContext): void {
    this.presenter.openMoreDetails(jobInformationContext);
  }

  public ngOnInit(): void {
    this.presenter.load(this.vm$);
  }
}
