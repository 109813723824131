import { Injectable } from '@angular/core';
import { pickLanguage } from '@jump-tech-frontend/app-config';
import { JumptechDateSettings } from '@jump-tech-frontend/domain';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../auth/services/authentication.service';
import { PathwayConfiguration, PathwayConfigurationService } from '../auth/services/pathway-configuration.service';
import { CustomLookupService } from '../core/custom-lookup.service';
import { TenantConfig, User } from '../core/domain/user';
import { ProjectConfigurationService } from '../core/project-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class SupportUserService {
  constructor(
    private projectConfigurationService: ProjectConfigurationService,
    private customLookupService: CustomLookupService,
    private translocoService: TranslocoService
  ) {}

  public updateStylesAndLogo(user: User, hostConfiguration: PathwayConfiguration) {
    if (user && hostConfiguration && AuthenticationService.getTier() === 'support') {
      this.setUpTranslations(user.accessInfo.configuration);
      const logoUrl = `${environment.staticDistribution}/tenant-logos/${user.tenant}.png`;
      const stylesUrl = `${environment.staticDistribution}/tenant-styles/${user.tenant}.css`;

      PathwayConfigurationService.persistConfigItem(hostConfiguration.tenant, 'logoUrl', logoUrl);
      PathwayConfigurationService.persistConfigItem(hostConfiguration.tenant, 'stylesUrl', stylesUrl);
    }
  }

  public flushCache(user: User) {
    if (!user && AuthenticationService.getTier() === 'support') {
      this.projectConfigurationService.flush();
      this.customLookupService.flush();
    }
  }

  private setUpTranslations(config: TenantConfig) {
    let allowedLanguageList = [];
    if (config) {
      allowedLanguageList = [...(config?.cognitoCountryConfig?.allowedLocales || []), config?.locale, 'en-GB'];
    }
    this.translocoService.setAvailableLangs(allowedLanguageList);

    for (const desiredLang of navigator.languages) {
      const matchedLanguage = pickLanguage(allowedLanguageList, desiredLang, { loose: true });
      if (matchedLanguage) {
        this.translocoService.setActiveLang(matchedLanguage);
        if (!environment.production) {
          console.log('\x1b[32m%s\x1b[0m', `Active Support LANG: [${desiredLang}]`);
        }
        break;
      } else {
        this.translocoService.setActiveLang('en-GB');
      }
      if (!environment.production) {
        console.log('\x1b[35m%s\x1b[0m', `Desired LANG: [${desiredLang}]`);
      }
    }
    JumptechDateSettings.defaultLocale = this.translocoService.getActiveLang();
  }
}
