import { BehaviorSubject } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import {
  VideoPlayerControlsParams,
  VideoPlayerControlsTranslations,
  VideoPlayerControlsViewModel,
  VideoPlayerState
} from './video-player-controls.model';
import { TRANSLATIONS_PROVIDER } from '../../../../translations.token';

@Injectable()
export class VideoPlayerControlsPresenter {
  private readonly recordingState$: BehaviorSubject<VideoPlayerState> = new BehaviorSubject<VideoPlayerState>(
    'stopped'
  );
  private readonly enableDeletion$ = new BehaviorSubject<boolean>(false);
  private readonly enableRecording$ = new BehaviorSubject<boolean>(false);
  private readonly translationsProvider = inject(TRANSLATIONS_PROVIDER);

  private getTranslations(): VideoPlayerControlsTranslations {
    return {
      confirmDeletion: this.translationsProvider.getTranslation('video.confirmDeletion'),
      startRecording: this.translationsProvider.getTranslation('video.record')
    };
  }

  public load(vm: BehaviorSubject<VideoPlayerControlsViewModel | null>, params: VideoPlayerControlsParams) {
    const translations = this.getTranslations();
    this.enableDeletion$.next(params.canDelete);
    this.enableRecording$.next(params.canRecord);
    vm.next({
      translations,
      showRecordControls: params.showRecordControls,
      enableDeletion$: this.enableDeletion$,
      enableRecording$: this.enableRecording$,
      recordingState$: this.recordingState$
    });
  }

  public enableDeletion(state: boolean) {
    this.enableDeletion$.next(state);
  }

  public enableRecording(state: boolean) {
    this.enableRecording$.next(state);
  }

  public updateRecordingState(state: VideoPlayerState) {
    this.recordingState$.next(state);
  }
}
