import {
  DOCUMENT_STATE_KEY_PREFIX,
  DocumentActionType,
  DocumentState,
  DocumentStateTransition,
  DocumentStateTranslationSuffix,
  DocumentType
} from './document-pack.model';

export const GetStateLabelTranslation = (key, i18n) => {
  const keySuffix = key.split(`${DOCUMENT_STATE_KEY_PREFIX}.`).pop();
  switch (keySuffix) {
    case DocumentStateTranslationSuffix.AWAITING_DOCUMENT:
      return i18n.awaitingDocument;
    case DocumentStateTranslationSuffix.NOT_REQUIRED:
      return i18n.notRequired;
    case DocumentStateTranslationSuffix.MISSING_INFO:
      return i18n.missingInfo;
    case DocumentStateTranslationSuffix.AWAITING_APPROVAL:
      return i18n.awaitingApproval;
    case DocumentStateTranslationSuffix.APPROVED:
      return i18n.approved;
    case DocumentStateTranslationSuffix.COMPLETED:
      return i18n.completed;
    case DocumentStateTranslationSuffix.PENDING_INSTALL:
      return i18n.pendinginstalled;
    case DocumentStateTranslationSuffix.PENDING_REVIEWING:
      return i18n.pendingreviewing;
    case DocumentStateTranslationSuffix.READY_TO_REVIEW:
      return i18n.readyToReview;
    case DocumentStateTranslationSuffix.READY_FOR_DNO:
      return i18n.pendingreadyForDno;
    case DocumentStateTranslationSuffix.AWAITING_RESPONSE:
      return i18n.awaitingResponse;
    case DocumentStateTranslationSuffix.SENT:
      return i18n.sent;
    case DocumentStateTranslationSuffix.GENERATING:
      return i18n.generating;
  }
};

export const GetPreviewMessage = (state: DocumentState, i18n) => {
  switch (state) {
    case DocumentState.AWAITING_APPROVAL:
      return i18n.previewApproval;
    case DocumentState.APPROVED:
      return i18n.previewWithdrawApproval;
    case DocumentState.READY_TO_REVIEW:
      return i18n.reviewingAutoGenDoc;
    case DocumentState.COMPLETED:
      return i18n.viewCompletedDoc;
  }
};

export const GetStateTransition = (state: DocumentState) => {
  switch (state) {
    case DocumentState.AWAITING_DOCUMENT:
      return DocumentStateTransition.UPLOADED;
    case DocumentState.AWAITING_APPROVAL:
      return DocumentStateTransition.APPROVED;
    case DocumentState.APPROVED:
      return DocumentStateTransition.WITHDRAW_APPROVAL;
    case DocumentState.NOT_REQUIRED:
      return DocumentStateTransition.REQUIRED;
    case DocumentState.READY_TO_REVIEW:
      return DocumentStateTransition.REVIEWED;
    case DocumentState.COMPLETED:
      return DocumentStateTransition.NONE;
  }
};

export const GetStateTransitionMessage = (transition: DocumentStateTransition, i18n) => {
  switch (transition) {
    case DocumentStateTransition.APPROVED:
      return i18n.approveDocumentInProgress;
    case DocumentStateTransition.WITHDRAW_APPROVAL:
      return i18n.withdrawDocumentApprovalInProgress;
    case DocumentStateTransition.UPLOADED:
      return i18n.manualUploadInProgress;
    case DocumentStateTransition.NOT_REQUIRED:
      return i18n.settingNotRequired;
  }
};

export const GetStateTransitionLabel = (state: DocumentState, isSendingEmailOnApproval: boolean, i18n) => {
  switch (state) {
    case DocumentState.AWAITING_DOCUMENT:
      return i18n.upload;
    case DocumentState.AWAITING_APPROVAL:
      if (isSendingEmailOnApproval) {
        return i18n.approveAndSend;
      } else {
        return i18n.approve;
      }
    case DocumentState.APPROVED:
      return i18n.withdrawApproval;
    case DocumentState.READY_TO_REVIEW:
      return i18n.sendForApproval;
  }
};

export const GetActionLabel = (type: DocumentActionType, currentState: DocumentState, i18n) => {
  switch (type) {
    case DocumentActionType.UPLOAD_DOCUMENT:
      return i18n.upload;
    case DocumentActionType.PREVIEW_DOCUMENT:
      if (currentState === DocumentState.COMPLETED) {
        return i18n.view;
      }
      return i18n.review;
    case DocumentActionType.REVIEW_MISSING_INFO:
      return i18n.view;
    case DocumentActionType.REQUIRES_RESPONSE:
      return i18n.response;
  }
};

export const GetUploadHint = (doc, i18n) => {
  if (
    doc.documentType === DocumentType.AUTO &&
    (doc.state === DocumentState.READY_TO_REVIEW || doc.state === DocumentState.AWAITING_APPROVAL)
  ) {
    return i18n.uploadHintReadyToReview;
  }

  return i18n.uploadHintDefault;
};

export const GetRegenerateHint = (doc, i18n) => {
  if (doc.missingInfo.length > 0) {
    return i18n.regenerateDocumentMissingInfoHint;
  }
  return i18n.regenerateDocumentHint;
};

export const GetI18nKeys = () => {
  return [
    'documentManager.addDocumentPack',
    'documentManager.removeDocumentPack',
    'documentManager.retry',
    'documentManager.uploading',
    'documentManager.uploadHintReadyToReview',
    'documentManager.uploadHintDefault',
    'documentManager.uploadAlternativeHint',
    'documentManager.reviewingVersion',
    'documentManager.stage',
    // states
    'documentManager.state.manualOverride',
    'documentManager.state.awaitingDocument',
    'documentManager.state.notRequired',
    'documentManager.state.missingInfo',
    'documentManager.state.awaitingApproval',
    'documentManager.state.approved',
    'documentManager.state.completed',
    'documentManager.state.pending',
    'documentManager.state.readyToReview',
    'documentManager.state.awaitingResponse',
    'documentManager.state.sent',
    'documentManager.state.generating',
    // actions
    'documentManager.action.upload',
    'documentManager.action.regenerate',
    'documentManager.action.approve',
    'documentManager.action.approveAndSend',
    'documentManager.action.cancel',
    'documentManager.action.close',
    'documentManager.action.review',
    'documentManager.action.view',
    'documentManager.action.withdrawApproval',
    'documentManager.action.sendForApproval',
    'documentManager.action.response',
    'documentManager.action.complete',
    'documentManager.action.revise',
    'documentManager.action.uploadEvidence',
    // messages
    'documentManager.message.addingDocumentPack',
    'documentManager.message.previewApproval',
    'documentManager.message.previewWithdrawApproval',
    'documentManager.message.openingPreview',
    'documentManager.message.openingResponse',
    'documentManager.message.uploadingResponseEvidence',
    'documentManager.message.openingMissingInfo',
    'documentManager.message.reviewMissingInfo',
    'documentManager.message.uploadingDocument',
    'documentManager.message.regeneratingDocument',
    'documentManager.message.regenerateDocumentHint',
    'documentManager.message.regenerateDocumentMissingInfoHint',
    'documentManager.message.settingNotRequired',
    'documentManager.message.savingDocument',
    'documentManager.message.manualUploadHint',
    'documentManager.message.manualUploadInProgress',
    'documentManager.message.reviewingAutoGenDoc',
    'documentManager.message.viewCompletedDoc',
    'documentManager.message.approveDocumentInProgress',
    'documentManager.message.withdrawDocumentApprovalInProgress',
    'documentManager.message.responseCompleteHint',
    'documentManager.message.pleaseProvideResponse',
    'documentManager.message.revisingDocument',
    'documentManager.message.completingDocument',
    'documentManager.message.documentOutstandingSingle',
    'documentManager.message.documentOutstandingMultiple',

    // other labels
    'documentManager.label.approvedLabel',
    'documentManager.label.responseLabel',
    'documentManager.label.rejectedLabel'
  ];
};
