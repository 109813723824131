import { DestroyRef, EventEmitter, inject, Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, filter } from 'rxjs';
import { SearchProviderFactory } from '../../providers/search-provider.factory';
import { COMMONS_TRANSLATIONS_PROVIDER } from '@jump-tech-frontend/app-config';
import { AddressProvider } from '../../domain/address-provider';
import { FormControl } from '@angular/forms';
import { ISearchProvider } from '../../domain/search-provider';
import { AddressSearchResultType, AddressSearchViewModel } from './address-search.vm';
import { IAddressV2 } from '../../domain/address-v2';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class AddressSearchPresenter {
  private readonly translationsProvider = inject(COMMONS_TRANSLATIONS_PROVIDER);
  private readonly searchProviderFactory = inject(SearchProviderFactory);
  private destroyRef = inject(DestroyRef);
  private noResult$ = new BehaviorSubject<boolean>(false);
  private searchProvider: ISearchProvider;
  private searchResults$ = new BehaviorSubject<
    { id: string; description: string; type?: AddressSearchResultType }[] | null
  >(null);
  public selectedResult: EventEmitter<IAddressV2>;

  load(
    vm$: BehaviorSubject<AddressSearchViewModel | null>,
    countryCodes: string[],
    selectedResult: EventEmitter<IAddressV2>,
    label?: string,
    provider?: AddressProvider,
    disabled?: boolean
  ) {
    this.searchProvider = this.searchProviderFactory.getProvider(countryCodes, provider);
    const searchTermControl = this.makeSearchFormControl(disabled);
    this.selectedResult = selectedResult;
    vm$.next({
      provider: this.searchProvider.name,
      countryCodes,
      label,
      noResult$: this.noResult$,
      noResultsFound: this.translationsProvider.getTranslation('noResultsFound') ?? '',
      searchResults$: this.searchResults$,
      searchTermControl
    });
  }

  private makeSearchFormControl(disabled?: boolean) {
    const searchTermControl = new FormControl<string>({ value: '', disabled: !!disabled });
    searchTermControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(200),
        filter(searchTerm => (searchTerm ?? '').length > 3)
      )
      .subscribe(async searchTerm => {
        if (searchTerm) {
          await this.search(searchTerm);
        }
      });
    return searchTermControl;
  }

  async search(searchTermEvent: string, container?: string | undefined) {
    this.noResult$.next(false);
    const searchResults = await this.searchProvider.search(searchTermEvent, container);
    if (!searchResults.length) {
      this.noResult$.next(true);
    }
    this.searchResults$.next(searchResults);
  }

  async retrieve(id: string, searchTerm: string) {
    this.searchResults$.next([]);
    this.selectedResult.next(await this.searchProvider.getResult(id, searchTerm));
  }

  async searchOrRetrieve(id: string, searchTerm: string, type: AddressSearchResultType) {
    if (type === 'Address' || type === 'ADD') {
      await this.retrieve(id, searchTerm);
    } else {
      await this.search(searchTerm, id);
    }
  }
}
