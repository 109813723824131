<div #addEngineerForm>
  <div class="schedule-more-details__add-row" *ngIf="!vm.isAddingEngineer">
    <jui-button
      (click)="onAddEngineer.emit(addEngineerFormEl)"
      [attr.data-qa]="vm.qaAddEngineerBtn"
      size="sm"
      color="secondary"
      display="ghost"
    >
      <span slot="icon" class="material-icons">add</span>
      {{ vm.i18nAddTradesPersonBtn }}
    </jui-button>
  </div>

  @if (vm.showEngineerRequiredError) {
  <div class="schedule-more-details__form-error">{{ vm.i18nEngineerRequiredError }}</div>
  }

  <div class="schedule-more-details__add-row" *ngIf="vm.isAddingEngineer" [formGroup]="vm.addEngineerForm">
    <div class="schedule-more-details__add-row-item">
      <ng-select
        class="schedule-more-details__select"
        [items]="vm.allEngineers"
        bindLabel="name"
        [placeholder]="vm.i18nSelectEngineerPlaceholder"
        formControlName="engineerToAdd"
        [attr.data-qa]="vm.qaEngineerSelect"
        appendTo="body"
        [multiple]="false"
        [clearable]="false"
        (keyup.enter)="onConfirmAddEngineer.emit()"
      >
      </ng-select>
    </div>
    <div class="schedule-more-details__add-row-item">
      <jui-button
        size="sm"
        (click)="onConfirmAddEngineer.emit()"
        [disabled]="vm.addEngineerForm.invalid"
        [attr.data-qa]="vm.qaConfirmAddEngineerBtn"
      >
        {{ vm.i18nConfirmBtn }}
      </jui-button>
    </div>
    <div class="schedule-more-details__add-row-item">
      <jui-button size="sm" color="low" (click)="onCancelAddEngineer.emit()" [attr.data-qa]="vm.qaCancelAddEngineerBtn">
        {{ vm.i18nCancelBtn }}
      </jui-button>
    </div>
  </div>
</div>
