import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDocumentVm } from '../../document-pack.model';
import { UploadFileInput } from '../../../../core/directives/upload-file-input';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-document-pack-document-row',
  templateUrl: './document-pack-document-row.component.html',
  styleUrls: ['../../document-pack.component.scss'],
  standalone: true,
  imports: [NgIf, CoreComponentsAngularModule, UploadFileInput]
})
export class DocumentPackDocumentRowComponent {
  @Input() doc: IDocumentVm;
  @Input() projectId;
  @Input() acceptedFileTypes;
  @Input() actionButtonDisabled;
  @Input() inProgress;
  @Input() locked = false;

  @Output() actionClick: EventEmitter<HTMLInputElement> = new EventEmitter();
  @Output() requiredToggle: EventEmitter<IDocumentVm> = new EventEmitter();
  @Output() fileChange: EventEmitter<any> = new EventEmitter();
  @Output() onAttachmentSaved: EventEmitter<any> = new EventEmitter();
}
