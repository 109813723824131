import { ProjectFilter } from './role';
import { SubTenant } from './subTenant';

export interface User {
  tenant: string;
  id: string; // Cognito sub
  userName: string;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  roles: string[];
  email: string;
  phoneNumber: string;
  enabled: boolean;
  label: string;
  created_on: string;
  created_by: string;
  updated_on: string;
  updated_by: string;
  team?: string;
  teams?: string[];
  subTenant?: { id: string; name: string };
  team_name?: string;
  accountStatus?: string;
  accessInfo: AccessInfo;
}

export interface AccessInfo {
  isAdmin: boolean;
  isSuperAdmin: boolean;
  team_name: string;
  teams: AccessInfoTeam[];
  subTenants: SubTenant[];
  roles: AccessInfoRole[];
  features: AccessInfoFeature[];
}

export interface AccessInfoTeam {
  id: string;
  name: string;
}

export interface AccessInfoRole {
  id: string;
  name: string;
}

export interface AccessInfoFeature {
  enabled?: boolean;
  id?: string;
  name: string;
  type: string;
  path?: string;
  data?: any;
  filter?: ProjectFilter[];
}
