import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalConfig } from '../../domain/confirm-modal.config';
import { TranslocoModule } from '@ngneat/transloco';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf, NgFor } from '@angular/common';

// TODO : introduce presenter

@Component({
  selector: 'confirm-modal',
  templateUrl: 'confirm-modal.component.html',
  styleUrls: ['confirm-modal.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, CoreComponentsAngularModule, TranslocoModule]
})
export class ConfirmModalComponent {
  @Input() config!: ConfirmModalConfig;
  @Input() parameters: any;

  constructor(public activeModal: NgbActiveModal) {}

  confirm($event: Event) {
    $event.preventDefault();
    this.activeModal.close(true);
  }

  cancel($event: Event) {
    $event.preventDefault();
    this.activeModal.dismiss();
  }
}
