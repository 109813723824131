import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { retryBackoff } from 'backoff-rxjs';
import { environment } from 'apps/pathway/src/environments/environment';
import { datadogLogs } from '@datadog/browser-logs';

@Injectable({
  providedIn: 'root'
})
export class HttpRetryBackoffInterceptor implements HttpInterceptor {
  constructor() {}

  private ALLOWED_RETRY_METHODS = ['GET', 'DELETE'];

  private shouldRetryRequest(request, status): boolean {
    return status === 429 && this.ALLOWED_RETRY_METHODS.includes(request.method);
  }

  private couldRetryRequest(request, status): boolean {
    return status === 429 || (status === 404 && request.url.includes('/store'));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipRetry = request.headers.has('skip-retry');
    if (skipRetry) {
      request = request.clone({
        headers: request.headers.delete('skip-retry')
      });
      return next.handle(request);
    }
    return next.handle(request).pipe(
      retryBackoff({
        initialInterval: environment.httpRetryConfig.initialInterval,
        maxRetries: environment.httpRetryConfig.maxRetries,
        resetOnSuccess: environment.httpRetryConfig.resetOnSuccess,
        shouldRetry: (error: HttpErrorResponse): boolean => {
          if (this.shouldRetryRequest(request, error.status)) {
            datadogLogs.logger.log(
              `Retry HTTP [${request.method}] request because of server error [${error.status}]`,
              error
            );
            return true;
          } else if (this.couldRetryRequest(request, error.status)) {
            datadogLogs.logger.log(
              `Potential retry HTTP [${request.method}] request because of server error [${error.status}]`,
              error
            );
            return false;
          } else {
            return false;
          }
        }
      })
    );
  }
}
