export interface SelectedAddress {
  addressCode: string;
  address: Address | null;
}

export interface Address {
  line1: string;
  postCode?: string;
  line2?: string;
  line3?: string;
  line4?: string;
  locality?: string;
  town?: string;
  county?: string;
  state?: string;
  country?: string;
  longitude?: string;
  latitude?: string;
  summaryline?: string;
}

export interface AddressMapping {
  from: string;
  to: keyof Address;
}

export interface PostCodeLookupProvider {
  name: string;
  apiKey: { name?: string; value?: string | null };
  addressEndpoint: string;
  match: string;
  endpointParams?: { [key: string]: boolean | number | string };

  getAddresses(addressCode: string): Promise<Address[]>;

  formatAddressCode(addressCode: string): string;
}
