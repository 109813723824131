import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InvalidItemsModel } from '../../task.model';
import { InvalidItemsModalComponent } from './invalid-items-modal.component';
import { InvalidItemsPresenter } from './invalid-items.presenter';

@Component({
  selector: 'task-invalid-items-component',
  template: `<div [attr.data-qa]="'invalidItemsPlaceholder'"></div>`,
  standalone: true,
  providers: [InvalidItemsPresenter]
})
export class InvalidItemsComponent implements OnInit {
  private modalRef?: NgbModalRef;

  constructor(private presenter: InvalidItemsPresenter, private modalService: NgbModal) {}

  ngOnInit() {
    this.presenter.load((vm: InvalidItemsModel) => {
      if (vm?.messages?.length && !this.modalRef) {
        this.modalRef = this.modalService.open(InvalidItemsModalComponent);
        this.modalRef.componentInstance.title = vm.title;
        this.modalRef.componentInstance.messages = vm.messages;
        this.modalRef.result.finally(() => {
          this.presenter.clearInvalidItems();
          this.modalRef = null;
        });
      }
    });
  }
}
