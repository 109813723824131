import { EventInput, LocaleInput } from '@fullcalendar/core';
import allLocales from '@fullcalendar/core/locales-all';
import defaultLocale from '@fullcalendar/core/locales/en-gb';
import { JumptechDate, JumptechDateSettings } from '@jump-tech-frontend/domain';
import { ProjectStatus } from '../../admin/user-management/domain/types';
import { CalendarEvent } from '../../core/domain/event';
import { Job } from '../../core/domain/job';
import {
  JOB_STATUSES_V2,
  JOB_STYLES,
  JOB_STYLES_BACKGROUND_COLOR,
  JOB_STYLES_LEGACY,
  JOB_STYLES_SELECTED_COLOR,
  TEMP_EVENT_ID
} from './schedule-constants';
import {
  DateRangeSelectInfo,
  JobDateTimeFormControls,
  JobInformation,
  JobStyle,
  ScheduleQuestion
} from './schedule-types';

export const OTHER_APPOINTMENTS_V2_BACKGROUND_COLOUR = '#FFD046';
export function resourcesFromUsers(users: { key: string; value: string }[]) {
  return users.map(user => ({
    ...user,
    id: user.value.split('|')[1],
    title: user.key
  }));
}

const fullDayHoursStart = 0;
const fullDayHoursEnd = 23;
const fallbackEventDurationHours = 2;

allLocales.find(x => x.code);

const findSupportedLocale = (): LocaleInput => {
  const found = allLocales.find(x => JumptechDateSettings.defaultLocale.toLowerCase().startsWith(x.code));
  if (found) {
    return found;
  }
  return defaultLocale;
};

const supportedLocale = findSupportedLocale();

export const standardCalendarConfiguration: any = {
  schedulerLicenseKey: '0173554956-fcs-1657206500',
  slotLabelInterval: '01:00',
  slotDuration: '00:30:00',
  dayHeaderFormat: { weekday: 'short' },
  slotLabelFormat: [{ hour: 'numeric', minute: 'numeric' }],
  locales: allLocales,
  locale: supportedLocale,
  timeZone: JumptechDateSettings.defaultTimeZone,
  headerToolbar: {
    left: 'prev,next today',
    center: '',
    right: 'dayGridMonth,resourceTimelineWeek,resourceTimelineDay'
  },
  weekends: true,
  businessHours: {
    daysOfWeek: [1, 2, 3, 4, 5, 6],
    startTime: `0${fullDayHoursStart}:00`,
    endTime: `${fullDayHoursEnd}:59`
  },
  slotMinTime: `0${fullDayHoursStart}:00:00`,
  slotMaxTime: `${fullDayHoursEnd}:59:00`,
  firstDay: 1,
  dayMaxEventRows: 4,
  eventTimeFormat: {
    hour: 'numeric',
    minute: 'numeric'
  },
  views: {
    resourceTimelineWeek: {
      type: 'resourceTimeline',
      duration: { days: 7 },
      buttonText: 'week',
      slotLabelFormat: [
        { weekday: 'short', day: '2-digit' },
        { hour: 'numeric', minute: 'numeric' } // Second row
      ],
      expandRows: true
    },
    resourceTimelineRollingMonth: {
      type: 'resourceTimeline',
      duration: { days: 30 },
      buttonText: 'Rolling Month',
      slotLabelFormat: [{ weekday: 'short', day: 'numeric' }],
      slotLabelInterval: { days: 1 },
      eventMinWidth: 50,
      slotMinWidth: 50,
      resourceAreaWidth: 200
    }
  },
  resourceOrder: 'title'
};

export const pad = (i: number): string => (i < 10 ? `0${i}` : `${i}`);

/**
 * @return the style for the given status
 */
export function getStyleForStatus(status: string, rollingMonthViewEnabled: boolean): JobStyle {
  if (!rollingMonthViewEnabled) {
    return JOB_STYLES_LEGACY[status] || JOB_STYLES_LEGACY.CREATED;
  }
  return (
    { ...JOB_STYLES[status], ...JOB_STYLES_BACKGROUND_COLOR[mapLegacyStatusesToNewStatuses([status])[0]] } || {
      ...JOB_STYLES.CREATED,
      ...JOB_STYLES_BACKGROUND_COLOR.SCHEDULED
    }
  );
}

export function getSelectedStyle(): { outline: string } {
  return JOB_STYLES_SELECTED_COLOR;
}

export function mapLegacyStatusesToNewStatuses(legacyStatuses: string[]): string[] {
  const newStatuses = [];
  for (const status of legacyStatuses) {
    Object.keys(JOB_STATUSES_V2).forEach(key => {
      if (JOB_STATUSES_V2[key].legacyStatuses.includes(status) && !newStatuses.includes(key)) {
        newStatuses.push(key);
      }
    });
  }

  return newStatuses;
}

/**
 * This method is ONLY to be used by the old schedule page and the old in-project scheduler.
 * Once they are completely deprecated this method can be removed.
 */
export function generateEvent(
  evt: CalendarEvent,
  rollingMonthViewEnabled: boolean,
  isExistingEvent?: boolean,
  bgColour = 'lightgrey',
  textColour = '#222'
) {
  if (evt.eventType === 'Job') {
    const j = evt.job as Job;
    const style = getStyleForStatus(j.status, rollingMonthViewEnabled);

    const start = j.scheduledDate ? new Date(j.scheduledDate) : new Date(evt.startIso);
    const end = j.endDate ? new Date(j.endDate) : new Date(j.scheduledDate);
    if (!j.endDate) {
      end.setHours(end.getHours() + 4);
    }

    const event: EventInput = {
      ...j,
      eventType: evt.eventType,
      title: j.type,
      resourceIds: j.jobAssignments?.length ? j.jobAssignments.map(x => x.assignedTo) : [j.assignedTo],
      start: JumptechDate.from(start).toIso(),
      end: JumptechDate.from(end).toIso(),
      borderColor: style.backgroundColor,
      backgroundColor: style.backgroundColor,
      textColor: style.color,
      classNames: ['jt-event']
    };
    if (isExistingEvent) {
      event.editable = true;
      event.durationEditable = true;
      event.resourceEditable = true;
      event.startEditable = true;
      // @ts-ignore it's always going to be an array because we've set it above
      event.classNames.push('jt-current-event');
    }
    return event;
  } else {
    let start;
    let end;
    if (evt.allDayEvent) {
      start = JumptechDate.from(evt.localStartDate).startOf('day').toIso();
      end = JumptechDate.from(evt.localEndDate).endOf('day').toIso();
    } else {
      start = evt.startIso;
      end = evt.endIso;
    }

    return {
      ...evt,
      id: evt.id,
      eventType: evt.eventType,
      title: evt.title ? evt.title : '',
      resourceId: evt.userIds?.[0],
      start,
      end,
      borderColor: rollingMonthViewEnabled ? OTHER_APPOINTMENTS_V2_BACKGROUND_COLOUR : bgColour,
      backgroundColor: rollingMonthViewEnabled ? OTHER_APPOINTMENTS_V2_BACKGROUND_COLOUR : bgColour,
      textColor: textColour ?? 'black',
      classNames: ['jt-event', 'jt-absence']
    };
  }
}

/**
 * This method is used exclusively by the v2 schedule component
 */
export function generateScheduleV2Event(
  evt: CalendarEvent,
  rollingMonthViewEnabled: boolean,
  bgColour = 'lightgrey',
  textColour = '#222',
  context?: string,
  selectedJobId?: string
) {
  if (evt.eventType === 'Job') {
    const j = evt.job as Job;
    const style = getStyleForStatus(j.status, rollingMonthViewEnabled);

    const start = j.scheduledDate ? new Date(j.scheduledDate) : new Date(evt.startIso);
    const end = j.endDate ? new Date(j.endDate) : new Date(j.scheduledDate);
    if (!j.endDate) {
      end.setHours(end.getHours() + 4);
    }

    const event: EventInput = {
      ...j,
      eventType: evt.eventType,
      title: j.type,
      resourceIds: j.jobAssignments ? j.jobAssignments.map(x => x.assignedTo) : [j.assignedTo],
      start: JumptechDate.from(start).toIso(),
      end: JumptechDate.from(end).toIso(),
      borderColor: style.backgroundColor,
      backgroundColor: style.backgroundColor,
      textColor: style.color,
      classNames: ['jt-event']
    };
    const canBeEditable = context === 'project' && (selectedJobId === j.id || evt.id === TEMP_EVENT_ID);
    if (canBeEditable) {
      event.editable = true;
      event.durationEditable = true;
      event.resourceEditable = true;
      event.startEditable = true;
      event.jobInformation = generateJobInformationForExistingEvent(j);
    } else if (context !== 'project' && JOB_STATUSES_V2['SCHEDULED'].legacyStatuses.includes(j.status)) {
      event.editable = true;
      event.durationEditable = true;
      event.resourceEditable = true;
      event.startEditable = true;
      event.jobInformation = generateJobInformationForExistingEvent(j);
    }
    return event;
  } else {
    let start;
    let end;
    if (evt.allDayEvent) {
      start = JumptechDate.from(evt.localStartDate).startOf('day').toIso();
      end = JumptechDate.from(evt.localEndDate).endOf('day').toIso();
    } else {
      start = evt.startIso;
      end = evt.endIso;
    }

    return {
      ...evt,
      id: evt.id,
      eventType: evt.eventType,
      title: evt.title ? evt.title : '',
      resourceId: evt.userIds?.[0],
      start,
      end,
      borderColor: rollingMonthViewEnabled ? OTHER_APPOINTMENTS_V2_BACKGROUND_COLOUR : bgColour,
      backgroundColor: rollingMonthViewEnabled ? OTHER_APPOINTMENTS_V2_BACKGROUND_COLOUR : bgColour,
      textColor: textColour ?? 'black',
      classNames: ['jt-event', 'jt-absence']
    };
  }
}

export function generateEventPreAssignment(job: JobInformation, resource: string, hidden: boolean): EventInput {
  const start = JumptechDate.from(new Date(new Date().setHours(9, 0, 0, 0))).toIso();
  const end = JumptechDate.from(start)
    .plus({ hours: job.defaultDuration ?? 2 })
    .toIso();
  const preAssignmentEvent = {
    id: job.id,
    eventType: 'Job',
    title: job.type,
    start,
    end,
    resourceId: '',
    editable: true,
    durationEditable: true,
    resourceEditable: true,
    startEditable: true,
    jobDuration: job.defaultDuration,
    jobInformation: job,
    status: 'PROVISIONALLY_SCHEDULED',
    dragged: true,
    jobAssigned: false,
    backgroundColor: getStyleForStatus('PROVISIONALLY_SCHEDULED', true).backgroundColor,
    classNames: ['jt-event', 'jt-event-preassignment']
  };

  // We want to hide the event on views with resources (Rolling Month, Week, Day) on first creation until
  // a tradesperson is assigned
  if (hidden) {
    preAssignmentEvent.classNames.push('jt-event--hidden');
    preAssignmentEvent.resourceId = resource;
  }

  return preAssignmentEvent;
}

function generateJobInformationForExistingEvent(job: Job): JobInformation {
  return {
    id: job.id,
    projectId: job.projectId,
    customerFirstName: job.firstName,
    customerLastName: job.lastName,
    type: job.type,
    tenantType: job.tenantType,
    jobAssignments: job.jobAssignments,
    address: {
      ...job.address
    },
    contactInfo: {
      email: job.email,
      telephoneNumber: job.phoneNumber
    },
    startDateTimestamp: job.startDate,
    endDateTimestamp: job.endDate,
    defaultDuration: job.defaultDuration ?? 2,
    isInitialSchedule: job.isInitialSchedule
  };
}

export function createTemporaryEvent(
  evt,
  rollingMonthViewEnabled: boolean,
  newEventTranslation: string,
  jobType?: string
): EventInput {
  const style = getStyleForStatus(ProjectStatus.SCHEDULED, rollingMonthViewEnabled);
  return {
    id: TEMP_EVENT_ID,
    eventType: 'Job',
    title: jobType || newEventTranslation,
    resourceId: evt.resource.id,
    start: evt.start,
    end: evt.end,
    startDate: evt.startDate ?? null,
    endDate: evt.endDate ?? null,
    extendedProps: {
      assignedToDisplayName: evt.resource.title,
      background: evt.extendedProps.background,
      color: 'var(--jds-theme-schedule-dts-color-text)',
      eventType: 'Job',
      address: evt.extendedProps.address ?? null
    },
    editable: true,
    durationEditable: true,
    resourceEditable: true,
    startEditable: true,
    backgroundColor: style.backgroundColor,
    borderColor: 'var(--jds-theme-schedule-dts-color-border)',
    textColor: 'var(--jds-theme-schedule-dts-color-text)',
    classNames: ['jt-event', 'jt-current-event']
  };
}

export function renderEvent(
  evt,
  rollingMonthViewEnabled: boolean,
  eventType?: any,
  engineerName?: string,
  translations?: any
) {
  const { event, el, view } = evt;
  event.isLead = evt.isLead;
  switch (event.extendedProps.eventType) {
    case 'Job': {
      renderJob(event, rollingMonthViewEnabled, el, view);
      return;
    }
    default: {
      if (eventType) {
        renderAbsence(rollingMonthViewEnabled, event, el, view, eventType, engineerName, translations);
      }
    }
  }
}

function renderJob(event, rollingMonthViewEnabled: boolean, el: HTMLElement, view) {
  const style = getStyleForStatus(event.extendedProps.status, rollingMonthViewEnabled);
  if (!el) {
    return;
  }

  if (event.id === TEMP_EVENT_ID || !event.id) {
    el.classList.add('jt-temp-event');
  }

  if (el.classList.contains('jt-event--hidden') && event.extendedProps.jobInformation.jobAssignments.length) {
    el.classList.remove('jt-event--hidden');
    el.classList.add('jt-temp-event');
  }
  const postcode = event.extendedProps.address ? event.extendedProps.address.postCode : '';
  let elements: HTMLCollectionOf<Element> = el.getElementsByClassName('fc-event-title fc-sticky');
  if (!elements?.length) {
    elements = el.getElementsByClassName('fc-event-title');
  }
  if (elements.length !== 1) {
    return;
  }

  if (event.extendedProps.dragged) {
    el.setAttribute('data-eventId', TEMP_EVENT_ID);
  } else {
    el.setAttribute('data-eventId', event.id);
  }

  const titleElement = elements[0];
  if (view.type.startsWith('dayGrid')) {
    const startTime = JumptechDate.from(event.start).toTimeFormat();
    const leadEngineer =
      event.extendedProps?.jobInformation?.jobAssignments.find(x => x.assignmentType === 'LEAD')
        ?.assignedToDisplayName ?? event.extendedProps?.assignedToDisplayName;
    el.style.backgroundColor = style.backgroundColor;
    el.style.color = event.extendedProps.color ? event.extendedProps.color : style.color;
    const titleElementHtmlFrag = `
        <div class="fc-content">
          <div class="item event-state">
            <div class="event-header-time">${startTime}</div>
            <div class="event-header-title">${event.title}</div>
            <div class="event-header-icon">
              <i class="material-icons">
                ${style.icon}
              </i>
            </div>
          </div>
          <div class="item extra-info">
            <div class="event-assigned"
                 style="color: ${el.style.color}">
              ${leadEngineer || ''}
            </div>
            <div class="event-postcode">${postcode}</div>
          </div>
        </div>
      `;

    // To support updating of events in the month view properly we need to ensure that we are creating a new .fc-content
    // block, otherwise it will create a duplicate calendar entry
    const previousElementIsExistingContent = titleElement.previousElementSibling?.className === 'fc-content';
    const nextElementIsExistingContent = titleElement.nextElementSibling?.className === 'fc-content';
    if (!previousElementIsExistingContent && !nextElementIsExistingContent) {
      titleElement.outerHTML = titleElementHtmlFrag;
    } else {
      if (previousElementIsExistingContent) {
        titleElement.previousElementSibling.remove();
      }
      if (nextElementIsExistingContent) {
        titleElement.nextElementSibling.remove();
      }
      titleElement.outerHTML = titleElementHtmlFrag;
    }
  } else {
    const start = JumptechDate.from(event.start).toTimeFormat();
    const end = JumptechDate.from(event.end).toTimeFormat();
    const updatedTime = `${start} - ${end}`;
    titleElement.closest('.fc-event-title-container').innerHTML = `
      <div class="week-day-view-event">
        <div class="item event-title">
          <span>${event.title}</span>
          <span class="event-postcode">
            <span>${postcode ? `(${postcode})` : ''}</span>
          </span>
        </div>
        <div class="item extra-info">
          <div class="event-time">${updatedTime}</div>
        </div>
      </div>
  `;
  }
  titleElement.classList.add('fc-event-title--hide');
}

function renderAbsence(
  rollingMonthViewEnabled: boolean,
  event,
  eventElement: HTMLElement,
  view,
  eventType,
  engineerName,
  translations
): void {
  let titleElements = eventElement.getElementsByClassName('fc-event-title-container');
  let isContainer = true;
  if (!titleElements?.length) {
    isContainer = false;
    titleElements = eventElement.getElementsByClassName('fc-event-title');
  }
  if (titleElements.length !== 1) {
    return;
  }
  const titleElement = titleElements[0];
  const title = event.title === 'null' || !event.title ? eventType.name : event.title;
  const allDayEvent: boolean = event.extendedProps.allDayEvent;

  if (rollingMonthViewEnabled) {
    renderAbsenceRollingMonthScheduleView(
      event,
      titleElement,
      eventElement,
      title,
      allDayEvent,
      isContainer,
      translations
    );
  } else {
    const startTime = JumptechDate.from(event.start).toTimeFormat();
    const timeFrag = allDayEvent
      ? `
      <div class="event-header-time">
        ${translations.allDay}
      </div>
      `
      : `
      <div class="event-header-time">
        ${startTime}
      </div>
  `;
    let isContainerFrag = `<div class="item event-state" style="background-color: ${eventType.colour}; border-color: ${eventType.colour}">`;
    if (!isContainer) {
      isContainerFrag = `<div class="item event-state" style="padding: 2px; border-radius: 2px; color: ${eventType.textColour}; background-color: ${eventType.colour}; border-color: ${eventType.colour}">`;
    }
    titleElement.outerHTML = `
    ${isContainerFrag}
      ${timeFrag}
      <div class="event-header-title" data-qa-event-title="${title}">${engineerName}: <span [innerHtml]='${title}'/></div>
      <div class="event-header-icon">
        <span class="material-icons">${eventType.icon}</span>
      </div>
    </div>`;
  }
}

function renderAbsenceRollingMonthScheduleView(
  event,
  titleElement: Element,
  eventElement: Element,
  title: string,
  allDayEvent: boolean,
  isContainer: boolean,
  translations: { [key: string]: string }
) {
  const startTime = JumptechDate.from(event.start).toTimeFormat();
  const timeFrag = allDayEvent
    ? `
      <div class="item extra-info">
        <div class="event-time">${translations.allDay}</div>
      </div>
      `
    : `
      <div class="item extra-info">
        <div class="event-time">${startTime}</div>
      </div>
  `;
  const eventStrokeBackgroundColour = '#CCA638';
  const backgroundStyle = `repeating-linear-gradient(135deg,${OTHER_APPOINTMENTS_V2_BACKGROUND_COLOUR},${OTHER_APPOINTMENTS_V2_BACKGROUND_COLOUR} 10px,${eventStrokeBackgroundColour} 10px,${eventStrokeBackgroundColour} 20px);`;
  let isContainerFrag = `<div class="item event-state" style="background: ${OTHER_APPOINTMENTS_V2_BACKGROUND_COLOUR} ${backgroundStyle}; border-color: ${OTHER_APPOINTMENTS_V2_BACKGROUND_COLOUR}; display: block; color: black;">`;

  // If the title element is not a fc-event-title-container, we want to add our own styled div next to fc-event-title and hide fc-event-title.
  // Otherwise we just set the innerHTML of fc-event-title-container to what we need.
  if (!isContainer) {
    isContainerFrag = `<div class="item event-state" style="padding: 2px; border-radius: 2px; color: black; background: ${eventStrokeBackgroundColour} ${backgroundStyle}; border-color: ${OTHER_APPOINTMENTS_V2_BACKGROUND_COLOUR}">`;

    // If we are updating an existing event element then remove the existing element before adding our updated one
    const existingContainerFrag = Array.from(eventElement.getElementsByClassName('item event-state'));
    if (existingContainerFrag.length) {
      existingContainerFrag[0].remove();
    }

    titleElement.insertAdjacentHTML(
      'beforebegin',
      `
    ${isContainerFrag}
      <div class="item event-header-title" data-qa-event-title="${title}">
        <span>${title}</span>
      </div>
      ${timeFrag}
    </div>`
    );
    titleElement.classList.add('fc-event-title--hide');
  } else {
    titleElement.innerHTML = `
    ${isContainerFrag}
      <div class="item event-header-title" data-qa-event-title="${title}">
        <span>${title}</span>
      </div>
      ${timeFrag}
    </div>`;
  }
}

export function validateEvent(
  { start, end, resource }: DateRangeSelectInfo,
  existingEvent: EventInput,
  question: ScheduleQuestion
): DateRangeSelectInfo {
  // This is either the start and end of the clicked cell, or if click and drag, it's the start of the
  // first cell to the end of the last cell
  const mStart = JumptechDate.from(start);
  const mEnd = JumptechDate.from(end);
  const existingStart = existingEvent && JumptechDate.from(existingEvent.start as string);
  const existingEnd = existingEvent && JumptechDate.from(existingEvent.end as string);
  const duration = existingEvent
    ? existingEnd.diff(existingStart, { units: 'hours' })
    : mEnd.diff(mStart, { units: 'hours' });

  const startResult = mStart;
  let endResult = mEnd;

  if (mEnd.diff(mStart, { units: 'hours' }).hours <= 1) {
    // if they're 1 hour apart or less, it probably means the user has just clicked in a cell
    if (existingEvent) {
      // let's default the duration to the same as before
      endResult = mStart.plus({ hours: duration.hours });
    } else if (question.defaultDuration) {
      const defaultDurationHours =
        typeof question.defaultDuration === 'string'
          ? Number.parseFloat(question.defaultDuration)
          : question.defaultDuration;
      endResult = mStart.plus({ hours: defaultDurationHours });
    } else {
      // set a fallback duration
      endResult = mStart.plus({ hours: fallbackEventDurationHours });
    }
  }
  return { start: startResult.toJsDate(), end: endResult.toJsDate(), resource };
}

export function calculateJobDuration(values: JobDateTimeFormControls) {
  const scheduledStartDate = values.scheduledStartDate;
  const scheduledStartTimeHour = values.scheduledStartTime.name.split(':')[0];
  const scheduledStartTimeMinute = values.scheduledStartTime.name.split(':')[1];
  const scheduledEndDate = values.scheduledEndDate;
  const scheduledEndTimeHour = values.scheduledEndTime.name.split(':')[0];
  const scheduledEndTimeMinute = values.scheduledEndTime.name.split(':')[1];

  const startDateString = new Date(
    scheduledStartDate.year,
    scheduledStartDate.month - 1,
    scheduledStartDate.day,
    parseInt(scheduledStartTimeHour),
    parseInt(scheduledStartTimeMinute)
  ).toISOString();

  const endDateString = new Date(
    scheduledEndDate.year,
    scheduledEndDate.month - 1,
    scheduledEndDate.day,
    parseInt(scheduledEndTimeHour),
    parseInt(scheduledEndTimeMinute)
  ).toISOString();

  return JumptechDate.from(endDateString).diff(JumptechDate.from(startDateString), {
    units: ['months', 'days', 'hours']
  });
}
