import { DestroyRef, inject, Injectable } from '@angular/core';
import { blobToBase64, getCamera, getMediaRecorder, waitForElement } from './video-utils';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class VideoRecorderService {
  private destroyRef = inject(DestroyRef);
  private recordedChunks: Blob[] = [];
  private stream: MediaStream | undefined;
  private video: HTMLVideoElement;
  private videoId: string;
  private mediaRecorder?: MediaRecorder;

  private flaggedForPause = false;
  private flaggedForStop = false;

  private recordingSubject = new BehaviorSubject<string | null>(null);
  private recording$ = this.recordingSubject.asObservable();

  private handleDataStopped = () => {
    this.video.srcObject = null;
    this.stream?.getTracks().forEach((track: MediaStreamTrack) => {
      track.stop();
    });
    if (this.recordedChunks.length === 0) {
      return;
    }
    const blob = new Blob(this.recordedChunks, { type: 'video/mp4' });
    this.video.src = URL.createObjectURL(blob);
    this.video.autoplay = false;
    this.video.controls = true;
    this.video.muted = false;
    this.video.load();
    blobToBase64(blob, 'video/mp4').then((base64: string) => {
      this.updateRecording(base64);
    });
  };

  private handleDataAvailable = (e: BlobEvent) => {
    if (e.data.size > 0) {
      this.recordedChunks.push(e.data);
    }
    if (this.flaggedForPause) {
      if (this.mediaRecorder?.state === 'recording') {
        this.mediaRecorder?.pause();
      }
      this.flaggedForPause = false;
    }
    if (this.flaggedForStop) {
      try {
        this.mediaRecorder?.stop();
      } catch (e) {
        console.error(e);
      }
      this.flaggedForStop = false;
    }
  };

  public async startRecording() {
    this.recordedChunks = [];
    this.flaggedForPause = false;
    this.flaggedForStop = false;
    const videoElement = (await waitForElement(this.videoId)) as HTMLVideoElement;
    if (!videoElement) {
      return;
    }
    this.video = videoElement;
    this.video.autoplay = true;
    this.video.controls = false;
    this.video.muted = true;
    this.stream = await getCamera('environment');
    if (!this.stream || this.flaggedForStop) {
      this.flaggedForStop = false;
      return;
    }
    this.video.srcObject = this.stream;
    this.mediaRecorder = getMediaRecorder(this.stream, this.handleDataAvailable, this.handleDataStopped);
    if (!this.mediaRecorder) {
      return;
    }
    this.mediaRecorder.start(1000);
    if (this.flaggedForPause) {
      this.mediaRecorder.pause();
    }
  }

  public init(videoId: string, video?: string) {
    this.videoId = videoId;
    this.updateVideoElement(video);
    this.updateRecording(video);
    this.destroyRef.onDestroy(() => {
      this.stopRecording();
    });
    return this.recording$;
  }

  public pauseRecording() {
    this.flaggedForPause = true;
  }

  public resumeRecording() {
    this.flaggedForPause = false;
    this.mediaRecorder?.resume();
  }

  public stopRecording() {
    if (this.mediaRecorder?.state === 'paused') {
      this.resumeRecording();
    }
    this.flaggedForStop = true;
  }

  public async removeRecording() {
    const videoElement = (await waitForElement(this.videoId)) as HTMLVideoElement;
    if (!videoElement) {
      return;
    }
    videoElement.srcObject = null;
    videoElement.load();
  }

  private updateVideoElement(video?: string) {
    if (video?.length) {
      waitForElement(this.videoId).then(videoElement => {
        this.video = videoElement as HTMLVideoElement;
        if (this.video) {
          this.video.src = video ?? '';
          this.video.load();
        }
      });
    }
  }

  public updateRecording(video?: string) {
    this.recordingSubject.next(video ?? null);
  }
}
