import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JumptechDate } from '@jump-tech-frontend/domain';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../auth/services/authentication.service';
import { UserService } from '../auth/services/user.service';
import { Job, JobAssignment, JobAssignmentType } from '../core/domain/job';
import { HttpGateway } from '../core/http-gateway.service';
import { DropDownElement } from '../shared/form-components/multiple-selection-dropdown.component';
import { ToasterService } from '../toast/toast-service';
import {
  ErrorType,
  ScheduleError,
  ScheduleI18n,
  ScheduleJobsDisplayDm,
  ScheduleJobViewDm,
  ScheduleMoreInfoDm,
  SchedulePayloadPostDto
} from './schedule.model';
import { TIME_SLOTS } from './utils/schedule-constants';
import { EventInfo, JobInformation, JobInformationEventContext } from './utils/schedule-types';
import { pad } from './utils/schedule.helper';
import { AggregatorReadOnlyService } from '../project-detail/aggregator-read-only.service';

@Injectable({ providedIn: 'root' })
export class ScheduleRepository {
  readyToScheduleSubscription: Subscription;
  jobViewSubscription: Subscription;
  errorsSubscription: Subscription;
  moreDetailsSubscription: Subscription;
  moreDetailsFormSubscription: Subscription;
  rsj$: Subject<ScheduleJobsDisplayDm>;
  jv$: Subject<ScheduleJobViewDm>;
  errors$: Subject<ScheduleError>;
  moreDetails$: Subject<ScheduleMoreInfoDm>;

  dm: ScheduleJobsDisplayDm;
  cachedDm: ScheduleJobsDisplayDm;
  allEngineersList: any;
  filteredEngineerList: any;
  isReadonlyAggregator = false;

  moreDetailsDm: ScheduleMoreInfoDm = {} as ScheduleMoreInfoDm;

  moreDetailsForm: FormGroup;
  addEngineerForm: FormGroup;
  filtersForm: FormGroup;

  i18n: ScheduleI18n = {};
  timeSlots = TIME_SLOTS;

  constructor(
    private gateway: HttpGateway,
    private i18nService: TranslocoService,
    private fb: FormBuilder,
    private router: Router,
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private readOnlyProjectService: AggregatorReadOnlyService,
    private userService: UserService
  ) {
    this.init().then();
  }

  async init(): Promise<void> {
    this.rsj$ = new Subject<ScheduleJobsDisplayDm>();
    this.jv$ = new Subject<ScheduleJobViewDm>();
    this.errors$ = new Subject<ScheduleError>();
    this.moreDetails$ = new Subject<ScheduleMoreInfoDm>();
    this.initI18ns();
    this.initForms();
    this.isReadonlyAggregator = this.readOnlyProjectService.isReadOnly();
    await this.initDataLists();
  }

  initForms(jobInformation?: JobInformation, resetFilters = true): void {
    // When dragging or assigning new jobs we want to default the time to 9am
    const assignmentStartTime = JumptechDate.from(new Date(new Date().setHours(9, 0, 0, 0)));
    const assignmentEndTime = assignmentStartTime.plus({ hours: jobInformation?.defaultDuration ?? 2 });
    const formStartTime = jobInformation?.setDefaultStartTime
      ? this.isoStringToTime(assignmentStartTime.toIso())
      : this.isoStringToTime(jobInformation?.startDateTimestamp);
    const formEndTime = jobInformation?.setDefaultStartTime
      ? this.isoStringToTime(assignmentEndTime.toIso())
      : this.isoStringToTime(jobInformation?.endDateTimestamp);

    this.moreDetailsForm = this.fb.group({
      id: [jobInformation?.actionId ?? null],
      startIso: [jobInformation?.startDateTimestamp, [Validators.required]],
      startDate: [
        jobInformation?.startDateTimestamp ? this.isoStringToDateStruct(jobInformation?.startDateTimestamp) : null,
        [Validators.required]
      ],
      endIso: [jobInformation?.endDateTimestamp, [Validators.required]],
      endDate: [
        jobInformation?.endDateTimestamp ? this.isoStringToDateStruct(jobInformation?.endDateTimestamp) : null,
        [Validators.required]
      ],
      startTime: [formStartTime ?? null, [Validators.required]],
      endTime: [formEndTime ?? null, [Validators.required]],
      jobAssignments: [jobInformation?.jobAssignments, [Validators.required]],
      rescheduleReason: [null]
    });
    this.addEngineerForm = this.fb.group({
      engineerToAdd: [null, [Validators.required]]
    });
    if (resetFilters) {
      this.filtersForm = this.fb.group({
        selectedJobTypes: [[]],
        freeText: ['']
      });
    }
  }

  load(cb): void {
    this.readyToScheduleSubscription?.unsubscribe();
    this.readyToScheduleSubscription = this.rsj$.subscribe(cb);
    this.loadReadyToScheduleJobs().then();
  }

  loadJobView(cb): void {
    this.jobViewSubscription?.unsubscribe();
    this.jobViewSubscription = this.jv$.subscribe(cb);
  }

  closeViewingJob(): void {
    this.jv$.next(null);
  }

  setViewingJobInfo(jobInfoContext: JobInformationEventContext): void {
    const dm: ScheduleJobViewDm = {
      labelJob: this.i18n.titleJob,
      labelLead: this.i18n.lead,
      labelStartDate: this.i18n.startDateLabel,
      labelStartTime: this.i18n.startTimeLabel,
      labelEndDate: this.i18n.endDateLabel,
      labelEndTime: this.i18n.endTimeLabel,
      labelName: this.i18n.nameLabel,
      labelContactInfo: this.i18n.contactInfoLabel,
      labelAddress: this.i18n.addressLabel,
      labelAssignedTradesperson: this.i18n.assignedTradespersonLabel,
      btnGoToProject: this.i18n.goToProjectBtn,
      btnMoreDetails: this.i18n.buttonMoreDetails,
      ...jobInfoContext,
      startDateDisplay: JumptechDate.from(jobInfoContext.jobInformation.startDateTimestamp).toDateFormat(),
      endDateDisplay: JumptechDate.from(jobInfoContext.jobInformation.endDateTimestamp).toDateFormat(),
      startTimeDisplay: JumptechDate.from(jobInfoContext.jobInformation.startDateTimestamp).toTimeFormat(),
      endTimeDisplay: JumptechDate.from(jobInfoContext.jobInformation.endDateTimestamp).toTimeFormat()
    };

    this.jv$.next(dm);
  }

  loadMoreDetails(cb): void {
    this.moreDetailsSubscription?.unsubscribe();
    this.moreDetailsSubscription = this.moreDetails$.subscribe(cb);
  }

  closeMoreDetails(success = false, payload = null): void {
    this.moreDetailsFormSubscription?.unsubscribe();
    if (success) {
      this.moreDetailsDm = { scheduleSuccess: true, ...payload } as ScheduleMoreInfoDm;
      this.moreDetails$.next(this.moreDetailsDm);
    } else {
      this.moreDetails$.next(null);
    }
  }

  addEngineer(): void {
    this.moreDetailsDm = { ...this.moreDetailsDm, isAddingEngineer: true };
    this.moreDetails$.next(this.moreDetailsDm);
  }

  setLeadEngineer(engineer: JobAssignment): void {
    if (engineer.assignmentType === 'LEAD') {
      return;
    }

    this.moreDetailsDm.jobAssignments = this.moreDetailsDm.jobAssignments.map(x => ({
      assignedTo: x.assignedTo,
      assignedToDisplayName: x.assignedToDisplayName,
      assignmentType: engineer.assignedTo === x.assignedTo ? 'LEAD' : ('SUPPORT' as JobAssignmentType)
    }));

    this.moreDetailsDm.jobAssignments.sort((a, b) => {
      if (a.assignmentType === 'LEAD') {
        return -1;
      } else {
        return 1;
      }
    });
    this.moreDetailsForm.get('jobAssignments').patchValue(this.moreDetailsDm.jobAssignments);

    this.moreDetails$.next(this.moreDetailsDm);
  }

  deleteEngineer(engineer: JobAssignment): void {
    this.moreDetailsDm.jobAssignments = this.moreDetailsDm.jobAssignments.filter(
      x => x.assignedTo !== engineer.assignedTo
    );
    if (engineer.assignmentType === 'LEAD' && this.moreDetailsDm.jobAssignments.length) {
      this.moreDetailsDm.jobAssignments[0].assignmentType = 'LEAD';
    }
    this.moreDetailsForm.patchValue({ jobAssignments: this.moreDetailsDm.jobAssignments });
    this.filterEngineerOptions(this.moreDetailsDm.jobAssignments);
    this.moreDetails$.next(this.moreDetailsDm);
  }

  filterEngineerOptions(jobAssignments: JobAssignment[]): void {
    const alreadyAssignedList = jobAssignments.map(a => a.assignedTo);
    this.filteredEngineerList = this.allEngineersList.filter(x => {
      return !alreadyAssignedList.includes(x.id);
    });
    this.moreDetailsDm.engineers = this.filteredEngineerList;
  }

  confirmAddEngineer(): void {
    const engineerData = this.moreDetailsDm.addEngineerForm.get('engineerToAdd').value;
    const newJobAssignment: JobAssignment = {
      assignedTo: engineerData.id,
      assignedToDisplayName: engineerData.name,
      assignmentType: this.moreDetailsDm.jobAssignments?.length ? 'SUPPORT' : 'LEAD'
    };

    // update job assignments
    this.moreDetailsDm = {
      ...this.moreDetailsDm,
      jobAssignments: [...this.moreDetailsDm.jobAssignments, newJobAssignment]
    };

    this.moreDetailsForm.patchValue({ jobAssignments: this.moreDetailsDm.jobAssignments });
    this.resetAddEngineerForm();
    this.filterEngineerOptions(this.moreDetailsDm.jobAssignments);
    this.moreDetails$.next(this.moreDetailsDm);
  }

  cancelAddEngineer(): void {
    this.resetAddEngineerForm();
    this.moreDetails$.next(this.moreDetailsDm);
  }

  resetAddEngineerForm(): void {
    this.moreDetailsDm.addEngineerForm.patchValue({ engineerToAdd: null });
    this.moreDetailsDm.isAddingEngineer = false;
  }

  async loadReadyToScheduleJobs(showloader = true, scheduledJobId: string = null): Promise<void> {
    try {
      // notify loading state
      if (showloader) {
        this.dm = {
          loading: true,
          jobs: [],
          jobTypes: [],
          filtersForm: this.filtersForm,
          i18ns: this.i18n
        };
        this.rsj$.next(this.dm);
      }

      const dto: Job[] = [];
      let currentNextPageToken: string | undefined;

      const { results, nextPageToken } = await this.getReadyToScheduleJobsBatch();
      const unscheduledJobs = this.removeRecentlyScheduledJob(results, scheduledJobId);

      dto.push(...unscheduledJobs);
      currentNextPageToken = nextPageToken;

      this.parseAndNotify(dto);

      while (currentNextPageToken) {
        const { results, nextPageToken } = await this.getReadyToScheduleJobsBatch(currentNextPageToken);
        const unscheduledJobs = this.removeRecentlyScheduledJob(results, scheduledJobId);

        dto.push(...unscheduledJobs);
        currentNextPageToken = nextPageToken;

        this.parseAndNotify(dto);
      }
    } catch (e) {
      this.handleErrors(ErrorType.fetchReadyToScheduleJobs, e);
    }
  }

  private removeRecentlyScheduledJob(jobs: Job[], scheduledJobId: string = null) {
    if (!scheduledJobId) {
      return jobs;
    }
    // remove recently scheduled job from list, so we don't have to retry or make user wait too long
    return jobs.filter(({ id }) => id !== scheduledJobId);
  }

  private async getReadyToScheduleJobsBatch(
    nextPageToken?: string
  ): Promise<{ results: Job[]; nextPageToken?: string }> {
    const params = { readyToSchedule: true, isScheduled: false };

    if (nextPageToken !== undefined) {
      params['nextPageToken'] = nextPageToken;
    }

    return await this.gateway.get(`${environment.apiJobsUrl}`, params);
  }

  public getErrors(cb): void {
    this.errorsSubscription?.unsubscribe();
    this.errorsSubscription = this.errors$.subscribe(cb);
  }

  public clearErrors(): void {
    this.errors$.next({} as ScheduleError);
  }

  private setError(e: ErrorType): void {
    const message = this.i18nService.translate(e);
    this.errors$.next({
      message,
      qaErrorMessage: 'scheduleErrorMessage',
      qaClearErrorsButton: 'scheduleClearErrorsButton'
    });
  }

  private parseAndNotify(dto: Job[]): void {
    const jobTypes = this.buildJobTypesFilter(dto);

    const updatedDm: ScheduleJobsDisplayDm = {
      ...this.dm,
      loading: false,
      jobs: dto,
      filtersForm: this.filtersForm,
      jobTypes,
      i18ns: this.i18n
    };

    this.cachedDm = { ...updatedDm };
    this.filterReadyToSchedule();
  }

  public setSelectedJob(job: Job): void {
    this.dm = { ...this.dm, selectedJob: job };
    this.cachedDm = { ...this.dm };
    this.rsj$.next(this.dm);
  }

  private buildJobTypesFilter(dto: Job[]): DropDownElement[] {
    return dto
      .map(job => job.type)
      .filter((value, index, currentValue) => currentValue.indexOf(value) === index)
      .map(x => ({ id: x, name: x }));
  }

  filterReadyToSchedule(): void {
    const selectedJobTypes: string[] = this.filtersForm.get('selectedJobTypes').value;
    const freeText: string = this.filtersForm.get('freeText').value;
    let filteredJobs = this.cachedDm?.jobs;

    if (selectedJobTypes?.length) {
      filteredJobs = this.cachedDm.jobs.filter((job: Job) => selectedJobTypes?.includes(job.type));
    }
    if (freeText) {
      filteredJobs = filteredJobs.filter((job: Job) => {
        return (
          `${job.firstName} ${job.lastName}`.toLowerCase().includes(freeText.toLowerCase()) ||
          job.address?.postCode?.toLowerCase()?.replace(/\s/g, '').includes(freeText?.toLowerCase()?.replace(/\s/g, ''))
        );
      });
    }
    this.dm = {
      loading: false,
      jobs: filteredJobs,
      filtersForm: this.filtersForm,
      jobTypes: this.cachedDm ? this.cachedDm.jobTypes : this.dm.jobTypes,
      selectedJob: this.cachedDm ? this.cachedDm.selectedJob : this.dm.selectedJob,
      i18ns: this.i18n
    };
    this.rsj$.next(this.dm);
  }

  public handleErrors(type: ErrorType | unknown, e): void {
    if (!environment.production) {
      console.log(e);
    }
    switch (type) {
      case ErrorType.fetchReadyToScheduleJobs:
        this.setError(ErrorType.fetchReadyToScheduleJobs);
        this.rsj$.next({ ...this.dm, jobs: [], loading: false, i18ns: this.i18n });
        break;
      case ErrorType.fetchEngineers:
        this.setError(ErrorType.fetchEngineers);
        break;
      case ErrorType.selectRescheduleJob:
        this.setError(ErrorType.selectRescheduleJob);
        break;
      case ErrorType.unknown:
        this.setError(ErrorType.unknown);
        break;
      case ErrorType.scheduleJob:
        this.setError(ErrorType.scheduleJob);
        this.moreDetails$.next({ ...this.moreDetailsDm, scheduleInProgress: false });
        break;
      default:
        this.setError(ErrorType.unknown);
    }
  }

  parseEngineers(dto): DropDownElement[] {
    const engineers = dto.map(x => ({ name: x.key, id: x.value.split('|')[1] }));
    if (AuthenticationService.getTier() === 'support') {
      const currentUser = this.userService.currentUser;
      engineers.push({ name: `${currentUser.label} (Support)`, id: currentUser.id });
    }
    return engineers;
  }

  async fetchEngineers(): Promise<void> {
    try {
      const dto = await this.gateway.get(`${environment.apiCustomRoot}/core/users/engineer`, {});
      this.allEngineersList = this.parseEngineers(dto);
    } catch (e) {
      this.handleErrors(ErrorType.fetchEngineers, e);
    }
  }

  listenForMoreDetailsFormChanges(cb, calEvent: EventInfo): void {
    this.moreDetailsFormSubscription?.unsubscribe();
    this.moreDetailsFormSubscription = this.moreDetailsForm.valueChanges.subscribe(change => {
      cb(change, calEvent);
    });
  }

  public openMoreDetails(jobInformation: JobInformation, cb, calEvent: EventInfo): void {
    this.filterEngineerOptions(jobInformation.jobAssignments);
    this.initForms(jobInformation, false);

    if (cb && calEvent) {
      this.listenForMoreDetailsFormChanges(cb, calEvent);
    }

    if (jobInformation.tenantType) {
      this.readOnlyProjectService.next(jobInformation.tenantType);
    }

    this.moreDetailsDm = {
      isReadonlyAggregator: this.isReadonlyAggregator,
      id: jobInformation.id,
      scheduleSuccess: false,
      type: jobInformation.type,
      projectId: jobInformation.projectId,
      firstName: jobInformation.customerFirstName,
      lastName: jobInformation.customerLastName,
      phoneNumber: jobInformation.contactInfo.telephoneNumber,
      email: jobInformation.contactInfo.email,
      address: jobInformation.address,
      startDate: jobInformation.startDateTimestamp,
      endDate: jobInformation.endDateTimestamp,
      engineers: this.filteredEngineerList,
      jobAssignments: jobInformation.jobAssignments,
      form: this.moreDetailsForm,
      addEngineerForm: this.addEngineerForm,
      isAddingEngineer: false,
      scheduleInProgress: false,
      isInitialSchedule: jobInformation.isInitialSchedule,
      i18nLead: this.i18n.lead,
      i18nConfirmBtn: this.i18n.confirmBtn,
      i18nCancelBtn: this.i18n.cancelBtn,
      i18nCloseBtn: this.i18n.closeBtn,
      i18nStartDatePlaceholder: this.i18n.startDatePlaceholder,
      i18nTradesPeopleHeader: this.i18n.tradesPeopleHeader,
      i18nStartDateLabel: this.i18n.startDateLabel,
      i18nScheduleNowBtn: this.i18n.scheduleNowBtn,
      i18nGoToProjectBtn: this.i18n.goToProjectBtn,
      i18nStartDateRequired: this.i18n.startDateRequired,
      i18nInvalidDateFormat: this.i18n.invalidDateFormat,
      i18nStartDateAfterEnd: this.i18n.startDateAfterEnd,
      i18nStartTimeLabel: this.i18n.startTimeLabel,
      i18nEndDatePlaceholder: this.i18n.endDatePlaceholder,
      i18nEndDateLabel: this.i18n.endDateLabel,
      i18nEndDateRequired: this.i18n.endDateRequired,
      i18nEndDateBeforeStart: this.i18n.endDateBeforeStart,
      i18nEndTimeLabel: this.i18n.endTimeLabel,
      i18nDurationLabel: this.i18n.durationLabel,
      i18nDayLabel: this.i18n.dayLabel,
      i18nDaysLabel: this.i18n.daysLabel,
      i18nHourLabel: this.i18n.hourLabel,
      i18nHoursLabel: this.i18n.hoursLabel,
      i18nMinutesLabel: this.i18n.minutesLabel,
      i18nAddTradesPersonLabel: this.i18n.addTradesPersonLabel,
      i18nSelectEngineerPlaceholder: this.i18n.selectEngineerPlaceholder,
      i18nProvisionallyScheduleBtn: this.i18n.provisionallyScheduleBtn,
      i18nAddTradesPersonBtn: this.i18n.addTradesPersonBtn,
      i18nSetLeadEngineerBtn: this.i18n.setLeadEngineerBtn,
      i18nRemoveEngineerBtn: this.i18n.removeEngineerBtn,
      i18nRescheduleReasonInputLabel: this.i18n.rescheduleReasonInputLabel,
      i18nRescheduleReasonInputPlaceholder: this.i18n.rescheduleReasonInputPlaceholder,
      i18nEngineerRequiredError: this.i18n.engineerRequiredError,
      i18nTimeIsInvalid: this.i18n.timeIsInvalid
    };
    this.moreDetails$.next(this.moreDetailsDm);
  }

  private initI18ns(): void {
    this.i18n.projectType = this.i18nService.translate('common.projectType');
    this.i18n.jobType = this.i18nService.translate('common.jobType');
    this.i18n.lead = this.i18nService.translate('common.lead');
    this.i18n.nameLabel = this.i18nService.translate('common.name');
    this.i18n.contactInfoLabel = this.i18nService.translate('schedule.jobInformation.contactInfo');
    this.i18n.addressLabel = this.i18nService.translate('common.formFields.address');
    this.i18n.assignedTradespersonLabel = this.i18nService.translate('schedule.jobInformation.assignedTradesperson');
    this.i18n.freeTextFilterLabel = this.i18nService.translate('common.filter');
    this.i18n.jobTypesDropdownPlaceholder = this.i18nService.translate('common.showAll');
    this.i18n.titleJob = this.i18nService.translate('schedule.jobInformation.job');
    this.i18n.titlePostcode = this.i18nService.translate('common.postcode');
    this.i18n.buttonAssign = this.i18nService.translate('common.assign');
    this.i18n.buttonProject = this.i18nService.translate('common.project');
    this.i18n.buttonMoreDetails = this.i18nService.translate('schedule.jobInformation.buttons.moreDetails');
    this.i18n.titleJobsReadyToSchedule = this.i18nService.translate('schedule.jobDisplay.jobsReadyToSchedule');
    this.i18n.titleAllOtherJobs = this.i18nService.translate('schedule.jobDisplay.allOtherJobs');
    this.i18n.titleSelectedJobFromProject = this.i18nService.translate('schedule.jobDisplay.selectedJobFromProjects');
    this.i18n.startDatePlaceholder = this.i18nService.translate('schedule.formFields.startDate.placeholder');
    this.i18n.tradesPeopleHeader = this.i18nService.translate('schedule.moreInfo.tradesPeopleHeader');
    this.i18n.startDateLabel = this.i18nService.translate('schedule.formFields.startDate.label');
    this.i18n.scheduleNowBtn = this.i18nService.translate('schedule.moreInfo.scheduleNowBtn');
    this.i18n.goToProjectBtn = this.i18nService.translate('schedule.jobInformation.buttons.goToProject');
    this.i18n.startDateRequired = this.i18nService.translate('schedule.errors.startDateRequired');
    this.i18n.invalidDateFormat = this.i18nService.translate('schedule.errors.invalidDateFormat');
    this.i18n.startDateAfterEnd = this.i18nService.translate('schedule.errors.startDateAfterEnd');
    this.i18n.startTimeLabel = this.i18nService.translate('schedule.formFields.startTime.label');
    this.i18n.endDatePlaceholder = this.i18nService.translate('schedule.formFields.endDate.placeholder');
    this.i18n.endDateLabel = this.i18nService.translate('schedule.formFields.endDate.label');
    this.i18n.endDateRequired = this.i18nService.translate('schedule.errors.endDateRequired');
    this.i18n.endDateBeforeStart = this.i18nService.translate('schedule.errors.endDateBeforeStart');
    this.i18n.endTimeLabel = this.i18nService.translate('schedule.formFields.endTime.label');
    this.i18n.durationLabel = this.i18nService.translate('schedule.jobInformation.duration');
    this.i18n.dayLabel = this.i18nService.translate('schedule.moreInfo.dayLabel');
    this.i18n.daysLabel = this.i18nService.translate('schedule.moreInfo.daysLabel');
    this.i18n.hourLabel = this.i18nService.translate('schedule.moreInfo.hourLabel');
    this.i18n.hoursLabel = this.i18nService.translate('schedule.moreInfo.hoursLabel');
    this.i18n.minutesLabel = this.i18nService.translate('schedule.moreInfo.minutesLabel');
    this.i18n.addTradesPersonBtn = this.i18nService.translate('schedule.moreInfo.addTradesPersonBtn');
    this.i18n.selectEngineerPlaceholder = this.i18nService.translate('schedule.moreInfo.selectEngineerPlaceholder');
    this.i18n.confirmBtn = this.i18nService.translate('common.confirm');
    this.i18n.cancelBtn = this.i18nService.translate('common.cancel');
    this.i18n.closeBtn = this.i18nService.translate('common.close');
    this.i18n.provisionallyScheduleBtn = this.i18nService.translate('schedule.moreInfo.provisionallyScheduleBtn');
    this.i18n.scheduleJobLabel = this.i18nService.translate('schedule.moreInfo.scheduleJobLabel');
    this.i18n.jobHasBeenScheduledLabel = this.i18nService.translate('schedule.moreInfo.jobHasBeenScheduledLabel');
    this.i18n.setLeadEngineerBtn = this.i18nService.translate('schedule.moreInfo.setLeadEngineerBtn');
    this.i18n.removeEngineerBtn = this.i18nService.translate('schedule.moreInfo.removeEngineerBtn');
    this.i18n.freeTextFilterPlaceholder = this.i18nService.translate('schedule.jobDisplay.freeTextFilterPlaceholder');
    this.i18n.rescheduleReasonInputPlaceholder = this.i18nService.translate(
      'schedule.moreInfo.rescheduleReasonInputPlaceholder'
    );
    this.i18n.rescheduleReasonInputLabel = this.i18nService.translate('schedule.moreInfo.rescheduleReasonInputLabel');
    this.i18n.engineerRequiredError = this.i18nService.translate('schedule.moreInfo.engineerRequiredError');
    this.i18n.timeIsInvalid = this.i18nService.translate('schedule.moreInfo.timeIsInvalid');
  }

  private async initDataLists(): Promise<void> {
    await this.fetchEngineers();
  }

  isoStringToDateStruct(isoString: string): NgbDateStruct {
    if (isoString) {
      const date = new Date(isoString);
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      };
    }
  }

  isoStringToTime(isoString: string): string {
    if (isoString) {
      const date = new Date(isoString);
      const hrs = pad(date.getHours());
      const mins = pad(date.getMinutes());
      return this.timeSlots.find(slot => slot.id === hrs + mins).name;
    }
  }

  handleDateTimeChange(): void {
    const startDate = this.moreDetailsForm.get('startDate').value;
    const endDate = this.moreDetailsForm.get('endDate').value;
    const startTime = this.moreDetailsForm.get('startTime').value;
    const endTime = this.moreDetailsForm.get('endTime').value;

    if (!startDate || !endDate || !startTime || !endTime) {
      this.moreDetailsForm.patchValue({ startIso: null, endIso: null });
      this.moreDetails$.next(this.moreDetailsDm);
      return;
    }

    const start = JumptechDate.from({
      year: startDate.year,
      month: startDate.month,
      day: startDate.day,
      hour: startTime.split(':')[0],
      minute: startTime.split(':')[1]
    }).toIso();
    const end = JumptechDate.from({
      year: endDate.year,
      month: endDate.month,
      day: endDate.day,
      hour: endTime.split(':')[0],
      minute: endTime.split(':')[1]
    }).toIso();
    this.moreDetailsForm.patchValue({ startIso: start, endIso: end });
    this.moreDetails$.next(this.moreDetailsDm);
  }

  async scheduleJob(): Promise<void> {
    const payload: SchedulePayloadPostDto = {
      id: this.moreDetailsForm.get('id').value,
      start: this.moreDetailsForm.get('startIso').value,
      end: this.moreDetailsForm.get('endIso').value,
      jobType: this.moreDetailsDm.type,
      jobAssignments: this.moreDetailsDm.jobAssignments,
      rescheduleReason: this.moreDetailsForm.get('rescheduleReason')?.value ?? ''
    };

    this.moreDetails$.next({ ...this.moreDetailsDm, scheduleInProgress: true });
    try {
      const headers = {
        'x-jt-project-id': this.moreDetailsDm.projectId
      };
      await this.gateway.post(
        `${environment.apiProjectUrl}/${this.moreDetailsDm.projectId}/schedule`,
        payload,
        headers,
        { ignoreState: 'true' }
      );
      this.toasterService.pop('success', this.i18n.scheduleJobLabel, this.i18n.jobHasBeenScheduledLabel);
      await this.optimisticUpdateJobsList(this.moreDetailsDm.id);
      this.closeMoreDetails(true, { ...payload, projectId: this.moreDetailsDm.projectId });
      this.modalService.dismissAll();
    } catch (e) {
      this.handleErrors(ErrorType.scheduleJob, e);
    }
  }

  async optimisticUpdateJobsList(jobId): Promise<void> {
    if (this.cachedDm.jobs && this.cachedDm.jobs.length) {
      const updated = { ...this.cachedDm, jobs: this.dm?.jobs?.filter(j => j.id !== jobId) };
      this.rsj$.next(updated);
      await this.loadReadyToScheduleJobs(false, jobId);
    }
  }

  goToProject(id?: string): void {
    const projectId: string = id ?? this.moreDetailsDm.projectId;
    this.modalService.dismissAll();
    this.router.navigate([`/project/${projectId}`]).catch(console.log);
  }
}
