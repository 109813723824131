<ng-container *ngIf="vm$ | async as vm">
  <div
    *ngIf="vm.form"
    [formGroup]="vm.form"
    [class]="vm.formClass"
  >
    <label *ngIf="vm.label" class="form-label textbox-question__label" [attr.data-qa]="vm.key">
      <div>
        {{ vm.label }}
        <span class="textbox-question__required">{{ vm.form.invalid ? vm.translations?.required : '' }}</span>
      </div>
    </label>
    <input
      [formControlName]="vm.key"
      [id]="vm.key"
      [type]="vm.type ?? 'text'"
      class="form-control"
      [attr.data-qa]="vm.key + 'Input'"
      [value]="vm.value ?? ''"
      autocomplete="off"
    />
  </div>
</ng-container>
