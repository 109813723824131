import { EventEmitter, Injectable } from '@angular/core';
import { DocumentPackRepository } from './document-pack.repository';
import {
  AUTO_GENERATE_STATES,
  DocumentActionType,
  DocumentStageState,
  DocumentState,
  IDocumentPackManagerDm,
  IDocumentPackManagerVm,
  IDocumentVm,
  IStageDm,
  IStageVm,
  NO_ACTION_DOCUMENT_STATES
} from './document-pack.model';

@Injectable()
export class DocumentPackPresenter {
  constructor(private repository: DocumentPackRepository) {}

  load(callback, config) {
    this.repository.initDocumentPackManager((dataDm: IDocumentPackManagerDm) => {
      const viewModel: IDocumentPackManagerVm = {
        projectId: dataDm.projectId,
        showAddDocumentPack: !dataDm.autoAddDocumentPackInProgress && !dataDm.hasDocumentPack,
        showRemoveDocumentPack: dataDm.canRemoveDocumentPack,
        addDocumentPackLabel: dataDm.addDocumentPackLabel,
        removeDocumentPackLabel: dataDm.removeDocumentPackLabel,
        addDocumentPackInProgress: dataDm.addDocumentPackInProgress,
        removeDocumentPackInProgress: dataDm.removeDocumentPackInProgress,

        stages: dataDm.documentPack.stages
          ? dataDm.documentPack.stages.map((stage: IStageDm): IStageVm => {
              return {
                displayName: `${dataDm.stageLabel} ${stage.stageNumber}: ${stage.name}`,
                name: stage.name,
                isOpen: stage.isOpen,
                toggleIcon: stage.isOpen ? 'expand_less' : 'expand_more',
                isCurrentStage: stage.state === DocumentStageState.ACTIVE,
                state: stage.state,
                isCompleted: stage.state === DocumentStageState.PREVIOUS,
                isInactive: stage.state === DocumentStageState.FUTURE,
                outstandingDocuments: stage.outstandingDocuments,
                documents: stage.documents.map(doc => this.parseDocument(dataDm, doc, stage)),
                qaStageName: `dmStageName--${stage.name}`,
                qaStageOutstandingDocCount: `dmStageOutstandingCount--${stage.name}`
              };
            })
          : [],

        documents:
          dataDm.documentPack.documents && !dataDm.documentPack.stages
            ? dataDm.documentPack.documents.map(doc => this.parseDocument(dataDm, doc))
            : [],
        inProgress:
          dataDm.stateChangeInProgress ||
          dataDm.addDocumentPackInProgress ||
          dataDm.uploadInProgress ||
          dataDm.removeDocumentPackInProgress,
        showMainSpinner: dataDm.autoAddDocumentPackInProgress || dataDm.fetchingDocumentPack,
        showRetryButton: dataDm.allowRetry,
        retryButtonLabel: dataDm.retryLabel,
        acceptedFileTypes: dataDm.acceptedFileTypes.map(a => a.type).join(','),
        actionButtonDisabled:
          dataDm.addDocumentPackInProgress ||
          dataDm.removeDocumentPackInProgress ||
          dataDm.uploadInProgress ||
          dataDm.isPreviewing ||
          dataDm.stateChangeInProgress ||
          dataDm.isRefreshing,
        qaComponent: 'dmComponent',
        qaRetry: 'dmRetryButton',
        qaAddDocPack: 'dmAddDocumentPackButton',
        qaRemoveDocPack: 'dmRemoveDocumentPackButton',
        qaMainSpinner: 'dmMainSpinner'
      };
      callback(viewModel);
    }, config);
  }

  isSendingEmailOnApproval(documentDefinition): boolean {
    return (
      documentDefinition.transitionActions?.APPROVED?.some(action => {
        return action.eventName === 'SEND_EMAIL';
      }) || false
    );
  }

  parseDocument(dataDm, doc, stage?): IDocumentVm {
    return {
      id: doc.id,
      name: doc.documentDefinition.displayName,
      documentType: doc.documentDefinition.documentType,
      state: doc.state.currentState,
      stateLabel: doc.stateLabel,
      actionLabel: doc.actionLabel,
      actionType: doc.actionType,
      stateTransition: doc.stateTransition,
      actionDone:
        doc.state.currentState === DocumentState.APPROVED || doc.state.currentState === DocumentState.COMPLETED,
      actionEnabled: stage
        ? stage &&
          stage.state !== DocumentStageState.FUTURE &&
          !NO_ACTION_DOCUMENT_STATES.includes(doc.state.currentState)
        : !NO_ACTION_DOCUMENT_STATES.includes(doc.state.currentState),
      missingInfo: doc.documentModel?.missingInformation || [],
      infoBox: doc.documentModel?.infoBox,
      isAutoActionState: AUTO_GENERATE_STATES.includes(doc.state.currentState),
      isMissingInfo: doc.actionType === DocumentActionType.REVIEW_MISSING_INFO,
      isRequired: doc.state.currentState !== DocumentState.NOT_REQUIRED,
      showNameInactive:
        (stage && stage.state === DocumentStageState.FUTURE) || doc.state.currentState === DocumentState.NOT_REQUIRED,
      requiredDisabled:
        !doc.allowRequiredTransition ||
        dataDm.stateChangeInProgress ||
        dataDm.removeDocumentPackInProgress ||
        (stage && stage.state === DocumentStageState.FUTURE) ||
        dataDm.isRefreshing,
      noAction: NO_ACTION_DOCUMENT_STATES.includes(doc.state.currentState),
      s3Url: doc.url,
      fileName: doc.url?.split('/').pop() ?? null,
      isSendingEmailOnApproval: this.isSendingEmailOnApproval(doc.documentDefinition),
      qaRequiredToggle: `requiredCheck--${doc.documentDefinition.displayName}`,
      qaDocumentLocked: `docLockedIcon--${doc.documentDefinition.displayName}`,
      qaDocAction: `docActionButton--${doc.documentDefinition.displayName}`,
      qaDocState: `docStateLabel--${doc.documentDefinition.displayName}`,
      qaDocUploadInput: `docUploadInput--${doc.documentDefinition.displayName}`,
      qaMainAction: `dmMainActionButton--${doc.documentDefinition.displayName}`,
      qaNotRequired: `dmNotRequiredButton--${doc.documentDefinition.displayName}`,
      qaCloseModal: `dmCloseButton--${doc.documentDefinition.displayName}`,
      qaUpload: `dmUploadButton--${doc.documentDefinition.displayName}`,
      qaRegenerate: `dmRegenerateButton--${doc.documentDefinition.displayName}`
    };
  }

  addDocumentPack() {
    this.repository.addDocumentPackToProject().then();
  }

  removeDocumentPack(docPackRemoved: EventEmitter<void>) {
    this.repository.removeDocumentPackFromProject(docPackRemoved).then();
  }

  uploadDocument(event, document, projectId) {
    this.repository.uploadDocument(event, document, projectId).then();
  }

  previewDocument(htmlInput, doc) {
    this.repository.previewDocument(htmlInput, doc).then();
  }

  reviewMissingInfo(htmlInput, doc) {
    this.repository.reviewMissingInfo(htmlInput, doc).then();
  }

  provideResponse(doc, evtEmitter) {
    this.repository.provideResponse(doc, evtEmitter).then();
  }

  toggleRequired(doc) {
    this.repository.toggleRequired(doc).then();
  }

  toggleStage(stage) {
    this.repository.toggleStage(stage);
  }

  retry(config) {
    this.repository.retryGetDocumentPackManager(config);
  }

  cleanup() {
    this.repository.unsubscribe();
  }
}
