import { DestroyRef, EventEmitter, inject, Injectable, signal } from '@angular/core';
import { TextareaQuestionParams } from './textarea.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormUpdate } from '../question.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class TextareaPresenter {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  load(params: TextareaQuestionParams, formChange: EventEmitter<FormUpdate>) {
    const form = this.getForm(params);
    this.subscribeToFormChanges(form, formChange, params);
    return signal({
      ...params,
      form
    });
  }

  private getForm(params: TextareaQuestionParams): FormGroup {
    const { key, value, required } = params;
    return new FormGroup({
      [key]: new FormControl(value, required ? [Validators.required] : [])
    });
  }

  private subscribeToFormChanges(
    form: FormGroup,
    formChange: EventEmitter<FormUpdate>,
    params: TextareaQuestionParams
  ) {
    form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      formChange?.emit({ key: params.key, value: value[params.key] });
    });
  }
}
