<error-component></error-component>
<upload-progress-component></upload-progress-component>
<ng-container *ngIf="vm?.form && vm?.card && vm?.i18nKeys">
  <div class="box" #boxEl>
    <link *ngIf="vm?.customStyle" rel="stylesheet" [href]="vm.customStyle" type="text/css" />
    <link *ngIf="vm?.customFont" rel="stylesheet" [href]="vm.customFont" />
    <logo-component id="logoComponent" [showClose]="vm.showClose"></logo-component>
    <div class="jumpcontent">
      <ng-container *ngIf="vm?.form && vm?.card && vm?.i18nKeys">
        <div [@fixedProgress]="vm.state" class="fixedSummary">
          <summary-component></summary-component>
        </div>
        <div [@cardsTop]="vm.state">
          <div class="relay-card-wrapper" [ngClass]="vm.wrapperClass">
            <div class="relay-card-inner" [class.showCard]="vm.card">
              <ng-container *ngIf="vm.card.type === 'csat'; else standardCard">
                <question-group [form]="asFormGroup(vm?.form?.controls[vm.card.key])" [card]="vm.card">
                </question-group>
              </ng-container>
              <ng-template #standardCard>
                <crds-card [form]="vm.form" [card]="vm.card" [i18ns]="vm?.i18nKeys"></crds-card>
              </ng-template>
            </div>
          </div>
          <div #actionsEl>
            <actions-component
              [readOnly]="vm.readOnly"
              [demo]="vm.demo"
              (actionClicked)="cardChanged()"
            ></actions-component>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ngx-spinner
    name="relaySpinner"
    bdColor="rgba(51, 51, 51, 0.8)"
    size="large"
    color="#ff8200"
    type="line-scale"
    [attr.data-qa]="'relaySpinner'"
  >
  </ngx-spinner>
</ng-container>
