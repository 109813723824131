import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IDisableProjectAutoAssignmentModalVm } from '../project-auto-assignment.model';
import { ProjectAutoAssignmentRepository } from '../project-auto-assignment.repository';

@Injectable()
export class DisableProjectAutoAssignmentModalPresenter {
  constructor(private repository: ProjectAutoAssignmentRepository) {}

  async load(vm$: BehaviorSubject<IDisableProjectAutoAssignmentModalVm>) {
    const translations = this.repository.getModalTranslations();
    vm$.next({
      ...translations
    });
  }
}
