import { Task } from './task';
import { ProjectAction } from './project-action';

export interface TasksWorkflow {
  tenant?: string;
  id?: string;
  description?: string;
  actions: ProjectAction<any>[];
  tasks?: Task[];
  state?: {};
}
