@if (!vm.isInitialSchedule) {
<div class="schedule-more-details__row-split" [formGroup]="vm.form">
  <div class="schedule-more-details__input">
    <jui-input
      id="filter"
      size="sm"
      clearable="true"
      formControlName="rescheduleReason"
      [label]="vm.i18nRescheduleReasonInputLabel"
      [placeholder]="vm.i18nRescheduleReasonInputPlaceholder"
      [attr.data-qa]="vm.qaRescheduleReasonInput"
    >
    </jui-input>
  </div>
</div>
}
