import { EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

export interface QuestionViewModel {
  key: string;
  form?: FormGroup;
  label?: string;
  readonly?: boolean;
  required?: boolean;
  value?: unknown;
}

export const Questions = {
  TextareaQuestion: 'Textarea',
  LikertQuestion: 'Likert',
  ModalQuestion: 'Modal',
  SectionQuestion: 'Section',
  PhoneQuestion: 'Phone',
  TextboxQuestion: 'Textbox',
  VideoQuestion: 'Video'
};

export interface IQuestionComponent {
  formChange?: EventEmitter<FormUpdate>;
}

export interface FormUpdate {
  key: string;
  value: unknown;
}
