import { Component, Input } from '@angular/core';
import { MyOrdersVm } from '../../my-orders.model';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'pathway-my-orders-address-type-selection',
  templateUrl: './address-type-selection.component.html',
  styleUrls: ['../../my-orders.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgxIntlTelInputModule]
})
export class MyOrdersAddressTypeSelectionComponent {
  @Input() vm: MyOrdersVm;
}
