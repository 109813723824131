import { AbstractControl, ValidationErrors } from '@angular/forms';

export const customEmailValidator = (control: AbstractControl): ValidationErrors | null => {
  if (control.value?.trim() === '') {
    return null;
  }
  const emailPattern = new RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.' +
      '[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
  );

  const isValidEmail = emailPattern.test(control.value);
  const hasUppercase = /\p{Lu}/u.test(control.value);

  if (!isValidEmail) {
    return { pattern: 'userManagement.validation.email' };
  }
  if (hasUppercase) {
    return { uppercase: 'userManagement.validation.emailUppercaseError' };
  }

  return null;
};
