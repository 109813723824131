import { computed, Injectable, signal } from '@angular/core';
import { IReadOnlyService } from '@jump-tech-frontend/angular-common';

@Injectable({ providedIn: 'root' })
export class AggregatorReadOnlyService implements IReadOnlyService {
  private tenantType = signal<string | undefined>(undefined);
  private isAggregator = computed(() => {
    return this.tenantType() === 'aggregator';
  });
  private allowedFieldIds = [
    'archiveProject',
    'unArchiveProject',
    'delegateProject',
    'unDelegateProject',
    'action_skip_to_activate'
  ];

  isReadOnly(fieldId?: string): boolean {
    if (!fieldId) {
      return this.isAggregator();
    }
    return this.isAggregator() && !this.allowedFieldIds.some(allowedFieldId => fieldId === allowedFieldId);
  }

  next(tenantType?: string) {
    if (tenantType) {
      this.tenantType.set(tenantType);
    }
  }
}
