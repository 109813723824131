@if (vm?.shouldRender) {
  <div class="reorder-list-actions">
    @if (qaIndex) {
      <jui-icon
        class="reorder-list__handle"
        cursor="move"
        name="drag_handle"
        [attr.data-qa]="'common.actions' | transloco"
        cdkDragHandle
        [openDelay]="1000"
        ngbTooltip="{{ 'common.move' | transloco }} {{ 'common.actions' | transloco }}"
        placement="right"
        tooltipClass="jt-tooltip--secondary-darker"
      >
      </jui-icon>
    }
    <div class="tasks card">
      <div>
        <div class="tasks__header card-header">
          <div class="tasks__header-title" [attr.data-qa]="'actionsPanel'">
            <div [attr.data-qa]="'panelHeadingText'">{{ vm.label }}</div>
            <div class="edit-card text-right"></div>
          </div>
        </div>
        @if (vm.showLoader) {
          <div class="card-body tasks__body" [attr.data-qa]="'actionsSpinner'">
            <div class="task-group-wrapper">
              <div class="loader-spacer">
                <jui-skeleton-loader count="3" height="28px" color="primary"></jui-skeleton-loader>
              </div>
            </div>
          </div>
        }

        @if (vm.showTasks) {
          <div class="card-body tasks__body">
          <div class="task-group-wrapper">
            @for (task of vm.tasks; track task; let index = $index) {
              @if (task.useScheduleV2) {
                <project-schedule-actions
                  *featureFlag="SCHEDULE_V2_PHASE_2_FEATURE_KEY"
                  [activeTheme]="vm.activeTheme"
                ></project-schedule-actions>
              } @else {
                <app-task [vm]="task" (toggleClick)="toggle($event)" (actionClick)="action($event)"></app-task>
              }
            }
            <project-schedule-actions
              *featureFlag="SCHEDULE_V2_PHASE_2_FEATURE_KEY"
              [activeTheme]="vm.activeTheme"
            ></project-schedule-actions>
            @if (!vm.isArchived) {
              <pathway-contact-log-action
                *featureFlag="CONTACT_LOGGING_LD_FEATURE_KEY"
                [projectId]="project.id"
                [activeTheme]="vm.activeTheme"
              ></pathway-contact-log-action>
              <pathway-disable-project-auto-assignment-action
                [project]="project"
                [activeTheme]="vm.activeTheme"
              ></pathway-disable-project-auto-assignment-action>
            }
          </div>
        </div>
        }
        <task-invalid-items-component></task-invalid-items-component>
        <task-action-component></task-action-component>
        <task-error-component></task-error-component>
      </div>
    </div>
  </div>
}
