import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageGateway } from './local-storage-gateway.service';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {
  private userPreferenceChangeSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public userPreferenceChanges$: Observable<any> = this.userPreferenceChangeSubject.asObservable();

  constructor(private localStorageGateway: LocalStorageGateway) {}
  setUserPreference(key: string, val: any): void {
    this.localStorageGateway.setItem(key, typeof val === 'string' ? val : JSON.stringify(val));
    this.userPreferenceChangeSubject.next({ key, val });
  }

  getUserPreference(key: string): any {
    const userPref = this.localStorageGateway.getItem(key);
    try {
      return JSON.parse(userPref);
    } catch (e) {
      return userPref;
    }
  }
}
