import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { MAIN_PATH } from '../app.routes';
import { TranslocoModule } from '@ngneat/transloco';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  standalone: true,
  imports: [CoreComponentsAngularModule, TranslocoModule]
})
export class ErrorModalComponent {
  constructor(public activeModal: NgbActiveModal, private router: Router) {}

  close() {
    this.activeModal.close();
    this.router.navigate([MAIN_PATH]);
  }
}
