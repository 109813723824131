import { inject, ModuleWithProviders } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';

import { ErrorComponent } from './error/error.component';
import {
  APP_HOME_PATH,
  CHANGE_PASSWORD_PATH,
  CONFIGURATION_PATH,
  ERROR_PATH,
  LOGIN_PATH,
  MAIN_PATH,
  PLANNING_MAP_PATH,
  PROJECTS_DETAILS_PATH,
  PROJECTS_PATH,
  REGISTER_PATH,
  RESET_PASSWORD_PATH,
  SCHEDULE_PATH,
  SCHEDULE_V2_PATH,
  SEARCH_PATH,
  SETUP_ATOM_PATH,
  TASKS_PATH
} from './app.routes';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { HomePageComponent } from './home/home-page.component';
import { LoginComponent } from './auth/login/login.component';
import { CanActivateRoute } from './auth/guards/can-activate-route';
import { CanDeactivateRoute } from './auth/guards/can-deactivate-route';
import { FeatureFlagService } from './core/feature-flag/feature-flag.service';

const fallbackRoute: Route = {
  path: '**',
  redirectTo: APP_HOME_PATH
};

export const appRoutes: Routes = [
  { path: '', redirectTo: APP_HOME_PATH, pathMatch: 'full' },
  {
    path: RESET_PASSWORD_PATH,
    component: LoginComponent,
    data: { state: 'forgotPassword', title: 'pageTitles.resetPassword' }
  },
  {
    path: CHANGE_PASSWORD_PATH,
    component: LoginComponent,
    data: { state: 'startChangePassword', title: 'pageTitles.changePassword' }
  },
  { path: ERROR_PATH, component: ErrorComponent },
  {
    path: LOGIN_PATH,
    component: LoginComponent,
    data: { title: 'pageTitles.login' }
  },
  {
    path: MAIN_PATH,
    canActivate: [CanActivateRoute],
    component: HomePageComponent,
    data: { title: 'pageTitles.home' }
  },
  {
    path: PROJECTS_PATH,
    canActivate: [CanActivateRoute],
    loadComponent: () => import('./dashboards/dashboards.component').then(m => m.DashboardsComponent),
    data: { title: 'pageTitles.projects' }
  },
  {
    path: SEARCH_PATH,
    canActivate: [CanActivateRoute],
    loadComponent: () => import('./projects-search/projects-search.component').then(m => m.ProjectsSearchComponent),
    data: { title: 'pageTitles.search' }
  },
  {
    path: `${PROJECTS_DETAILS_PATH}/:projectId`,
    canActivate: [CanActivateRoute],
    component: ProjectDetailComponent, // @TODO: Refactor - DO NOT LAZY LOAD FOR NOW - RESULTS IN DATA LOSS
    data: { title: 'pageTitles.details' }
  },
  {
    path: SCHEDULE_PATH,
    canActivate: [CanActivateRoute],
    loadComponent: () => import('./schedule/schedule.component').then(m => m.ScheduleComponent),
    data: { title: 'pageTitles.schedule' }
  },
  {
    path: SCHEDULE_V2_PATH,
    canMatch: [() => inject(FeatureFlagService).isFeatureEnabled('rolling-month-schedule-view')],
    canActivate: [CanActivateRoute],
    loadComponent: () => import('./schedule/schedule-v2/schedule.component').then(m => m.ScheduleV2Component),
    data: { title: 'pageTitles.schedule', feature: 'rolling-month-schedule-view' }
  },
  {
    path: PLANNING_MAP_PATH,
    canActivate: [CanActivateRoute],
    loadComponent: () =>
      import('./feature-modules/planning-map/planning-map.component').then(m => m.PlanningMapComponent),
    data: { title: 'pageTitles.map' }
  },
  {
    path: TASKS_PATH,
    canMatch: [() => inject(FeatureFlagService).isFeatureEnabled('task-management')],
    canActivate: [CanActivateRoute],
    canDeactivate: [CanDeactivateRoute],
    loadComponent: () =>
      import('./feature-modules/task-management/task-management.component').then(m => m.TaskManagementComponent),
    data: { title: 'pageTitles.taskManagement', feature: 'task-management' }
  },
  {
    path: 'reports',
    canActivate: [CanActivateRoute],
    loadComponent: () => import('./reports/reports.component').then(m => m.ReportsComponent),
    data: { title: 'pageTitles.reports' }
  },
  {
    path: 'reports-beta',
    canMatch: [() => inject(FeatureFlagService).isFeatureEnabled('beta-reports')],
    canActivate: [CanActivateRoute],
    canDeactivate: [CanDeactivateRoute],
    loadComponent: () => import('./feature-modules/reports/reports.component').then(m => m.ReportsComponent),
    data: { title: 'pageTitles.reports', feature: 'beta-reports' }
  },
  {
    path: 'user-management',
    canActivate: [CanActivateRoute],
    loadComponent: () =>
      import('./admin/user-management/user-management.component').then(m => m.UserManagementComponent),
    data: { allowAdmin: true, title: 'pageTitles.userManagement' }
  },
  {
    path: CONFIGURATION_PATH,
    canActivate: [CanActivateRoute],
    loadComponent: () =>
      import('./feature-modules/configuration/configuration.component').then(m => m.ConfigurationComponent),
    loadChildren: () => import('./feature-modules/configuration/configuration.routing').then(m => m.tabRoutes),
    data: { allowAdmin: true, title: 'pageTitles.configuration' }
  },
  {
    path: `${REGISTER_PATH}/:registrationForm/:suid`,
    loadComponent: () => import('./registration/registration.component').then(m => m.RegistrationComponent),
    data: { title: 'pageTitles.register' }
  },
  {
    path: SETUP_ATOM_PATH,
    loadComponent: () => import('./setup-atom/setup-atom.component').then(m => m.SetupAtomComponent),
    data: { title: 'pageTitles.setupAtom' }
  },
  fallbackRoute
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, {
  onSameUrlNavigation: 'reload'
});
