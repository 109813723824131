<div class="card-wrapper">
  <div class="card__title-header">
    <div class="card__title">{{ card.label }}</div>
    <div class="card__step">{{ card.stepProgress }}</div>
  </div>
  <div class="card__description">
    <p class="card-text" [innerHTML]="card.description" [attr.data-qa]="'description'"></p>
    <crds-image-asset [images]="images" [key]="'address'"></crds-image-asset>
  </div>
</div>
<div class="form-wrapper">
  <form *ngIf="form" [formGroup]="form">
    <crds-questions [form]="form" [card]="card" [i18ns]="i18ns"></crds-questions>
  </form>
</div>
