import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { Address } from '@jump-tech-frontend/address-lookup';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { I18nKeys } from '../../domain/i18n-keys';
import { BaseQuestionComponent } from '../question.component';
import { Options } from 'ngx-google-places-autocomplete-esb/lib/objects/options/options';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete-esb/lib/objects/address';
import { AddressComponent } from 'ngx-google-places-autocomplete-esb/lib/objects/addressComponent';
import { Geometry } from 'ngx-google-places-autocomplete-esb/lib/objects/geometry';
import { untilDestroyed } from '@jump-tech-frontend/angular-common';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete-esb';
import { QuestionHintComponent } from '@jump-tech-frontend/question-components';
import { QuestionLabelContentComponent } from '@jump-tech-frontend/question-components';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: `crds-google-places-autocomplete`,
  template: `
    <div *ngIf="show && form" [formGroup]="form" class="mb-3">
      <label *ngIf="question.label" [attr.for]="question.key" [attr.data-qa]="question.key">
        <question-label-content [question]="question"></question-label-content>
      </label>
      <!-- QUESTION HINT -->
      <question-hint [question]="question"></question-hint>
      <input
        ngx-google-places-autocomplete
        [options]="autocompleteOptions"
        #placesRef="ngx-places"
        (onAddressChange)="handleAddressChange($event)"
      />
      <form *ngIf="addressForm" [formGroup]="addressForm">
        <formly-form [form]="addressForm" [fields]="fields$ | async" [model]="model$ | async"></formly-form>
      </form>
    </div>
  `,
  styles: [
    `
      .pac-target-input {
        width: 100%;
        margin-bottom: 1rem;
      }
    `
  ],
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    QuestionLabelContentComponent,
    QuestionHintComponent,
    GooglePlaceModule,
    FormlyModule,
    AsyncPipe
  ]
})
export class GooglePlacesAutocompleteComponent extends BaseQuestionComponent implements OnChanges, OnDestroy {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<string>;
  @Input() i18ns: I18nKeys;
  addressForm: UntypedFormGroup;
  addressFormChanges$: Observable<{ [key: string]: string }> | undefined;
  autocompleteOptions: Options;
  destroy$ = new Subject();
  fields$: BehaviorSubject<FormlyFieldConfig[]> = new BehaviorSubject<FormlyFieldConfig[]>([]);
  model$: BehaviorSubject<Address> = new BehaviorSubject<Address>({} as Address);
  hidePostCode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private untilDestroyed = untilDestroyed();
  private requiredValidation = {
    messages: {
      required: (error: unknown, field: FormlyFieldConfig) => this.i18ns.required
    }
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    this.setAutocompleteOptions();
    this.setFormlyFields();
    this.setInitialModel();
    this.hidePostCode$.next(!this.form?.get('address')?.value?.postCode);
    this.subscribeToAddressFormChanges();
  }

  private setAutocompleteOptions() {
    this.autocompleteOptions = {
      componentRestrictions: { country: this.question.countryCodes ?? [] },
      fields: ['address_components', 'geometry'],
      types: ['address']
    } as unknown as Options;
  }

  private setFormlyFields() {
    this.fields$.next([
      {
        key: 'line1',
        wrappers: ['address-field'],
        type: 'input',
        templateOptions: {
          label: this.i18ns?.address.line1,
          required: this.question?.required ?? false,
          error: this.i18ns?.required
        },
        validation: this.requiredValidation,
        hideExpression: model => !Object.keys(model)?.length
      },
      {
        key: 'line2',
        wrappers: ['address-field'],
        type: 'input',
        templateOptions: {
          label: this.i18ns?.address.line2,
          required: false
        },
        hideExpression: model => !Object.keys(model)?.length
      },
      {
        key: 'town',
        wrappers: ['address-field'],
        type: 'input',
        templateOptions: {
          label: this.i18ns?.address.town,
          required: this.question?.required ?? false,
          error: this.i18ns?.required
        },
        validation: this.requiredValidation,
        hideExpression: model => !Object.keys(model)?.length
      },
      {
        key: 'county',
        wrappers: ['address-field'],
        type: 'input',
        templateOptions: {
          label: this.i18ns?.address.county,
          required: false
        },
        hideExpression: model => !Object.keys(model)?.length
      },
      {
        key: 'country',
        wrappers: ['address-field'],
        type: 'input',
        templateOptions: {
          label: this.i18ns?.address.country,
          required: this.question?.required ?? false,
          error: this.i18ns?.required
        },
        validation: this.requiredValidation,
        hideExpression: model => !Object.keys(model)?.length
      },
      {
        key: 'postCode',
        wrappers: ['address-field'],
        type: 'input',
        templateOptions: {
          label: this.i18ns?.address.postCode,
          required: true,
          error: this.i18ns?.required
        },
        validation: this.requiredValidation,
        expressions: {
          'templateOptions.required': field => (this.question?.required && field.model.postCode) ?? false,
          hide: this.hidePostCode$.asObservable()
        }
      },
      {
        key: 'latitude'
      },
      {
        key: 'longitude'
      }
    ]);
  }

  private setInitialModel() {
    this.model$.next(this.form?.get('address')?.value ?? {});
  }

  private setAddressFormValidity() {
    this.form.controls['address'].setErrors(this.addressForm.valid ? null : { required: true });
    this.form.updateValueAndValidity();
  }

  private subscribeToAddressFormChanges() {
    this.addressForm = new UntypedFormGroup({});
    this.addressFormChanges$ = this.addressForm?.valueChanges.pipe(takeUntil(this.destroy$));
    this.addressFormChanges$?.pipe(this.untilDestroyed()).subscribe(address => {
      this.updatePostCodeFromFormAddressPostCode(address);
      this.form?.get('address')?.setValue(address);
      this.setAddressFormValidity();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private updatePostCodeFromFormAddressPostCode(address: { postCode?: string }) {
    if (address?.postCode) {
      if (!this.form.controls['postCode']) {
        this.form.addControl('postCode', new UntypedFormControl(address.postCode));
      } else {
        this.form.get('postCode')?.setValue(address.postCode);
      }
    }
  }

  override get isInvalid() {
    const formUnpopulated = Object.keys(this.addressForm?.value)?.length === 0;
    return formUnpopulated || (this.addressForm?.invalid ?? true);
  }

  handleAddressChange($event: GoogleAddress) {
    const { address_components: addressComponents } = $event;
    console.log(addressComponents);
    const country = getGoogleAddressComponentByType(addressComponents, 'country');
    const postCode = getGoogleAddressComponentByType(addressComponents, 'postal_code');
    this.hidePostCode$.next(!postCode);
    const address: Address = googleAddressComponentToAddress(addressComponents, country, $event.geometry);
    this.model$.next(address);
    this.form?.get('address')?.setValue(address);
  }
}

const googleAddressComponentToAddress = (
  addressComponents: AddressComponent[],
  country?: string,
  geometry?: Geometry
): Address => {
  const transformer = getGoogleAddressToAddressTransformer(country);
  return transformer.toAddress(addressComponents, geometry);
};

const getGoogleAddressToAddressTransformer = (country?: string) => {
  switch (country) {
    default:
      return new DefaultGoogleAddressToAddressTransformer();
  }
};

const getGoogleAddressComponentByType = (addressComponents: AddressComponent[], type: string): string | undefined => {
  const match = addressComponents.find(ac => ac.types.includes(type));
  return match?.long_name ?? undefined;
};

interface GoogleAddressToAddressTransformer {
  toAddress(addressComponent: AddressComponent[], geometry?: Geometry): Address;
}

class DefaultGoogleAddressToAddressTransformer implements GoogleAddressToAddressTransformer {
  toAddress(addressComponent: AddressComponent[], geometry?: Geometry): Address {
    const address: Address = {
      line1: [
        getGoogleAddressComponentByType(addressComponent, 'street_number'),
        getGoogleAddressComponentByType(addressComponent, 'route')
      ].join(' '),
      line2: getGoogleAddressComponentByType(addressComponent, 'sublocality'),
      town: getGoogleAddressComponentByType(addressComponent, 'locality'),
      country: getGoogleAddressComponentByType(addressComponent, 'country'),
      postCode: getGoogleAddressComponentByType(addressComponent, 'postal_code')
    };
    const latLng = geometry?.location?.toJSON();
    if (latLng) {
      address.latitude = latLng.lat.toString();
      address.longitude = latLng.lng.toString();
    }
    return address;
  }
}
