import { Component, inject, Input, NgZone, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';

import { Card } from '../domain/card';
import { CardActionBase } from '../domain/card-actions/card-action.base';
import { IAddressV2 } from '@jump-tech-frontend/address-lookup-v2';
import { FormUpdate, QuestionHintComponent } from '@jump-tech-frontend/question-components';
import { VideoCaptureComponent } from '@jump-tech-frontend/question-components';
import { AddressLookupV2Component } from '@jump-tech-frontend/address-lookup-v2';
import { GooglePlacesAutocompleteComponent } from './question-components/google-places-autocomplete.component';
import { HtmlQuestionComponent } from './question-components/html-question.component';
import { DateQuestionComponent } from './question-components/date-question.component';
import { GoogleMapsMultiMarkerQuestionComponent } from './question-components/google-maps-multi-marker-question.component';
import { ImageOrFileUploadQuestionComponent } from './question-components/image-or-file-upload-question.component';
import { FileUploadQuestionComponent } from './question-components/file-upload-question/file-upload-question.component';
import { DocumentViewerQuestionComponent } from './question-components/document-viewer-question.component';
import { CheckboxButtonGroupQuestionComponent } from './question-components/checkbox-button-group-question.component';
import { ButtonGroupQuestionComponent } from './question-components/button-group-question.component';
import { ToggleQuestionComponent } from './question-components/toggle-question.component';
import { TextareaQuestionComponent } from './question-components/textarea-question.component';
import { TextboxQuestionComponent } from './question-components/textbox-question.component';
import { SignatureQuestionComponent } from './question-components/signature-question.component';
import { OcrImageUploadQuestionComponent } from './question-components/ocr-image-upload-question.component';
import { MultiImageUploadQuestionComponent } from './question-components/multi-image-upload-question/multi-image-upload-question.component';
import { ImageUploadQuestionComponent } from './question-components/image-upload-question.component';
import { GeneralQuestionComponent } from './question-components/general-question.component';
import { AddressListDropdownQuestionComponent } from './question-components/address-list-dropdown-question.component';
import { MultiDropdownWithValueQuestionComponent } from './question-components/multi-dropdown-with-value-question.component';
import { DropdownQuestionComponent } from './question-components/dropdown-question.component';
import { GoogleMapsMarkerQuestionComponent } from './question-components/google-maps-marker-question.component';
import { PostcodeQuestionV2Component } from './question-components/postcode-question-v2.component';
import { PostcodeQuestionComponent } from './question-components/postcode-question.component';
import { NgFor, NgSwitch, NgSwitchCase } from '@angular/common';
import { QuestionWrapperComponent, getVideoQuestionParams } from '@jump-tech-frontend/question-components';
import { I18nKeys } from '@jump-tech-frontend/app-config';

@Component({
  selector: 'crds-questions',
  template: `
    <div *ngFor="let question of questions">
      <div [ngSwitch]="question.controlType" [formGroup]="form" class="form-group">
        <crds-question-postcode *ngSwitchCase="'PostcodeQuestion'" [question]="question" [form]="form" [i18ns]="i18ns">
        </crds-question-postcode>
        <crds-question-v2-postcode
          *ngSwitchCase="'PostcodeV2Question'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-question-v2-postcode>
        <ng-container *ngSwitchCase="'MapsMarkerQuestion'">
          <crds-question-google-maps-marker [question]="question" [form]="form" [i18ns]="i18ns">
          </crds-question-google-maps-marker>
        </ng-container>
        <crds-question-dropdown *ngSwitchCase="'DropdownQuestion'" [question]="question" [form]="form" [i18ns]="i18ns">
        </crds-question-dropdown>
        <crds-question-multi-dropdown-value
          *ngSwitchCase="'MultiDropdownQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-question-multi-dropdown-value>
        <crds-address-list-question-dropdown
          *ngSwitchCase="'AddressDropdownQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-address-list-question-dropdown>
        <crds-question-general *ngSwitchCase="'GeneralText'" [question]="question" [form]="form" [i18ns]="i18ns">
        </crds-question-general>
        <crds-question-image-upload
          *ngSwitchCase="'ImageUploadQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-question-image-upload>
        <crds-question-multi-image-upload
          *ngSwitchCase="'MultiImageUploadQuestion'"
          [question]="question"
          [form]="form"
          [attr.data-qa]="'multiImageUpload'"
          [i18ns]="i18ns"
        >
        </crds-question-multi-image-upload>
        <crds-question-ocr-image-upload
          *ngSwitchCase="'OcrImageUploadQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-question-ocr-image-upload>
        <crds-question-signature
          *ngSwitchCase="'SignatureQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-question-signature>
        <crds-question-textbox *ngSwitchCase="'TextboxQuestion'" [question]="question" [form]="form" [i18ns]="i18ns">
        </crds-question-textbox>
        <crds-question-textarea *ngSwitchCase="'TextareaQuestion'" [question]="question" [form]="form" [i18ns]="i18ns">
        </crds-question-textarea>
        <crds-question-toggle *ngSwitchCase="'ToggleQuestion'" [question]="question" [form]="form" [i18ns]="i18ns">
        </crds-question-toggle>
        <crds-question-button-group
          *ngSwitchCase="'ButtonGroupQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-question-button-group>
        <crds-question-checkbox-button-group
          *ngSwitchCase="'CheckboxButtonGroupQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-question-checkbox-button-group>
        <crds-question-doc-viewer
          *ngSwitchCase="'DocumentViewerQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        ></crds-question-doc-viewer>
        <crds-question-file-upload
          *ngSwitchCase="'FileUploadQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-question-file-upload>
        <crds-question-image-or-file-upload
          *ngSwitchCase="'ImageOrFileUploadQuestion'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        >
        </crds-question-image-or-file-upload>
        <crds-question-google-maps-multi-marker
          *ngSwitchCase="'MultiMarkerQuestion'"
          [question]="question"
          [form]="form"
        >
        </crds-question-google-maps-multi-marker>
        <crds-question-date
          *ngSwitchCase="'DateQuestionComponent'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        ></crds-question-date>
        <question-hint *ngSwitchCase="'QuestionHint'" [question]="question"></question-hint>
        <crds-question-html *ngSwitchCase="'HtmlQuestionComponent'" [question]="question"></crds-question-html>
        <crds-google-places-autocomplete
          *ngSwitchCase="'GooglePlacesAddress'"
          [question]="question"
          [form]="form"
          [i18ns]="i18ns"
        ></crds-google-places-autocomplete>
        <jump-tech-frontend-address-lookup-v2
          *ngSwitchCase="'AddressTypeaheadQuestion'"
          [countryCodes]="question.countryCodes"
          [label]="question.label"
          [provider]="question.provider"
          [address]="form.get(question.key).value"
          (addressUpdate)="updateAddress($event, question.key)"
        >
        </jump-tech-frontend-address-lookup-v2>
        <question-wrapper *ngSwitchCase="'VideoUploadQuestion'" [question]="question" [form]="form">
          <question-video-capture
            (formChange)="handleFormChange($event)"
            [params]="getVideoQuestionParams(question, form.get(question.key).value)"
          ></question-video-capture>
        </question-wrapper>
      </div>
    </div>
  `,
  styleUrls: ['../sass/relay.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgFor,
    NgSwitch,
    ReactiveFormsModule,
    NgSwitchCase,
    PostcodeQuestionComponent,
    GoogleMapsMarkerQuestionComponent,
    DropdownQuestionComponent,
    MultiDropdownWithValueQuestionComponent,
    AddressListDropdownQuestionComponent,
    GeneralQuestionComponent,
    ImageUploadQuestionComponent,
    MultiImageUploadQuestionComponent,
    OcrImageUploadQuestionComponent,
    SignatureQuestionComponent,
    TextboxQuestionComponent,
    TextareaQuestionComponent,
    ToggleQuestionComponent,
    ButtonGroupQuestionComponent,
    CheckboxButtonGroupQuestionComponent,
    DocumentViewerQuestionComponent,
    FileUploadQuestionComponent,
    ImageOrFileUploadQuestionComponent,
    GoogleMapsMultiMarkerQuestionComponent,
    DateQuestionComponent,
    QuestionHintComponent,
    HtmlQuestionComponent,
    GooglePlacesAutocompleteComponent,
    AddressLookupV2Component,
    VideoCaptureComponent,
    QuestionWrapperComponent,
    PostcodeQuestionV2Component
  ]
})
export class QuestionsComponent implements OnChanges {
  @Input() card: Card<CardActionBase>;
  @Input() form: UntypedFormGroup;
  @Input() i18ns: I18nKeys;

  questions: any;

  getVideoQuestionParams = getVideoQuestionParams;

  private readonly ngZone = inject(NgZone);

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.card || !Object.prototype.hasOwnProperty.call(changes, 'card')) {
      return;
    }

    this.questions = Object.assign([], this.card.questions);
  }

  updateAddress($event: { address: IAddressV2; location: string }, key: string) {
    this.form.get(key)?.setValue($event.address);
    this.form.get('postCode')?.setValue($event.address?.postCode);
    this.form.get('installationLatLng')?.setValue($event.location);
  }

  handleFormChange($event: FormUpdate) {
    this.ngZone.run(() => {
      this.form.controls[$event.key]?.patchValue($event.value);
    });
  }
}
