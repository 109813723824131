<div *ngIf="!isLoading && (vm$ | async) as vm; else loading">
  <jui-alert-block *ngIf="vm.filteredAuditLogs.length" iconName="browser_updated" size="sm">
    <div
      [ngSwitch]="
        featureFlagEnabled &&
        !(vm.filteredAuditLogs[0].messageOptions === undefined || vm.filteredAuditLogs[0].messageOptions === null)
      "
      class="audit-log-event"
      [attr.data-qa]="'auditLogEvent'"
    >
      <span *ngSwitchCase="false" class="audit-log-name" [attr.data-qa]="vm.filteredAuditLogs[0].eventName"
        >{{ 'project.auditLogs.latestEvent' | transloco }} {{ vm.filteredAuditLogs[0].eventName }}</span
      >
      <span *ngSwitchCase="true" class="audit-log-name" [attr.data-qa]="vm.filteredAuditLogs[0].eventName"
        >{{ 'project.auditLogs.latestEvent' | transloco }}
        {{ localeContext + '.' + vm.filteredAuditLogs[0].messageOptions.key | transloco }}</span
      >
      <span *ngSwitchCase="false" class="audit-log-created"
        >{{ 'common.created' | transloco }} {{ getDate(vm.filteredAuditLogs[0].created_on) }}</span
      >
      <span *ngSwitchCase="true" class="audit-log-created"
        >{{ 'common.created' | transloco }} {{ getDate(vm.filteredAuditLogs[0].created_on) }}</span
      >
      <span *ngSwitchCase="false" class="audit-log-status"
        >{{ 'project.status' | transloco }} {{ vm.filteredAuditLogs[0].status }}</span
      >
      <span *ngSwitchCase="true" class="audit-log-status"
        >{{ 'project.status' | transloco }} {{ displayAuditStatus(vm.filteredAuditLogs[0].status) }}</span
      >
    </div>
  </jui-alert-block>
</div>

<ng-template #loading>
  <jui-alert-block iconName="browser_updated" size="sm">
    <div class="audit-log-event">
      <jui-skeleton-loader color="info" inline="true" height="13px" width="160px"></jui-skeleton-loader>
      <jui-skeleton-loader
        color="info"
        inline="true"
        height="13px"
        width="150px"
        class="audit-log-created"
      ></jui-skeleton-loader>
      <jui-skeleton-loader
        color="info"
        inline="true"
        height="13px"
        width="100px"
        class="audit-log-status"
      ></jui-skeleton-loader>
    </div>
  </jui-alert-block>
</ng-template>
