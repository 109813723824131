<div
  class="document-stage"
  [class.document-stage--completed]="vm.isCompleted"
  [class.document-stage--inactive]="vm.isInactive"
  [attr.data-qa]="vm.qaStageName"
  (click)="toggleStage.emit(vm)"
>
  <span class="document-stage__label"
    >{{ vm.displayName }}
    <span
      *ngIf="vm.outstandingDocuments"
      [attr.data-qa]="vm.qaStageOutstandingDocCount"
      class="document-stage__todo badge badge-pill"
      >{{ vm.outstandingDocuments }}</span
    >
    <jui-icon
      class="document-stage__check"
      *ngIf="vm.isCompleted"
      size="xs"
      color="success"
      name="check"
      isButton="false"
    ></jui-icon>
  </span>
  <jui-icon
    class="document-stage__toggle"
    size="sm"
    color="high"
    name="expand_more"
    [name]="vm.toggleIcon"
    [attr.aria-expanded]="!vm.isOpen"
    aria-controls="collapseExample"
  ></jui-icon>
</div>
<div class="document-stage__documents" #collapse="ngbCollapse" [ngbCollapse]="!vm.isOpen" (ngbCollapseChange)="vm.isOpen = $event">
  <div *ngFor="let docVm of vm.documents">
    <app-document-pack-document-row
      [doc]="docVm"
      [locked]="vm.state === 'FUTURE'"
      [projectId]="projectId"
      [acceptedFileTypes]="acceptedFileTypes"
      [actionButtonDisabled]="actionButtonDisabled"
      [inProgress]="inProgress"
      (actionClick)="actionClick.emit({ fileInput: $event, docVm })"
      (requiredToggle)="requiredToggle.emit($event)"
      (fileChange)="fileChange.emit({ event: $event, docVm })"
    ></app-document-pack-document-row>
  </div>
</div>
