import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError, catchError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ERROR_PATH } from '../../app.routes';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { datadogLogs } from '@datadog/browser-logs';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipIntercept = request.headers.has('skip');
    if (skipIntercept) {
      request = request.clone({
        headers: request.headers.delete('skip')
      });
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Client Error: ${error.error.message}`;
          datadogLogs.logger.error(errorMsg, error);
        } else {
          errorMsg = `Server Error Code: ${error.status},  Message: ${error.message}`;
          datadogLogs.logger.error(errorMsg, error);
          if (error.status === 0 || error.status === 401 || error.status === 403) {
            // swallow zero status (CORS) && 401 unauthorized && 403 forbidden
            console.log('\x1b[32m%s\x1b[0m', 'Swallowing auth server error');
            // If no cognito session force sign out user
            this.authenticationService.getSession().then(
              session => {
                if (!session) {
                  this.authenticationService.signOut().then();
                }
              },
              () => {
                this.authenticationService.signOut().then();
              }
            );
            return of(undefined);
          }
        }
        this.router.navigate([ERROR_PATH]).then();
        return throwError(errorMsg);
      })
    );
  }
}
