import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const w3wEndpoint = 'https://api.what3words.com/v2';
const w3wApiKey = 'DP5ZNNCR';

@Injectable({ providedIn: 'root' })
export class W3wService {
  constructor(private httpClient: HttpClient) {}

  lookupByLatLng(lat: number, lng: number) {
    return this.httpClient.get(`${w3wEndpoint}/reverse?coords=${lat}%2C${lng}&key=${w3wApiKey}&display=minimal`, {
      headers: {
        'X-Api-Key': w3wApiKey
      }
    });
  }
}
