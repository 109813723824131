import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '../../../environments/environment';
import { ToasterService, ToastResultConfig } from '../../toast/toast-service';
import {
  IDisableProjectAutoAssignmentActionVm,
  IDisableProjectAutoAssignmentModalVm
} from './project-auto-assignment.model';

@Injectable()
export class ProjectAutoAssignmentRepository {
  constructor(
    private httpClient: HttpClient,
    private translationService: TranslocoService,
    private toasterService: ToasterService
  ) {}

  disableProjectAutoAssignmentAndNotify(projectId: string) {
    const toggleProjectAutoAssignmentPostUrl = `${environment.apiRoot}/projects/${projectId}/auto-assignment/toggle`;
    const saveTranslations = this.getActionToastTranslations();
    const toastResultConfig: ToastResultConfig = {
      success: {
        type: 'success',
        title: saveTranslations.saveSuccessTitle,
        body: saveTranslations.saveSuccessBody
      },
      failure: {
        type: 'failure',
        title: saveTranslations.saveFailureTitle,
        body: saveTranslations.saveFailureBody
      }
    };
    this.toasterService.observe(
      this.httpClient.post(toggleProjectAutoAssignmentPostUrl, { disabled: true }),
      toastResultConfig
    );
  }

  getActionToastTranslations() {
    return {
      saveSuccessTitle: this.translationService.translate(
        'projects.autoAssignment.disableAutoAssignment.toast.saveSuccessTitle'
      ),
      saveSuccessBody: this.translationService.translate(
        'projects.autoAssignment.disableAutoAssignment.toast.saveSuccessBody'
      ),
      saveFailureTitle: this.translationService.translate(
        'projects.autoAssignment.disableAutoAssignment.toast.saveFailureTitle'
      ),
      saveFailureBody: this.translationService.translate(
        'projects.autoAssignment.disableAutoAssignment.toast.saveFailureBody'
      )
    };
  }

  getActionTranslations(): IDisableProjectAutoAssignmentActionVm {
    return {
      disableProjectAutoAssignment: this.translationService.translate(
        'projects.autoAssignment.disableAutoAssignment.title'
      )
    };
  }

  getModalTranslations(): IDisableProjectAutoAssignmentModalVm {
    return {
      disableProjectAutoAssignment: this.translationService.translate(
        'projects.autoAssignment.disableAutoAssignment.title'
      ),
      confirmationMessage: this.translationService.translate(
        'projects.autoAssignment.disableAutoAssignment.confirmationMessage'
      ),
      cancel: this.translationService.translate('common.cancel'),
      submit: this.translationService.translate('common.submit')
    };
  }
}
