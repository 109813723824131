<div #tooltip role="tooltip" class="jt-tooltip--schedule tooltip" [ngClass]="showTooltip" [ngStyle]="tooltipStyle">
  <div class="arrow" style="left: calc(50% - 0.4rem)"></div>
  <div class="tooltip-inner" [class.event-absence]="info.isAbsence" *ngIf="info">
    <ng-container *ngIf="info.isAbsence">
      <div class="item event-type" [ngStyle]="{ 'background-color': info.colour, color: info.textColour }">
        <div class="event-type__status">
          <span *ngIf="info.icon" class="event-type__icon material-icons">{{ info.icon }}</span>
          <ng-container *ngIf="info.showTitle; else displayTypeAsTitle">
            <div class="item event-title">{{ info.title }}</div>
          </ng-container>
          <ng-template #displayTypeAsTitle>
            <span>{{ info.status }}</span>
          </ng-template>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!info.isAbsence">
      <div class="item event-title" *ngIf="info.title">{{ info.title }}</div>
      <div class="item event-state">
        <span *ngIf="info.icon" class="material-icons event-title-icon">{{ info.icon }}</span>
        {{ info.status }}
      </div>
    </ng-container>

    <div class="item extra-info">
      <div class="event-assigned">
        {{ info.assignedTo }}
      </div>
      <div
        *ngFor="let item of info.bodyItems"
        [innerHtml]="item"
        [ngStyle]="{ 'border-top-color': info.textColour }"
        class="event-body"
      ></div>
    </div>
  </div>
</div>
