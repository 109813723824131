import { EventEmitter, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Job, JumptechDate } from '@jump-tech-frontend/domain';
import { JobAssignment } from '../../../core/domain/job';
import { ScheduleMoreInfoDm, ScheduleMoreInfoVm } from '../../schedule.model';
import { ScheduleRepository } from '../../schedule.repository';
import { ScheduleJobsDisplayPresenter } from '../schedule-jobs-display/schedule-jobs-display.presenter';

@Injectable()
export class ScheduleEventMoreDetailsPresenter {
  context = null;

  constructor(private repository: ScheduleRepository, private sjdPresenter: ScheduleJobsDisplayPresenter) {}

  public load(viewModelSubject$, closedOnSuccessEventEmitter: EventEmitter<any>, ctx) {
    this.context = ctx;
    this.repository.loadMoreDetails((dm: ScheduleMoreInfoDm) => {
      let vm: ScheduleMoreInfoVm = null;
      if (dm && !dm.scheduleSuccess) {
        const startIso = dm.form.get('startIso').value;
        const endIso = dm.form.get('endIso').value;
        const durationDisplay = this.calculateDurationDisplay(startIso, endIso, dm);
        const addressDisplay = this.formatAddress(dm.address);
        const isTimeInvalid = this.isTimeInvalid(dm.form);
        const selectedEngineers: JobAssignment[] = dm.jobAssignments.map((x, idx) => ({
          ...x,
          assignmentType: idx === 0 ? 'LEAD' : 'SUPPORT',
          type: x.assignmentType === 'LEAD' ? 'filled' : 'outlined',
          color: x.assignmentType === 'LEAD' ? 'primary' : 'high',
          isSupportEngineer: x.assignmentType === 'SUPPORT',
          titleText: x.assignmentType === 'SUPPORT' ? dm.i18nSetLeadEngineerBtn : ''
        }));

        vm = {
          ...this.parseI18ns(dm),
          ...this.parseQaHooks(),
          addressDisplay,
          allEngineers: dm.engineers,
          customerName: `${dm.firstName} ${dm.lastName}`,
          email: dm.email,
          phoneNumber: dm.phoneNumber,
          selectedEngineers,
          projectId: dm.projectId,
          jobId: dm.id,
          jobType: dm.type,
          form: dm.form,
          addEngineerForm: dm.addEngineerForm,
          isAddingEngineer: dm.isAddingEngineer,
          durationDisplay,
          tradesPeopleDisplay: ` ${dm.i18nTradesPeopleHeader} (${dm.jobAssignments.length})`,
          scheduleInProgress: dm.scheduleInProgress,
          scheduleValid: dm.form.valid,
          isInitialSchedule: dm.isInitialSchedule,
          isTimeInvalid,
          isScheduleButtonDisabled: dm.scheduleInProgress || !dm.form.valid || isTimeInvalid || dm.isReadonlyAggregator,
          showEngineerRequiredError: !selectedEngineers.length && !dm.isAddingEngineer
        };
      }

      viewModelSubject$.next(vm);
      if (dm && dm.scheduleSuccess) {
        closedOnSuccessEventEmitter.emit(dm);
      }
    });
  }

  calculateDurationDisplay(startIso: string, endIso: string, dm: ScheduleMoreInfoDm): string {
    if (!startIso || !endIso) return '';

    const duration = JumptechDate.from(startIso).until(JumptechDate.from(endIso), {
      units: ['days', 'hours', 'minutes']
    });
    let displayDuration = `${dm.i18nDurationLabel}:`;
    if (duration.days === 1) {
      displayDuration = `${duration.days} ${dm.i18nDayLabel}`;
    } else if (duration.days > 1) {
      displayDuration = `${duration.days} ${dm.i18nDaysLabel}`;
    }
    if (duration.hours === 1) {
      displayDuration = `${displayDuration} ${duration.hours} ${dm.i18nHourLabel}`;
    } else if (duration.hours > 1) {
      displayDuration = `${displayDuration} ${duration.hours} ${dm.i18nHoursLabel}`;
    }
    if (duration.minutes > 1) {
      displayDuration = `${displayDuration} ${duration.minutes} ${dm.i18nMinutesLabel}`;
    }
    return displayDuration;
  }

  formatAddress(address: Partial<Job['address']>): string {
    if (!address) {
      return '';
    }
    const addressArray = [
      address.line1,
      address.line2,
      address.line3,
      address.line4,
      address.town,
      address.county,
      address.postCode
    ];
    return addressArray.filter(x => !!x).join(', ');
  }

  public closeMoreDetails(): void {
    this.repository.closeMoreDetails();
    if (this.context !== 'project') {
      this.sjdPresenter.initDraggableItems();
    }
  }

  public addEngineer(formEl): void {
    this.repository.addEngineer();
    setTimeout((): void => {
      formEl.nativeElement.querySelector('[data-qa="mdEngineerSelect"] input').focus();
    });
  }

  public setLeadEngineer(engineer: JobAssignment): void {
    this.repository.setLeadEngineer(engineer);
  }

  public deleteEngineer(engineer: JobAssignment): void {
    this.repository.deleteEngineer(engineer);
  }

  public confirmAddEngineer(): void {
    this.repository.confirmAddEngineer();
  }

  public cancelAddEngineer(): void {
    this.repository.cancelAddEngineer();
  }

  handleDateTimeChange(): void {
    this.repository.handleDateTimeChange();
  }

  scheduleJob(): void {
    this.repository.scheduleJob().then();
  }

  goToProject(): void {
    this.repository.goToProject();
  }

  parseI18ns(dm: ScheduleMoreInfoDm) {
    return {
      i18nProvisionallyScheduleBtn: dm.i18nProvisionallyScheduleBtn,
      i18nInvalidDateFormat: dm.i18nInvalidDateFormat,
      i18nStartDateLabel: dm.i18nStartDateLabel,
      i18nStartDatePlaceholder: dm.i18nStartDatePlaceholder,
      i18nStartDateRequired: dm.i18nStartDateRequired,
      i18nTradesPeopleHeader: dm.i18nTradesPeopleHeader,
      i18nScheduleNowBtn: dm.i18nScheduleNowBtn,
      i18nGoToProjectBtn: dm.i18nGoToProjectBtn,
      i18nCancelBtn: dm.i18nCancelBtn,
      i18nConfirmBtn: dm.i18nConfirmBtn,
      i18nCloseBtn: dm.i18nCloseBtn,
      i18nEndDateLabel: dm.i18nEndDateLabel,
      i18nEndDatePlaceholder: dm.i18nEndDatePlaceholder,
      i18nEndDateRequired: dm.i18nEndDateRequired,
      i18nEndDateBeforeStart: dm.i18nEndDateBeforeStart,
      i18nStartTimeLabel: dm.i18nStartTimeLabel,
      i18nEndTimeLabel: dm.i18nEndTimeLabel,
      i18nAddTradesPersonBtn: dm.i18nAddTradesPersonBtn,
      i18nSelectEngineerPlaceholder: dm.i18nSelectEngineerPlaceholder,
      i18nStartDateAfterEnd: dm.i18nStartDateAfterEnd,
      i18nSetLeadEngineerBtn: dm.i18nSetLeadEngineerBtn,
      i18nRemoveEngineerBtn: dm.i18nRemoveEngineerBtn,
      i18nRescheduleReasonInputLabel: dm.i18nRescheduleReasonInputLabel,
      i18nRescheduleReasonInputPlaceholder: dm.i18nRescheduleReasonInputPlaceholder,
      i18nEngineerRequiredError: dm.i18nEngineerRequiredError,
      i18nTimeIsInvalid: dm.i18nTimeIsInvalid,
      i18nLead: `(${dm.i18nLead})`
    };
  }

  parseQaHooks() {
    return {
      qaDragHandle: 'mdDragHandle',
      qaCloseBtn: 'mdCloseBtn',
      qaGoToProjectBtn: 'mdGoToProjectBtn',
      qaScheduleNowBtn: 'mdScheduleNowBtn',
      qaStartDateInput: 'mdStartDateInput',
      qaStartDateBtn: 'mdStartDateBtn',
      qaStartTimeSelect: 'mdStartTimeSelect',
      qaEndDateInput: 'mdEndDateInput',
      qaEndDateBtn: 'mdEndDateBtn',
      qaEndTimeSelect: 'mdEndTimeSelect',
      qaSetLeadEngineerBtn: 'mdSetLeadEngineerBtn',
      qaRemoveEngineerBtn: 'mdRemoveEngineerBtn',
      qaAddEngineerBtn: 'mdAddEngineerBtn',
      qaEngineerSelect: 'mdEngineerSelect',
      qaConfirmAddEngineerBtn: 'mdConfirmAddEngineerBtn',
      qaCancelAddEngineerBtn: 'mdCancelAddEngineerBtn',
      qaRescheduleReasonInput: 'mdRescheduleReasonInput'
    };
  }

  isTimeInvalid = (form: FormGroup) => {
    const startDate = form.get('startDate').value;
    const endDate = form.get('endDate').value;
    const startIso = form.get('startIso').value;
    const endIso = form.get('endIso').value;

    if (!startDate || !endDate || !startIso || !endIso) return false;

    const isSameDate =
      startDate.day === endDate.day && startDate.month === endDate.month && startDate.year === endDate.year;

    if (isSameDate) {
      const startTime = Date.parse(startIso) / 1000;
      const endTime = Date.parse(endIso) / 1000;

      if (startTime >= endTime) {
        return true;
      }
    }

    return false;
  };
}
