<div class="addressV2" *ngIf="vm$ | async as vm" [class.addressV2--desktop]="context === ControlContext.Desktop">
  <jump-tech-frontend-address-search
    *ngIf="(manualEdit$ | async) === false"
    [provider]="provider"
    [context]="context"
    [disabled]="disabled"
    [countryCodes]="vm.countryCodes"
    [label]="vm.label"
    (selectedResult)="addressSelected($event)"
  ></jump-tech-frontend-address-search>

  <div class="addressV2__results">
    <jump-tech-frontend-address-result
      class="addressV2__results__result"
      *ngIf="address && (manualEdit$ | async) === false"
      [address]="address"
      [context]="context"
      [countryCodes]="vm.countryCodes"
    ></jump-tech-frontend-address-result>

    <jump-tech-frontend-address-editor
      class="addressV2__results__editor"
      *ngIf="manualEdit$ | async"
      [context]="context"
      [address]="address"
      [countryCodes]="vm.countryCodes"
      (addressUpdated)="addressUpdatedManually($event)"
    ></jump-tech-frontend-address-editor>
  </div>

  <span
    class="addressV2__manual-toggle"
    size="sm"
    *ngIf="(manualEdit$ | async) === false && !disabled"
    [attr.data-qa]="'addressInputToggle'"
    (click)="toggleEditAddress()"
  >
    {{ vm.enterManually }}
  </span>
</div>
