<div class="schedule-more-details__row">
  <div class="schedule-more-details__heading">{{ vm.i18nTradesPeopleHeader }}</div>
</div>
<div class="schedule-more-details__row-split" [formGroup]="vm.form">
  <div class="schedule-more-details__input">
    <label class="schedule-more-details__label" for="startDate">{{ vm.i18nStartDateLabel }}</label>
    <div class="input-group">
      <input
        class="form-control schedule-more-details__calendar-input"
        [placeholder]="vm.i18nStartDatePlaceholder"
        [attr.data-qa]="vm.qaStartDateInput"
        name="dp"
        id="startDate"
        formControlName="startDate"
        ngbDatepicker
        #startDate="ngbDatepicker"
        (input)="onHandleDateTimeChange.emit()"
        (dateSelect)="onHandleDateTimeChange.emit()"
        [maxDate]="vm.form.get('endDate').value"
      />
      <div class="input-group-append">
        <button
          class="schedule-more-details__calendar-button"
          (click)="startDate.toggle()"
          type="button"
          [attr.data-qa]="vm.qaStartDateBtn"
        >
          <span class="schedule-more-details__calendar-icon">
            <jui-icon name="event" size="sm" color="secondary"></jui-icon>
          </span>
        </button>
      </div>
    </div>
    <div
      *ngIf="vm.form.get('startDate').errors && vm.form.get('startDate').dirty"
      class="schedule-more-details__form-error"
    >
      <div *ngIf="vm.form.get('startDate').errors?.required">
        {{ vm.i18nStartDateRequired }}
      </div>
      <div *ngIf="vm.form.get('startDate').errors?.ngbDate?.invalid">
        {{ vm.i18nInvalidDateFormat }}
      </div>
      <div *ngIf="vm.form.get('startDate').errors?.ngbDate?.maxDate">
        {{ vm.i18nStartDateAfterEnd }}
      </div>
    </div>

    @if(vm.isTimeInvalid){
    <div class="schedule-more-details__form-error">
      {{ vm.i18nTimeIsInvalid }}
    </div>
    } @else {
    <div class="schedule-more-details__sub-heading">
      {{ vm.durationDisplay }}
    </div>
    }
  </div>
  <div class="schedule-more-details__input schedule-more-details__input--time-control">
    <label class="schedule-more-details__label" for="startTime">{{ vm.i18nStartTimeLabel }}</label>
    <ng-select
      class="schedule-more-details__time-input"
      formControlName="startTime"
      id="startTime"
      bindLabel="label"
      bindValue="name"
      [items]="this.timeSlots"
      [clearable]="false"
      [attr.data-qa]="vm.qaStartTimeSelect"
      (change)="onHandleDateTimeChange.emit()"
    ></ng-select>
  </div>
  <div class="schedule-more-details__input">
    <label class="schedule-more-details__label" for="endDate">{{ vm.i18nEndDateLabel }}</label>
    <div class="input-group">
      <input
        class="form-control schedule-more-details__calendar-input"
        [placeholder]="vm.i18nEndDatePlaceholder"
        name="dp"
        id="endDate"
        formControlName="endDate"
        ngbDatepicker
        #endDate="ngbDatepicker"
        [attr.data-qa]="vm.qaEndDateInput"
        (input)="onHandleDateTimeChange.emit()"
        (dateSelect)="onHandleDateTimeChange.emit()"
        [minDate]="vm.form.get('startDate').value"
      />
      <div class="input-group-append">
        <button
          class="schedule-more-details__calendar-button"
          (click)="endDate.toggle()"
          type="button"
          [attr.data-qa]="vm.qaEndDateBtn"
        >
          <span class="schedule-more-details__calendar-icon">
            <jui-icon name="event" size="sm" color="secondary"></jui-icon>
          </span>
        </button>
      </div>
    </div>
    <div
      *ngIf="vm.form.get('endDate').errors && vm.form.get('endDate').dirty"
      class="schedule-more-details__form-error"
    >
      <div *ngIf="vm.form.get('endDate').errors?.required">
        {{ vm.i18nEndDateRequired }}
      </div>
      <div *ngIf="vm.form.get('endDate').errors?.ngbDate?.invalid">
        {{ vm.i18nInvalidDateFormat }}
      </div>
      <div *ngIf="vm.form.get('endDate').errors?.ngbDate?.minDate">
        {{ vm.i18nEndDateBeforeStart }}
      </div>
    </div>
  </div>
  <div class="schedule-more-details__input schedule-more-details__input--time-control">
    <label class="schedule-more-details__label" for="endTime">{{ vm.i18nEndTimeLabel }}</label>
    <ng-select
      class="schedule-more-details__time-input"
      formControlName="endTime"
      id="endTime"
      bindLabel="label"
      bindValue="name"
      [items]="timeSlots"
      [clearable]="false"
      [attr.data-qa]="vm.qaEndTimeSelect"
      (change)="onHandleDateTimeChange.emit()"
    ></ng-select>
  </div>
</div>
