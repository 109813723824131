import { SupportedDateFormats, SupportedExportDateFormats } from './types';

export class JumptechDateFormats {
  private static JumptechDateFormat: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  };

  private static JumptechTimeFormat: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short'
  };

  private static JumptechDateTimeFormat: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short'
  };

  private static JumptechTimestampFormat: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short'
  };

  private static JumptechExportDateFormat: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  };

  private static JumptechExportTimeFormat: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short'
  };

  private static JumptechExportDateTimeFormat: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };

  public static getFormatOptions(
    format: SupportedDateFormats | SupportedExportDateFormats
  ): Intl.DateTimeFormatOptions {
    switch (format) {
      case 'Date':
        return JumptechDateFormats.JumptechDateFormat;
      case 'Time':
        return JumptechDateFormats.JumptechTimeFormat;
      case 'DateTime':
        return JumptechDateFormats.JumptechDateTimeFormat;
      case 'Timestamp':
        return JumptechDateFormats.JumptechTimestampFormat;
      case 'ExportDate':
        return JumptechDateFormats.JumptechExportDateFormat;
      case 'ExportTime':
        return JumptechDateFormats.JumptechExportTimeFormat;
      case 'ExportDateTime':
        return JumptechDateFormats.JumptechExportDateTimeFormat;
    }
  }
}
