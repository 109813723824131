<div class="address-editor" *ngIf="vm$ | async as vm" [class.address-editor--desktop]="context === ControlContext.Desktop">
  <div class="address-editor__questions">
    <question-textbox
      *ngFor="let params of vm.addressFieldParams"
      [params]="params"
      (formChange)="fieldChanged($event)"
    ></question-textbox>
  </div>

  @if(context === ControlContext.Desktop) {
    <div class="address-editor__actions address-editor__actions--desktop">
      <jui-button size="sm" color="low" (click)="addressUpdated.next(vm.originalAddress)">
        {{ vm.i18n.cancel }}</jui-button
      >
      <jui-button size="sm" [disabled]="vm.form.invalid" (click)="addressUpdated.next(vm.form.value)">
        {{ vm.i18n.ok }}</jui-button
      >
    </div>
  } @else {
    <div class="address-editor__actions">
      <jui-button size="lg" color="secondary" display="ghost" (click)="addressUpdated.next(vm.originalAddress)">
        {{ vm.i18n.cancel }}</jui-button
      >
      <jui-button size="lg" [disabled]="vm.form.invalid" (click)="addressUpdated.next(vm.form.value)">
        {{ vm.i18n.ok }}</jui-button
      >
    </div>
  }


</div>
