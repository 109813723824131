import { RendererInterface, RenderOptions, STANDARD_RENDER_REGEX_GLOBAL } from '@jump-tech-frontend/domain';
import { get } from 'lodash';

export class StandardRender implements RendererInterface {
  public render(template: string, context: unknown, options?: RenderOptions): string {
    const matches = this.getReplaceableMatches(template);

    if (!matches) {
      return template;
    }

    const defaultReplacement = '';

    if (
      options?.returnAsList &&
      matches.length === 1 &&
      template.at(0) === '{' &&
      template.at(template.length - 1) === '}'
    ) {
      const key = matches[0].replace(/[{}]/g, '');
      return get(context, key) || defaultReplacement;
    }

    matches.forEach(match => {
      const matchParts = match.replace(/[{}]/g, '').split('.');
      const nestedValue = this.getNestedValue(context, matchParts);

      template = template.replace(match, nestedValue || defaultReplacement);
    });

    return template;
  }

  private getReplaceableMatches(template: string): RegExpMatchArray | null {
    return template.match(STANDARD_RENDER_REGEX_GLOBAL);
  }

  private getNestedValue(context: unknown, matchParts: string[], index = 0): string {
    const matchPart = matchParts.at(index);
    const value = context?.[matchPart];

    if (Array.isArray(value)) {
      let text = '';

      for (const item of value) {
        if (item.key && item.value) {
          text += `<div>${item.key || ''}: ${item.value || ''}</div>`;
        }
      }
      return text;
    } else {
      return typeof value === 'object' ? this.getNestedValue(value, matchParts, index + 1) : value;
    }
  }
}
