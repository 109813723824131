import { ApiService } from './api.service';
import { Datasource } from '../domain/datasource';
import { inject } from '@angular/core';

export function getDataSourceData() {
  const apiService = inject(ApiService);

  return {
    getData: async (datasource?: Datasource): Promise<unknown[]> => {
      if (!datasource) {
        return [];
      }
      const url = new URL(`${datasource.url}/${datasource.type}`);

      if (datasource?.tenant) {
        url.searchParams.append('tenant', datasource.tenant);
      }

      if (datasource?.fields && datasource.fields.length > 0) {
        url.searchParams.append('fields', datasource.fields.join(','));
      }

      if (datasource.filters) {
        url.searchParams.append('filters', encodeURIComponent(JSON.stringify(datasource.filters)));
      }

      if (datasource.projectionList) {
        url.searchParams.append('projectionList', datasource.projectionList);
      }

      let result = await apiService.get(url.href);

      if (!result) {
        result = [];
      }

      return result;
    }
  };
}
