<div class="task-section" [ngClass]="vm().typeClass" *ngIf="vm().visible">
  <div class="task-section__title">
    <div class="task-section__text">{{ vm().label }}</div>
    <div class="" *ngIf="vm().showTick">
      <i *ngIf="vm().isComplete" class="task-section__icon material-icons" [attr.data-qa]="vm().label + '_checkmark'"
        >check_circle_outline</i
      >
      <div *ngIf="vm().showCheck">
        <label class="check-box-container">
          <input
            type="checkbox"
            autocomplete="off"
            [checked]="vm().isComplete"
            (change)="toggle(vm().id)"
            [attr.data-qa]="vm().label + '_checkbox'"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="task-section__description" *ngIf="vm().description">{{ vm().description }}</div>
  <div *ngIf="vm().showQuestions" class="task-section__question">
    <app-edit-layout-questions
      *ngFor="let question of vm().questions"
      [question]="question"
      [form]="vm().form"
      [project]="vm().project"
    >
    </app-edit-layout-questions>
  </div>
  <div>
    <jui-button
      *ngIf="vm().actionable"
      [readOnlyDisable]="vm().id"
      [readOnlyDisableIsDisabled]="!vm().enabled"
      [class]="'task-action-button ' + vm().id"
      [display]="vm().activeTheme === 'dark' ? 'ghost' : 'contained'"
      size="sm"
      expand
      (click)="action(vm().id)"
      [attr.data-qa]="vm().actionLabel + '_action-button'"
    >
      {{ vm().actionLabel }}
    </jui-button>
  </div>
</div>
