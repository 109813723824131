import { HttpParams } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ProjectConfiguration } from '@jump-tech-frontend/domain';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { DataSharing, ProjectAssignment } from '../admin/user-management/domain/types';
import { PathwayFeature } from '../auth/services/access.service';
import { AuthenticationService } from '../auth/services/authentication.service';
import { UserService } from '../auth/services/user.service';
import { UserLookupResultItem } from '../core/api.service';
import { User } from '../core/domain/user';
import { FeatureFlagService } from '../core/feature-flag/feature-flag.service';
import { HttpGateway } from '../core/http-gateway.service';
import { CONTACT_LOGGING_LD_FEATURE_KEY } from '../feature-modules/contact-logging/contact-log.model';
import {
  ApiDocumentState,
  DOC_PACK_MANAGER_LD_FEATURE_KEY,
  DOCUMENT_STATE_KEY_PREFIX,
  IDocumentPackDefinition,
  IDocumentPackDefinitionDetails
} from '../project-detail/document-pack/document-pack.model';
import { ProgressIndicatorStatus } from '../shared/progress-indicator/progress-indicator.component';
import { ToasterService } from '../toast/toast-service';
import { NgxCsvExport } from './ngx-csv-export';
import { ProjectResult } from './project-result';
import {
  CustomDashboardPositionType,
  DashboardField,
  ExportField,
  FilterDropDownElement,
  isDashboardField,
  isDateRange,
  isExportField,
  ProjectsDm,
  SearchParameters,
  SearchResult
} from './projects.model';

export const ProjectSearchSettings = 'ProjectSearchSettings';
@Injectable({ providedIn: 'root' })
export class ProjectsRepository {
  private destroyRef: DestroyRef = inject(DestroyRef);

  private user: User;

  private projectTypesCache: string[] = [];
  private projectConfigurationCache: { [key: string]: ProjectConfiguration } = {};
  private dataSharingCache: DataSharing = null;
  private projectAssignmentCache: ProjectAssignment = null;
  private ownersCache: FilterDropDownElement[] = [];

  refreshTimeout: NodeJS.Timeout;

  data$: Subject<ProjectsDm>;
  dataSubscription: Subscription;
  dataDm: ProjectsDm = {
    showLoaders: true,
    userTenant: null,
    projectStates: [],
    projectTypes: [],
    projectStatuses: [],
    delegates: [],
    subTenants: [],
    contactLoggingEnabled: false,
    contactAttempts: [],
    documentPackEnabled: false,
    documents: [],
    documentStates: [],

    ownerFilterEnabled: false,
    owners: [],
    teams: [],

    customFilters: [],
    advancedFilters: [],

    searchParameters: null,

    searchInProgress: false,
    result: {
      page: 1,
      total: 0,
      projects: []
    },
    exportInProgress: false,
    showExport: false,

    i18n: {
      projectTypeLabel: '',
      projectStatusLabel: '',
      installerLabel: '',
      documentsLabel: '',
      documentsStatusLabel: '',
      ownerLabel: '',
      projectsFoundLabel: '',
      projectFoundLabel: '',
      assignmentLabel: '',
      pageSizeLabel: ''
    }
  };

  constructor(
    private router: Router,
    private gateway: HttpGateway,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private i18nService: TranslocoService,
    private featureFlagService: FeatureFlagService,
    protected toasterService: ToasterService,
    private ngxCsv: NgxCsvExport
  ) {
    this.init();
  }

  public init() {
    this.data$ = new Subject<ProjectsDm>();
    this.subscribeToUser();
    this.authenticationService.signOutObservable
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => localStorage.removeItem(ProjectSearchSettings));
  }

  public async warmUp() {
    await this.initialiseDm();
  }

  public async load(cb: (dm: ProjectsDm) => void) {
    this.dataSubscription?.unsubscribe();
    this.dataSubscription = this.data$.subscribe(cb);
    await this.initialiseDm();
  }

  public async updateSearchProjectType(projectType?: string) {
    this.dataDm.showLoaders = true;
    this.dataDm.result = {
      page: 1,
      total: 0,
      projects: []
    };
    this.data$.next(this.dataDm);
    if (this.dataDm.searchParameters?.type !== projectType) {
      this.dataDm.searchParameters = {
        type: projectType,
        from: 0,
        maxResults: this.dataDm.searchParameters.maxResults || 25,
        sortBy: 'created_on',
        sortOrder: 'desc'
      };
    } else {
      this.dataDm.searchParameters.type = projectType;
    }

    const projectConfiguration = await this.getProjectConfiguration(projectType);
    if (!projectConfiguration) {
      console.log('Project Configuration not found');
      return;
    }

    this.dataDm.customDashboardFields = projectConfiguration.configuration.customDashboardFields;
    this.dataDm.exportConfiguration = projectConfiguration.configuration.exportConfiguration;
    this.dataDm.projectStatuses = projectConfiguration.configuration.states.map(x => ({
      id: x.status,
      name: x.label
    }));
    this.dataDm.projectStatuses.push({
      id: 'ALL',
      name: this.i18nService.translate('dashboard.anyStatus'),
      sticky: true
    });
    this.dataDm.projectStates = (projectConfiguration.configuration.states || [])
      .filter(state => {
        return !state.hidden;
      })
      .map(state => ({
        id: state.status,
        label: state.label,
        count: 0,
        showProgress: state.showProgress ?? false,
        status: ProgressIndicatorStatus.todo,
        showSkipped: false,
        statusData: null
      }));

    this.dataDm.delegates = await this.getDelegates(projectType);
    this.dataDm.owners = await this.getOwners(projectConfiguration.configuration?.managerRole);
    this.dataDm.teams = await this.getTeamAssignments(projectType);

    const [documents, documentStates] = await this.getDocumentPackDefinitionDetails(
      projectConfiguration.documentPackDefinition
    );
    this.dataDm.documents = documents;
    this.dataDm.documentStates = documentStates;

    this.dataDm.customFilters = (projectConfiguration.configuration.customDashboardFilters || []).filter(
      x => x.position && x.position === CustomDashboardPositionType.MAIN
    );
    this.dataDm.advancedFilters = (projectConfiguration.configuration.customDashboardFilters || []).filter(
      x => !x.position || x.position === CustomDashboardPositionType.ADVANCED
    );
    this.dataDm.fields = this.getDashboardFields();
    this.data$.next(this.dataDm);
    await this.doSearch();
  }

  public async updateSearchStatuses(statuses: string[]) {
    if (statuses?.length && statuses[statuses?.length - 1] === 'ALL') {
      statuses = [];
    }
    statuses = statuses.filter(st => st !== 'ALL');

    if (!statuses?.length) {
      delete this.dataDm.searchParameters.status;
    } else {
      this.dataDm.searchParameters.status = statuses.join(',');
    }
    this.data$.next(this.dataDm);
    await this.doSearch();
  }

  public async updateSearchDelegate(delegate: string) {
    if (!delegate || delegate === 'ALL') {
      delete this.dataDm.searchParameters.delegateTenant;
    } else if (delegate === 'UNASSIGNED') {
      this.dataDm.searchParameters.delegateTenant = null;
    } else {
      this.dataDm.searchParameters.delegateTenant = delegate;
    }
    this.data$.next(this.dataDm);
    await this.doSearch();

    this.dataDm.subTenants = await this.getSubTenants();
    this.data$.next(this.dataDm);
  }

  public async updateSearchSubTenant(subTenant: string) {
    if (!subTenant || subTenant === 'ALL') {
      delete this.dataDm.searchParameters.subTenantIds;
    } else {
      this.dataDm.searchParameters.subTenantIds = subTenant;
    }
    this.data$.next(this.dataDm);
    await this.doSearch();
  }

  public async updateSearchContactAttempts(contactLogAttempts: number | number[] | null) {
    if (contactLogAttempts === null) {
      delete this.dataDm.searchParameters.contactLogAttempts;
    } else {
      this.dataDm.searchParameters.contactLogAttempts = contactLogAttempts;
    }
    this.data$.next(this.dataDm);
    await this.doSearch();
  }

  public async updateSearchOwner(owner: string) {
    if (!owner || owner === 'ALL') {
      delete this.dataDm.searchParameters.ownerName;
    } else if (owner === 'UNASSIGNED') {
      this.dataDm.searchParameters.ownerName = '';
    } else {
      this.dataDm.searchParameters.ownerName = owner;
    }
    this.data$.next(this.dataDm);
    await this.doSearch();
  }

  public async updateSearchDocuments(documents: string[]) {
    if (!documents?.length) {
      delete this.dataDm.searchParameters.documentNames;
    } else {
      this.dataDm.searchParameters.documentNames = documents.join(',');
    }
    this.data$.next(this.dataDm);
    await this.doSearch();
  }

  public async updateSearchDocumentStates(documentStates: string[]) {
    if (!documentStates?.length) {
      delete this.dataDm.searchParameters.documentStatuses;
    } else {
      this.dataDm.searchParameters.documentStatuses = documentStates.join(',');
    }
    this.data$.next(this.dataDm);
    await this.doSearch();
  }

  public async updateSearchCustomFilter(filter: object) {
    if (!filter) {
      return;
    }

    this.dataDm.searchParameters = {
      ...this.dataDm.searchParameters,
      ...filter
    };
    this.resolveSearchEventType();
    this.data$.next(this.dataDm);
    await this.doSearch();
  }

  public async updateSearchSort(field: DashboardField) {
    if (field.noSort) {
      return;
    }
    const sort = field.sortField || field.field;
    if (sort !== this.dataDm.searchParameters.sortBy) {
      this.dataDm.searchParameters.sortBy = sort;
      this.dataDm.searchParameters.sortOrder = 'desc';
    } else {
      this.dataDm.searchParameters.sortOrder = this.dataDm.searchParameters.sortOrder === 'desc' ? 'asc' : 'desc';
    }
    this.data$.next(this.dataDm);
    await this.doSearch();
  }

  public async updateSearchMaxResults(maxResults: number) {
    this.dataDm.searchParameters.maxResults = maxResults;
    this.data$.next(this.dataDm);
    await this.doSearch();
  }

  public async viewProject(id: string, newTab: boolean) {
    const url = `/project/${id}`;
    if (newTab) {
      window.open(url, id);
    } else {
      await this.router.navigate([url]);
    }
  }

  public async refresh(delay: number) {
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(() => {
      this.doSearch();
    }, delay);
  }

  public async export() {
    this.dataDm.exportInProgress = true;
    this.data$.next(this.dataDm);
    this.toasterService.pop(
      'info',
      this.i18nService.translate('common.export'),
      this.i18nService.translate('dashboard.exportWillDownloadOnceComplete')
    );
    const exportSearchParameters = JSON.parse(JSON.stringify(this.dataDm.searchParameters).replace(/null/g, '""'));
    delete exportSearchParameters.from;
    let results: ProjectResult[] = [];
    let nextPageToken: string = null;
    do {
      const params = {
        ...exportSearchParameters,
        nextPageToken
      };
      const result: SearchResult = await this.gateway.get(environment.apiSearchProjectsUrl, params);
      const page = this.processResults(result, true);
      results = results.concat(page);
      nextPageToken = result.nextPageToken;
    } while (nextPageToken);

    this.doExport(results);
  }

  public async changePage(page: number) {
    if (!this.dataDm.result || !this.dataDm.searchParameters) {
      return;
    }

    await this.doSearch(page);
  }

  private subscribeToUser() {
    this.userService.userObservable.pipe().subscribe(async (user: User) => {
      if (user !== null) {
        this.user = user;
        this.dataDm.userTenant = user.tenant;
        this.dataDm.contactLoggingEnabled = await this.featureFlagService.isFeatureEnabled(
          CONTACT_LOGGING_LD_FEATURE_KEY
        );
        this.dataDm.documentPackEnabled = await this.featureFlagService.isFeatureEnabled(
          DOC_PACK_MANAGER_LD_FEATURE_KEY
        );
        this.dataDm.ownerFilterEnabled = this.isFeatureEnabled(PathwayFeature.DashboardFilterOwner);
      }
    });
  }

  private async initialiseDm() {
    this.data$.next(this.dataDm);
    this.initI18ns();
    const projectTypes = await this.getProjectTypes();
    this.dataDm.projectTypes = projectTypes.map(x => ({ id: x, name: x }));
    this.dataDm.searchParameters = this.loadSearchParameters() || {};
    await this.updateSearchProjectType(this.dataDm.searchParameters?.type || projectTypes[0]);
  }

  private async doSearch(page = 1) {
    try {
      if (!this.dataDm.searchParameters.type) {
        this.dataDm.searchParameters.type = this.dataDm.projectTypes?.[0]?.id;
      }

      const clonedParams = JSON.parse(JSON.stringify(this.dataDm.searchParameters).replace(/null/g, '""'));
      delete clonedParams.eventFilterType;
      delete clonedParams.eventFilterDateRange;

      const params = {
        ...clonedParams,
        from: (page - 1) * clonedParams.maxResults,
        aggregations: 'status'
      };

      this.saveSearchParams();
      this.dataDm.searchInProgress = true;
      this.data$.next(this.dataDm);
      const result: SearchResult = await this.gateway.get(environment.apiSearchProjectsUrl, params);
      this.processAggregations(result);
      const projects = this.processResults(result, false);
      this.dataDm.result = {
        page: page,
        total: result.total,
        projects: projects
      };
      this.dataDm.showExport = !!projects?.length;
      this.dataDm.searchInProgress = false;
      this.dataDm.showLoaders = false;
      this.data$.next(this.dataDm);
    } catch (err) {
      console.log(err);
    }
    this.dataDm.searchInProgress = false;
    this.data$.next(this.dataDm);
  }

  private processAggregations(searchResult: SearchResult) {
    for (const state of this.dataDm.projectStates) {
      const foundAggregation = searchResult.aggregations.find(aggregation => {
        return aggregation.name === 'status_aggregation';
      });
      const found = foundAggregation?.value?.find(item => item.key === state.id);
      state.count = found ? found.doc_count : 0;
    }
  }

  private processResults(searchResult: SearchResult, isExport: boolean): ProjectResult[] {
    if (!searchResult?.results?.length) {
      return [];
    }

    return searchResult.results.map(r => {
      return new ProjectResult(
        this.i18nService,
        this.dataDm.searchParameters,
        isExport,
        r,
        this.user,
        this.dataDm.projectStates.map(s => ({ status: s.id, label: s.label, showProgress: s.showProgress }))
      );
    });
  }

  private loadSearchParameters(): SearchParameters {
    const settings = localStorage.getItem(ProjectSearchSettings);
    if (!settings) {
      return null;
    }

    const parsedSettings: SearchParameters = JSON.parse(settings);

    if (parsedSettings.type && this.dataDm.projectTypes.some(pct => pct.id === parsedSettings.type)) {
      return parsedSettings;
    }

    return null;
  }

  private saveSearchParams() {
    if (this.dataDm.searchParameters) {
      localStorage.setItem(ProjectSearchSettings, JSON.stringify(this.dataDm.searchParameters));
    }
  }

  private async getProjectTypes(): Promise<string[]> {
    if (!this.projectTypesCache?.length) {
      const params: HttpParams = new HttpParams()
        .set('projectionExpression', 'projectType')
        .set('feature', 'MainDashboard');
      const projectTypes = await this.gateway.get(environment.apiProjectConfigurationsUrl, params);
      this.projectTypesCache = projectTypes.map(x => x.projectType);
    }
    return this.projectTypesCache;
  }

  private async getProjectConfiguration(projectType: string): Promise<ProjectConfiguration> {
    if (!this.projectConfigurationCache[projectType]) {
      this.projectConfigurationCache[projectType] = await this.gateway.get(
        `${environment.apiProjectConfigurationUrl}/${projectType}`,
        {
          v: 1
        }
      );
    }
    return this.projectConfigurationCache[projectType];
  }

  private async getDataSharing() {
    if (!this.dataSharingCache) {
      const results = await this.gateway.get(`${environment.apiTenantSettingsUrl}/dataSharing`, {});
      this.dataSharingCache = results?.[0];
    }
    return this.dataSharingCache;
  }

  private async getProjectAssignment(): Promise<ProjectAssignment | null> {
    if (!this.projectAssignmentCache) {
      const results = await this.gateway.get(`${environment.apiTenantSettingsUrl}/projectAssignment`, {});
      this.projectAssignmentCache = results?.[0];
    }
    return this.projectAssignmentCache;
  }

  private async getDelegates(projectType?: string): Promise<FilterDropDownElement[]> {
    const dataSharing: DataSharing = await this.getDataSharing();
    const dataSharingDelegates = dataSharing.data.filter(dataItem => {
      return dataItem.tenant && dataItem.projectType === projectType;
    });
    const delegates: FilterDropDownElement[] = dataSharingDelegates.map(d => ({ ...d, id: d.tenant, name: d.tenant }));
    if (delegates?.length) {
      delegates.unshift({
        id: 'ALL',
        name: this.i18nService.translate('common.all')
      });
      delegates.push({
        id: 'UNASSIGNED',
        name: this.i18nService.translate('common.unassigned')
      });
    }
    return delegates;
  }

  private async getSubTenants(): Promise<FilterDropDownElement[]> {
    if (!this.dataDm.searchParameters.delegateTenant) {
      return [];
    }

    const dataSharing: DataSharing = await this.getDataSharing();
    const dataSharingDelegates = dataSharing.data.filter(dataItem => {
      return dataItem.tenant && dataItem.projectType === this.dataDm.searchParameters.type;
    });

    const delegationShare = dataSharingDelegates.find(d => d.tenant === this.dataDm.searchParameters.delegateTenant);
    if (!delegationShare?.subTenants?.length) {
      return [];
    }
    const subTenants: FilterDropDownElement[] = delegationShare.subTenants.map(x => ({ ...x }));
    subTenants.unshift({
      id: 'ALL',
      name: this.i18nService.translate('common.all')
    });

    return subTenants;
  }

  private async getUsersByRole(role: string): Promise<UserLookupResultItem[]> {
    return await this.gateway.get(`${environment.apiCustomRoot}/core/users/${encodeURIComponent(role)}`, {});
  }

  private async getOwners(role?: string): Promise<FilterDropDownElement[]> {
    if (!this.ownersCache?.length) {
      const managerRole = role || 'Account Manager';
      const managersList: UserLookupResultItem[] = await this.getUsersByRole(managerRole);
      const managersDropDownElements: FilterDropDownElement[] = managersList
        .filter(e => {
          return e.key !== 'Admin User';
        })
        .map(e => {
          return { id: e.key, name: e.key };
        });
      const stickyDropDownElements = [
        { id: 'UNASSIGNED', name: this.i18nService.translate('common.unassigned'), sticky: true },
        { id: 'ALL', name: this.i18nService.translate('dashboard.anyOwner'), sticky: true }
      ];
      this.ownersCache = [...managersDropDownElements, ...stickyDropDownElements];
    }
    return this.ownersCache;
  }

  private async getTeamAssignments(projectType?: string): Promise<FilterDropDownElement[]> {
    const projectAssignment = await this.getProjectAssignment();
    const assignments = projectAssignment?.data?.filter(x => x.projectType === projectType) || [];

    return assignments.reduce((a, assignment) => {
      const assignmentTeams = assignment.teams;

      for (const assignmentTeam of assignmentTeams) {
        if (a.some(x => x.id === assignmentTeam.id)) {
          continue;
        }
        a.push(assignmentTeam);
      }

      return a;
    }, []);
  }

  private async getDocumentPackDefinition(id: string, version: number): Promise<IDocumentPackDefinitionDetails> {
    try {
      return await this.gateway.get(
        `${environment.apiDocumentPackDefinitionUrl}/${id}/${version}`,
        { parseBody: false },
        { skip: 'true' }
      );
    } catch (_err) {
      return null;
    }
  }

  private async getDocumentPackDefinitionDetails(
    definition: IDocumentPackDefinition
  ): Promise<[FilterDropDownElement[], FilterDropDownElement[]]> {
    if (!definition?.id || !definition?.version) {
      return [[], []];
    }

    const definitionDetails: IDocumentPackDefinitionDetails = await this.getDocumentPackDefinition(
      definition.id,
      definition.version
    );
    if (definitionDetails?.documents && definitionDetails?.documents?.length) {
      const documents: FilterDropDownElement[] = definitionDetails.documents.map(doc => ({
        id: doc.displayName,
        name: doc.displayName
      }));
      const documentStates: FilterDropDownElement[] = Object.keys(ApiDocumentState).map(state => ({
        id: state,
        name: this.i18nService.translate(`${DOCUMENT_STATE_KEY_PREFIX}.${ApiDocumentState[state]}`)
      }));

      return [documents, documentStates];
    }
    return [[], []];
  }

  private resolveSearchEventType() {
    delete this.dataDm.searchParameters.createdBefore;
    delete this.dataDm.searchParameters.createdAfter;
    delete this.dataDm.searchParameters.updatedBefore;
    delete this.dataDm.searchParameters.updatedAfter;
    delete this.dataDm.searchParameters.scheduledBefore;
    delete this.dataDm.searchParameters.scheduledAfter;
    if (this.dataDm.searchParameters.eventFilterType && this.dataDm.searchParameters.eventFilterDateRange) {
      if (
        typeof this.dataDm.searchParameters.eventFilterType === 'string' &&
        isDateRange(this.dataDm.searchParameters.eventFilterDateRange)
      ) {
        const eventFilterType = this.dataDm.searchParameters.eventFilterType.replace(/_.*|Date/, '');
        const params = {};
        if (this.dataDm.searchParameters.eventFilterDateRange?.start) {
          params[`${eventFilterType}After`] = this.dataDm.searchParameters.eventFilterDateRange?.start?.toISOString();
        }
        if (this.dataDm.searchParameters.eventFilterDateRange?.end) {
          params[`${eventFilterType}Before`] = this.dataDm.searchParameters.eventFilterDateRange?.end?.toISOString();
        }
        this.dataDm.searchParameters = {
          ...this.dataDm.searchParameters,
          ...params
        };
      }
    }
  }

  private isFeatureEnabled(featureToAccess: PathwayFeature) {
    if (!this.user || !this.user.accessInfo) {
      return false;
    }
    return (this.user.accessInfo.features || []).filter(feature => feature.name === featureToAccess).length > 0;
  }

  private initI18ns(): void {
    this.dataDm.i18n.projectTypeLabel = this.i18nService.translate('common.projectType');
    this.dataDm.i18n.projectStatusLabel = this.i18nService.translate('common.status');
    this.dataDm.i18n.installerLabel = this.i18nService.translate('common.installer');
    this.dataDm.i18n.documentsLabel = this.i18nService.translate('dashboard.fields.documents');
    this.dataDm.i18n.documentsStatusLabel = this.i18nService.translate('dashboard.fields.documentStatus');
    this.dataDm.i18n.ownerLabel = this.i18nService.translate('common.owner');
    this.dataDm.i18n.projectsFoundLabel = this.i18nService.translate('dashboard.projectsFound');
    this.dataDm.i18n.projectFoundLabel = this.i18nService.translate('dashboard.projectFound');
    this.dataDm.i18n.pageSizeLabel = this.i18nService.translate('dashboard.pageSizeLabel');
    this.dataDm.i18n.assignmentLabel = this.projectAssignmentCache?.label || this.i18nService.translate('common.team');
  }

  private getDashboardFields(eventFilterType?: string): DashboardField[] {
    const hasDocumentPack = this.dataDm.documentPackEnabled && !!this.dataDm.documents.length;
    const canDelegate = !!this.dataDm.delegates?.length;
    const baseFields: DashboardField[] = [
      {
        display: this.i18nService.translate('dashboard.fields.nameAndEmail'),
        field: 'name'
      },
      {
        display: this.i18nService.translate('dashboard.fields.postCode'),
        field: 'postCode'
      },
      {
        display: this.i18nService.translate('dashboard.fields.status'),
        field: 'status',
        sortField: 'statusName'
      },
      {
        display: this.i18nService.translate('dashboard.fields.updatedDate'),
        field: 'updated_on'
      },
      {
        display: this.i18nService.translate('dashboard.fields.createdBy'),
        field: 'created_by_name'
      },
      {
        display: this.i18nService.translate('dashboard.fields.createdOn'),
        field: 'created_on'
      }
    ];
    const customDashboardFields = this.dataDm?.customDashboardFields || [];

    if (canDelegate) {
      baseFields.push({
        display: this.i18nService.translate('dashboard.fields.assignedDate'),
        field: 'assigned_to_date'
      });
      baseFields.push({
        display: this.i18nService.translate('dashboard.fields.installer'),
        field: 'installer',
        sortField: 'subTenantNames'
      });
    } else {
      baseFields.push({
        display: this.i18nService.translate('dashboard.fields.installationDate'),
        field: '{{jobs[installation].scheduledDate | dateFormat: "DateTime"}}',
        sortField: 'scheduledDate',
        context: {
          installation: {
            jobTypeCategory: {
              $eq: 'Installation'
            }
          }
        }
      });
    }
    if (hasDocumentPack) {
      baseFields.push({
        display: this.i18nService.translate('dashboard.fields.documents'),
        field: 'documents',
        noSort: true,
        maxLength: 40
      });
    }

    if (eventFilterType) {
      if (baseFields.find(x => x.field !== eventFilterType)) {
        baseFields.push({
          display: this.i18nService.translate('dashboard.fields.filteredEventDate'),
          field: eventFilterType,
          noSort: true
        });
      }
    }
    return this.mergeCustomFields(baseFields, customDashboardFields);
  }

  private doExport(results: ProjectResult[], eventFilterType?: string) {
    const exportConfiguration = this.getExportConfiguration();
    const prefix = exportConfiguration.prefix;
    const fieldList = exportConfiguration.fields;
    const canDelegate = !!this.dataDm.delegates?.length;

    const opts = {
      headers: fieldList.map(field => field.header)
    };

    if (canDelegate) {
      opts.headers.push(this.i18nService.translate('common.assignedDate'));
      opts.headers.push(this.i18nService.translate('common.installer'));
    }

    if (eventFilterType) {
      opts.headers.push(this.i18nService.translate('common.eventFilterType'));
    }

    const data: unknown[] = results.map(item => {
      const result = {};

      for (const fieldItem of fieldList) {
        result[fieldItem.header] = this.escapeValue(item.getField(fieldItem.field, fieldItem.context));
      }

      if (canDelegate) {
        result[this.i18nService.translate('common.assignedDate')] = item.assigned_to_date;
        result[this.i18nService.translate('common.installer')] = item.installer;
      }

      if (eventFilterType) {
        result[this.i18nService.translate('common.eventFilterType')] = item[eventFilterType];
      }

      return result;
    });
    this.ngxCsv.export(data, `${prefix}_${new Date().getTime()}`, opts);
    this.dataDm.exportInProgress = false;
    this.data$.next(this.dataDm);
  }

  private getExportConfiguration() {
    const defaultExportConfiguration = this.getDefaultExportConfiguration(
      this.dataDm?.exportConfiguration?.excludePii ?? false
    );
    if (this.dataDm?.exportConfiguration?.fields?.length) {
      defaultExportConfiguration.fields = this.mergeCustomFields(
        defaultExportConfiguration.fields,
        this.dataDm?.exportConfiguration.fields || []
      );
    }

    return defaultExportConfiguration;
  }

  private getDefaultExportConfiguration(excludePii: boolean): {
    prefix: string;
    fields: ExportField[];
  } {
    const fields: ExportField[] = [
      {
        header: this.i18nService.translate('dashboard.projectId'),
        field: '{id}'
      },
      {
        header: this.i18nService.translate('dashboard.createdOn'),
        field: 'created_on'
      },
      {
        header: this.i18nService.translate('dashboard.updatedOn'),
        field: 'updated_on'
      },
      {
        header: this.i18nService.translate('dashboard.installationDate'),
        field: '{{jobs[installation].scheduledDate | dateFormat: "DateTime" : "true"}}',
        context: {
          installation: {
            jobTypeCategory: {
              $eq: 'Installation'
            }
          }
        }
      },
      {
        header: this.i18nService.translate('dashboard.createdBy'),
        field: '{created_by_name}',
        alias: 'created_by'
      },
      {
        header: this.i18nService.translate('dashboard.projectType'),
        field: '{type}'
      },
      {
        header: this.i18nService.translate('dashboard.projectStatus'),
        field: 'status'
      }
    ];
    const piiFields: ExportField[] = [
      {
        header: this.i18nService.translate('dashboard.customerFirstName'),
        field: 'firstName'
      },
      {
        header: this.i18nService.translate('dashboard.customerLastName'),
        field: 'lastName'
      },
      {
        header: this.i18nService.translate('dashboard.customerEmail'),
        field: '{email}'
      },
      {
        header: this.i18nService.translate('dashboard.customerPhoneNumber'),
        field: '{phoneNumber}'
      }
    ];
    if (!excludePii) {
      fields.push(...piiFields);
    }
    return {
      prefix: this.i18nService.translate('dashboard.project'),
      fields
    };
  }

  private mergeCustomFields<T extends DashboardField | ExportField>(baseFields: T[], customFields: T[]) {
    const removeIfExisting = (customField: T) => {
      const existingIdx = baseFields.findIndex(f => {
        if (isDashboardField(f) && isDashboardField(customField)) {
          return f.field === customField.field;
        }
        if (isExportField(f) && isExportField(customField)) {
          return f.header === customField.header;
        }
        return false;
      });
      if (existingIdx > -1) {
        baseFields.splice(existingIdx, 1);
      }
    };

    const insertOrAdd = (baseFields: T[], customField: T, beforeAfterPos: 'before' | 'after' | 'pos') => {
      if (beforeAfterPos === 'pos') {
        baseFields.splice(customField.pos, 0, customField);
      } else {
        let idx = baseFields.findIndex((f: T) => {
          if (isDashboardField(f)) {
            return f.field === customField[beforeAfterPos];
          }
          if (isExportField(f)) {
            return f.header === customField[beforeAfterPos];
          }
          return false;
        });
        idx = idx === -1 ? baseFields.length : beforeAfterPos === 'after' ? idx + 1 : idx;
        baseFields.splice(idx, 0, customField);
      }
    };

    for (const customField of customFields || []) {
      removeIfExisting(customField);
      if (customField.remove) {
        // Already removed
      } else if (customField.after) {
        insertOrAdd(baseFields, customField, 'after');
      } else if (customField.before) {
        insertOrAdd(baseFields, customField, 'before');
      } else if (typeof customField.pos !== 'undefined') {
        insertOrAdd(baseFields, customField, 'pos');
      } else {
        baseFields.push(customField);
      }
    }
    return baseFields;
  }

  private escapeValue(value: string): string {
    if (/^[+\-=@].*/.test(value)) {
      return `'${value}`;
    }
    return value;
  }
}
