<ng-container *ngIf="useLayoutGroups(); else standardGallery">
  <div *ngFor="let item of layoutCollection(); index as i">
    <div *ngFor="let layout of item.layouts">
      <div class="gallery-v2" [attr.data-qa]="'galleryGroup_' + layout.label">
        <div class="card">
          <div class="card-header">
            <div class="card-header-title">
              {{ layout.label }}
            </div>
          </div>
          <div class="card-body">
            <div class="gallery-v2__grouped">
              <div class="gallery-col-wrap" [appGalleryLayout]="grpLayoutItem[i]().length" #appGalleryLayout="layoutCols">
                <div class="gallery-col" *ngFor="let bounds of appGalleryLayout.bounds()">
                  <ng-container *ngFor="let galleryItem of slice(grpLayoutItem[i](), bounds.start, bounds.end); index as galleryIndex">
                    <ng-container *ngTemplateOutlet="galleryLayout; context: { galleryItem, galleryIndex }"></ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #standardGallery>
    <div class="gallery-col-wrap" [appGalleryLayout]="layoutItems().length" #appGalleryLayout="layoutCols">
      <div class="gallery-col" *ngFor="let bounds of appGalleryLayout.bounds()">
        <ng-container *ngFor="let galleryItem of slice(layoutItems(), bounds.start, bounds.end); index as galleryIndex">
          <ng-container *ngTemplateOutlet="galleryLayout; context: { galleryItem, galleryIndex }"></ng-container>
        </ng-container>
      </div>
    </div>
</ng-template>

<ng-template #galleryLayout let-galleryItem="galleryItem" let-cssClass="cssClass">
  <app-gallery-v2-layout
    [class]="cssClass"
    [readOnly]="isGalleryReadOnly()"
    [project]="project()"
    [data]="project().data"
    [galleryItem]="galleryItem"
    [useLayoutGroups]="useLayoutGroups()"
  >
  </app-gallery-v2-layout>
</ng-template>
