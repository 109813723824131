import { Component, computed, input } from '@angular/core';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIf } from '@angular/common';

@Component({
  selector: 'question-hint-popover',
  template: `
    <div
      class="survey-popover-trigger"
      *ngIf="question().hint && !question().permanentHint"
      [ngbPopover]="popoverContent"
      container="body"
      popoverClass="survey-popover"
    >
      <ng-template #popoverContent><div [innerHtml]="question().hint"></div></ng-template>
      <span class="material-icons material-icons-inline">{{ icon() }}</span>
    </div>
  `,
  standalone: true,
  imports: [NgIf, NgbPopoverModule]
})
export class QuestionHintPopoverComponent {
  question = input.required<QuestionBase<string>>();
  icon = computed(() => this.question().icon || 'info');
}
