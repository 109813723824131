<div class="card" [attr.data-qa]="layout.label">
  <ngx-loading [show]="loading"></ngx-loading>
  <div class="card-header">
    <div class="card-header-title">
      <div [attr.data-qa]="layout.key">{{ layout.label }}</div>
      <div *ngIf="!isReadOnly" class="edit-card text-right">
        <jui-icon
          readOnlyDisable
          (click)="setForm()"
          name="edit"
          size="xs"
          [attr.data-qa]="layout.key + '_editIcon'"
        ></jui-icon>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div *ngIf="!!layout.edit">
      <ng-content></ng-content>
      <div *ngFor="let item of layoutItems">
        <div *ngIf="item.editConfig.controlType === 'mapMarker'; else standardEditItems">
          <app-charge-point-location-map [item]="item"></app-charge-point-location-map>
        </div>
        <ng-template #standardEditItems>
          <app-edit-layout-questions
            *ngIf="item['editConfig'] && form"
            [question]="item['editConfig']"
            [form]="form"
            [project]="project"
          >
          </app-edit-layout-questions>
        </ng-template>
      </div>
    </div>
    <div *ngIf="!layout.edit">
      <div *ngFor="let item of layoutItems">
        <div *ngIf="item.editConfig.controlType === 'mapMarker'; else standardItems">
          <app-charge-point-location-map [item]="item"></app-charge-point-location-map>
        </div>
        <ng-template #standardItems>
          <div *ngIf="showCardItem(item)">
            <div class="row card-row">
              <div
                *ngIf="item.key"
                class="col-6 pr-1 card-detail-content strong"
                [attr.data-qa]="item.editConfig && item.editConfig.key ? item.editConfig.key : item.key"
              >
                {{ getLabel(item.key) }}
              </div>
              <div
                *ngIf="item.content && htmlInputSupportedCardKeys.includes(layout.key)"
                class="col-6 card-detail-content"
                [innerHtml]="item.content"
                [attr.data-qa]="
                  item.editConfig && item.editConfig.key ? item.editConfig.key + '_value' : item.key + '_value'
                "
              ></div>
              <div
                *ngIf="item.content && !htmlInputSupportedCardKeys.includes(layout.key)"
                class="col-6 card-detail-content"
                [innerText]="item.content"
                [attr.data-qa]="
                  item.editConfig && item.editConfig.key ? item.editConfig.key + '_value' : item.key + '_value'
                "
              ></div>
              <div class="col-6" *ngIf="isStaticImage(item)">
                <crds-http-image [src]="item.src" [alt]="item.key" imageClass="img-static-small"></crds-http-image>
              </div>
              <div *ngIf="isSingleImage(item)">
                <crds-http-image
                  [src]="item.src"
                  [alt]="item.key"
                  [downloadUrl]="getOriginalUrl(item.src)"
                  imageClass="card-img-bottom img-thumbnail-small img-single"
                  style="cursor: pointer"
                  (clicked)="showGalleryForImage(item, layout)"
                ></crds-http-image>
              </div>
              <div *ngIf="isImageArray(item)" class="img-array">
                <div *ngFor="let src of item.src; index as i" class="img-container">
                  <crds-http-image
                    *ngIf="src"
                    [src]="src"
                    [downloadUrl]="getOriginalUrl(src)"
                    [alt]="item.key"
                    imageClass="card-img-bottom img-thumbnail-small img-list"
                    style="cursor: pointer"
                    (clicked)="showGalleryForImage(item, layout, i)"
                    [qaHook]="item.key + '_projectImg'"
                  ></crds-http-image>
                </div>
              </div>
            </div>
            <div *ngIf="isVideoArray(item)" class="img-array">
              <div *ngFor="let src of item.src; index as i" class="img-container">
                <question-video-player
                  [params]="{
                    readOnly: true,
                    qaHook: item.key + '_projectVid',
                    type: 'video/mp4',
                    videoClass: 'img-static-small',
                    videoSource: getOriginalUrl(item.src)
                  }"
                ></question-video-player>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div *ngIf="!!layout.edit && showSave" class="card-footer list-layout__footer">
    <div class="list-layout__footer-item">
      <jui-button color="low" size="sm" (click)="cancelEdit()" [attr.data-qa]="'cancelButton'">
        {{ 'common.cancel' | transloco }}
      </jui-button>
    </div>
    <div class="list-layout__footer-item">
      <jui-button
        readOnlyDisable
        [readOnlyDisableIsDisabled]="form.invalid"
        size="sm"
        [color]="form.invalid ? 'low' : 'primary'"
        *ngIf="form"
        (click)="save(form)"
        [attr.data-qa]="'saveButton'"
      >
        <span slot="icon" class="material-icons">check</span>
        {{ okAction || ('common.save' | transloco) }}
      </jui-button>
    </div>
  </div>
  <ng-container *ngIf="staticMapUrl() as staticMap">
    <img class="card-img-bottom" [src]="staticMap" alt="" />
  </ng-container>
</div>
