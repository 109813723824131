<ng-container *ngIf="vm() as vm; else loading">
  <div *ngIf="vm?.form" [formGroup]="vm.form" class="video-capture-question">
    <question-video-player
      (recordingUpdated)="updateRecordedValue($event)"
      [params]="{
        qaHook: vm.key + 'VideoCapture',
        type: 'video/mp4',
        videoClass: 'img-static-small',
        videoSource: vm.videoSrc
      }"
    ></question-video-player>
  </div>
</ng-container>
<ng-template #loading>
  <jui-skeleton-loader
    color="info"
    inline="true"
    height="13px"
    width="100%"
    class="video-loading"
  ></jui-skeleton-loader>
</ng-template>
