import { DestroyRef, inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, firstValueFrom, of, Subject, Subscription, switchMap, timeout } from 'rxjs';
import { VideoPlayerDataModel, VideoPlayerParams } from './video-player.model';
import { VideoRecorderService } from './video-recorder.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class VideoPlayerRepository {
  private readonly httpClient = inject(HttpClient);
  private readonly videoRecorderService = inject(VideoRecorderService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly videoCache = new Map<string, string>();
  private readonly videoPlayerDataModel$ = new Subject<VideoPlayerDataModel>();

  private videoId: string;
  private videoPlayerSubscription$: Subscription;

  private updateCache(url: string, videoBlobBody: Blob) {
    url = url.replace(/&size=[a-z]$/, '');
    this.videoCache.set(url, URL.createObjectURL(videoBlobBody));
  }

  private updateModel(canDelete: boolean, video?: string | null, showVideo = false) {
    this.videoPlayerDataModel$.next({
      canDelete,
      canRecord: !video,
      id: this.videoId,
      videoSource: video,
      showVideo
    });
  }

  public async load(params: VideoPlayerParams & { id: string }, callback: (dataModel: VideoPlayerDataModel) => void) {
    if (this.videoPlayerSubscription$) {
      this.videoPlayerSubscription$.unsubscribe();
    }
    this.videoPlayerSubscription$ = this.videoPlayerDataModel$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(callback);
    await this.initialise(params);
  }

  public async initialise(params: VideoPlayerParams & { id: string }) {
    this.videoId = params?.id;
    const video =
      typeof params.videoSource === 'string' && params.videoSource.startsWith('data')
        ? params.videoSource
        : await this.getVideoByUrl(params.videoSource);
    this.videoRecorderService
      .init(this.videoId, video)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((video: string | null) => {
        this.updateModel(!!video, video, !!video);
      });
  }

  public async getVideoByUrl(urlOrUrlList?: string | string[]): Promise<string | undefined> {
    const url = typeof urlOrUrlList === 'string' ? urlOrUrlList : urlOrUrlList?.[0];
    if (!url?.startsWith('http')) {
      return;
    }

    if (this.videoCache.has(url)) {
      return this.videoCache.get(url);
    }

    return await firstValueFrom(
      this.httpClient
        .get(url, {
          headers: { skip: 'true' },
          observe: 'response',
          responseType: 'blob'
        })
        .pipe(
          timeout(30000),
          switchMap(response => {
            const videoBlobBody = response.body as Blob;
            this.updateCache(url, videoBlobBody);
            return of(URL.createObjectURL(videoBlobBody));
          }),
          catchError(e => {
            console.log(e);
            return of('');
          })
        )
    );
  }

  public async startRecording() {
    this.updateModel(false, null, true);
    await this.videoRecorderService.startRecording();
  }

  public pauseRecording() {
    this.videoRecorderService.pauseRecording();
  }

  public resumeRecording() {
    this.videoRecorderService.resumeRecording();
  }

  public stopRecording() {
    this.videoRecorderService.stopRecording();
  }

  public async removeRecording() {
    await this.videoRecorderService.removeRecording();
    this.updateModel(false, null, false);
  }
}
