import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { BasePathwayLayout } from './base-pathway.layout';
import { Project } from '../domain/project';
import { CardControlService } from '../card-control.service';
import { TranslocoModule } from '@ngneat/transloco';
import { EditLayoutQuestionsComponent } from './edit-layout-questions.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: `app-array-question`,
  template: `
    <ng-container *ngIf="initialised">
      <ng-container *ngFor="let questionGroup of asFormArray(question.key).controls; index as i">
        <ng-container *ngFor="let question of question.items">
          <app-edit-layout-questions
            [project]="project"
            [question]="question"
            [form]="asFormGroup(questionGroup)"
          ></app-edit-layout-questions>
        </ng-container>
        <button (click)="removeQuestion(i)" type="button" class="mb-2 mr-2 btn btn-sm btn-warning">
          {{ 'common.remove' | transloco }}
        </button>
      </ng-container>
    </ng-container>
    <button
      *ngIf="currentCount < question.limit"
      (click)="addQuestion()"
      type="button"
      class="mb-2 btn btn-sm btn-primary"
    >
      {{ 'common.add' | transloco }}
    </button>
  `,
  standalone: true,
  imports: [NgIf, NgFor, forwardRef(() => EditLayoutQuestionsComponent), TranslocoModule]
})
export class ArrayQuestionComponent extends BasePathwayLayout implements OnInit {
  @Input() form!: UntypedFormGroup;
  @Input() question!: {
    key: string;
    label: string;
    controlType: string;
    items: { key: string; label: string; controlType: string; items: unknown[] }[];
    limit: number;
  };
  @Input() project!: Project;
  initialised = false;

  constructor(public cardControlService: CardControlService) {
    super();
  }

  async ngOnInit() {
    const questionFormControls: UntypedFormGroup[] = [];
    for (const questionValue of this.project.data[this.question.key] || []) {
      questionFormControls.push(await this.cardControlService.toFormGroup(this.question.items, questionValue));
    }
    if (!questionFormControls.length) {
      await questionFormControls.push(await this.getAdditionalQuestion());
    }
    this.form.setControl(this.question.key, new UntypedFormArray(questionFormControls));
    this.form.updateValueAndValidity();
    this.initialised = true;
  }

  async addQuestion() {
    if (this.currentCount < this.question.limit) {
      (this.form.controls[this.question.key] as UntypedFormArray).push(await this.getAdditionalQuestion());
    }
    this.form.updateValueAndValidity();
  }

  async removeQuestion(index: number) {
    (this.form.controls[this.question.key] as UntypedFormArray).removeAt(index);
    this.form.updateValueAndValidity();
  }

  async getAdditionalQuestion() {
    return await this.cardControlService.toFormGroup(this.question.items, {});
  }

  get currentCount() {
    return this.asFormArray(this.question.key).length;
  }

  asFormGroup(form: AbstractControl): UntypedFormGroup {
    return form as UntypedFormGroup;
  }

  asFormArray(key: string): UntypedFormArray {
    return (this?.form?.get(key) || []) as UntypedFormArray;
  }
}
