import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentPackPreviewPresenter } from './document-pack-preview.presenter';
import { IPreviewVm } from '../document-pack.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocumentPackProgressComponent } from '../document-pack-progress/document-pack-progress.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf } from '@angular/common';
import { ReadOnlyDisableDirective } from '@jump-tech-frontend/angular-common';

@Component({
  selector: 'app-document-pack-preview',
  templateUrl: './document-pack-preview.component.html',
  styleUrls: ['./document-pack-preview.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    CoreComponentsAngularModule,
    NgbTooltipModule,
    DocumentPackProgressComponent,
    ReadOnlyDisableDirective
  ],
  providers: [DocumentPackPreviewPresenter]
})
export class DocumentPackPreviewComponent implements OnInit {
  @Input() docUrl: string;
  @Input() htmlFileInput: HTMLInputElement;
  @Output() fileChangeEvent = new EventEmitter<any>();

  safeUrl: SafeResourceUrl;
  vm: IPreviewVm = null;

  constructor(private presenter: DocumentPackPreviewPresenter, public sanitizer: DomSanitizer) {}

  set setDocUrl(url: string) {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.docUrl);
    this.htmlFileInput.addEventListener('change', ev => {
      this.fileChangeEvent.emit(ev);
    });
    this.presenter.load(generatedVm => {
      this.vm = generatedVm;
    });
  }

  closeModal(reason) {
    this.presenter.closeModal(reason);
  }

  actionClick(x) {
    this.presenter.actionClick(x);
  }

  notRequired(doc) {
    this.presenter.setNotRequired(doc);
  }

  upload() {
    this.htmlFileInput.value = null;
    this.htmlFileInput.click();
  }

  regenerate(doc) {
    this.presenter.regenerateDocument(doc);
  }
}
