import { Input, Component } from '@angular/core';
import { EnaFormVm } from '../ena.model';
import { AddressLookupV2Component, IAddressV2 } from '@jump-tech-frontend/address-lookup-v2';
import { AsyncPipe } from '@angular/common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { GalleryV2LayoutComponent } from '../../../core/cardLayouts/gallery-v2/gallery-v2-layout.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ApplicationFormControlWithHooks, ApplicationFormGroup, RegisteredDeviceData } from '../ena.model';
import { EnaApplicationPresenter } from '../ena-application.presenter';

@Component({
  selector: 'ena-form-control-list',
  standalone: true,
  styleUrls: ['./ena-form-control-list.component.scss'],
  templateUrl: 'ena-form-control-list.component.html',
  imports: [
    AddressLookupV2Component,
    AsyncPipe,
    CoreComponentsAngularModule,
    GalleryV2LayoutComponent,
    NgSelectModule,
    NgbDatepickerModule,
    ReactiveFormsModule
  ]
})
export class EnaFormControlListComponent {
  @Input() vm: EnaFormVm;
  @Input() control: ApplicationFormControlWithHooks;
  @Input() group: ApplicationFormGroup;
  @Input() itemIdx: number;
  @Input() fc: FormControl;

  constructor(private presenter: EnaApplicationPresenter) {}

  getErrors(group: ApplicationFormGroup, control: ApplicationFormControlWithHooks, i?: number) {
    return this.presenter.getErrors(group, control, i);
  }

  getDisabled(group: ApplicationFormGroup, control: ApplicationFormControlWithHooks, i?: number) {
    return this.presenter.getDisabled(group, control, i);
  }

  getValidationMessageForError(
    group: ApplicationFormGroup,
    control: ApplicationFormControlWithHooks,
    i?: number
  ): string {
    return this.presenter.getValidationMessageForError(group, control, i);
  }

  updateAddress($event: { address: IAddressV2; location: string }, key: string, formGroup: FormGroup): void {
    this.presenter.updateAddress($event, key, formGroup);
  }

  setSelectedDevice(selected: RegisteredDeviceData, itemIdx: number) {
    this.presenter.setSelectedDevice(selected, itemIdx);
  }
}
