import { Project } from '../../core/domain/project';

export function shouldShowPreAssignmentDetails(project: Partial<Project>): boolean {
  const preAssignmentTarget = project?.data?.preAssignment?.target;
  const preAssignmentTenant: string | undefined = preAssignmentTarget?.subTenant?.name ?? preAssignmentTarget?.delegate;

  const preferredAssignment = project?.data?.preferredAssignment;
  const preferredAssignmentTenant: string | undefined =
    preferredAssignment?.subTenant?.name ?? preferredAssignment?.delegate;

  const autoAssignmentDisabled = project.autoAssignment?.disabled === true;

  return Boolean(preferredAssignmentTenant ?? preAssignmentTenant) && !autoAssignmentDisabled;
}
