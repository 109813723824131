export interface RelayEnvironment {
  production: boolean;
  name: string;
  apiFormBase: string;
  relayFormUrl: string;
  relayCardUrl: string;
  apiProjectParamsUrl: string;
  apiRelayProgressUrl: string;
  apiOcrRekognitionUrl: string;
  apiGetListUrl: string;
  apiGetTranslationUrl: string;
  datadog?: DatadogConfiguration;
  staticDistribution: string;
}

export interface DatadogConfiguration {
  site: string;
  service: string;
  clientToken: string;
  clientTokenLogging: string;
  applicationId: string;
  sampleRate: number;
  replaySampleRate: number;
  actionNameAttribute: string;
  trackInteractions: boolean;
  trackFrustrations: boolean;
  forwardConsoleLogs: string[];
}
