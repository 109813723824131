import { ILinkedProjectsVm, ISummaryDataVm } from '../../linked-projects.model';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-linked-projects-summary',
  templateUrl: './linked-projects-summary.component.html',
  styleUrls: ['../../linked-projects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor, CoreComponentsAngularModule]
})
export class LinkedProjectsSummaryComponent {
  @Input() vm: ILinkedProjectsVm;
  @Output() linkClicked: EventEmitter<ISummaryDataVm> = new EventEmitter<ISummaryDataVm>();
  @Output() unlinkBtnClick: EventEmitter<{ groupId: string; projectId: string }> = new EventEmitter<{
    groupId: string;
    projectId: string;
  }>();
}
