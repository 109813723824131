import { debounceTime, fromEvent } from 'rxjs';
import { AfterContentInit, computed, Directive, ElementRef, input, Signal, signal } from '@angular/core';
import { untilDestroyed } from '@jump-tech-frontend/angular-common';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appGalleryLayout]',
  exportAs: 'layoutCols',
  standalone: true
})
export class GalleryLayoutDirective implements AfterContentInit {
  private readonly untilDestroyed = untilDestroyed();

  private readonly maxCols = signal(5);
  private readonly maxColWidth = signal(400);
  private readonly defaultWidth = signal(1000);
  private readonly currentWidth = signal(this.defaultWidth());
  private currentCols = computed(() => {
    return Math.min(Math.ceil(this.currentWidth() / this.maxColWidth()), this.maxCols());
  });

  public appGalleryLayout = input.required<number>();

  public bounds: Signal<{ start: number; end: number }[]> = computed(() => {
    const bounds = [];
    for (let i = 0; i < this.currentCols(); i++) {
      bounds.push({
        start: Math.ceil((this.appGalleryLayout() / this.currentCols()) * i),
        end: Math.ceil((this.appGalleryLayout() / this.currentCols()) * (i + 1))
      });
    }
    return bounds;
  });

  constructor(private el: ElementRef) {
    fromEvent(window, 'resize')
      .pipe(debounceTime(200), this.untilDestroyed())
      .subscribe(() => {
        this.currentWidth.set(this.el.nativeElement?.offsetWidth ?? this.defaultWidth());
      });
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.currentWidth.set(this.el.nativeElement?.offsetWidth ?? this.defaultWidth());
    }, 300);
  }
}
