
<div class="schedule-event-selected-job-details__tradesperson">
  <div class="schedule-event-selected-job-details__tradesperson-data">{{ vm.assignedToDisplayName }}</div>
  <div class="schedule-event-selected-job-details__tradesperson-data">
    <span *ngIf="vm.isLeadEngineer" class="schedule-event-selected-job-details__lead">{{ vm.i18nLead }}</span>
    <span class="schedule-event-selected-job-details__tradesperson-icon">
      <jui-icon
        name="person"
        [type]="vm.type"
        [color]="vm.color"
        size="xs"
        [attr.data-qa]="vm.qaSetLeadEngineerBtn"
        [title]="vm.titleText"
        (click)="setLeadEngineer.emit(vm)"
        ></jui-icon>
    </span>
    <span class="schedule-event-selected-job-details__tradesperson-icon">
      <jui-icon
        name="delete"
        color="high"
        size="xs"
        [title]="vm.i18nRemoveEngineerBtn"
        [attr.data-qa]="vm.qaRemoveEngineerBtn"
        (click)="removeEngineer.emit(vm)"
      ></jui-icon>
    </span>
  </div>
</div>
