@if (vm) {
<ng-container [formGroup]="group.form">
  @if (itemIdx !== undefined && itemIdx !== null) {
  <ng-container [formArrayName]="group.key">
    <ng-container [formGroupName]="itemIdx">
      <div class="ena-application__section">
        @for (control of section.controls; track control) {
        <div class="ena-application__control">
          <ena-form-control-list
            [vm]="vm"
            [group]="group"
            [control]="control"
            [itemIdx]="itemIdx"
            [fc]="getFormControl(group, control, itemIdx)"
          ></ena-form-control-list>
        </div>
        }
      </div>
    </ng-container>
  </ng-container>
  } @else {
  <div class="ena-application__section">
    @for (control of section.controls; track control) {
    <div class="ena-application__control">
      <ena-form-control-list
        [vm]="vm"
        [group]="group"
        [control]="control"
        [itemIdx]="itemIdx"
        [fc]="getFormControl(group, control)"
      ></ena-form-control-list>
    </div>
    }
  </div>
  }
</ng-container>
}
