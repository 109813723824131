import { InputSignal } from '@angular/core';

export type ComponentInputs<C> = {
  [K in keyof Pick<
    C,
    {
      [K in keyof C]: C[K] extends InputSignal<unknown> ? K : never;
    }[keyof C]
  >]: C[K] extends InputSignal<infer T> ? T : never;
};
