import { Injectable } from '@angular/core';
import { DropDownElement } from '../shared/form-components/multiple-selection-dropdown.component';
import { UserPreferencesService } from './user-preferences.service';
import { TranslocoService } from '@ngneat/transloco';
import { FeatureFlagService } from './feature-flag/feature-flag.service';
import { UserPreferenceType } from './domain/user';
import { RouteAccessService } from '../auth/services/route-access.service';

@Injectable()
export class DefaultRouteService {
  private defaultRouteOptions: DropDownElement[] = [
    {
      label: 'pageTitles.home',
      id: 'home'
    },
    {
      label: 'pageTitles.projects',
      id: 'projects'
    },
    {
      label: 'pageTitles.map',
      id: 'planning-map'
    }
  ];

  private defaultRoute: DropDownElement = null;

  private routesConfigured = false;

  constructor(
    private userPreferencesService: UserPreferencesService,
    private featureFlagService: FeatureFlagService,
    private routeAccessService: RouteAccessService,
    private transloco: TranslocoService
  ) {}

  get defaultRoutes() {
    return this.defaultRouteOptions;
  }

  get currentDefaultRoute() {
    return this.defaultRoute;
  }

  async init() {
    this.translateLabels();
    if (!this.routesConfigured) {
      await this.setupAvailableRoutes();
    }
  }

  private translateLabels() {
    this.defaultRouteOptions = this.defaultRouteOptions.map(opt => {
      return { ...opt, label: this.transloco.translate(opt.label) };
    });
  }

  private async setupAvailableRoutes() {
    const taskManagementEnabled = await this.featureFlagService.isFeatureEnabled('task-management');
    if (taskManagementEnabled) {
      this.defaultRouteOptions.push({ label: this.transloco.translate('pageTitles.taskManagement'), id: 'tasks' });
    }

    const scheduleV2Enabled = await this.featureFlagService.isFeatureEnabled('rolling-month-schedule-view');
    if (scheduleV2Enabled) {
      this.defaultRouteOptions.push({ label: this.transloco.translate('pageTitles.schedule'), id: 'schedule-v2' });
    } else {
      this.defaultRouteOptions.push({ label: this.transloco.translate('pageTitles.schedule'), id: 'schedule' });
    }

    // check access to all routes
    this.defaultRouteOptions = this.defaultRouteOptions.filter(route =>
      this.routeAccessService.isRouteAccessAllowed(route.id, false)
    );

    // sort alphabetically
    this.defaultRouteOptions.sort((a, b) => a.label.localeCompare(b.label));

    this.routesConfigured = true;

    this.defaultRoute = this.getCurrentDefaultRoute();
  }

  public getCurrentDefaultRoute() {
    const currentDefaultRoute = this.userPreferencesService.getUserPreference(UserPreferenceType.DefaultRoute);
    if (currentDefaultRoute) {
      const foundRoute = this.defaultRouteOptions.find(route => route.id === currentDefaultRoute);
      if (!foundRoute) {
        // Could not find saved route in route options - default to home page
        this.updateDefaultRoute('home');
        return this.defaultRouteOptions.find(route => route.id === 'home');
      }
      return foundRoute;
    } else {
      return this.defaultRouteOptions.find(route => route.id === 'home');
    }
  }

  public updateDefaultRoute(selectedRouteId: string) {
    this.userPreferencesService.setUserPreference(UserPreferenceType.DefaultRoute, selectedRouteId);
  }
}
