import { Info, Settings } from 'luxon';

export class JumptechDateSettings {
  public static get defaultLocale(): string {
    if (!Settings.defaultLocale) {
      JumptechDateSettings.defaultLocale = navigator.language || Intl.DateTimeFormat().resolvedOptions().locale;
    }
    return Settings.defaultLocale;
  }

  public static set defaultLocale(locale: string) {
    if (!locale) {
      return;
    }
    Settings.defaultLocale = locale.toLowerCase();
    console.log(`%cRegion settings - locale: ${Settings.defaultLocale}`, 'color: limegreen;');
  }

  public static get defaultTimeZone(): string {
    if (!Settings.defaultZone) {
      JumptechDateSettings.defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return Settings.defaultZone.name;
  }

  public static set defaultTimeZone(timeZone: string) {
    Settings.defaultZone = timeZone;
    console.log(`%cRegion settings - tz: ${Settings.defaultZone.name}`, 'color: limegreen;');
  }

  public static get TimeZones(): string[] {
    if (typeof Intl.supportedValuesOf === 'undefined') {
      return ['Europe/London'];
    }
    return Intl.supportedValuesOf('timeZone');
  }

  public static get WeekdaysShort(): string[] {
    return Info.weekdays('short', { locale: JumptechDateSettings.defaultLocale });
  }

  public static get WeekdaysLong(): string[] {
    return Info.weekdays('long', { locale: JumptechDateSettings.defaultLocale });
  }

  public static get MonthsShort(): string[] {
    return Info.months('short', { locale: JumptechDateSettings.defaultLocale });
  }

  public static get MonthsLong(): string[] {
    return Info.months('long', { locale: JumptechDateSettings.defaultLocale });
  }

  public static get WeekendDayNumbers(): number[] {
    return Info.getWeekendWeekdays({ locale: JumptechDateSettings.defaultLocale });
  }

  public static get StartOfWeekNumber(): number {
    return Info.getStartOfWeek({ locale: JumptechDateSettings.defaultLocale });
  }

  public static get BusinessDayNumbers(): number[] {
    const result: number[] = [];
    let day = JumptechDateSettings.StartOfWeekNumber;
    for (let i = 1; i <= 7; i++) {
      if (!JumptechDateSettings.WeekendDayNumbers.includes(day)) {
        result.push(day);
      }
      day++;
      if (day > 7) {
        day = 1;
      }
    }

    return result;
  }
}
