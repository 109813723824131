import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  template: `
    <div>{{ fileName }}</div>
    <div
      class="progress-bar bg-success"
      role="progressbar"
      [style.width]="progress + '%'"
      [style.height]="'2rem'"
      [attr.aria-valuenow]="roundedProgress + '%'"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <span class="pl-3">{{ roundedProgress + '%' }}</span>
    </div>
  `,
  styles: [
    `
      .progress-bar {
        text-align: left;
      }
    `
  ],
  standalone: true
})
export class ProgressBarComponent {
  @Input() fileName: string;
  @Input() progress: number;

  get roundedProgress() {
    return this.progress.toFixed(1);
  }
}
