import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { BehaviorSubject, Subscription } from 'rxjs';

import { Subject } from 'rxjs';
import { Role } from '../../admin/user-management/domain/types';
import { SupportUserService } from '../../support-login/support-user.service';
import { UserService } from './user.service';

import * as Analytics from '../../app.analytics';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { ProjectLayout } from '../../core/domain/project-layout';
import { ProjectAction } from '../../core/domain/project.action';
import { User } from '../../core/domain/user';
import { ProjectState } from '../../core/domain/project-configuration';

export interface HostConfiguration {
  cognitoClientId: string;
  authDomain: string;
  poolId: string;
  identityPoolId: string;
  scopes: string[];
  redirectSignIn: string;
  redirectLogOut: string;
  sso: { name: string; responseType: string; scopes: string[] };
  styles: string;
  font?: string;
  layouts: ProjectLayout[];
  actions: { projectType: string; actions: ProjectAction[] }[];
  states: { projectType: string; states: ProjectState[] }[];
  logo: string;
  roles: Role[];
  webSocketEndpoint?: string;
}

export interface PathwayConfiguration {
  host: string;
  configuration: HostConfiguration;
  tenant: string;
  i18n: {
    allowedLocales: string[];
    locale: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class PathwayConfigurationService {
  hostConfiguration: PathwayConfiguration;
  hostConfigurationSubject = new Subject<PathwayConfiguration>();
  hostConfigurationObservable = this.hostConfigurationSubject.asObservable();
  userSubscription: Subscription;

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private supportUserService: SupportUserService
  ) {}

  get tenant() {
    return this.hostConfiguration.tenant;
  }

  get roles() {
    return this.hostConfiguration.configuration.roles;
  }

  get websocket() {
    return this.hostConfiguration.configuration.webSocketEndpoint;
  }

  static persistConfigItem(tenant: string, itemName: string, value: string) {
    localStorage.setItem(`${tenant}_${environment.name}_${itemName}`, value);
  }

  async getConfig() {
    return await this.httpClient.get<PathwayConfiguration>(environment.apiConfigurationUrl, {}).toPromise();
  }

  async configure(): Promise<any> {
    try {
      const hostConfiguration = await this.getConfig();
      this.hostConfiguration = hostConfiguration;
      this.authenticationService.setAuthData(hostConfiguration);
      await this.authenticationService.configureAuth();

      if (this.userSubscription) {
        this.userSubscription.unsubscribe();
      }
      this.userSubscription = this.userService.userObservable.subscribe(async (user: User) => {
        if (!user) {
          this.supportUserService.flushCache(user);
        }
        const subTenantConfiguration = user?.accessInfo?.subTenants?.[0]?.configuration;
        Analytics.setTenant(hostConfiguration.tenant, subTenantConfiguration?.name);

        let logoUrl = hostConfiguration.configuration.logo;
        let stylesUrl = hostConfiguration.configuration.styles;

        if (subTenantConfiguration) {
          const userSubTenantPathwayConfiguration = subTenantConfiguration.pathwayConfiguration;
          for (const configKey of Object.keys(userSubTenantPathwayConfiguration || [])) {
            if (
              // eslint-disable-next-line no-prototype-builtins
              userSubTenantPathwayConfiguration.hasOwnProperty(configKey) &&
              userSubTenantPathwayConfiguration[configKey] &&
              userSubTenantPathwayConfiguration[configKey] !== ''
            ) {
              hostConfiguration.configuration[configKey] = userSubTenantPathwayConfiguration[configKey];
            }
          }

          logoUrl = hostConfiguration.configuration.logo;
          stylesUrl = hostConfiguration.configuration.styles;
        }

        PathwayConfigurationService.persistConfigItem(hostConfiguration.tenant, 'logoUrl', logoUrl);
        PathwayConfigurationService.persistConfigItem(hostConfiguration.tenant, 'stylesUrl', stylesUrl);

        this.supportUserService.updateStylesAndLogo(user, hostConfiguration);

        this.hostConfigurationSubject.next(this.hostConfiguration);
      });

      return this.hostConfiguration;
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.info('No configuration');
      alert(`Unable to get configuration, please contact ${environment.supportEmail}`);
      return null;
    }
  }
}
