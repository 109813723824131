import { DestroyRef, inject, Injectable } from '@angular/core';
import { UserService } from './user.service';
import {
  DataSharing,
  DelegationShare,
  ProjectAssignment,
  ProjectAssignmentTeam,
  ProjectFilter
} from '../../admin/user-management/domain/types';
import { TenantConfig, User } from '../../core/domain/user';
import { ApiService } from '../../core/api.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export enum PathwayFeature {
  // Projects
  ProjectCreation = 'ProjectCreation',
  ProjectDelegation = 'ProjectDelegation',

  // Dashboards
  MainDashboard = 'MainDashboard',
  DelegationDashboard = 'DelegationDashboard',

  // Features
  AllowArchive = 'AllowArchive',
  AllowUnarchive = 'AllowUnarchive',
  AttachmentsAccess = 'AttachmentsAccess',
  ForceUserCreatePhoneNumber = 'ForceUserCreatePhoneNumber',
  GalleryAccess = 'GalleryAccess',
  OwnProjectsOnly = 'OwnProjectsOnly',
  ShowExtendedOwnerDetails = 'ShowExtendedOwnerDetails',
  SummaryOwnerAccess = 'SummaryOwnerAccess',
  UserIsCustomer = 'UserIsCustomer',
  AllowViewOtherUserProjects = 'AllowViewOtherUserProjects',
  AllowViewOtherTeamProjects = 'AllowViewOtherTeamProjects',
  AllowInviteInstaller = 'AllowInviteInstaller',
  DelegationV2 = 'DelegationV2',
  DashboardFilterOwner = 'DashboardFilterOwner',
  ScheduleAccess = 'ScheduleAccess',
  ShowSetupAtom = 'ShowSetupAtom',
  AutoAssignment = 'AutoAssignment',
  StandardData = 'StandardData'
}

export enum PathwayDataSharing {
  ProjectDelegation = 'ProjectDelegation'
}

@Injectable({
  providedIn: 'root'
})
export class AccessService {
  private user: User;
  private _tenantConfig: any = null;
  private _dataSharing: DataSharing = null;
  private _projectAssignment: ProjectAssignment | null | undefined = undefined;
  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(private apiService: ApiService, private userService: UserService) {
    this.userService.userObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user: User) => {
      if (user !== null) {
        this.user = user;
      }
    });
  }

  async getTenantConfig(): Promise<TenantConfig> {
    if (!this._tenantConfig) {
      this._tenantConfig = await this.apiService.getTenantConfig().toPromise();
    }
    return this._tenantConfig;
  }

  async getDataSharing() {
    if (!this._dataSharing) {
      const results = await this.apiService.getTenantSettingsByTypeString('dataSharing', null, null, true).toPromise();
      if (results && results.length) {
        this._dataSharing = results[0];
      }
    }
    return this._dataSharing;
  }

  async getProjectAssignment(): Promise<ProjectAssignment | null> {
    if (this._projectAssignment === undefined) {
      const results = await this.apiService
        .getTenantSettingsByTypeString('projectAssignment', null, null, true)
        .toPromise();
      this._projectAssignment = results?.[0] ?? null;
    }
    return this._projectAssignment;
  }

  /** reset the dataSharing so we re-fetch it next time we need it */
  resetDataSharing() {
    this._dataSharing = null;
  }

  /**
   * @param featureToAccess
   * @param allowUndefined
   */
  isFeatureAccessAllowed(featureToAccess: PathwayFeature, allowUndefined = false): boolean {
    if (!this.user || !this.user.accessInfo) {
      return false;
    }
    return (this.user.accessInfo.features || []).filter(feature => feature.name === featureToAccess).length > 0;
  }

  async isDataSharingAccessAllowedForProject(tenant: string, projectType: string): Promise<boolean> {
    if (!this.user || !this.user.accessInfo) {
      return false;
    }

    const dataSharing = await this.getDataSharing();
    if (!dataSharing) {
      return false;
    }
    return (
      tenant === this.user.tenant &&
      (dataSharing.data || []).filter(d => d.tenant && d.projectType === projectType).length > 0
    );
  }

  async isTeamAssignmentAllowedForProject(tenant: string, projectType: string): Promise<boolean> {
    if (!this.user || !this.user.accessInfo) {
      return false;
    }

    const teamAssignment = await this.getProjectAssignment();
    if (!teamAssignment) {
      return false;
    }
    return (
      tenant === this.user.tenant && (teamAssignment.data || []).filter(d => d.projectType === projectType).length > 0
    );
  }

  /**
   * @param pathwayFeature
   * @param field
   * @param value
   */
  isProjectAccessAllowed(pathwayFeature: PathwayFeature, field: string, value: string): boolean {
    if (!this.user || !this.user.accessInfo) {
      return false;
    }

    if (!this.isFeatureAccessAllowed(pathwayFeature)) {
      return false;
    }

    return (
      this.user.accessInfo.features
        .filter(feature => feature.name === pathwayFeature)
        .filter(feature => {
          return this.filterByFilterType(field, value, feature.filter);
        }).length > 0
    );
  }

  filterByFilterType(field: string, value: any, filters: ProjectFilter[]): boolean {
    if (!filters || !filters.length) {
      // @TODO: Revise if we should default to letting through or not
      return true;
    }

    const filtersForField = filters.filter(filter => Object.keys(filter)[0] === field);

    if (!filtersForField.length) {
      // @TODO: Revise if we should default to letting through or not
      return true;
    } else {
      const filterForField = filtersForField.pop();
      const filterKeyField = Object.keys(filterForField)[0];
      const valueToCompare = filterForField[filterKeyField];
      switch (Object.keys(valueToCompare)[0]) {
        case '$eq':
          return valueToCompare.$eq === value || valueToCompare.$eq === '*';
        case '$ne':
          return valueToCompare.$ne !== value;
        case '$in':
          return (
            valueToCompare.$in
              .split(',')
              .map(valueItem => valueItem.trim())
              .indexOf(value) > -1
          );
        case '$nin':
          return (
            valueToCompare.$nin
              .split(',')
              .map(valueItem => valueItem.trim())
              .indexOf(value) === -1
          );
        case '$gt':
          return Number.parseFloat(valueToCompare.$gt) > Number.parseFloat(value);
        case '$gte':
          return Number.parseFloat(valueToCompare.$gte) >= Number.parseFloat(value);
        case '$lt':
          return Number.parseFloat(valueToCompare.$lt) < Number.parseFloat(value);
        case '$lte':
          return Number.parseFloat(valueToCompare.$lte) <= Number.parseFloat(value);
      }
    }
  }

  async getDelegatesForProjectType(projectType: string, creationOnly = false): Promise<DelegationShare[]> {
    const dataSharing = await this.getDataSharing();
    if (!dataSharing) {
      return [];
    }
    return dataSharing.data.filter(dataItem => {
      return (
        dataItem.tenant &&
        dataItem.projectType === projectType &&
        (!creationOnly || dataItem.showOnCreation == null || dataItem.showOnCreation === creationOnly)
      );
    });
  }

  async getTeamsForProjectType(projectType: string, creationOnly = false): Promise<ProjectAssignmentTeam[]> {
    const assignment = await this.getProjectAssignment();
    if (!assignment) {
      return [];
    }
    const assignments = assignment.data.filter(dataItem => {
      return (
        dataItem.projectType === projectType &&
        (!creationOnly || dataItem.showOnCreation == null || dataItem.showOnCreation === creationOnly)
      );
    });
    return assignments.reduce((a, assignment) => {
      const assignmentTeams = assignment.teams;

      for (const assignmentTeam of assignmentTeams) {
        if (a.some(x => x.id === assignmentTeam.id)) {
          continue;
        }
        a.push(assignmentTeam);
      }

      return a;
    }, []);
  }

  async getAssignmentLabel(): Promise<string> {
    const assignment = await this.getProjectAssignment();
    if (!assignment) {
      return 'Team';
    }
    return assignment.label;
  }
}
