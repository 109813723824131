<div *ngIf="initialised" class="card-wrapper">
  <div class="card__title-header">
    <div class="card__title">{{ card().label }}</div>
    <div class="card__step">{{ card().stepProgress }}</div>
  </div>
  <div class="card">
    <div class="card-body">
      <p class="card-text" [innerHTML]="card().description" [attr.data-qa]="'description'"></p>
      <ngu-carousel
        #carouselMain
        class="main"
        [inputs]="carouselMainTile"
        [dataSource]="card().action.mainSlides"
        (onMove)="updateMainSlide($event.currentSlide)"
      >
        <ngu-tile *nguCarouselDef="let item; let i = index">
          <img
            class="main-large"
            [src]="'https://s3.eu-west-2.amazonaws.com/' + card().action.assets + '/' + item.text + '.png'"
            alt=""
          />
          <div *ngIf="item.content" [innerHTML]="toSafeHtml(item.content)"></div>
        </ngu-tile>

        <i NguCarouselPrev class="main leftRs material-icons md-60" [attr.data-qa]="'carouselPrevious'">
          navigate_before
        </i>
        <i NguCarouselNext class="main rightRs material-icons md-60" [attr.data-qa]="'carouselNext'"> navigate_next </i>
      </ngu-carousel>
    </div>
  </div>
</div>
<div class="form-wrapper">
  <form *ngIf="form" [formGroup]="form">
    <crds-questions [form]="form" [card]="card()" [i18ns]="i18ns"></crds-questions>
  </form>
</div>
