import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ScheduleMoreInfoVm } from '../../schedule.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
  selector: 'schedule-event-more-details-add-engineer-form',
  templateUrl: 'schedule-event-more-details-add-engineer-form.component.html',
  styleUrls: ['../schedule-event-more-details/schedule-event-more-details.component.scss'],
  standalone: true,
  imports: [CoreComponentsAngularModule, NgSelectModule, ReactiveFormsModule, NgIf]
})
export class ScheduleEventMoreDetailsAddEngineerFormComponent {
  @ViewChild('addEngineerForm')
  public addEngineerFormEl: ElementRef;
  @Input() vm: ScheduleMoreInfoVm;
  @Output() onConfirmAddEngineer: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCancelAddEngineer: EventEmitter<void> = new EventEmitter<void>();
  @Output() onAddEngineer: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();

  constructor() {}
}
