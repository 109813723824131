import { ChangeDetectionStrategy, Input, Component } from '@angular/core';
import {
  ApplicationFormControlWithHooks,
  ApplicationFormGroup,
  ApplicationFormItemWithHooks,
  ApplicationFormSectionWithHooks,
  EnaFormVm,
  RegisteredDeviceData
} from '../ena.model';
import { AddressLookupV2Component, IAddressV2 } from '@jump-tech-frontend/address-lookup-v2';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { GalleryV2LayoutComponent } from '../../../core/cardLayouts/gallery-v2/gallery-v2-layout.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { EnaApplicationPresenter } from '../ena-application.presenter';
import { AsyncPipe, JsonPipe } from '@angular/common';
import {EnaFormControlListComponent} from "../ena-form-control-list/ena-form-control-list.component";

@Component({
  selector: 'ena-form-controls',
  standalone: true,
  templateUrl: 'ena-form-controls.component.html',
  styleUrls: ['./ena-form-controls.component.scss'],
  imports: [
    AddressLookupV2Component,
    CoreComponentsAngularModule,
    FormsModule,
    GalleryV2LayoutComponent,
    NgSelectModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    AsyncPipe,
    JsonPipe,
    EnaFormControlListComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnaFormControlsComponent {
  constructor(private presenter: EnaApplicationPresenter) {}

  @Input() vm: EnaFormVm;
  @Input() control: ApplicationFormControlWithHooks;
  @Input() section: ApplicationFormSectionWithHooks;
  @Input() group: ApplicationFormGroup;
  @Input() item?: ApplicationFormItemWithHooks;
  @Input() itemIdx?: number;

  getFormControl(group: ApplicationFormGroup, control: ApplicationFormControlWithHooks, idx?: number) {
    return this.presenter.getFormControl(group, control, idx);
  }

}
