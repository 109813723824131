import { Component, Input } from '@angular/core';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { I18nKeys } from '../../domain/i18n-keys';

@Component({
  selector: 'crds-form-error',
  templateUrl: './form-error.component.html',
  standalone: true
})
export class FormErrorComponent {
  @Input() message: string;
  @Input() question: QuestionBase<any>;
  @Input() i18ns: I18nKeys;
}
