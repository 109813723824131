import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnaApplicationPresenter } from '../ena-application.presenter';
import { AddressLookupV2Component } from '@jump-tech-frontend/address-lookup-v2';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { GalleryV2LayoutComponent } from '../../../core/cardLayouts/gallery-v2/gallery-v2-layout.component';
import { JumptechCardsLibModule } from '@jump-tech-frontend/cards';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationFormGroup, ApplicationFormItemWithHooks, EnaFormVm } from '../ena.model';
import { AbstractControl, UntypedFormArray } from '@angular/forms';
import { EnaApplicationAttachmentsComponent } from '../ena-application-attachments/ena-application-attachments.component';
import { EnaFormControlsComponent } from '../ena-form-controls/ena-form-controls.component';
@Component({
  selector: 'ena-application-groups',
  standalone: true,
  imports: [
    CommonModule,
    AddressLookupV2Component,
    CoreComponentsAngularModule,
    GalleryV2LayoutComponent,
    JumptechCardsLibModule,
    NgSelectModule,
    NgbDatepickerModule,
    EnaApplicationAttachmentsComponent,
    EnaFormControlsComponent
  ],
  templateUrl: './ena-application-groups.component.html',
  styleUrl: `./ena-application-groups.component.scss`
})
export class EnaApplicationGroupsComponent {
  @Input() vm: EnaFormVm;

  constructor(private presenter: EnaApplicationPresenter) {}

  duplicateDevice(i: number): void {
    this.presenter.duplicateDevice(i);
  }

  addDevice(): void {
    this.presenter.addDevice();
  }

  removeDevice(i: number): void {
    this.presenter.removeDevice(i);
  }

  toggleManualEntry(itemId: ApplicationFormItemWithHooks, manualEntry: boolean) {
    this.presenter.toggleDeviceManualEntry(itemId, manualEntry);
  }

  asFormArray(form: AbstractControl, field: string) {
    return form.get(field) as UntypedFormArray;
  }

  getState(group: ApplicationFormGroup) {
    return this.presenter.getState(group);
  }

  getStateCss(group: ApplicationFormGroup) {
    return this.presenter.getStateCss(group);
  }

  toggleGroup(group: ApplicationFormGroup): void {
    this.presenter.toggleGroup(group);
  }

  handleAttachmentChecked() {
    this.presenter.handleAttachmentChecked();
  }
}
