<div *ngIf="vm$ | async as vm">
  <div class="address-search" [class.address-search--desktop]="context === 'desktop'">
    <label class="address-search__label form-label" for="address-search">{{ vm.label }}</label>
    <div class="address-search__search">
      <input
        *ngIf="vm.searchTermControl"
        class="address-search__input"
        id="address-search"
        type="text"
        [formControl]="vm.searchTermControl"
        [attr.data-qa]="'address-search-input'"
        autocomplete="off"
      />
    </div>
  </div>

  <ng-container *ngIf="vm.searchResults$ | async as vmResults">
    <div class="address-results" *ngIf="vmResults.length">
      <div class="address-results__results" [class.address-results__results--desktop]="context === 'desktop'">
        <div
          class="address-results__result"
          *ngFor="let vmResult of vmResults"
          (click)="searchOrRetrieve(vmResult.id, vmResult.type)"
          [attr.data-qa]="'address-search-result'"
        >
          {{ vmResult.description }}
        </div>
      </div>
    </div>
  </ng-container>

  <div class="address-results" *ngIf="vm.noResult$ | async">
    <div class="address-results__results">
      <div class="address-results__result" [attr.data-qa]="'address-search-no-result'">{{ vm.noResultsFound }}</div>
    </div>
  </div>
</div>
