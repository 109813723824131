import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-project-structure-support-modal',
  templateUrl: 'project-structure-support-modal.component.html',
  styleUrls: ['../../../project-detail/project-detail.component.scss'],
  standalone: true
})
export class ProjectStructureSupportModalComponent {
  @Input() projectId: string;

  constructor(public activeModal: NgbActiveModal) {}
}
