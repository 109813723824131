import { Injectable } from '@angular/core';
import { ScheduleRepository } from '../../schedule.repository';
import { ScheduleJobsDisplayDm, ScheduleJobsDisplayVm } from '../../schedule.model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Draggable } from '@fullcalendar/interaction';
import { getStyleForStatus } from '../../utils/schedule.helper';
import { JobInformation } from '../../utils/schedule-types';
import { Job } from '../../../core/domain/job';

@Injectable({ providedIn: 'root' })
export class ScheduleJobsDisplayPresenter {
  public draggable: Draggable;
  viewModel: ScheduleJobsDisplayVm;

  constructor(private repository: ScheduleRepository, protected router: Router, private modalService: NgbModal) {}
  load(vmSubject$): void {
    this.repository.load((dm: ScheduleJobsDisplayDm): void => {
      const viewModel: ScheduleJobsDisplayVm = {
        showLoader: dm.loading,
        jobs: dm.jobs.map(job => ({
          id: job.id,
          projectId: job.projectId,
          jobTypeDetails: job.type,
          jobCustomerNameDetails: `${job.firstName} ${job.lastName}`,
          postcode: job.address?.postCode ?? null,
          jobInformation: { ...this.parseJobInfo(job) },
          qaJobEntry: 'scheduleJobsListDraggableJob'
        })),
        filtersVm: {
          filtersForm: dm.filtersForm,
          jobTypes: dm.jobTypes,
          jobTypeLabel: dm.i18ns.jobType,
          jobTypesDropdownPlaceholder: dm.i18ns.jobTypesDropdownPlaceholder,
          freeTextFilterPlaceholder: dm.i18ns.freeTextFilterPlaceholder,
          freeTextFilterLabel: dm.i18ns.freeTextFilterLabel,

          // hooks
          qaCloseFilterButton: 'scheduleJobsCloseFilterButton',
          qaFilterJobTypesDropdown: 'scheduleJobsFilterJobTypesDropdown',
          qaFilterFreeTextInput: 'scheduleJobsFilterFreeTextInput'
        },
        numberOfJobs: `(${dm.jobs.length})`,
        headerDetails: dm.i18ns.titleJob,
        headerPostcode: dm.i18ns.titlePostcode,
        buttonAssign: dm.i18ns.buttonAssign,
        buttonProject: dm.i18ns.buttonProject,
        headerJobsReadyToSchedule: dm.i18ns.titleJobsReadyToSchedule,

        // hooks
        qaButtonProject: 'scheduleJobsOpenProjectButton',
        qaButtonAssign: 'scheduleJobsAssignButton',
        qaButtonFilters: 'scheduleJobsFilterButton'
      };
      this.viewModel = viewModel;
      vmSubject$.next(viewModel);
      this.initDraggableItems();
    });
  }

  openProject(projectId: string): void {
    this.modalService.dismissAll();
    this.router.navigate([`/project/${projectId}`]).catch(console.log);
  }

  filterReadyToScheduleJobs(): void {
    this.repository.filterReadyToSchedule();
  }

  toggleDisplay(): void {
    setTimeout(() => this.initDraggableItems());
  }

  preventDraggableItems(): void {
    if (this.draggable) {
      this.draggable.destroy();
    }
  }

  initDraggableItems(): void {
    if (this.viewModel?.jobs?.length) {
      if (this.draggable) {
        this.draggable.destroy();
      }

      this.draggable = new Draggable(document.querySelector('.jobs-container'), {
        itemSelector: '.job-entry',
        eventData: eventEl => {
          const jobId = eventEl.querySelector('[data-jobid]').getAttribute('data-jobid');
          // look up job details
          const jobData = this.viewModel.jobs.find(job => job.id === jobId);

          return {
            eventType: 'Job',
            assignedToDisplayName: 'Unassigned', // todo i18n
            editable: true,
            durationEditable: true,
            resourceEditable: true,
            startEditable: true,
            jobDuration: jobData.jobInformation.defaultDuration,
            jobInformation: { ...jobData.jobInformation },
            title: jobData.jobInformation.type,
            status: 'PROVISIONALLY_SCHEDULED',
            classNames: ['jt-event'],
            dragged: true,
            backgroundColor: getStyleForStatus('PROVISIONALLY_SCHEDULED', true).backgroundColor
          };
        }
      });
    }
  }

  parseJobInfo(job: Job): JobInformation {
    return {
      id: job.id,
      projectId: job.projectId,
      customerFirstName: job.firstName,
      customerLastName: job.lastName,
      type: job.type,
      tenantType: job.tenantType,
      jobAssignments: [],
      address: {
        ...job.address
      },
      contactInfo: {
        email: job.email,
        telephoneNumber: job.phoneNumber
      },
      startDateTimestamp: '',
      endDateTimestamp: '',
      defaultDuration: job.defaultDuration ?? 2,
      isInitialSchedule: job.isInitialSchedule ?? true
    };
  }
}
