export interface IAddressV2 {
  line1: string;
  line2?: string;
  line3?: string;
  town?: string;
  county?: string;
  longitude?: string;
  latitude?: string;
  countryCode?: string;
  country?: string;
  postCode?: string;
}
