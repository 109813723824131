import { DelegationShareSubTenant } from './role';

export interface NewUser {
  tenant: string;
  label: string;
  email: string;
  phoneNumber?: string;
  isAdmin?: boolean;
  subTenant?: DelegationShareSubTenant;
}
