import { DropDownElement } from '../../shared/form-components/multiple-selection-dropdown.component';

export const VALIDATOR_MAX_LEN_DESCRIPTION = 300;
export const VALIDATOR_MAX_LEN_TITLE = 30;
export const ALL_DAY_TIME_SLOT: DropDownElement = { id: '0000', name: '00:00', label: '00:00' };

export const TIME_SLOTS: DropDownElement[] = [
  { id: '0000', name: '00:00', label: '00:00' },
  { id: '0030', name: '00:30', label: '00:30' },
  { id: '0100', name: '01:00', label: '01:00' },
  { id: '0130', name: '01:30', label: '01:30' },
  { id: '0200', name: '02:00', label: '02:00' },
  { id: '0230', name: '02:30', label: '02:30' },
  { id: '0300', name: '03:00', label: '03:00' },
  { id: '0330', name: '03:30', label: '03:30' },
  { id: '0400', name: '04:00', label: '04:00' },
  { id: '0430', name: '04:30', label: '04:30' },
  { id: '0500', name: '05:00', label: '05:00' },
  { id: '0530', name: '05:30', label: '05:30' },
  { id: '0600', name: '06:00', label: '06:00' },
  { id: '0630', name: '06:30', label: '06:30' },
  { id: '0700', name: '07:00', label: '07:00' },
  { id: '0730', name: '07:30', label: '07:30' },
  { id: '0800', name: '08:00', label: '08:00' },
  { id: '0830', name: '08:30', label: '08:30' },
  { id: '0900', name: '09:00', label: '09:00' },
  { id: '0930', name: '09:30', label: '09:30' },
  { id: '1000', name: '10:00', label: '10:00' },
  { id: '1030', name: '10:30', label: '10:30' },
  { id: '1100', name: '11:00', label: '11:00' },
  { id: '1130', name: '11:30', label: '11:30' },
  { id: '1200', name: '12:00', label: '12:00' },
  { id: '1230', name: '12:30', label: '12:30' },
  { id: '1300', name: '13:00', label: '13:00' },
  { id: '1330', name: '13:30', label: '13:30' },
  { id: '1400', name: '14:00', label: '14:00' },
  { id: '1430', name: '14:30', label: '14:30' },
  { id: '1500', name: '15:00', label: '15:00' },
  { id: '1530', name: '15:30', label: '15:30' },
  { id: '1600', name: '16:00', label: '16:00' },
  { id: '1630', name: '16:30', label: '16:30' },
  { id: '1700', name: '17:00', label: '17:00' },
  { id: '1730', name: '17:30', label: '17:30' },
  { id: '1800', name: '18:00', label: '18:00' },
  { id: '1830', name: '18:30', label: '18:30' },
  { id: '1900', name: '19:00', label: '19:00' },
  { id: '1930', name: '19:30', label: '19:30' },
  { id: '2000', name: '20:00', label: '20:00' },
  { id: '2030', name: '20:30', label: '20:30' },
  { id: '2100', name: '21:00', label: '21:00' },
  { id: '2130', name: '21:30', label: '21:30' },
  { id: '2200', name: '22:00', label: '22:00' },
  { id: '2230', name: '22:30', label: '22:30' },
  { id: '2300', name: '23:00', label: '23:00' },
  { id: '2330', name: '23:30', label: '23:30' }
];

export const SCHEDULE_VIEWS: DropDownElement[] = [
  { id: 'rollingMonth', name: 'Rolling Month', label: 'Rolling Month' },
  { id: 'month', name: 'Month', label: 'Month' },
  { id: 'week', name: 'Week', label: 'Week' },
  { id: 'day', name: 'Day', label: 'Day' }
];

export const JOB_STYLES_LEGACY = {
  CREATED: {
    icon: 'schedule',
    color: 'var(--jds-theme-schedule-dts-color-job-status-scheduled-text)',
    backgroundColor: 'var(--jds-theme-schedule-dts-color-job-status-scheduled-bg)',
    key: 'jobStatuses.scheduled'
  },
  RECEIVED: {
    icon: 'how_to_reg',
    color: 'var(--jds-theme-schedule-dts-color-job-status-received-text)',
    backgroundColor: 'var(--jds-theme-schedule-dts-color-job-status-received-bg)',
    key: 'jobStatuses.received'
  },
  DEPARTED: {
    icon: 'drive_eta',
    color: 'var(--jds-theme-schedule-dts-color-job-status-departed-text)',
    backgroundColor: 'var(--jds-theme-schedule-dts-color-job-status-departed-bg)',
    key: 'jobStatuses.departed'
  },
  ARRIVED: {
    icon: 'person_pin_circle',
    color: 'var(--jds-theme-schedule-dts-color-job-status-arrived-text)',
    backgroundColor: 'var(--jds-theme-schedule-dts-color-job-status-arrived-bg)',
    key: 'jobStatuses.arrived'
  },
  STARTED: {
    icon: 'timelapse',
    color: 'var(--jds-theme-schedule-dts-color-job-status-started-text)',
    backgroundColor: 'var(--jds-theme-schedule-dts-color-job-status-started-bg)',
    key: 'jobStatuses.started'
  },
  COMPLETED: {
    icon: 'check_circle',
    color: 'var(--jds-theme-schedule-dts-color-job-status-completed-text)',
    backgroundColor: 'var(--jds-theme-schedule-dts-color-job-status-completed-bg)',
    key: 'jobStatuses.completed'
  },
  'COMPLETE-PENDING': {
    icon: 'system_update',
    color: 'var(--jds-theme-schedule-dts-color-job-status-complete-pending-text)',
    backgroundColor: 'var(--jds-theme-schedule-dts-color-job-status-complete-pending-bg)',
    key: 'jobStatuses.completePending'
  },
  PAUSED: {
    icon: 'timer_off',
    color: 'var(--jds-theme-schedule-dts-color-job-status-paused-text)',
    backgroundColor: 'var(--jds-theme-schedule-dts-color-job-status-paused-bg)',
    key: 'jobStatuses.paused'
  },
  ABORTING: {
    icon: 'error_outline',
    color: 'var(--jds-theme-schedule-dts-color-job-status-abort-pending-text)',
    backgroundColor: 'var(--jds-theme-schedule-dts-color-job-status-abort-pending-bg)',
    key: 'jobStatuses.aborting'
  },
  'ABORT-PENDING': {
    icon: 'error_outline',
    color: 'var(--jds-theme-schedule-dts-color-job-status-abort-pending-text)',
    backgroundColor: 'var(--jds-theme-schedule-dts-color-job-status-abort-pending-bg)',
    key: 'jobStatuses.abortPending'
  },
  ABORTED: {
    icon: 'error_outline',
    color: 'var(--jds-theme-schedule-dts-color-job-status-aborted-text)',
    backgroundColor: 'var(--jds-theme-schedule-dts-color-job-status-aborted-bg)',
    key: 'jobStatuses.aborted'
  }
};

/**
 * t(jobStatuses.scheduled, jobStatuses.received, jobStatuses.departed, jobStatuses.arrived, jobStatuses.started, jobStatuses.completed, jobStatuses.completePending, jobStatuses.paused, jobStatuses.aborting, jobStatuses.abortPending, jobStatuses.aborted)
 */
export const JOB_STYLES = {
  PROVISIONALLY_SCHEDULED: {
    icon: 'schedule',
    color: 'black',
    key: 'schedule.jobLabels.provisionallyScheduled'
  },
  CREATED: {
    icon: 'schedule',
    color: 'black',
    key: 'jobStatuses.scheduled'
  },
  RECEIVED: {
    icon: 'how_to_reg',
    color: 'black',
    key: 'jobStatuses.received'
  },
  DEPARTED: {
    icon: 'drive_eta',
    color: 'black',
    key: 'jobStatuses.departed'
  },
  ARRIVED: {
    icon: 'person_pin_circle',
    color: 'black',
    key: 'jobStatuses.arrived'
  },
  STARTED: {
    icon: 'timelapse',
    color: 'black',
    key: 'jobStatuses.started'
  },
  COMPLETED: {
    icon: 'check_circle',
    color: 'black',
    key: 'jobStatuses.completed'
  },
  'COMPLETE-PENDING': {
    icon: 'system_update',
    color: 'black',
    key: 'jobStatuses.completePending'
  },
  PAUSED: {
    icon: 'timer_off',
    color: 'black',
    key: 'jobStatuses.paused'
  },
  ABORTING: {
    icon: 'error_outline',
    color: 'black',
    key: 'jobStatuses.aborting'
  },
  'ABORT-PENDING': {
    icon: 'error_outline',
    color: 'black',
    key: 'jobStatuses.abortPending'
  },
  ABORTED: {
    icon: 'error_outline',
    color: 'black',
    key: 'jobStatuses.aborted'
  }
};

export const JOB_STATUSES_V2 = {
  SCHEDULED: {
    key: 'schedule.jobLabels.scheduled',
    legacyStatuses: ['CREATED', 'SCHEDULED', 'RECEIVED']
  },
  IN_PROGRESS: {
    key: 'schedule.jobLabels.inProgress',
    legacyStatuses: ['DEPARTED', 'ARRIVED', 'STARTED', 'PAUSED']
  },
  DONE: {
    key: 'schedule.jobLabels.done',
    legacyStatuses: ['COMPLETED', 'COMPLETE-PENDING']
  },
  ABORTED: {
    key: 'schedule.jobLabels.aborted',
    legacyStatuses: ['ABORTING', 'ABORTED', 'ABORT-PENDING']
  },
  PROVISIONALLY_SCHEDULED: {
    key: 'schedule.jobLabels.provisionallyScheduled',
    legacyStatuses: ['PROVISIONALLY_SCHEDULED']
  }
};

export const JOB_STYLES_SELECTED_COLOR = {
  outline: '3px solid var(--jds-theme-schedule-v2-job-selected-color)'
};

export const JOB_STYLES_BACKGROUND_COLOR = {
  PROVISIONALLY_SCHEDULED: {
    backgroundColor: 'var(--jds-theme-schedule-v2-job-status-color-provisionally-scheduled-bg)',
    outline: '1px dashed var(--jds-theme-schedule-v2-job-status-color-provisionally-scheduled-border)'
  },
  SCHEDULED: {
    backgroundColor: 'var(--jds-theme-schedule-v2-job-status-color-scheduled-bg)',
    outline: '1px solid var(--jds-theme-schedule-v2-job-status-color-scheduled-border)'
  },
  IN_PROGRESS: {
    backgroundColor: 'var(--jds-theme-schedule-v2-job-status-color-in-progress-bg)',
    outline: '1px solid var(--jds-theme-schedule-v2-job-status-color-in-progress-border)'
  },
  DONE: {
    backgroundColor: 'var(--jds-theme-schedule-v2-job-status-color-done-bg)',
    outline: '1px solid var(--jds-theme-schedule-v2-job-status-color-done-border)'
  },
  ABORTED: {
    backgroundColor: 'var(--jds-theme-schedule-v2-job-status-color-aborted-bg)',
    outline: '1px solid var(--jds-theme-schedule-v2-job-status-color-aborted-border)'
  }
};

export const CURRENT_EVENT_BACKGROUND = `repeating-linear-gradient(
    45deg,
    var(--jds-theme-schedule-dts-color-bg-current-event) 0px,
    var(--jds-theme-schedule-dts-color-bg-current-event-darker) 0px,
    var(--jds-theme-schedule-dts-color-bg-current-event-darker) 25px,
    var(--jds-theme-schedule-dts-color-bg-current-event) 25px,
    var(--jds-theme-schedule-dts-color-bg-current-event) 50px,
    var(--jds-theme-schedule-dts-color-bg-current-event-darker) 50px,
    var(--jds-theme-schedule-dts-color-bg-current-event-darker) 75px,
    var(--jds-theme-schedule-dts-color-bg-current-event) 75px,
    var(--jds-theme-schedule-dts-color-bg-current-event) 100px
)`;

export const CURRENT_EVENT_TEXT = ``;

export const TEMP_EVENT_ID = 'temporaryCalendarEvent';

export const EVENT_TYPE_FIELD_LIST = [
  'name',
  'description',
  'colour',
  'textColour',
  'busy',
  'title',
  'sort',
  'id',
  'icon',
  'translation'
];

export const SELECTABLE_CAL_TYPES = ['resourceTimelineWeek', 'resourceTimelineDay'];
