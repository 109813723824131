import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/cards';
import { createRenderer } from '@jump-tech/lib-mustache-renderer';
import { get } from 'lodash';
import * as XRegExp from 'xregexp';
import { JumptechDateSettings } from '@jump-tech-frontend/domain';
import { environment } from '../../environments/environment';
import { UserService } from '../auth/services/user.service';
import { CustomLookupService } from './custom-lookup.service';

// These types aren't really for external use, just here to carry the valuePath
class AugmentedFormControl extends UntypedFormControl {
  public valuePath?: string;
}

interface AugmentedEditConfig<T> extends QuestionBase<T> {
  valuePath?: string;
}

@Injectable({ providedIn: 'root' })
export class CardControlService {
  constructor(private customLookupService: CustomLookupService, private userService: UserService) {}

  async processLookupField(item, field) {
    if (item[field] && typeof item[field] === 'string') {
      if (environment.name === 'docker') {
        item[field] = item[field].replace(/^core\/|projects\//, '');
      }
      item[field] = await this.customLookupService.customLookup(item[field]);
    }
  }

  private static shouldRenderField(item, key) {
    // Certain fields shouldn't be rendered under any circumstances
    const protectedFields = ['pattern'];
    return item[key] && typeof item[key] === 'string' && !protectedFields.includes(key) && item[key].includes('{');
  }

  renderFieldsFor(item, prepopulatedFields) {
    for (const key of Object.keys(item)) {
      if (CardControlService.shouldRenderField(item, key)) {
        const renderer = createRenderer({
          timezone: JumptechDateSettings.defaultTimeZone,
          locale: JumptechDateSettings.defaultLocale
        });

        item[key] = renderer.render('{{={ }=}}' + item[key], prepopulatedFields);
      }
    }
  }

  async toFormGroup(items: any[] = [], context = {}, existing: UntypedFormGroup = null) {
    const editGroup: { [k: string]: UntypedFormControl } = {};

    items = items.filter(item => item.editConfig || item.controlType).map(item => item.editConfig || item);

    for (const item of items) {
      if (!item.key) {
        continue; // Registration forms have section headers without keys
      }
      this.renderFieldsFor(item, context);
      for (const field of item?.datasource ? ['resources'] : ['options', 'resources']) {
        await this.processLookupField(item, field);
      }

      /*
       Angular Forms uses a dot notation to support nested forms, but we
       want to support dotted notation to reach into projects.
       So, copy the original key (with the dotted notation) to valuePath and
       then replace the dots with underscores to that we don't break the Form
      */
      if (!item.valuePath) {
        item.valuePath = item.key;
        item.key = item.key.replace(/\./g, '_');
      }

      if (!existing) {
        editGroup[item.key] = this.createFormControl(item);
      } else {
        const formControl = existing.get(item.key) as AugmentedFormControl;
        if (item.valuePath) {
          formControl.valuePath = item.valuePath;
        }
        editGroup[item.key] = formControl;
      }

      /**
       * Make sure that any persisted form that does not contain this questions is disabled
       * to avoid false invalid form states.
       */
      if (context && item.showIf && 'undefined' === typeof context[item.key]) {
        editGroup[item.key].disable();
      }
    }

    const group = existing ?? new UntypedFormGroup(editGroup);

    if (context) {
      for (const [key, control] of Object.entries(editGroup) as [string, AugmentedFormControl][]) {
        const value = control.valuePath ? get(context, control.valuePath) : context[key];
        if (value) {
          control.patchValue(value);
          control.updateValueAndValidity();
        }
      }
    }
    group.updateValueAndValidity();

    return group;
  }

  async patchValues(items: any[] = [], context = {}, group: UntypedFormGroup) {
    await this.toFormGroup(items, context, group);
  }

  createFormControl(editConfig: AugmentedEditConfig<any>) {
    const validators = [];

    if (editConfig.required) {
      validators.push(Validators.required);
    }

    if (editConfig.pattern) {
      validators.push(Validators.pattern(XRegExp(editConfig.pattern, 's')));
    }

    // Cards lib is explicitly looking for nulls
    if (editConfig.value === undefined) {
      editConfig.value = null;
    }

    const control = {
      value: editConfig.value,
      disabled: editConfig.disabled
    };
    const formControl = new UntypedFormControl(control, validators) as AugmentedFormControl;
    if (editConfig.valuePath) {
      formControl.valuePath = editConfig.valuePath;
    }
    return formControl;
  }

  private setDataPropertyPath(data, keys, value) {
    const key = keys[0];
    if (keys.length === 1) {
      data[key] = value;
      return;
    }
    if (!data[key]) {
      data[key] = {};
    }
    this.setDataPropertyPath(data[key], keys.slice(1), value);
  }

  /**
   * Rather than using form.value (which would use the control keys, which might
   * have underscores, instead go through the controls and look at the valuePath.
   * Use this to create a nested structure where each dotted path means going in
   * a level. e.g.
   * ```
   * {
   *   firstName: "Homer",
   *   lastName: "Simpson",
   *   address: {
   *     line1: "1 The rocks",
   *     town: "Stoneville"
   *   }
   * }
   * ```
   * Secondly, this method only includes the properties
   * @param form
   * @param includeAll if true, then include all properties, otherwise only
   * include those properties that have been changed by the user.
   * TODO Fix so that it uses the dirty flag, when that's reliable
   */
  dataFromForm(form: UntypedFormGroup, includeAll?: boolean) {
    const data = {};
    if (!form) {
      return data;
    }
    for (const [key, control] of Object.entries(form.controls) as [string, AugmentedFormControl][]) {
      const value = control.value;
      // TODO use just control.dirty once we've fixed the image controls to set the
      //  dirty flag properly
      if (!control.disabled && (control.dirty || value || includeAll)) {
        const keys = control.valuePath ? control.valuePath.split('.') : [key];
        this.setDataPropertyPath(data, keys, value);
      }
    }
    return data;
  }
}
