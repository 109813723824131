import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentToken } from '@jump-tech-frontend/app-config';
import { RelayEnvironment } from '@jump-tech-frontend/relay/shared';

export interface TextDetection {
  DetectedText?: string;
  Type?: string;
  Id?: number;
  ParentId?: number;
  Confidence?: number;
  Geometry?: {
    BoundingBox?: {
      Width: number;
      Height: number;
      Left: number;
      Top: number;
    };
    Polygon: {
      X: number;
      Y: number;
    }[];
  };
}

@Injectable({ providedIn: 'root' })
export class OcrService {
  constructor(@Inject(EnvironmentToken) private environment: RelayEnvironment, private httpClient: HttpClient) {}

  recognise(image: any) {
    return this.httpClient
      .post<TextDetection[]>(
        this.environment.apiOcrRekognitionUrl,
        { image: image },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .toPromise();
  }
}
