import { Injectable } from '@angular/core';
import { CardActionDataItem } from '@jump-tech-frontend/cards';
import { RelayCard } from '../../domain/relay-cards.model';
import { RelayRepository } from '../../relay.repository';
import { ActionsBarViewModel, ActionViewModel } from './actionsBarViewModel';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ActionPresenter {
  constructor(private relayRepository: RelayRepository) {}
  load(readOnly: boolean, demo: boolean, vm: BehaviorSubject<ActionsBarViewModel>) {
    const model: ActionsBarViewModel = {
      actions: [],
      hasReset: demo
    };
    this.relayRepository.getCurrentCard((relayCard: RelayCard): void => {
      if (!relayCard || !relayCard.card) {
        vm.next(model);
        return;
      }

      if (!relayCard.card.actions?.length) {
        model.actions = this.useDefaultActions(relayCard, readOnly);
        vm.next(model);
        return;
      }

      model.actions = this.buildActions(relayCard);
      vm.next(model);
    });
  }

  useDefaultActions(relayCard: RelayCard, readOnly: boolean): ActionViewModel[] {
    const actions: ActionViewModel[] = [];
    if (relayCard?.hasPrevious) {
      actions.push({
        label: relayCard?.previousLabel,
        action: 'previous',
        qaAttribute: 'backButton',
        buttonDisplay: 'ghost',
        buttonVariant: 'secondary'
      });
    }
    if (relayCard?.hasNext) {
      actions.push({
        label: relayCard?.nextLabel,
        action: 'next',
        qaAttribute: 'forwardButton',
        disabled: readOnly ? false : relayCard?.nextDisabled,
        buttonDisplay: 'contained',
        buttonVariant: 'primary'
      });
    }
    if (relayCard?.hasSubmit) {
      actions.push({
        label: relayCard?.submitLabel,
        action: 'submit',
        qaAttribute: 'forwardButton',
        disabled: relayCard?.submitDisabled,
        buttonDisplay: 'contained',
        buttonVariant: 'primary'
      });
    }
    return actions;
  }

  buildActions(relayCard: RelayCard): ActionViewModel[] {
    const actions: ActionViewModel[] = [];

    for (const actionDef of relayCard.card.actions) {
      actions.push({
        label: actionDef.label,
        action: actionDef.key,
        qaAttribute: `${actionDef.key}Button`,
        data: actionDef.data,
        disabled: this.isDisabled(actionDef.key, relayCard),
        buttonDisplay: 'ghost',
        buttonVariant: 'secondary'
      });
    }
    // set the last action as the primary action
    actions[actions.length - 1] = {
      ...actions[actions.length - 1],
      buttonDisplay: 'contained',
      buttonVariant: 'primary'
    };
    return actions;
  }

  isDisabled(action: string, relayCard: RelayCard): boolean {
    if (action === 'submit') {
      return relayCard?.submitDisabled;
    }
    if (action === 'next') {
      return relayCard?.nextDisabled;
    }
    return false;
  }

  async previous(readOnly = false, data?: CardActionDataItem[]): Promise<void> {
    await this.relayRepository.previousCard(readOnly, data);
  }

  async next(readOnly = false, data?: CardActionDataItem[]): Promise<void> {
    await this.relayRepository.nextCard(readOnly, data);
  }

  async submit(readOnly = false, data?: CardActionDataItem[]): Promise<void> {
    await this.relayRepository.submitCard(readOnly, data);
  }

  async reset(): Promise<void> {
    await this.relayRepository.reset();
  }
}
