import { DataSharing, ProjectFilter, SubTenant } from '../../admin/user-management/domain/types';

export interface User {
  tenant?: string;
  id: string; // Cognito sub
  userName: string;
  isAdmin: boolean;
  isSuperAdmin?: boolean;
  accountStatus?: string;
  team?: string;
  teams?: string[];
  subTenant?: { id: string; name: string };
  team_name?: string;
  roles: string[];
  email: string;
  phoneNumber: string;
  enabled: boolean;
  label: string;
  created_on: string;
  created_by: string;
  updated_on: string;
  updated_by: string;
  accessInfo: AccessInfo;
  security?: {
    mfa?: {
      sms: boolean;
    };
  };
  tags?: string[];

  defaultSignature?: string;
  configurableProperties?: {
    [key: string]: unknown;
  };
}

export interface TenantConfig {
  [key: string]: any;
  timezone?: string;
  locale?: string;
  cognitoCountryConfig?: {
    preferredCountries?: string[];
    allowedLocales?: string[];
    allowedCountries?: string[];
    defaultCountry?: string;
  };
}

export interface AccessInfo {
  teams: AccessInfoTeam[];
  roles: AccessInfoRole[];
  features: AccessInfoFeature[];
  subTenants?: SubTenant[];
  dataSharing: DataSharing[];
  configuration: TenantConfig;
}

export interface AccessInfoTeam {
  id: string;
  name: string;
}

export interface AccessInfoRole {
  id: string;
  name: string;
}

export interface AccessInfoFeature {
  id: string;
  name: string;
  type: string;
  path?: string;
  filter?: ProjectFilter[];
}

export enum AccessInfoFeatureType {
  PROJECTS = 'Projects',
  MENU = 'Menu'
}

export enum UserPreferenceType {
  NavShrink = 'USER_PREF--NAV-SHRINK',
  Theme = 'USER_PREF--THEME',
  DefaultRoute = 'USER_PREF--DEFAULT-ROUTE'
}
