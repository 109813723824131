import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { MyOrdersPresenter } from './my-orders.presenter';
import { BehaviorSubject } from 'rxjs';
import { CustomerShippingAddress, MyOrdersVm, ProductResource, TenantShippingAddress } from './my-orders.model';
import { MyOrdersHistoryComponent } from './order-history/order-history.component';
import { MyOrdersViewComponent } from './order-view/order-view.component';
import { MyOrdersDriverAddressComponent } from './components/driver-address/driver-address.component';
import { MyOrdersCustomAddressComponent } from './components/custom-address/custom-address.component';
import { MyOrdersAddressTypeSelectionComponent } from './components/address-type-selection/address-type-selection.component';
import { MyOrdersProductsSelectionComponent } from './components/products-selection/products-selection.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'pathway-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    CoreComponentsAngularModule,
    ReactiveFormsModule,
    MyOrdersProductsSelectionComponent,
    MyOrdersAddressTypeSelectionComponent,
    MyOrdersCustomAddressComponent,
    MyOrdersDriverAddressComponent,
    MyOrdersViewComponent,
    MyOrdersHistoryComponent,
    AsyncPipe
  ],
  providers: [MyOrdersPresenter]
})
export class MyOrdersComponent implements OnChanges, OnDestroy {
  @Input() products: ProductResource[];
  @Input() orders: any[];
  @Input() driverAddress?: CustomerShippingAddress;
  @Input() defaultAddress?: TenantShippingAddress;
  @Input() projectId: string;
  @Input() refresh: boolean;
  @Input() canOrderProducts: boolean;
  @Input() minShipmentDays: number;

  vm$: BehaviorSubject<MyOrdersVm> = new BehaviorSubject<MyOrdersVm>(null);

  constructor(private presenter: MyOrdersPresenter) {}

  ngOnChanges(): void {
    this.presenter.load({
      vmSubject$: this.vm$,
      products: this.products,
      orders: this.orders,
      driverAddress: this.driverAddress,
      defaultAddress: this.defaultAddress,
      projectId: this.projectId,
      refresh: this.refresh,
      minShipmentDays: this.minShipmentDays,
      canOrderProducts: this.canOrderProducts
    });
  }

  orderHardware(): void {
    this.presenter.submitOrder();
  }

  startNewOrder(): void {
    this.presenter.startNewOrder();
  }

  stopNewOrder(): void {
    this.presenter.stopNewOrder();
  }

  ngOnDestroy(): void {
    this.presenter.cleanUp();
  }
}
