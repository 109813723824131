import { Component, Input } from '@angular/core';
import { Project } from '../../core/domain/project';
import { LayoutUpdateService } from '../../core/layout-update.service';
import { DisplayTabLayout, ExpandedCardLayoutCollection, TabLayout } from '../project-detail.component';
import { GalleryLayoutComponent } from '../../core/cardLayouts/gallery-layout.component';
import { NgIf, NgFor, NgTemplateOutlet } from '@angular/common';
import { GalleryV2LayoutComponent } from '../../core/cardLayouts/gallery-v2/gallery-v2-layout.component';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, NgTemplateOutlet, GalleryLayoutComponent, GalleryV2LayoutComponent],
  providers: [LayoutUpdateService]
})
export class GalleryComponent {
  @Input() layoutCollection;
  @Input() project: Project;
  @Input() useLayoutGroups: boolean;
  @Input() isArchived: boolean;
  @Input() isTabReadOnly: boolean;

  constructor(private layoutUpdateService: LayoutUpdateService) {}

  isGalleryReadOnly(layout: TabLayout | DisplayTabLayout | ExpandedCardLayoutCollection): boolean {
    return this.isArchived || (this.isTabReadOnly && !this.layoutUpdateService.allowEdit(layout));
  }
}
