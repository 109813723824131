import { DestroyRef, inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { APP_HOME_PATH, LOGIN_PATH } from '../../app.routes';
import { environment } from '../../../environments/environment';
import { setReturnUrl } from '../../core/utils/location.helper';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class CanActivateLeadsRoute {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    setReturnUrl(state.url);

    return new Promise(resolve => {
      this.authenticationService.authObservable
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          catchError(() => of({} as any))
        )
        .subscribe(
          cognitoUser => {
            if (
              cognitoUser?.attributes['custom:platform'] === 'leads' &&
              cognitoUser?.attributes['custom:tier'] === 'free'
            ) {
              resolve(true);
            } else {
              if (this.authenticationService.isUserSignedIn) {
                if (!environment.production) {
                  console.log('User does not have access to:', state.url);
                }
                this.router.navigate([APP_HOME_PATH], { replaceUrl: true }).then();
              } else {
                this.router.navigate([LOGIN_PATH], { replaceUrl: true }).then();
              }
              resolve(false);
            }
          },
          () => {
            resolve(false);
          }
        );
    });
  }
}
