import { Address } from './address';

export type Gps = {
  lat: number;
  lng: number;
};

export type StatusLog = {
  status: string;
  timestamp: string;
  gps?: Gps;
};

export interface ElasticSearchJob {
  tenant: string;
  id: string;
  delegationChain?: string;
  projectId: string;
  type: string;
  scheduledDate: string;
  assignedTo: string;
  assignedToDisplayName?: string;
  status: string;
  statusLog: StatusLog[];
  firstName: string;
  lastName: string;
  phoneNumber: string;
  address: Address;
  gps: Gps;
  riskAssessor?: string;
  startDate?: string;
  endDate?: string;
  siteSupervisor?: string;
  natureOfWorks?: string;
  installChargePoint?: string;
  subTenantIds?: string[];
  subTenantNames?: string[];
}
