import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { ReadOnlyDisableDirective } from '@jump-tech-frontend/angular-common';

@Component({
  selector: 'app-document-pack-main-action',
  templateUrl: './document-pack-main-action.component.html',
  styleUrls: ['../../document-pack.component.scss'],
  standalone: true,
  imports: [CoreComponentsAngularModule, ReadOnlyDisableDirective]
})
export class DocumentPackMainActionComponent {
  @Input() inProgress = false;
  @Input() isDisabled = false;
  @Input() label = '';
  @Input() qaHook: string;
  @Output() actionClick: EventEmitter<void> = new EventEmitter();
}
