import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { ScheduleJobsDisplayFilterInfoVm } from '../../schedule.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'schedule-jobs-display-filter-popover',
  templateUrl: './schedule-jobs-display-filter-popover.component.html',
  styleUrls: ['./schedule-jobs-display-filter-popover.component.scss'],
  standalone: true,
  imports: [TranslocoModule, CommonModule, CoreComponentsAngularModule, NgSelectModule, ReactiveFormsModule]
})
export class ScheduleJobsDisplayFilterPopoverComponent {
  @Input() vm: ScheduleJobsDisplayFilterInfoVm;
  @Output() filterChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() popoverClosed: EventEmitter<void> = new EventEmitter<void>();
}
