// this file is so that consuming apps know what translation keys are required
export interface I18nKeys {
  add: string;
  address: {
    line1: string;
    line2: string;
    line3: string;
    line4: string;
    latitude: string;
    longitude: string;
    locality: string;
    town: string;
    county: string;
    country: string;
    postCode: string;
  };
  addressNotFound: string;
  back: string;
  clickHere: string;
  closeBtn: string;
  enterAddressManually: string;
  file: string;
  fileTooLarge: string;
  fileTypeNotAllowedError: string;
  isRequired: string;
  lookupAddress: string;
  next: string;
  no: string;
  noResultsFound: string;
  ok: string;
  cancel: string;
  retry: string;
  percentComplete: string;
  required: string;
  selectedAddress: string;
  start: string;
  submit: string;
  upload: string;
  uploadImage: string;
  uploadImageError: string;
  viewDocument: string;
  yes: string;
  yourLocation: string;
  video: {
    confirmDeletion: string;
    record: string;
  };
  email: {
    body: string;
    from: string;
    subject: string;
    to: string;
  };
}
