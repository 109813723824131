<ng-container *ngIf="this.vm.filtersForm">
  <div class="schedule-filters" [formGroup]="vm.filtersForm">
    <span>
      <jui-icon
        class="schedule-filters__close"
        name="clear"
        size="sm"
        color="high"
        [attr.data-qa]="vm.qaCloseFilterButton"
        (click)="popoverClosed.emit()"
        [disabled]="!vm.jobTypes.length"
      ></jui-icon>
    </span>

    <div class="schedule-filters__content">
      <div class="schedule-filters__item">
        <label class="schedule-filters__label">{{ vm.jobTypeLabel }}</label>
        <ng-select
          id="jobTypes"
          [items]="vm.jobTypes"
          placeholder="{{ vm.jobTypesDropdownPlaceholder }}"
          [multiple]="true"
          formControlName="selectedJobTypes"
          bindLabel="name"
          bindValue="id"
          (change)="filterChanged.emit()"
          [closeOnSelect]="false"
          [attr.data-qa]="vm.qaFilterJobTypesDropdown"
        >
        </ng-select>
      </div>
      <div class="schedule-filters__item">
        <jui-input
          id="filter"
          size="sm"
          icon="search"
          clearable="true"
          formControlName="freeText"
          (juiInput)="filterChanged.emit()"
          [label]="vm.freeTextFilterLabel"
          [placeholder]="vm.freeTextFilterPlaceholder"
          [attr.data-qa]="vm.qaFilterFreeTextInput"
        >
        </jui-input>
      </div>
    </div>
  </div>
</ng-container>
