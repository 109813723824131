import { HttpClient, HttpDownloadProgressEvent, HttpEventType, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  catchError,
  firstValueFrom,
  interval,
  map,
  Observable,
  of,
  share,
  startWith,
  switchMap,
  tap,
  throwError
} from 'rxjs';
import { environment } from '../../environments/environment';
import { TenantSettingType } from '../admin/user-management/domain/types';
import { SearchContext } from '../dashboards/search-context';
import { asJobArray } from './api.service.helpers';
import { CalendarEvent } from './domain/event';
import { Form } from './domain/form';
import { Job } from './domain/job';
import { Project } from './domain/project';
import { ProjectActionPayload } from './domain/project-action.payload';
import { NewProjectLayout } from './domain/project-configuration';
import { ProjectAction } from './domain/project.action';
import { Report } from './domain/report';
import { TenantConfig, User } from './domain/user';
import { ProjectOwnerService } from './project-owner.service';
import { ProjectDelegation } from './delegate/delegation.service';
import { v4 as uuidv4 } from 'uuid';
import { PresignedPost } from 'aws-sdk/clients/s3';
import { IDocumentPackDefinitionDetails } from '../project-detail/document-pack/document-pack.model';
import { AuditLog } from '@jump-tech-frontend/domain';
import { AuditLogAggregations } from '../project-detail/audit-logs/project-audit-logs.repository';
import { AggregatorReadOnlyService } from '../project-detail/aggregator-read-only.service';
import { readOnlyServiceToken } from '@jump-tech-frontend/angular-common';

export interface UserLookupResultItem {
  key: string;
  value: string;
  team_name?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private projectOwnerService: ProjectOwnerService,
    @Inject(readOnlyServiceToken) private readOnlyProjectService: AggregatorReadOnlyService
  ) {}

  private static handleError(error) {
    if (['production, staging'].find(x => environment.name != x)) {
      console.log(error);
    }

    // Dont expose any server error response to the client
    const errorMessage = ApiService._getErrorMessage(error);
    return throwError(() => new Error(errorMessage));
  }

  private static _getErrorMessage(error) {
    let uuid = uuidv4();
    if (Object.hasOwnProperty.call(error, 'requestId')) {
      uuid = error.requestId;
    }
    if (Object.hasOwnProperty.call(error, 'status')) {
      switch (error.status) {
        case 400:
          return `Bad Request.\nIf you believe this is not the case please contact support.\nref: ${uuid}`;
        case 401:
          return `You are not authenticated.\nIf you believe this is not the case please contact support.\nref: ${uuid}`;
        case 403:
          return `You are not authorised to perform this action.\nIf you believe this is not the case please contact support.\nref: ${uuid}`;
        case 405:
          return `Unsupported action.\nPlease contact support.\nref: ${uuid}`;
        case 408:
          return `Request Timed Out.\nref: ${uuid}`;
        default:
          return `Something went wrong.\nPlease contact support.\nref: ${uuid}`;
      }
    }
  }

  addProject(projectData: any) {
    return this.httpClient
      .post(`${environment.apiProjectUrl}`, projectData, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((response: HttpResponse<string>) => JSON.parse(response.body)));
  }

  updateProject(project: Project, value: any, updateResource?: string, dataSource?: string): Observable<any> {
    let params = new HttpParams();
    if (updateResource && updateResource !== 'data' && updateResource !== dataSource) {
      params = params.append('updateResource', updateResource);
    } else if (dataSource && updateResource === dataSource) {
      params = params.append('updateResourceOverride', dataSource);
    }
    if (this.projectOwnerService.isPrimaryOwner(project.id)) {
      params = params.append('tenant', project.tenant);
    }
    const options = { headers: { 'Content-Type': 'application/json', 'x-jt-project-id': project.id }, params: params };
    return this.httpClient
      .post(`${environment.apiProjectUrl}/${project.id}`, value, options)
      .pipe(map((response: HttpResponse<string>) => JSON.parse(response.body)));
  }

  getProject(projectId: string) {
    return this.httpClient.get(`${environment.apiProjectUrl}/${projectId}`, {}).pipe(
      map((response: HttpResponse<string>) => JSON.parse(response.body)),
      tap((project: Project) => {
        this.readOnlyProjectService.next(project?.tenantType);
      }),
      catchError(ApiService.handleError)
    );
  }

  search(context: SearchContext) {
    return this.httpClient
      .get(context.getUrl(), {
        params: context.getSearchOptions()
      })
      .pipe(map((response: HttpResponse<string>) => JSON.parse(response.body)));
  }

  searchProjects(
    search: string,
    searchOptions?: { start?: string; sort?: string; dir?: string; max?: string },
    includedDelegates?: { key: string; value: string }[]
  ) {
    if (includedDelegates) {
      searchOptions['search'] = JSON.stringify(includedDelegates);
    }

    return this.httpClient
      .get(`${environment.apiProjectsSearchUrl}/${search}`, {
        params: searchOptions
      })
      .pipe(map((response: HttpResponse<string>) => JSON.parse(response.body)));
  }

  searchAuditLogs(
    search: string | { key: string; value: string }[],
    searchOptions?: { start?: string; sort?: string; dir?: string; max?: string }
  ): Observable<{ results: AuditLog[] }> {
    searchOptions['search'] = JSON.stringify(search);
    return this.httpClient
      .get(`${environment.apiAuditLogsSearchUrl}`, {
        params: searchOptions
      })
      .pipe(map((response: HttpResponse<string>) => JSON.parse(response.body)));
  }

  async searchAllAuditLogs(searchOptions?: {
    status?: string;
    sort?: string;
    dir?: string;
    max?: string;
  }): Promise<{ results: AuditLog[]; aggregations: AuditLogAggregations }> {
    return (await firstValueFrom(
      this.httpClient.get(`${environment.apiAuditLogsReadSearchUrl}`, {
        params: searchOptions
      })
    )) as { results: AuditLog[]; aggregations: AuditLogAggregations };
  }

  getUsers(paginationToken?: string, limit?: number) {
    let params: HttpParams = new HttpParams();

    if (paginationToken) {
      params = params.set('paginationToken', encodeURIComponent(paginationToken));
    }

    if (limit) {
      params = params.set('limit', encodeURIComponent(limit.toString(10)));
    }

    return this.httpClient
      .get(environment.apiUsersUrl, { params: params || {} })
      .pipe(map((response: HttpResponse<string>) => JSON.parse(response.body)));
  }

  addUser(user: User) {
    return this.httpClient
      .post(`${environment.apiUserUrl}`, user, {
        headers: {
          'Content-Type': 'application/json',
          skip: 'true'
        }
      })
      .pipe(
        map((response: HttpResponse<string>) => {
          const userId = JSON.parse(response.body);
          return userId;
        })
      );
  }

  updateUser(user: User) {
    return this.httpClient
      .put(`${environment.apiUserUrl}`, user, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((response: HttpResponse<string>) => response.body));
  }

  getUser(userName: string, throwIfNotFound = true) {
    const options = throwIfNotFound ? {} : { headers: { skip: 'true' } };
    return this.httpClient.get(`${environment.apiUserUrl}/${userName}`, options).pipe(
      map((response: HttpResponse<string>) => {
        return response.body ? JSON.parse(response.body) : null;
      })
    );
  }

  findUser(userName: string) {
    return this.getUser(userName, false);
  }

  changePassword(userName: string, verificationCode: string, newPassword: string) {
    return this.httpClient
      .post(
        `${environment.apiUserUrl}/${userName}/changePassword`,
        {
          verificationCode,
          newPassword
        },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .pipe(map((response: HttpResponse<string>) => response.body));
  }

  resetPassword(userName: string) {
    return this.httpClient
      .post(`${environment.apiUserUrl}/${userName}/resetPassword`, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((response: HttpResponse<string>) => response.body));
  }

  resendInvitation(userName: string) {
    return this.httpClient
      .post(`${environment.apiUserUrl}/${userName}/resendInvitation`, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(map((response: HttpResponse<string>) => response.body));
  }

  resendAtomConfigInfo(userName: string) {
    return this.httpClient.post(`${environment.apiUserUrl}/${userName}/resendAtomConfigInfo`, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  getDownloadUrl(projectId: any, downloadType: string) {
    const params: HttpParams = new HttpParams().set('type', downloadType);
    return this.httpClient
      .get(`${environment.apiProjectCore}/${projectId}/export`, {
        params: params
      })
      .pipe(map((response: HttpResponse<string>) => JSON.parse(response.body)));
  }

  getAttachmentOrUrl(url: string) {
    // todo stick this in the gateway
    return this.httpClient
      .get(url, {
        headers: { skip: 'true' },
        observe: 'response',
        responseType: 'arraybuffer'
      })
      .pipe(
        map(response => {
          const blob = new Blob([response.body], { type: response.headers.get('Content-Type') });
          return window.URL.createObjectURL(blob);
        }),
        catchError(errorResponse => {
          console.log('errorResponse', errorResponse);
          if (errorResponse.status === 413) {
            if (errorResponse.headers.has('location')) {
              return of(errorResponse.headers.get('location'));
            }
          }
          return ApiService.handleError(errorResponse);
        })
      );
  }

  projectAction(projectId: string, action: ProjectAction, data: any) {
    const projectActionPayload: ProjectActionPayload = {
      projectAction: action,
      data: data || undefined
    };
    return this.httpClient
      .post(`${environment.apiProjectsRoot}/${projectId}/action`, projectActionPayload, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(
        share(),
        map((response: HttpResponse<string>) => response.body)
      );
  }

  getProjectConfiguration(projectType: string) {
    return this.httpClient.get(`${environment.apiProjectConfigurationUrl}/${projectType}`).pipe(
      map((response: HttpResponse<string>) => {
        const projectConfiguration = JSON.parse(response.body);
        return {
          ...projectConfiguration.configuration,
          documentPackDefinition: projectConfiguration?.documentPackDefinition,
          projectMarket: projectConfiguration?.marketType,
          dno: projectConfiguration?.dno,
          hardwareOrderingMetaStatus: projectConfiguration.hardwareOrderingMetaStatus
        };
      })
    );
  }

  /**
   * Return the project configuration types. By default just returns an array of objects
   * with just `projectType` defined. You can also pass a specific projectionExpression
   * if you want more data
   * @param featureName
   * @param projectionExpression defaults to `projectType`
   */
  getProjectConfigurationTypes(
    featureName: string,
    projectionExpression = 'projectType'
  ): Promise<{ projectType: string }[]> {
    const params: HttpParams = new HttpParams()
      .set('projectionExpression', projectionExpression)
      .set('feature', featureName);
    return this.httpClient
      .get(`${environment.apiProjectConfigurationsUrl}`, {
        params: params
      })
      .pipe(map((response: HttpResponse<string>) => JSON.parse(response.body)))
      .toPromise();
  }

  getNewProjectLayoutByProjectType(projectType: string): Promise<NewProjectLayout> {
    const projectionExpression = 'configuration.newProjectLayout';
    const params: HttpParams = new HttpParams().set('projectionExpression', projectionExpression);
    return this.httpClient
      .get(`${environment.apiProjectConfigurationUrl}/${projectType}`, {
        params: params
      })
      .pipe(
        map((response: HttpResponse<string>) => {
          const body = JSON.parse(response.body);
          return body.configuration && body.configuration.newProjectLayout ? body.configuration.newProjectLayout : null;
        })
      )
      .toPromise();
  }

  getTaskWorkflow(taskWorkflowId: string, projectId: string) {
    const params = new HttpParams().set('projectId', projectId);
    return this.httpClient
      .get(`${environment.apiTaskWorkFlowUrl}/${taskWorkflowId}`, {
        params: params
      })
      .pipe(
        map((response: HttpResponse<string>) => JSON.parse(response.body)),
        catchError(() => of(null))
      );
  }

  listReports() {
    return this.httpClient
      .get(`${environment.apiReportsUrl}`, {})
      .pipe(map((response: HttpResponse<string>) => JSON.parse(response.body)));
  }

  runReport(report: Report, formGroup: UntypedFormGroup, subId: string) {
    return this.httpClient.post(`${environment.apiRunReportUrl}/${report.configuration.name}/${subId}`, {
      report: report,
      data: formGroup.value
    });
  }

  getReport(subId: string) {
    return this.httpClient.get(`${environment.apiGetReportResultUrl}/${subId}`).pipe(
      map((response: HttpResponse<string>) => {
        return response.body ? JSON.parse(response.body) : null;
      }),
      catchError(err => {
        if (err.status === 504) {
          // Ignore timeouts
          return new Observable(subscriber => subscriber.next(null));
        }
        return ApiService.handleError(err);
      })
    );
  }

  customLookup(urlPath: string) {
    return this.httpClient
      .get(`${environment.apiCustomRoot}/${urlPath}`, {})
      .pipe(
        map((response: HttpResponse<string>) => {
          return JSON.parse(response.body);
        })
      )
      .toPromise();
  }

  getTenantConfig(): Observable<TenantConfig> {
    return this.httpClient.get(`${environment.apiTenantConfigurationUrl}`).pipe(
      map((response: HttpResponse<string>) => {
        const tenantConfig = JSON.parse(response.body);
        return Object.assign(tenantConfig.configuration, { tenant: tenantConfig.tenant });
      })
    );
  }

  getTenantSettingsByType(type: TenantSettingType, paginationToken?: string, limit?: number) {
    return this.getTenantSettingsByTypeString(`${type}`, paginationToken, limit);
  }

  getTenantSettingsByTypeString(type: string, paginationToken?: string, limit?: number, beginsWith = false) {
    let params: HttpParams = new HttpParams();

    if (beginsWith) {
      params = params.append('beginsWith', encodeURIComponent(beginsWith.toString()));
    }

    if (paginationToken) {
      params = params.append('paginationToken', encodeURIComponent(paginationToken));
    }

    if (limit) {
      params = params.append('limit', encodeURIComponent(limit.toString(10)));
    }

    return this.httpClient
      .get(`${environment.apiTenantSettingsUrl}/${type}`, {
        params: params || {}
      })
      .pipe(map((response: HttpResponse<string>) => JSON.parse(response.body)));
  }

  getTenantSettingsListByType(type: TenantSettingType) {
    const path = type === TenantSettingType.USER ? 'users' : `${type}`;

    return this.httpClient
      .get(`${environment.apiTenantSettingsUrl}/list/${path}`, {})
      .pipe(map((response: HttpResponse<string>) => JSON.parse(response.body)));
  }

  getDelegationSubTenantSettingsList(tenant: string) {
    return this.httpClient.get(`${environment.apiTenantSettingsUrl}/subTenants/list?tenant=${tenant}`, {}).pipe(
      map((response: HttpResponse<string>) => {
        return JSON.parse(response.body || '[]');
      })
    );
  }

  getGlobalSettings() {
    return this.httpClient.get(`${environment.apiTenantSettingsUrl}/global/globalSettings`, {}).pipe(
      map((response: HttpResponse<string>) => {
        return JSON.parse(response.body || '[]');
      })
    );
  }

  updateTenantSettingsByType(type: TenantSettingType, payload: any) {
    if (type === TenantSettingType.USER) {
      return;
    }

    return this.httpClient
      .put(`${environment.apiTenantSettingsUrl}/update/${type}`, payload, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(
        map((response: HttpResponse<string>) => response.body),
        catchError(ApiService.handleError)
      );
  }

  bulkUpdateTenantSettingsByType(type: TenantSettingType, payload: any[]) {
    if (type === TenantSettingType.USER) {
      return;
    }

    return this.httpClient
      .put(`${environment.apiTenantSettingsUrl}/bulkUpdate/${type}`, payload, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(
        map((response: HttpResponse<string>) => response.body),
        catchError(ApiService.handleError)
      );
  }

  bulkUpdateUsers(users: User[]) {
    return this.httpClient
      .put(`${environment.apiBulkUserUpdateUrl}`, users, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((response: HttpResponse<string>) => response.body));
  }

  getGlobalSettingsByTypeString(type: string, paginationToken?: string, limit?: number, beginsWith = false) {
    let params: HttpParams = new HttpParams();

    if (beginsWith) {
      params = params.append('beginsWith', encodeURIComponent(beginsWith.toString()));
    }

    if (paginationToken) {
      params = params.append('paginationToken', encodeURIComponent(paginationToken));
    }

    if (limit) {
      params = params.append('limit', encodeURIComponent(limit.toString(10)));
    }

    return this.httpClient
      .get(`${environment.apiGlobalSettingsUrl}/${type}`, {
        params: params || {}
      })
      .pipe(map((response: HttpResponse<string>) => JSON.parse(response.body)));
  }

  bulkUpdateTenantSettingsByTypeString(type: string, payload: any[]) {
    let params: HttpParams = new HttpParams();
    params = params.append('invalidate', 'false');

    return this.httpClient
      .put(`${environment.apiTenantSettingsUrl}/bulkUpdate/${type}`, payload, {
        params: params || {},
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(
        map((response: HttpResponse<string>) => response.body),
        catchError(ApiService.handleError)
      );
  }

  bulkUpdateGlobalSettingsByTypeString(type: string, payload: any[]) {
    return this.httpClient
      .put(`${environment.apiGlobalSettingsUrl}/bulkUpdate/${type}`, payload, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(
        map((response: HttpResponse<string>) => response.body),
        catchError(ApiService.handleError)
      );
  }

  bulkDeleteTenantSettings(payload: any[]) {
    return this.httpClient
      .post(`${environment.apiTenantSettingsUrl}/bulkDelete`, payload, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(
        map((response: HttpResponse<string>) => response.body),
        catchError(ApiService.handleError)
      );
  }

  observeCalendarEvents(start: string, end: string, intervalInt = 120000): Observable<CalendarEvent[]> {
    return interval(intervalInt).pipe(
      startWith(0),
      switchMap(async () => await firstValueFrom(this.listCalendarEvents(start, end)))
    );
  }

  listCalendarEvents(start: string, end: string): Observable<CalendarEvent[]> {
    return this.httpClient
      .get(`${environment.apiCalendarEntriesUrl}`, {
        params: {
          start,
          end
        }
      })
      .pipe(map((response: HttpResponse<string>) => JSON.parse(response.body)));
  }

  createCalendarEvent(calendarEvent: CalendarEvent) {
    return this.httpClient
      .post(`${environment.apiCalendarEntriesUrl}`, calendarEvent, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(
        map((response: HttpResponse<string>) => response.body && JSON.parse(response.body)),
        catchError(ApiService.handleError)
      )
      .toPromise();
  }

  updateCalendarEvent(calendarEvent: CalendarEvent) {
    return this.httpClient
      .put(`${environment.apiCalendarEntriesUrl}/${encodeURIComponent(calendarEvent.id)}`, calendarEvent, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(
        map((response: HttpResponse<string>) => response.body && JSON.parse(response.body)),
        catchError(ApiService.handleError)
      )
      .toPromise();
  }

  deleteCalendarEvent(id: string) {
    return this.httpClient
      .delete(`${environment.apiCalendarEntriesUrl}/${encodeURIComponent(id)}`, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(
        map((response: HttpResponse<string>) => response.body && JSON.parse(response.body)),
        catchError(ApiService.handleError)
      )
      .toPromise();
  }

  listJobs(start: string, end: string) {
    return this.httpClient
      .get(`${environment.apiListJobsUrl}`, {
        params: {
          start,
          end
        }
      })
      .pipe(map((response: HttpResponse<string>) => asJobArray(response.body)));
  }

  observeJobs(start: string, end: string, intervalInt = 60000): Observable<Job[]> {
    return interval(intervalInt).pipe(
      startWith(0),
      switchMap(async () => await firstValueFrom(this.listJobs(start, end)))
    );
  }

  delegateProjects(payload: ProjectDelegation[]) {
    return this.httpClient
      .post(`${environment.apiProjectDelegationUrl}`, payload, { headers: { 'Content-Type': 'application/json' } })
      .pipe(map((response: HttpResponse<string>) => response.body));
  }

  async unDelegateProject(projectId: string, tenant: string) {
    return this.httpClient
      .post(
        `${environment.apiProjectUnDelegationUrl}`,
        { projectId, tenant },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      .pipe(
        map((response: HttpResponse<string>) => response.body && JSON.parse(response.body)),
        catchError(ApiService.handleError)
      )
      .toPromise();
  }

  getForm(form: string, token?: string): Promise<Form> {
    const params = { form };
    if (token) {
      // The token will only be passed if we're not logged in, otherwise we just use the normal
      // authentication
      params['suid'] = token;
    }
    return this.httpClient
      .get(`${environment.apiGetFormUrl}`, {
        params: new HttpParams({ fromObject: params })
      })
      .pipe(
        map((response: HttpResponse<string>) => response.body && JSON.parse(response.body)),
        catchError(ApiService.handleError)
      )
      .toPromise();
  }

  singleUseAction(token: string, payload: any) {
    const params = new HttpParams({ fromObject: { suid: token } });
    return this.httpClient
      .post(`${environment.apiSingleUseActionUrl}`, payload, {
        headers: { 'Content-Type': 'application/json' },
        params
      })
      .pipe(
        map((response: HttpResponse<string>) => response && response.body),
        catchError(ApiService.handleError)
      )
      .toPromise();
  }

  createSingleUseAction(payload: any) {
    return this.httpClient
      .post(`${environment.apiCreateSingleUseActionUrl}`, payload, { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        map((response: HttpResponse<string>) => response && response.body && JSON.parse(response.body)),
        catchError(ApiService.handleError)
      )
      .toPromise();
  }

  getList(datasource: any) {
    const url = new URL(`${datasource.url || environment.apiTenantSettingsListUrl}/${datasource.type}`);

    if (datasource.tenant) {
      url.searchParams.append('tenant', datasource.tenant);
    }

    if (datasource.fields && datasource.fields.length > 0) {
      url.searchParams.append('fields', datasource.fields.join(','));
    }

    if (datasource.filters) {
      url.searchParams.append('filters', encodeURIComponent(JSON.stringify(datasource.filters)));
    }

    if (datasource.projectionList) {
      url.searchParams.append('projectionList', datasource.projectionList);
    }

    const params: HttpParams = new HttpParams();
    return this.httpClient
      .get(url.toString(), { params: params })
      .pipe(
        map((response: HttpResponse<string>) => response.body && JSON.parse(response.body)),
        catchError(ApiService.handleError)
      )
      .toPromise();
  }

  getRelayConfigurations(type?: string, tenant?: string) {
    let params: HttpParams = new HttpParams();
    if (type) {
      params = params.set('type', type);
    }
    if (tenant) {
      params = params.set('tenant', tenant);
    }
    const options = { params };
    return this.httpClient
      .get(`${environment.apiRelayConfigurationsUrl}`, options)
      .pipe(
        map((response: HttpResponse<string>) => response.body && JSON.parse(response.body)),
        catchError(ApiService.handleError)
      )
      .toPromise();
  }

  getRelayConfiguration(id: string) {
    let params: HttpParams = new HttpParams();
    params = params.set('translate', 'true');
    const options = { params };
    return this.httpClient
      .get(`${environment.apiRelayConfigurationsUrl}/${id}`, options)
      .pipe(
        map((response: HttpResponse<string>) => response.body && JSON.parse(response.body)),
        catchError(ApiService.handleError)
      )
      .toPromise();
  }

  postToS3(presignedPost: PresignedPost, file: File) {
    const form = this.getFormDataFromPresignedPost(presignedPost, file);
    const options = {
      headers: new HttpHeaders({
        enctype: 'multipart/form-data',
        Accept: 'application/xml',
        skip: 'true'
      }),
      reportProgress: true,
      observe: 'events' as any,
      responseType: 'text' as any
    };

    return this.httpClient.post(`${presignedPost.url}`, form, options).pipe(
      switchMap((event: any) => {
        if (event?.type === HttpEventType.DownloadProgress) {
          return of(this.toPartialError(event));
        }
        return of(event);
      }),
      catchError(e => of(null))
    );
  }

  getFormDataFromPresignedPost(presignedPost: PresignedPost, file: File) {
    const form = new FormData();
    form.append('acl', 'private');
    Object.keys(presignedPost.fields).forEach(key => form.append(key, presignedPost.fields[key]));
    form.append('Content-Type', file.type);
    form.append('file', file);
    return form;
  }

  toPartialError(event: HttpDownloadProgressEvent) {
    if (/<Message>/.test(event?.partialText)) {
      event.partialText = event.partialText.match(/<Message>(.*)<\/Message>/)?.[1] || null;
    }
    return event;
  }

  getSignedPostUrl(key: string, bucket: string, fileType: string): Observable<PresignedPost> {
    return this.httpClient
      .get(environment.apiSignUrlForPostUrl, {
        params: {
          bucket: encodeURIComponent(bucket),
          key: encodeURIComponent(key),
          fileType: encodeURIComponent(fileType)
        }
      })
      .pipe(
        map((response: PresignedPost) => {
          console.log(response);
          return response;
        })
      );
  }

  getSignedAttachmentUrl(key: string, projectId: string): Observable<string> {
    return this.httpClient
      .get(environment.apiSignedAttachmentUrl, {
        params: {
          key: encodeURIComponent(key),
          projectId: encodeURIComponent(projectId)
        }
      })
      .pipe(
        map((response: string) => {
          return response;
        })
      );
  }

  getUserImageContent(url: string): Observable<string> {
    return this.httpClient
      .get(url, {
        headers: { skip: 'true' },
        observe: 'response',
        responseType: 'blob'
      })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          return URL.createObjectURL(response.body);
        })
      );
  }

  getDocumentPackDefinition(id: string, version: number): Promise<IDocumentPackDefinitionDetails> {
    return firstValueFrom(
      this.httpClient.get<IDocumentPackDefinitionDetails>(
        `${environment.apiDocumentPackDefinitionUrl}/${id}/${version}`,
        {
          headers: { skip: 'true' }
        }
      )
    );
  }

  getSignedMapsUrl(url: string): Observable<string> {
    return this.httpClient
      .get(environment.apiStaticMapSign, {
        params: {
          url: encodeURIComponent(url)
        }
      })
      .pipe(
        map((response: string) => {
          return response;
        })
      );
  }
}
