import { NgIf } from '@angular/common';
import { AfterViewInit, Component, computed, input, Input, signal, ViewChild } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { toObservable } from '@angular/core/rxjs-interop';
import * as JmesPath from 'jmespath';
import { untilDestroyed } from '@jump-tech-frontend/angular-common';
import { NguCarousel, NguCarouselModule } from '@ngu/carousel';
import { Observable } from 'rxjs';

import { getDataSourceData } from '../../core/list-data-source';
import { QuestionsComponent } from '../../core/questions.component';
import { BaseQuestionComponent } from '../../core/question.component';
import { Card } from '../../domain/card';
import { CarouselAction } from '../../domain/card-actions/carousel.action';
import { CarouselMultipleAction } from '../../domain/card-actions/carousel-multiple.action';
import { I18nKeys } from '../../domain/i18n-keys';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'crds-carousel-multiple-form',
  templateUrl: './carousel-multiple-form.component.html',
  standalone: true,
  imports: [NgIf, NguCarouselModule, ReactiveFormsModule, QuestionsComponent]
})
export class CarouselMultipleFormComponent extends BaseQuestionComponent implements AfterViewInit {
  @Input() override form: UntypedFormGroup;
  @Input() i18ns: I18nKeys;
  public card = input.required<Card<CarouselMultipleAction>>();

  @ViewChild('carouselMain') carouselMain: NguCarousel<unknown>;
  @ViewChild('carouselDynamic') carouselDynamic: NguCarousel<unknown>;

  private action: CarouselAction | undefined;
  private currentKey: string | null = null;
  private cardObservable: Observable<Card<CarouselMultipleAction>> = toObservable(this.card);
  private sourceData = getDataSourceData();
  private untilDestroyed = untilDestroyed();

  public readonly initialised = signal(false);
  public currentMainSlideNumber = signal<number>(0);
  public currentMainSlideName = computed(
    () => this.card().action?.mainSlides?.[this.currentMainSlideNumber()].text ?? ''
  );
  public currentDynamicSlides = computed(() => {
    return this.card().action?.dynamicSlides?.find(dS => dS.name === this.currentMainSlideName())?.slides ?? [];
  });
  public currentDynamicSlideNumber = signal<number>(0);
  public currentDynamicSlide = computed(() => {
    return this.currentDynamicSlides()[this.currentDynamicSlideNumber()] ?? null;
  });
  public currentDynamicSlideName = computed(() => {
    return this.currentDynamicSlide()?.text ?? '';
  });

  public carouselMainTile: unknown;
  public carouselDynamicTile: unknown;
  public dynamicSlides: { name: string; slides: { text: string }[] };

  ngAfterViewInit() {
    this.cardObservable.pipe(this.untilDestroyed()).subscribe(async card => {
      if (card.key !== this.currentKey) {
        this.currentKey = card.key;
        await this.initialise();
      }
    });
  }

  private setInitialSlidesState() {
    this.carouselMainTile = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      point: {
        visible: false
      },
      load: 5,
      touch: true,
      loop: false
    };
    this.carouselDynamicTile = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      point: {
        visible: true
      },
      load: 3,
      touch: true,
      loop: false
    };
    this.dynamicSlides = {
      name: '',
      slides: []
    };
  }

  private async initialise() {
    this.initialised.set(false);
    this.setInitialSlidesState();
    this.action = this.card().action;

    if (this.action?.datasource) {
      const defaultTransform =
        '[*].{ text: name, name: name, content: description, slides: children[*].{ text: name, name: name, content: description } }';
      this.mapData(
        await this.sourceData.getData(this.action.datasource),
        this.action.datasource?.transform ?? defaultTransform,
        this.card()
      );
    }

    this.setCurrentMainSlideNumber(this.getCurrentMainSlideFromForm());
    this.setDynamicSlides(this.currentMainSlideName());
    this.updateTargetField(this.getCurrentDynamicSlideFromForm());
    this.initialised.set(true);
    setTimeout(() => {
      if (this.carouselMain) {
        this.carouselMain.moveTo(this.getCurrentMainSlideFromForm(), false);
      }
      if (this.carouselDynamic) {
        this.carouselDynamic.moveTo(this.getCurrentDynamicSlideFromForm(), false);
      }
    }, 10);
  }

  mapData(result: unknown[], datasourceTransform: string, card: Card<CarouselMultipleAction>) {
    const slides: [] = JmesPath.search(result, datasourceTransform) || [];

    if (slides && slides.length && card?.action) {
      card.action.dynamicSlides = slides;
      card.action.mainSlides = slides;
    } else {
      if ((!card?.action?.dynamicSlides || !card?.action?.dynamicSlides.length) && card?.action) {
        card.action.dynamicSlides = [];
      }
      if ((!card?.action?.mainSlides || !card.action.mainSlides.length) && card?.action) {
        card.action.mainSlides = [];
      }
    }
  }

  setCurrentMainSlideNumber(slide: number) {
    this.currentMainSlideNumber.set(slide);
  }

  setCurrentDynamicSlideNumber(slide: number) {
    this.currentDynamicSlideNumber.set(slide);
  }

  setCurrentDynamicSlideName(name: string) {
    this.dynamicSlides.name = name;
  }

  setDynamicSlides(mainSlideName: string) {
    this.setCurrentDynamicSlideName(mainSlideName);
    const currentDynamicSlide = this.card().action?.dynamicSlides.filter(
      slide => slide.name === this.currentMainSlideName()
    )[0];
    this.dynamicSlides.slides = Object.assign([], currentDynamicSlide?.slides ?? []);
  }

  updateMainSlide(currentSlide: number) {
    if (currentSlide !== this.currentMainSlideNumber()) {
      this.carouselDynamic.reset();
      this.setCurrentMainSlideNumber(currentSlide);
      this.setCurrentDynamicSlideNumber(0);
      this.setDynamicSlides(this.currentMainSlideName());
      this.updateTargetField(0);
    }
  }

  updateTargetField(currentSlide: number) {
    this.setCurrentDynamicSlideNumber(currentSlide);
    setTimeout(() => {
      this.form
        .get(this.action?.targetField ?? '')
        ?.patchValue(this.currentMainSlideName() + ' - ' + this.currentDynamicSlideName());
    });
  }

  private getCurrentDynamicSlideFromForm() {
    const currentFormValue = this.form.get(this.action?.targetField ?? '')?.value ?? null;
    if (!currentFormValue) {
      return 0;
    }
    const currentDynamicSlideValue = currentFormValue.split(' - ')[1];
    const slideIndex = this.dynamicSlides.slides.findIndex(
      dynamicSlide => dynamicSlide?.text === currentDynamicSlideValue
    );
    return slideIndex > -1 ? slideIndex : 0;
  }

  private getCurrentMainSlideFromForm() {
    const currentFormValue = this.form.get(this.action?.targetField as string)?.value;
    if (!currentFormValue) {
      return 0;
    }
    const currentMainSlideValue = currentFormValue.split(' - ')[0];
    const slideIndex =
      this.card().action?.mainSlides?.findIndex(mainSlide => mainSlide.text === currentMainSlideValue) ?? -1;
    return slideIndex > -1 ? slideIndex : 0;
  }
}
