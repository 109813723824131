<div class="card-wrapper">
  <div class="card__title-header">
    <div class="card__title">{{ card.label }}</div>
    <div class="card__step">{{ card.stepProgress }}</div>
  </div>

  <div class="card__description">
    <div class="card-text" [innerHTML]="description" [attr.data-qa]="'description'"></div>

    <input id="ocr" type="file" style="display: none" (change)="setFromOcr($event)" accept="image/*" />

    <crds-image-asset [images]="images" [key]="'ocr'"></crds-image-asset>

    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" type="line-scale" [attr.data-qa]="'spinner'">
    </ngx-spinner>
  </div>
</div>
<div class="form-wrapper">
  <form [formGroup]="form">
    <crds-questions [form]="form" [card]="card" [i18ns]="i18ns"></crds-questions>
  </form>
  <question-form-error
    *ngIf="uploadError"
    [message]="uploadError"
    [i18ns]="i18ns"
    class="file-upload-error"
  ></question-form-error>
</div>
