import { DestroyRef, inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ProjectCardLayout } from './domain/project-card-layout';
import { Subject } from 'rxjs';
import { User } from './domain/user';
import { AccessInfoHelper } from '../shared/access-info.helper';
import { DisplayTabLayout, ExpandedCardLayoutCollection, TabLayout } from '../project-detail/project-detail.component';
import { UserService } from '../auth/services/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class LayoutUpdateService {
  static CANCEL = 'cancel';
  private destroyRef: DestroyRef = inject(DestroyRef);
  private layoutUpdatesSubject: Subject<string> = new Subject();

  public layoutUpdates: Observable<string> = this.layoutUpdatesSubject.asObservable();
  public user: User;

  constructor(userService: UserService) {
    userService.userObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
  }

  next(dataSource: string) {
    this.layoutUpdatesSubject.next(dataSource);
  }

  cancel() {
    this.layoutUpdatesSubject.next(LayoutUpdateService.CANCEL);
  }

  allowEdit(layout: ProjectCardLayout | TabLayout | DisplayTabLayout | ExpandedCardLayoutCollection) {
    if (!this.user || !this.user.accessInfo) {
      return false;
    }

    if (typeof layout.editFilter === 'boolean') {
      return layout.editFilter;
    }

    if (layout.editFilter === undefined || (Array.isArray(layout.editFilter) && layout.editFilter.length === 0)) {
      return true;
    }

    return AccessInfoHelper.filterListForUser(layout, this.user, 'editFilter');
  }
}
