import { Component, ElementRef, EventEmitter, Input, AfterViewInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { AuthStateEvent } from '../../../utils/auth-types';
import { TranslocoModule } from '@ngneat/transloco';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['../../login.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, CoreComponentsAngularModule, TranslocoModule]
})
export class SignInFormComponent implements AfterViewInit {
  @Input() signInForm: UntypedFormGroup;
  @Input() submitInProgress: boolean;

  @Output() signInEvent: EventEmitter<null> = new EventEmitter<null>();
  @Output() setViewEvent: EventEmitter<AuthStateEvent> = new EventEmitter<AuthStateEvent>();

  public showPassword = false;

  @ViewChild('usernameInput')
  public usernameInput: ElementRef;

  ngAfterViewInit() {
    this.focusForm();
  }

  focusForm() {
    setTimeout(() => {
      this.usernameInput.nativeElement.focus();
    });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  setView() {
    this.setViewEvent.emit(AuthStateEvent.StartForgotPassword);
  }

  signIn() {
    this.showPassword = false;
    this.signInEvent.emit();
  }
}
