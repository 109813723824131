import { Component, signal } from '@angular/core';
import { NgIf } from '@angular/common';
import { timer } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'spinner-component',
  templateUrl: 'spinner.component.html',
  styleUrls: ['spinner.component.scss'],
  standalone: true,
  imports: [NgIf]
})
export class SpinnerComponent {
  constructor() {
    timer(2000)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.show.set(false);
      });
  }

  public show = signal(true);
}
