<div *ngIf="vm$ | async as vm" class="sj-view">
  <span class="sj-view__header">
    {{ vm.titleJob }}
    <jui-icon
      class="sj-view__close-icon"
      name="clear"
      size="xs"
      color="high"
      (click)="closeCard()"
      [attr.data-qa]="vm.qaCloseBtn"
    ></jui-icon>
  </span>

  <div class="sj-view__job-details">
    <div class="sj-view__general-info">
      <div class="sj-view__section">
        <div class="sj-view__label">{{ vm.labelStartDate }}</div>
        <div>{{ vm.startDateDisplay }}</div>
      </div>
      <div class="sj-view__section sj-view__section--bordered">
        <div class="sj-view__label">{{ vm.labelStartTime }}</div>
        <div>{{ vm.startTimeDisplay }}</div>
      </div>
      <div class="sj-view__section">
        <div class="sj-view__label">{{ vm.labelName }}</div>
        <div>{{ vm.jobInformation.customerFirstName }} {{ vm.jobInformation.customerLastName }}</div>
      </div>
      <div class="sj-view__section">
        <div class="sj-view__label">{{ vm.labelContactInfo }}</div>
        <div>
          <div>{{ vm.jobInformation.contactInfo.email }}</div>
          <div>{{ vm.jobInformation.contactInfo.telephoneNumber }}</div>
        </div>
      </div>
      <div class="sj-view__section">
        <div class="sj-view__label">{{ vm.labelEndDate }}</div>
        <div>{{ vm.endDateDisplay }}</div>
      </div>
      <div class="sj-view__section sj-view__section--bordered">
        <div class="sj-view__label">{{ vm.labelEndTime }}</div>
        <div>{{ vm.endTimeDisplay }}</div>
      </div>
      <div class="sj-view__section">
        <div class="sj-view__label">{{ vm.labelAddress }}</div>
        <div>{{ vm.formattedAddress }}</div>
      </div>
      <div
        class="sj-view__section"
        [ngbTooltip]="vm.assignedEngineerTooltip"
        tooltipClass="jt-tooltip--secondary-darker"
        placement="left"
      >
        <div class="sj-view__label">
          {{ vm.titleAssignedTradesperson }}
        </div>
        <div class="sj-view__assignments">
          <div>
            {{ vm.assignedEngineerDisplay }}
          </div>
        </div>
      </div>
    </div>
    <div class="sj-view__actions">
      <jui-button
        class="sj-view__button"
        size="md"
        color="secondary"
        display="ghost"
        expand="true"
        [attr.data-qa]="vm.qaGoToProjectsBtn"
        (click)="openProject(vm)"
        >{{ vm.btnGoToProject }}</jui-button
      >
      <jui-button
        *ngIf="phase2Enabled && vm.event.event.durationEditable"
        class="sj-view__button"
        size="md"
        expand="true"
        [attr.data-qa]="vm.qaMoreDetailsBtn"
        (click)="openMoreDetails(vm)"
        >{{ vm.btnMoreDetails }}</jui-button
      >
    </div>
  </div>
</div>
