import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { QuestionHintComponent } from '@jump-tech-frontend/question-components';

@Component({
  selector: `crds-question-html`,
  template: `
    <div [innerHTML]="rawHtml"></div>
    <!-- QUESTION HINT -->
    <question-hint [question]="question"></question-hint>
  `,
  standalone: true,
  imports: [QuestionHintComponent]
})
export class HtmlQuestionComponent implements OnChanges {
  @Input() question: QuestionBase<string>;
  rawHtml;

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    this.rawHtml = this.question?.html;
  }
}
