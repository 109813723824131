import { TaskStatus } from './task-status';

export interface Task {
  id: string;
  task: string;
  status: TaskStatus;
  questions?: any[];
  tenant?: string;
  description?: string;
  taskOptions?: string[];
  due_by?: Date;
  assigned_to?: string;
  updated_on?: Date;
  updated_by?: string;
  created_on?: Date;
  created_by?: string;
}
