import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { PasswordStrengthCheckerComponent } from '../password-strength-checker/password-strength-checker.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['../../login.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, PasswordStrengthCheckerComponent, CoreComponentsAngularModule, TranslocoModule]
})
export class ChangePasswordFormComponent implements AfterViewInit {
  @Input() changePasswordForm: UntypedFormGroup;
  @Input() submitInProgress: boolean;

  @Output() changePasswordEvent: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild('oldPasswordInput')
  public oldPasswordInput: ElementRef;

  public showPassword = false;

  constructor() {}

  ngAfterViewInit() {
    this.focusForm();
  }

  focusForm() {
    setTimeout(() => {
      this.oldPasswordInput.nativeElement.focus();
    });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  changePassword() {
    this.showPassword = false;
    this.changePasswordEvent.emit();
  }
}
