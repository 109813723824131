<ng-container *ngIf="vm$ | async as vm">
  <div
    *ngIf="vm.form"
    [formGroup]="vm.form"
    class="phone-number-question"
  >
    <label *ngIf="vm.label" class="form-label phone-number-question__label" [attr.data-qa]="vm.key">
      <div>
        {{ vm.label }}
        <span class="phone-number-question__validation-message" *ngIf="vm.showValidationMessage">{{ vm.validationMessage }}</span>
      </div>
    </label>
    <ngx-intl-tel-input
      *ngIf="vm.allowedCountries"
      [id]="vm.key"
      [cssClass]="'form-control'"
      [onlyCountries]="vm.allowedCountries"
      [preferredCountries]="vm.preferredCountries"
      [enableAutoCountrySelect]="false"
      [enablePlaceholder]="false"
      [searchCountryFlag]="true"
      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
      [selectFirstCountry]="false"
      [selectedCountryISO]="vm.defaultCountry"
      [maxLength]="15"
      [phoneValidation]="true"
      [formControlName]="vm.key"
    />
  </div>
</ng-container>
