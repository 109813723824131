import { Component, Input } from '@angular/core';
import { MyOrdersVm } from '../../my-orders.model';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReactiveFormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
  selector: 'pathway-my-orders-custom-address',
  templateUrl: './custom-address.component.html',
  styleUrls: ['../../my-orders.component.scss'],
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, NgxIntlTelInputModule]
})
export class MyOrdersCustomAddressComponent {
  @Input() vm: MyOrdersVm;
}
