<div class="schedule-key-container" [attr.data-qa]="'scheduleKey'">
  <div class="schedule-key-icons">
    <span class="key-label">{{ 'schedule.key' | transloco }}</span>
  </div>
  <div class="schedule-key-jobs">
    <div class="job-key" *ngIf="provisionalSchedulingEnabled">
      <div class="job-colour-circle job-colour-circle--provisionally-scheduled"></div>
      <span class="job-key-text">{{ 'schedule.jobLabels.provisionallyScheduled' | transloco }}</span>
    </div>
    <div class="job-key">
      <div class="job-colour-circle job-colour-circle--scheduled"></div>
      <span class="job-key-text">{{ 'schedule.jobLabels.scheduled' | transloco }}</span>
    </div>
    <div class="job-key">
      <div class="job-colour-circle job-colour-circle--in-progress"></div>
      <span class="job-key-text">{{ 'schedule.jobLabels.inProgress' | transloco }}</span>
    </div>
    <div class="job-key">
      <div class="job-colour-circle job-colour-circle--done"></div>
      <span class="job-key-text">{{ 'schedule.jobLabels.done' | transloco }}</span>
    </div>
    <div class="job-key">
      <div class="job-colour-circle job-colour-circle--aborted"></div>
      <span class="job-key-text">{{ 'schedule.jobLabels.aborted' | transloco }}</span>
    </div>
  </div>
</div>
