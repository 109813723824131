import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CardControlService } from '../../../../core/card-control.service';
import { Project } from '../../../../core/domain/project';
import { ProjectAction } from '../../task.model';
import { TranslocoModule } from '@ngneat/transloco';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { ModalLayoutComponent } from '../../../../core/cardLayouts/modal-layout.component';
import { NgSwitch, NgSwitchCase, NgSwitchDefault, NgIf } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';

@Component({
  selector: 'action-modal-component',
  template: `
    <div class="modal-wrapper">
      <ngx-spinner
        bdColor="var(--jds-theme-spinner-bd-rgba)"
        size="large"
        type="line-scale"
        [attr.data-qa]="'statusTransitionSpinner'"
        name="statusTransitionModal"
      >
      </ngx-spinner>
      <div class="modal-header-wrapper">
        <div class="modal-header">
          <div>{{ action.label }}</div>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-banner">{{ action.confirmation.layout.label }}</div>
      </div>
      <div class="modal-body action-modal-body" [class.action-modal-body--full-screen]="this.fullScreen">
        <div [ngSwitch]="action.confirmation.layout.dataSource">
          <app-modal-layout
            *ngSwitchCase="'data'"
            [data]="projectAndJobData"
            [layout]="action.confirmation.layout"
            [mode]="action.confirmation.layout['mode'] || 'readonly'"
            [fullScreen]="fullScreen"
            (formUpdate)="setFormData($event)"
          ></app-modal-layout>
          <app-modal-layout
            *ngSwitchCase="'all'"
            [data]="projectAndResources"
            [layout]="action.confirmation.layout"
            [mode]="action.confirmation.layout['mode'] || 'readonly'"
            (formUpdate)="setFormData($event)"
          ></app-modal-layout>
          <div *ngSwitchDefault>
            <app-modal-layout
              *ngIf="resources[action.confirmation.layout['dataSource']]"
              [data]="mergedResources"
              [layout]="action.confirmation.layout"
              [mode]="action.confirmation.layout['mode'] || 'readonly'"
              (formUpdate)="setFormData($event)"
            >
            </app-modal-layout>
          </div>
        </div>
      </div>
      <jui-alert-block *ngIf="action.confirmation.alert" color="warning" [attr.data-qa]="'warning'">
        <span [innerHtml]="action.confirmation.alert"></span>
      </jui-alert-block>
      <div class="modal-footer" [attr.data-qa]="action.id" *ngIf="form && submitEnabled">
        <jui-button [disabled]="form.invalid" size="sm" [attr.data-qa]="'submitButton'" (click)="submit()">
          {{ action.confirmation.submitLabel || ('common.submit' | transloco) }}
        </jui-button>
      </div>
    </div>
  `,
  styleUrls: ['action-modal.component.scss'],
  standalone: true,
  imports: [
    NgxSpinnerModule,
    NgSwitch,
    NgSwitchCase,
    ModalLayoutComponent,
    NgSwitchDefault,
    NgIf,
    CoreComponentsAngularModule,
    TranslocoModule
  ]
})
export class ActionModalComponent implements OnInit {
  @Input() project: Project;
  @Input() action: ProjectAction;
  @Input() resources: any;
  @Input() activeTheme: string;
  @Input() submitEnabled: boolean;
  @Input() fullScreen: boolean;
  @Output() submitCompleteHook: EventEmitter<any> = new EventEmitter<any>();

  form: UntypedFormGroup;
  projectAndResourceData: any;

  constructor(public activeModal: NgbActiveModal, private cardControlService: CardControlService) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({});
  }

  get mergedResources() {
    return {
      ...this.resources[this.action.confirmation.layout['dataSource']],
      ...{ ...this.project, ...this.project.data }
    };
  }

  public get projectAndJobData() {
    const jobObject = {
      jobs: {}
    };
    for (let index = 0; index < this.project.jobs.length; index++) {
      jobObject.jobs[this.project.jobs[index].type] = this.project.jobs[index];
      jobObject.jobs[index] = this.project.jobs[index];
    }
    return { ...this.project, ...this.project.data, ...jobObject };
  }

  get projectAndResources() {
    if (!this.projectAndResourceData) {
      this.projectAndResourceData = {
        ...this.projectAndJobData,
        resources: {}
      };
      for (const resource of this.project.resources) {
        this.projectAndResourceData.resources[resource.type] = resource.summary;
      }
    }
    return this.projectAndResourceData;
  }

  setFormData(form: UntypedFormGroup) {
    this.form = form;
  }

  submit() {
    if (this.form && this.form.invalid) {
      this.validateAllFormFields(this.form);
      document.querySelector('.modal').scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }
    const data = this.cardControlService.dataFromForm(this.form);
    this.activeModal.close(data);
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
