import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Project } from '../../../../../core/domain/project';
import { IAssignmentVm } from '../../domain';
import { TranslocoService } from '@ngneat/transloco';
import { shouldShowPreAssignmentDetails } from '../../../../project-auto-assignment/project-auto-assignment.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-assignment-label',
  templateUrl: './assignment-label.component.html',
  styleUrls: ['./assignment-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf]
})
export class AssignmentLabelComponent implements OnChanges {
  vm: IAssignmentVm;
  _project: Partial<Project>;

  @Input()
  set project(project: Partial<Project>) {
    this._project = project;
  }

  constructor(private translocoService: TranslocoService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['project'] !== undefined) {
      const preAssignmentTarget = this._project?.data?.preAssignment?.target;
      const preAssignmentTenant: string | undefined =
        preAssignmentTarget?.subTenant?.name ?? preAssignmentTarget?.delegate;

      const preferredAssignment = this._project?.data?.preferredAssignment;
      const preferredAssignmentTenant: string | undefined =
        preferredAssignment?.subTenant?.name ?? preferredAssignment?.delegate;

      this.vm = {
        show: shouldShowPreAssignmentDetails(this._project),
        label: preferredAssignmentTenant
          ? this.translocoService.translate('autoAssignment.preferredAssignment')
          : this.translocoService.translate('autoAssignment.provisionallyAssigned'),
        tenant: preferredAssignmentTenant ?? preAssignmentTenant
      };
    }
  }
}
