import { Component, Input, OnChanges, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { I18nKeys } from '../../domain/i18n-keys';
import { BaseQuestionComponent } from '../question.component';
import { ScrollService } from '../scroll.service';
import { untilDestroyed } from '@jump-tech-frontend/angular-common';
import { QuestionHintComponent } from '@jump-tech-frontend/question-components';
import { QuestionLabelContentComponent } from '@jump-tech-frontend/question-components';
import { NgIf } from '@angular/common';

@Component({
  selector: `crds-question-toggle`,
  template: `
    <div *ngIf="show && form" [formGroup]="form" class="form-group form-inline">
      <label *ngIf="question.label" class="form-label" [attr.for]="question.key" [attr.data-qa]="question.key">
        <question-label-content [question]="question" [isInvalid]="isInvalid" [i18ns]="i18ns"></question-label-content>
      </label>
      <label class="switch">
        <input [id]="question.key" type="checkbox" [formControlName]="question.key" #toggleInput />
        <span class="slider round" [attr.data-qa]="question.key + '_click'"></span>
        <span class="toggleValueUserDisplay"></span>
      </label>
      <span [attr.data-qa]="question.key + '_value'">{{ form.get(question.key).value ? i18ns.yes : i18ns.no }}</span>

      <!-- QUESTION HINT -->
      <question-hint [question]="question"></question-hint>
    </div>
  `,
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, QuestionLabelContentComponent, QuestionHintComponent]
})
export class ToggleQuestionComponent extends BaseQuestionComponent implements OnChanges {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<any>;
  @Input() i18ns: I18nKeys;
  @ViewChild('toggleInput') input;
  type: string;

  private untilDestroyed = untilDestroyed();

  constructor(private renderer: Renderer2, private scrollService: ScrollService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    this.scrollService.scrollObservable.pipe(this.untilDestroyed()).subscribe(documentId => {
      if (documentId === this.question.key) {
        this.input.nativeElement.scrollIntoView();
      }
    });
  }

  get isInvalid() {
    return this.form?.get(this.question.key)?.touched && this.form?.get(this.question.key)?.invalid;
  }

  get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }
}
