<jui-icon
  class="schedule-more-details__close"
  name="close"
  size="xs"
  color="high"
  [title]="vm.i18nCloseBtn"
  [attr.data-qa]="vm.qaCloseBtn"
  (click)="onCloseMoreDetails.emit()"
></jui-icon>
<div class="schedule-more-details__header">
  <jui-icon class="schedule-more-details__drag" name="drag_handle" cursor="move" color="secondary"></jui-icon>

  <div class="schedule-more-details__header-item">
    <jui-button
      color="secondary"
      display="ghost"
      size="sm"
      (click)="onHandleGoToProject.emit()"
      [attr.data-qa]="vm.qaGoToProjectBtn"
      >{{ vm.i18nGoToProjectBtn }}</jui-button
    >
  </div>

  <div class="schedule-more-details__header-item">
    <jui-button
      size="sm"
      (click)="onHandleScheduleNow.emit()"
      [loading]="vm.scheduleInProgress"
      [disabled]="vm.isScheduleButtonDisabled"
      [attr.data-qa]="vm.qaScheduleNowBtn"
      >{{ vm.i18nScheduleNowBtn }}</jui-button
    >
  </div>
</div>
