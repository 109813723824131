<div class="order-view" *ngIf="vm$ | async as vm">
  <div class="order-view__order">
    <!-- Summary -->
    <pathway-orders-order-summary [vm]="vm"></pathway-orders-order-summary>
    <div class="order-view__title">{{ vm.labelFulfilment }}</div>
    <!-- Fulfilment parts -->
    <pathway-orders-order-fulfilment [vm]="vm"></pathway-orders-order-fulfilment>
    <!-- Awaiting Fulfilment -->
    <div *ngIf="!vm.hasFulfilmentParts">{{ vm.messageAwaitingFulfilment }}</div>

    <!-- Cancel action -->
    <div class="order-view__actions" *ngIf="vm.canCancel">
      <div class="order-view__action">
        <jui-button
          [attr.data-qa]="vm.qaCancelOrderButton"
          (click)="cancelOrder(vm)"
          [disabled]="vm.isCancelling"
          [loading]="vm.isCancelling"
          color="primary"
          size="sm"
          >{{ vm.buttonCancelOrder }}</jui-button
        >
      </div>
    </div>
  </div>
</div>
