import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { JsonPipe, NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { DropDownElement } from '../../../shared/form-components/multiple-selection-dropdown.component';
import { ScheduleMoreInfoVm } from '../../schedule.model';

@Component({
  selector: 'schedule-event-more-details-schedule-date-time-form',
  templateUrl: 'schedule-event-more-details-schedule-date-time-form.component.html',
  styleUrls: ['../schedule-event-more-details/schedule-event-more-details.component.scss'],
  standalone: true,
  imports: [
    CoreComponentsAngularModule,
    NgIf,
    NgSelectModule,
    NgbDatepickerModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    JsonPipe
  ]
})
export class ScheduleEventMoreDetailsScheduleDateTimeFormComponent {
  @Input() vm: ScheduleMoreInfoVm;
  @Input() timeSlots: DropDownElement[];
  @Output() onHandleDateTimeChange: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}
}
