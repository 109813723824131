<div class="order-history" *ngIf="vm$ | async as vm">
  <div class="order-history__title">{{ vm.titleHistory }} {{ vm.numberOfOrders }}</div>

  <table class="order-history__table">
    <thead class="order-history__table-head">
      <tr class="order-history__tr order-history__tr--head">
        <th class="order-history__th order-history__tr--marker">&nbsp;</th>
        <th class="order-history__th">{{ vm.thCreated }}</th>
        <th class="order-history__th order-history__th--wide">{{ vm.thItems }}</th>
        <th class="order-history__th order-history__th--wide">{{ vm.thShipping }}</th>
        <th class="order-history__th">{{ vm.thStatus }}</th>
      </tr>
    </thead>
    <tbody class="order-history__table-body">
      <tr
        [attr.data-qa]="row.qaViewOrderAction"
        class="order-history__tr"
        [class.order-history__tr--active]="row.isActive"
        *ngFor="let row of vm.orders"
        (click)="viewOrder(row)"
      >
        <td class="order-history__td order-history__td--marker">
          <jui-icon isButton="false" name="chevron_right" color="primary" *ngIf="row.isActive" size="sm"></jui-icon>
        </td>
        <td class="order-history__td" [attr.data-qa]="'order-history-created-date'">
          <span *ngIf="!row.isOptimistic; else cellLoader">{{ row.createdOnDisplay }}</span>
        </td>
        <td class="order-history__td" [attr.data-qa]="'order-history-items'">{{ row.itemNameList }}</td>
        <td class="order-history__td" [attr.data-qa]="'order-history-shipping-date'">
          {{ row.requestedShippingDate }}
        </td>
        <td class="order-history__td order-history__td--status" [attr.data-qa]="'order-history-status'">
          <span
            class="order-history__status-chip"
            [class.order-history__status-chip--progress]="row.inProgress"
            *ngIf="!row.isOptimistic; else cellLoader"
            >{{ row.orderStatusDisplay }}</span
          >
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #cellLoader><jui-skeleton-loader width="80px"></jui-skeleton-loader></ng-template>
