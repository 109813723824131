<div class="order-view__summary">
  <div class="order-view__title">
    {{ vm.labelSummary }}
    <span class="order-view__val--emphasis" [class.order-view__val--cancelled]="vm.isCancelled">
      <span *ngIf="vm.isOptimistic" class="order-view__title-loader"
        ><jui-skeleton-loader color="primary" width="180px" height="18px" inline="true"></jui-skeleton-loader
      ></span>
      <span *ngIf="!vm.isOptimistic">{{ vm.summaryStatus }}</span>
    </span>
  </div>
  <div class="order-view__data" [attr.data-qa]="'order-summary-address'">
    <div class="order-view__key" [attr.data-qa]="'order-summary-address-key'">{{ vm.labelShippingAddress }}</div>
    <div class="order-view__val" [attr.data-qa]="'order-summary-address-val'">{{ vm.summaryAddress }}</div>
  </div>
  <div class="order-view__data" [attr.data-qa]="'order-summary-date'">
    <div class="order-view__key" [attr.data-qa]="'order-summary-date-key'">{{ vm.labelPreferredShipmentDate }}</div>
    <div class="order-view__val" [attr.data-qa]="'order-summary-date-val'">{{ vm.summaryPreferredShipDate }}</div>
  </div>
  <div class="order-view__data" *ngIf="vm.showPackage" [attr.data-qa]="'order-summary-package'">
    <div class="order-view__key" [attr.data-qa]="'order-summary-package-key'">{{ vm.labelPackage }}</div>
    <div class="order-view__val order-view__val--package" [attr.data-qa]="'order-summary-package-val'">
      {{ vm.summaryDefaultPackage }} <span class="order-view__val--foc">{{ vm.labelFoC }}</span>
    </div>
  </div>
  <div class="order-view__data" [attr.data-qa]="'order-summary-hardware'">
    <div class="order-view__key" [attr.data-qa]="'order-summary-hardware-key'">{{ vm.labelHardware }}</div>
    <div class="order-view__val order-view__val--items" [attr.data-qa]="'order-summary-hardware-val'">
      {{ vm.summaryHardwareOrdered }}
    </div>
  </div>
</div>
