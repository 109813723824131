import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { NgxSpinnerService } from 'ngx-spinner';
import { ImageAsset, ImageAssetType } from '../../../domain/card';
import { ImageError, ImageManipulationService } from '../../image-manipulation.service';
import { BaseQuestionComponent } from '../../question.component';
import { NgIf } from '@angular/common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { I18nKeys } from '@jump-tech-frontend/app-config';
import { QuestionFormErrorComponent } from '@jump-tech-frontend/question-components';

@Component({
  selector: `crds-question-multi-image-upload-input`,
  template: `
    <!-- QUESTION INPUT -->
    <!-- TODO Remove text/plain from accept. It is a temporary workaround to show both camera and file picker in Chrome & Android 14 -->
    <input
      [id]="'image_' + question.key"
      [name]="'image_' + question.key"
      (change)="setFromImage($event)"
      class="imageCapture"
      style="display:none;"
      type="file"
      [accept]="accept + ',text/plain'"
    />
    <jui-button
      (click)="clickFile(question.key)"
      expand
      size="lg"
      #imageUploadButton
      [attr.data-qa]="question.key + '_imageUploadButton'"
    >
      {{ i18ns.uploadImage }}
      <span slot="icon" class="material-icons">{{ hasUploadedImages ? 'add_a_photo' : 'photo_camera' }}</span>
    </jui-button>
    <question-form-error
      *ngIf="uploadError"
      [message]="uploadError"
      [i18ns]="i18ns"
      class="file-upload-error"
    ></question-form-error>
  `,
  standalone: true,
  imports: [CoreComponentsAngularModule, NgIf, QuestionFormErrorComponent]
})
export class MultiImageUploadQuestionInputComponent extends BaseQuestionComponent implements OnChanges {
  @Input() files: any[];
  @Input() override question: QuestionBase<any>;
  @Input() i18ns: I18nKeys;
  @Input() clearInput: boolean;
  private _hasUploadedImages: boolean;
  @Input() set hasUploadedImages(value: boolean) {
    this._hasUploadedImages = value;
  }
  get hasUploadedImages(): boolean {
    return this._hasUploadedImages;
  }
  @ViewChild('imageUploadButton') input;
  @Output() imageUploaded: EventEmitter<string> = new EventEmitter<string>();

  readonly accept = 'image/*';
  uploadError: string;
  imageError: ImageError | null;
  type: string;
  images: ImageAsset[];
  imageAssetType = ImageAssetType;

  constructor(private spinnerService: NgxSpinnerService, private imageService: ImageManipulationService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (Object.hasOwnProperty.call(changes, propName)) {
        switch (propName) {
          case 'clearInput': {
            if (!changes['clearInput'].firstChange) {
              (document.getElementById(`image_${this.question.key}`) as HTMLInputElement).value = '';
            }
          }
        }
      }
    }
  }

  get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }

  clickFile(questionKey) {
    (document.getElementById(`image_${questionKey}`) as HTMLElement).click();
  }

  async setFromImage(event) {
    const file: File = event.target.files[0];
    if (!file) {
      return; // User cancelled
    }
    this.imageError = this.imageService.checkFileTypeAndSize(file, this.accept);
    if (this.imageError) {
      this.uploadError = `${this.imageError.fileName}: ${this.i18ns[this.imageError.type]}`;
      return;
    }

    await this.spinnerService.show();
    try {
      const resizedImage = await this.imageService.resizeImageFromFile(file);
      this.imageUploaded.emit(resizedImage);
    } catch (error) {
      // Swallowing error as likely comes from Cancelled upload
      console.log('Failed image upload', error);
      // Cannot use instanceof
      if (error.name === 'ImageUploadError') {
        this.uploadError = this.i18ns.uploadImageError;
      }
    } finally {
      await this.spinnerService.hide();
    }
  }
}
