export interface ElasticSearchResults {
  _scroll_id?: string;
  hits: ElasticSearchResult;
  aggregations?: any;
}

export interface ElasticSearchResult {
  total: any;
  hits: ElasticSearchHit[];
}

export interface ElasticSearchHit {
  _index: string;
  _type: string;
  _id: string;
  _score: number;
  _source: {
    doc: any;
  };
}
