import { IfDefinition, QuestionBase } from '@jump-tech-frontend/domain';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ConfirmModalConfig } from './confirm-modal.config';

export interface FileAsset {
  name: string;
  data: string | ArrayBuffer;
}

export enum ImageAssetType {
  S3 = 'S3',
  LOCAL = 'LOCAL',
  ICON = 'ICON',
  S3_FORM = 'S3_FORM'
}

export interface ImageAsset {
  type: ImageAssetType;
  name?: string;
  bucket?: string;
  size?: string;
  formPath?: string;
  key?: string;

  getSrc(): string;
}

class S3Asset implements ImageAsset {
  type: ImageAssetType;
  bucket: string;
  key: string;

  constructor(type: ImageAssetType, bucket: string, key: string) {
    this.type = type;
    this.bucket = bucket;
    this.key = key;
  }

  getSrc(): string {
    return `https://s3.eu-west-2.amazonaws.com/${this.bucket}/${this.key}`;
  }
}

class S3FromFormPathAsset implements ImageAsset {
  type: ImageAssetType;
  bucket: string;
  key: string;
  _src: string;
  form?: UntypedFormGroup;
  formPath: string;

  constructor(type: ImageAssetType, bucket: string, formPath: string, form?: UntypedFormGroup) {
    this.type = type;
    this.bucket = bucket;
    this.form = form;
    this.formPath = formPath;
    this._src = this.makeSrcPath();
  }

  makeSrcPath() {
    const formRouteParts = this.formPath.split('.');
    const key = this.form?.get(formRouteParts[0])?.get(formRouteParts[1])?.value;
    return `https://s3.eu-west-2.amazonaws.com/${this.bucket}/${key}.png`;
  }

  getSrc(): string {
    return this._src;
  }
}

class LocalAsset implements ImageAsset {
  type: ImageAssetType;
  name: string;

  constructor(type: ImageAssetType, name: string) {
    this.type = type;
    this.name = name;
  }

  getSrc(): string {
    return `/assets/${this.name}`;
  }
}

export class IconAsset implements ImageAsset {
  type: ImageAssetType;
  name: string;
  _size?: string;

  constructor(type: ImageAssetType, name: string, size?: string) {
    this.type = type;
    this.name = name;
    if (size) {
      this._size = size;
    }
  }

  getSrc(): string {
    return this.name;
  }

  get size() {
    return this._size || 'md-5r';
  }
}

@Injectable({ providedIn: 'root' })
export class ImageAssetFactory {
  create(imageSpec: any | any[], form?: UntypedFormGroup): Array<ImageAsset> {
    if (!imageSpec || (Array.isArray(imageSpec) && !imageSpec.length)) {
      return [];
    }

    const assets: ImageAsset[] = (Array.isArray(imageSpec) ? imageSpec : [imageSpec]).filter(asset =>
      [ImageAssetType.S3_FORM, ImageAssetType.S3, ImageAssetType.LOCAL, ImageAssetType.ICON].includes(asset.type)
    );

    return assets.map(asset => {
      switch (asset.type) {
        case ImageAssetType.S3_FORM:
          return new S3FromFormPathAsset(asset.type, asset.bucket ?? '', asset.formPath ?? '', form);
        case ImageAssetType.S3:
          return new S3Asset(asset.type, asset.bucket ?? '', asset.key ?? '');
        case ImageAssetType.LOCAL:
          return new LocalAsset(asset.type, asset.name ?? '');
        case ImageAssetType.ICON:
          return new IconAsset(asset.type, asset.name ?? '', asset.size);
      }
    });
  }
}

export interface CardAction {
  label: string;
  key: string;
  data?: CardActionDataItem[];
}

export interface CardActionDataItem {
  key: string;
  value: string;
}

export interface Card<T> {
  type: string;
  key: string;
  label: string;
  description: string;
  order?: number;
  description2?: string;
  description3?: string;
  quoteSentBy?: string;
  quoteTotalTitle?: string;
  quoteTotalValue?: string;
  quoteTotalDescription?: string;
  questions?: QuestionBase<any>[];
  section?: number;
  action?: T;
  image?: ImageAsset | ImageAsset[];
  nextButton?: string;
  disabled?: boolean;
  outputs?: string[];
  assets?: string;
  submit?: boolean;
  submitConfig?: ConfirmModalConfig;
  confirmConfig?: ConfirmModalConfig;
  complete?: boolean;
  questionList?: string;
  showIf?: IfDefinition;
  actions?: CardAction[];
  step?: number;
  total?: number;
  stepProgress?: string;
}
