import { ApiService } from '../../core/api.service';
import { firstValueFrom } from 'rxjs';
import { AuditLog } from '@jump-tech-frontend/domain';
import { Injectable } from '@angular/core';

export type AuditLogAggregations = { [key: string]: { [key: string]: string }[] };

@Injectable({ providedIn: 'root' })
export class ProjectAuditLogsRepository {
  constructor(private apiService: ApiService) {}
  async list(projectId: string): Promise<AuditLog[]> {
    const search = [{ key: 'projectId', value: projectId }];
    const searchOptions = {
      sort: 'created_on',
      dir: 'desc',
      max: '200'
    };
    const { results } = await firstValueFrom(this.apiService.searchAuditLogs(search, searchOptions));
    return results;
  }

  async listForTenant(aggregators: {
    [key: string]: string;
  }): Promise<{ results: AuditLog[]; aggregations: AuditLogAggregations }> {
    const searchOptions = {
      sort: 'created_on',
      dir: 'desc',
      max: '200'
    };

    for (const key of Object.keys(aggregators)) {
      searchOptions[key] = aggregators[key];
    }

    return this.apiService.searchAllAuditLogs(searchOptions);
  }
}
