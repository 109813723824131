import { Injectable } from '@angular/core';
import { Translation } from '@ngneat/transloco';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { catchError, firstValueFrom, map, of } from 'rxjs';
import { extractLanguageFromLocale } from './language-parser';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader {
  constructor(private http: HttpClient, private environment: string) {}

  getTranslation(language: string, namespace?: string) {
    // Handle nucleus module translations
    if (language.indexOf('/') > -1) {
      return firstValueFrom(this.getTranslationsFromLocal(language));
    }

    return this.getTranslationsWithCommons(extractLanguageFromLocale(language), namespace);
  }

  private async getTranslationsWithCommons(lang: string, namespace?: string) {
    const app = await firstValueFrom(this.getAppTranslations(lang, namespace));

    if (app?.['_commons']) {
      return app;
    }

    const commons = await firstValueFrom(this.getCommonsTranslations(lang));
    return [app, commons].reduce((app, commons) => {
      return { ...app, _commons: commons };
    });
  }

  private getCommonsTranslations(lang: string) {
    return this.getTranslationsFromServer(`${lang}/commons`);
  }

  private getAppTranslations(lang: string, namespace?: string) {
    let path: string;
    if (namespace) {
      path = `${lang}/${namespace}`;
    } else {
      path = lang;
    }

    return this.getTranslationsFromLocal(`${path}`).pipe(
      catchError(() => {
        return this.getTranslationsFromServer(`${path}`);
      })
    );
  }

  private getTranslationsFromServer(path: string) {
    const hostEnv = this.environment === 'production' ? '' : `.${this.environment}`;
    const apiUrl = `https://api${hostEnv}.jumptech.co.uk`;

    return this.http
      .get<HttpResponse<any>>(`${apiUrl}/i18n/${this.environment}/${path}`, {
        headers: { skip: 'true' }
      })
      .pipe(
        map((response: HttpResponse<any>) => response.body && JSON.parse(response.body)),
        catchError(() => {
          throw new Error(`Could not parse translation file [${path}]`);
        })
      );
  }

  private getTranslationsFromLocal(path: string) {
    return this.http
      .get<Translation>(`assets/i18n/${path}.json`, {
        headers: { skip: 'true' }
      })
      .pipe(
        catchError(() => {
          throw new Error(`Could not parse translation file [${path}]`);
        })
      );
  }
}
