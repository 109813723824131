import { JumptechDateSettings } from '@jump-tech-frontend/domain';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private defaultLang = 'en';

  public currentLang = new BehaviorSubject(this.lang);

  public constructor(private translationService: TranslocoService) {}

  set lang(value) {
    if (!value) {
      return;
    }
    localStorage.setItem('jt:lang', value);
    this.translationService.setActiveLang(value);
    JumptechDateSettings.defaultLocale = value;
    this.currentLang.next(value);
  }

  get lang(): string {
    return localStorage.getItem('jt:lang') || this.defaultLang;
  }
}
