import { firstValueFrom } from 'rxjs';
import { AssignmentTarget, DelegationShare, DelegationShareSubTenant } from '../../admin/user-management/domain/types';
import { Injectable } from '@angular/core';
import { checkIfExpression } from '../utils/filter';
import { ApiService } from '../api.service';
import { AccessService } from '../../auth/services/access.service';
import { LoggerService } from '../../error/logger.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '../../toast/toast-service';
import { TranslocoService } from '@ngneat/transloco';
import { DelegateProjectModalComponent } from '../../dashboards/delegation/delegate-project-modal.component';
import { ConfirmModalComponent } from '../../shared/modals/confirm-modal.component';
import { Project } from '../domain/project';
import { SearchResult } from '../../dashboards/search-result';

export interface ProjectDelegation {
  projectId: string;
  tenant: string; // The tenant being delegated to
  subTenants?: DelegationShareSubTenant[];
}

@Injectable({ providedIn: 'root' })
export class DelegationService {
  constructor(
    private apiService: ApiService,
    private featureAccessService: AccessService,
    private loggerService: LoggerService,
    private modalService: NgbModal,
    private toast: ToasterService,
    private translocoService: TranslocoService
  ) {}

  async getDelegatesForProjectType(projectType: string, creationOnly = false): Promise<DelegationShare[]> {
    const dataSharing = await this.featureAccessService.getDataSharing();
    if (!dataSharing) {
      return [];
    }
    return dataSharing.data.filter(dataItem => {
      return (
        dataItem.tenant &&
        dataItem.projectType === projectType &&
        (!creationOnly || dataItem.showOnCreation == null || dataItem.showOnCreation === creationOnly)
      );
    });
  }

  async showDelegationModal(
    projectType: string,
    preAssignment?: AssignmentTarget
  ): Promise<{ delegate: string; subTenants?: DelegationShareSubTenant[] }> | null {
    const delegates = await this.getDelegatesForProjectType(projectType);
    const modalRef = this.modalService.open(DelegateProjectModalComponent);
    modalRef.componentInstance.delegates = delegates;
    modalRef.componentInstance.preAssignment = preAssignment;
    try {
      return await modalRef.result;
    } catch (error) {
      return null;
    }
  }

  async delegateProject(projectId: string, projectType: string, preAssignment?: AssignmentTarget): Promise<boolean> {
    if (!preAssignment) {
      const project = await firstValueFrom(this.apiService.getProject(projectId));
      preAssignment = project.data?.preAssignment?.target;
    }

    const delegateDetail = await this.showDelegationModal(projectType, preAssignment);
    if (!delegateDetail) {
      return;
    }

    try {
      this.toast.pop(
        'info',
        this.translocoService.translate('toasts.assignInProgress.heading'),
        this.translocoService.translate('toasts.assignInProgress.body')
      );
      await this.apiService
        .delegateProjects([
          {
            tenant: delegateDetail.delegate,
            projectId: projectId,
            subTenants: delegateDetail.subTenants || null
          }
        ])
        .toPromise();
      const delegationResponse =
        delegateDetail.subTenants && delegateDetail.subTenants.length
          ? ` ${delegateDetail.subTenants.map(dds => dds.name).join(', ')}`
          : delegateDetail.delegate.toUpperCase();
      this.toast.pop('info', this.translocoService.translate('toasts.assignSuccess'), delegationResponse);
      return true;
    } catch (error) {
      if (delegateDetail) {
        this.loggerService.error(error);
      }
      this.toast.pop(
        'error',
        this.translocoService.translate('toasts.assignFailure.heading'),
        this.translocoService.translate('toasts.assignFailure.body')
      );
      return false;
    }
  }

  async undelegateProject(projectId: string, tenant: string): Promise<boolean> {
    try {
      const modalRef = this.modalService.open(ConfirmModalComponent, { size: 'sm' });
      modalRef.componentInstance.config = {
        title: this.translocoService.translate('project.modals.unassignFromInstaller.title'),
        messages: [this.translocoService.translate('project.modals.unassignFromInstaller.messages.confirmation')]
      };
      await modalRef.result;
    } catch (err) {
      this.loggerService.error(err);
      return;
    }

    this.toast.pop(
      'info',
      this.translocoService.translate('toasts.unassigning.heading'),
      this.translocoService.translate('toasts.unassigning.body')
    );
    try {
      await this.apiService.unDelegateProject(projectId, tenant);
      this.toast.pop(
        'info',
        this.translocoService.translate('toasts.projectUnassigned'),
        this.translocoService.translate('toasts.projectUnassigned')
      );
      return true;
    } catch (err) {
      this.loggerService.error(err);
      this.toast.pop(
        'error',
        this.translocoService.translate('toasts.unassignFailure.heading'),
        this.translocoService.translate('toasts.unassignFailure.body')
      );
      return false;
    }
  }

  showDelegationAction(
    delegationEnabled: boolean,
    delegates: DelegationShare[],
    userTenant: string,
    projectTenant: string
  ): boolean {
    return delegationEnabled && delegates.length > 0 && userTenant === projectTenant;
  }

  showUnDelegationAction(
    delegationEnabled: boolean,
    project: Project | SearchResult,
    tenant: string,
    delegates: DelegationShare[]
  ): boolean {
    if (!delegationEnabled || !this.isDelegated(project, tenant)) {
      return false;
    }

    if (tenant !== project.tenant) {
      const delegate = delegates?.find(d => d.tenant === project.tenant);
      return delegate && checkIfExpression(delegate.showIf, project);
    }
    return false;
  }

  isDelegated(project: Project | SearchResult, tenant: string): any {
    let delegationChain;
    if ('params' in project) {
      delegationChain = project.params.delegationChain;
    } else {
      delegationChain = project.delegationChain;
    }
    if (delegationChain) {
      const delegationParts = delegationChain.split('|');
      const userChildTenant = delegationParts[delegationParts.indexOf(tenant) + 1];
      if (userChildTenant) {
        return userChildTenant;
      }
    }
    return null;
  }

  canUndelegate(project: Project | SearchResult, tenant: string, delegates: DelegationShare[]) {
    if (tenant !== project.tenant) {
      const delegate = delegates?.find(d => d.tenant === project.tenant);
      return delegate && checkIfExpression(delegate.showIf, project);
    }
    return false;
  }
}
