import { Component, computed, input, OnDestroy, OnInit, output, Signal } from '@angular/core';
import { Project } from '../../core/domain/project';
import { LayoutUpdateService } from '../../core/layout-update.service';
import { ExpandedCardLayout, ExpandedCardLayoutCollection } from '../project-detail.component';
import { GalleryLayoutComponent } from '../../core/cardLayouts/gallery-layout.component';
import { AsyncPipe, JsonPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { GalleryLayoutDirective } from './gallery-layout.directive';
import { ListLayoutComponent } from '../../core/cardLayouts/list-layout.component';
import { StandardQuoteSelectComponent } from '../../feature-modules/configuration/standard-data/quotes/components/selector/standard-quote-select.component';
import { GalleryV2LayoutComponent } from '../../core/cardLayouts/gallery-v2/gallery-v2-layout.component';
import { slice } from 'lodash';
import { CardLayoutItem } from '../../core/domain/card-layout-item';

@Component({
  selector: 'app-gallery-v2',
  templateUrl: './gallery-v2.component.html',
  styleUrls: ['./gallery-v2.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgTemplateOutlet,
    GalleryLayoutComponent,
    GalleryLayoutDirective,
    AsyncPipe,
    ListLayoutComponent,
    StandardQuoteSelectComponent,
    GalleryV2LayoutComponent,
    JsonPipe
  ],
  providers: [LayoutUpdateService]
})
export class GalleryV2Component implements OnInit, OnDestroy {
  layoutCollection = input.required<ExpandedCardLayoutCollection[]>();
  project = input.required<Project>();
  useLayoutGroups = input.required<boolean>();
  isArchived = input.required<boolean>();
  isTabReadOnly = input.required<boolean>();
  onViewingGallery = output<boolean>();

  grpLayoutItem: Signal<CardLayoutItem[]>[] = [];

  constructor(private layoutUpdateService: LayoutUpdateService) {}

  ngOnInit(): void {
    this.groupLayoutItems();
    this.onViewingGallery.emit(true);
  }

  ngOnDestroy(): void {
    this.onViewingGallery.emit(false);
  }

  isGalleryReadOnly = computed(() => {
    return (
      this.isArchived() || (this.isTabReadOnly() && !this.layoutUpdateService.allowEdit(this.layoutCollection()[0]))
    );
  });

  layoutItems = computed(() => {
    let layoutItems: CardLayoutItem[] = [];
    this.layoutCollection().forEach((layout: ExpandedCardLayoutCollection): void => {
      layout.layouts.forEach((layoutLayout: ExpandedCardLayout): void => {
        layoutItems = layoutItems.concat(layoutLayout.items);
      });
    });
    return layoutItems.sort((a, b) => a.key.localeCompare(b.key));
  });

  groupLayoutItems() {
    this.layoutCollection().forEach((layout: ExpandedCardLayoutCollection, i: number): void => {
      layout.layouts.forEach((layoutLayout: ExpandedCardLayout): void => {
        this.grpLayoutItem[i] = computed(() => {
          let layoutItems: CardLayoutItem[] = layoutLayout.items;
          return layoutItems.sort((a, b) => a.key.localeCompare(b.key));
        });
      });
    });
  }

  protected readonly Math = Math;
  protected readonly slice = slice;
}
