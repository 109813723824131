import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import {
  ErrorType,
  ScheduleError,
  ScheduleI18n,
  ScheduleJobsDisplayDm,
  SelectedJobDetailsDm,
  SchedulePayloadPostDto,
  SelectedJobDetailsToggleDm,
  TradesPerson,
  DateTimeChangeEvent,
  DateChangeType
} from './schedule.model';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TIME_SLOTS } from './utils/schedule-constants';
import { HttpGateway } from '../../core/http-gateway.service';
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { ToasterService } from '../../toast/toast-service';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AggregatorReadOnlyService } from '../../project-detail/aggregator-read-only.service';
import { UserService } from '../../auth/services/user.service';
import { EventInfo, JobInformation } from './utils/schedule-types';
import { JumptechDate } from '@jump-tech-frontend/domain';
import { Job, JobAssignment, JobAssignmentType } from '../../core/domain/job';
import { environment } from '../../../environments/environment';
import { DropDownElement } from '../../shared/form-components/multiple-selection-dropdown.component';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { pad } from './utils/schedule.helper';
import { Slot } from 'aws-sdk/clients/lexruntimev2';

@Injectable({ providedIn: 'root' })
export class ScheduleRepositoryV3 {
  readyToScheduleSubscription: Subscription;
  errorsSubscription: Subscription;
  selectedJobDetailsSubscription: Subscription;
  selectedJobDetailsFormSubscription: Subscription;
  detailsToggleSubscription: Subscription;
  slotFormSubscriptions: Subscription[] = [];

  errors$: Subject<ScheduleError>;
  rsj$: Subject<ScheduleJobsDisplayDm>;
  selectedJobDetails$: Subject<SelectedJobDetailsDm>;
  dt$: Subject<SelectedJobDetailsToggleDm>;

  dm: ScheduleJobsDisplayDm;
  cachedDm: ScheduleJobsDisplayDm;
  allEngineersList: any;
  filteredEngineerList: any;
  isReadonlyAggregator = false;

  selectedJobDetailsDm: SelectedJobDetailsDm = {} as SelectedJobDetailsDm;

  selectedJobDetailsForm: FormGroup;
  addEngineerForm: FormGroup;
  filtersForm: FormGroup;

  i18n: ScheduleI18n = {};
  timeSlots = TIME_SLOTS;

  constructor(
    private gateway: HttpGateway,
    private i18nService: TranslocoService,
    private fb: FormBuilder,
    private router: Router,
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private readOnlyProjectService: AggregatorReadOnlyService,
    private userService: UserService
  ) {
    this.init().then();
  }

  async init(): Promise<void> {
    this.rsj$ = new Subject<ScheduleJobsDisplayDm>();
    this.errors$ = new Subject<ScheduleError>();
    this.selectedJobDetails$ = new Subject<SelectedJobDetailsDm>();
    this.dt$ = new Subject<SelectedJobDetailsToggleDm>();
    this.initI18ns();
    this.initForms();
    this.isReadonlyAggregator = this.readOnlyProjectService.isReadOnly();
    await this.initDataLists();
  }

  initForms(jobInformation?: JobInformation, resetFilters = true): void {
    // When dragging or assigning new jobs we want to default the time to 9am
    const assignmentStartTime = JumptechDate.from(new Date(new Date().setHours(9, 0, 0, 0)));
    const assignmentEndTime = assignmentStartTime.plus({ hours: jobInformation?.defaultDuration ?? 2 });
    const fullJobStart = jobInformation?.fullJobStart ?? jobInformation?.startDateTimestamp;
    const fullJobEnd = jobInformation?.fullJobEnd ?? jobInformation?.endDateTimestamp;

    const formStartTime = jobInformation?.setDefaultStartTime
      ? this.isoStringToTime(assignmentStartTime.toIso())
      : this.isoStringToTime(fullJobStart);
    const formEndTime = jobInformation?.setDefaultStartTime
      ? this.isoStringToTime(assignmentEndTime.toIso())
      : this.isoStringToTime(fullJobEnd);

    this.selectedJobDetailsForm = this.fb.group({
      id: [jobInformation?.actionId ?? null],
      fullJob: this.fb.group({
        startIso: [fullJobStart, [Validators.required]],
        startDate: [fullJobStart ? this.isoStringToDateStruct(fullJobStart) : null, [Validators.required]],
        endIso: [fullJobEnd, [Validators.required]],
        endDate: [fullJobEnd ? this.isoStringToDateStruct(fullJobEnd) : null, [Validators.required]],
        startTime: [formStartTime ?? null, [Validators.required]],
        endTime: [formEndTime ?? null, [Validators.required]],
        lastChange: [null]
      }),
      rescheduleReason: [null]
    });

    this.buildTradesPeopleForms(jobInformation);

    this.addEngineerForm = this.fb.group({
      engineerToAdd: [null, [Validators.required]]
    });
    if (resetFilters) {
      this.filtersForm = this.fb.group({
        selectedJobTypes: [[]],
        freeText: ['']
      });
    }
  }

  buildTradesPeopleForms(jobInfo: JobInformation): void {
    jobInfo?.tradesPeople.forEach(tp => {
      this.selectedJobDetailsForm.addControl(
        tp.assignedTo,
        this.fb.group({
          assignedTo: [tp.assignedTo, Validators.required],
          assignedToDisplayName: [tp.assignedToDisplayName],
          assignmentId: [tp.assignmentId],
          assignmentType: [tp.assignmentType, Validators.required]
        })
      );

      if (tp.slots.length) {
        // build slot array
        const formArraySlots = [];
        tp.slots.forEach(slot => {
          const slotStartTime = this.isoStringToTime(slot.startDate);
          const slotEndTime = this.isoStringToTime(slot.endDate);

          formArraySlots.push(
            this.fb.group({
              assignedTo: [tp.assignedTo, Validators.required],
              assignedToDisplayName: [tp.assignedToDisplayName],
              assignmentType: [tp.assignmentType, Validators.required],
              assignmentId: [slot.assignmentId],
              // todo constraints around dates validation - no overlapping slots for same resource + bounds of full job + start before end
              startDate: [this.isoStringToDateStruct(slot.startDate), Validators.required],
              endDate: [this.isoStringToDateStruct(slot.endDate), Validators.required],
              startTime: [slotStartTime, Validators.required],
              endTime: [slotEndTime, Validators.required],
              startIso: slot.startDate,
              endIso: slot.endDate,
              lastChange: [null]
            })
          );
        });
        (this.selectedJobDetailsForm.controls[tp.assignedTo] as FormGroup).addControl(
          'slots',
          this.fb.array(formArraySlots)
        );
      }
    });
  }

  load(cb): void {
    this.readyToScheduleSubscription?.unsubscribe();
    this.readyToScheduleSubscription = this.rsj$.subscribe(cb);
    this.loadReadyToScheduleJobs().then();
  }

  loadJobDetails(cb): void {
    this.selectedJobDetailsSubscription?.unsubscribe();
    this.selectedJobDetailsSubscription = this.selectedJobDetails$.subscribe(cb);
  }

  closeJobDetails(success = false, payload = null): void {
    this.selectedJobDetailsFormSubscription?.unsubscribe();
    if (success) {
      this.selectedJobDetailsDm = { scheduleSuccess: true, ...payload } as SelectedJobDetailsDm;
      this.selectedJobDetails$.next(this.selectedJobDetailsDm);
    } else {
      this.selectedJobDetails$.next(null);
    }
  }

  loadDetailsToggle(cb): void {
    this.detailsToggleSubscription?.unsubscribe();
    this.detailsToggleSubscription = this.dt$.subscribe(cb);
  }

  showDetailsToggle(show: boolean): void {
    this.selectedJobDetailsDm = { ...this.selectedJobDetailsDm, hidden: show };
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
    this.dt$.next({ display: show, hint: this.i18n.toggleSelectedJobHint });
  }

  addEngineer(): void {
    this.selectedJobDetailsDm = { ...this.selectedJobDetailsDm, isAddingEngineer: true };
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  setLeadEngineer(engineer: JobAssignment): void {
    if (engineer.assignmentType === 'LEAD') {
      return;
    }

    this.selectedJobDetailsDm.jobAssignments = this.selectedJobDetailsDm.jobAssignments.map(x => ({
      assignedTo: x.assignedTo,
      assignedToDisplayName: x.assignedToDisplayName,
      assignmentType: engineer.assignedTo === x.assignedTo ? 'LEAD' : ('SUPPORT' as JobAssignmentType)
    }));

    this.selectedJobDetailsDm.jobAssignments.sort(a => {
      if (a.assignmentType === 'LEAD') {
        return -1;
      } else {
        return 1;
      }
    });
    this.selectedJobDetailsForm.get('jobAssignments').patchValue(this.selectedJobDetailsDm.jobAssignments);

    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  deleteEngineer(engineer: JobAssignment): void {
    this.selectedJobDetailsDm.jobAssignments = this.selectedJobDetailsDm.jobAssignments.filter(
      x => x.assignedTo !== engineer.assignedTo
    );
    if (engineer.assignmentType === 'LEAD' && this.selectedJobDetailsDm.jobAssignments.length) {
      this.selectedJobDetailsDm.jobAssignments[0].assignmentType = 'LEAD';
    }
    this.selectedJobDetailsForm.patchValue({ jobAssignments: this.selectedJobDetailsDm.jobAssignments });
    this.filterEngineerOptions(this.selectedJobDetailsDm.jobAssignments);
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  filterEngineerOptions(jobAssignments: JobAssignment[]): void {
    const alreadyAssignedList = jobAssignments.map(a => a.assignedTo);
    this.filteredEngineerList = this.allEngineersList.filter(x => {
      return !alreadyAssignedList.includes(x.id);
    });
    this.selectedJobDetailsDm.engineers = this.filteredEngineerList;
  }

  confirmAddEngineer(): void {
    const engineerData = this.selectedJobDetailsDm.addEngineerForm.get('engineerToAdd').value;
    const newJobAssignment: JobAssignment = {
      assignedTo: engineerData.id,
      assignedToDisplayName: engineerData.name,
      assignmentType: this.selectedJobDetailsDm.jobAssignments?.length ? 'SUPPORT' : 'LEAD'
    };

    // update job assignments
    this.selectedJobDetailsDm = {
      ...this.selectedJobDetailsDm,
      jobAssignments: [...this.selectedJobDetailsDm.jobAssignments, newJobAssignment]
    };

    this.selectedJobDetailsForm.patchValue({ jobAssignments: this.selectedJobDetailsDm.jobAssignments });
    this.resetAddEngineerForm();
    this.filterEngineerOptions(this.selectedJobDetailsDm.jobAssignments);
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  cancelAddEngineer(): void {
    this.resetAddEngineerForm();
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  resetAddEngineerForm(): void {
    this.selectedJobDetailsDm.addEngineerForm.patchValue({ engineerToAdd: null });
    this.selectedJobDetailsDm.isAddingEngineer = false;
  }

  async loadReadyToScheduleJobs(showloader = true, scheduledJobId: string = null): Promise<void> {
    try {
      // notify loading state
      if (showloader) {
        this.dm = {
          loading: true,
          jobs: [],
          jobTypes: [],
          filtersForm: this.filtersForm,
          i18ns: this.i18n
        };
        this.rsj$.next(this.dm);
      }

      const dto: Job[] = [];
      let currentNextPageToken: string | undefined;

      const { results, nextPageToken } = await this.getReadyToScheduleJobsBatch();
      const unscheduledJobs = this.removeRecentlyScheduledJob(results, scheduledJobId);

      dto.push(...unscheduledJobs);
      currentNextPageToken = nextPageToken;

      this.parseAndNotify(dto);

      while (currentNextPageToken) {
        const { results, nextPageToken } = await this.getReadyToScheduleJobsBatch(currentNextPageToken);
        const unscheduledJobs = this.removeRecentlyScheduledJob(results, scheduledJobId);

        dto.push(...unscheduledJobs);
        currentNextPageToken = nextPageToken;

        this.parseAndNotify(dto);
      }
    } catch (e) {
      this.handleErrors(ErrorType.fetchReadyToScheduleJobs, e);
    }
  }

  private removeRecentlyScheduledJob(jobs: Job[], scheduledJobId: string = null) {
    if (!scheduledJobId) {
      return jobs;
    }
    // remove recently scheduled job from list, so we don't have to retry or make user wait too long
    return jobs.filter(({ id }) => id !== scheduledJobId);
  }

  private async getReadyToScheduleJobsBatch(
    nextPageToken?: string
  ): Promise<{ results: Job[]; nextPageToken?: string }> {
    const params = { readyToSchedule: true, isScheduled: false };

    if (nextPageToken !== undefined) {
      params['nextPageToken'] = nextPageToken;
    }

    return await this.gateway.get(`${environment.apiJobsUrl}`, params);
  }

  public getErrors(cb): void {
    this.errorsSubscription?.unsubscribe();
    this.errorsSubscription = this.errors$.subscribe(cb);
  }

  public clearErrors(): void {
    this.errors$.next({} as ScheduleError);
  }

  private setError(e: ErrorType): void {
    const message = this.i18nService.translate(e);
    this.errors$.next({
      message,
      qaErrorMessage: 'scheduleErrorMessage',
      qaClearErrorsButton: 'scheduleClearErrorsButton'
    });
  }

  private parseAndNotify(dto: Job[]): void {
    const jobTypes = this.buildJobTypesFilter(dto);

    const updatedDm: ScheduleJobsDisplayDm = {
      ...this.dm,
      loading: false,
      jobs: dto,
      filtersForm: this.filtersForm,
      jobTypes,
      i18ns: this.i18n
    };

    this.cachedDm = { ...updatedDm };
    this.filterReadyToSchedule();
  }

  public setSelectedJob(job: Job): void {
    this.dm = { ...this.dm, selectedJob: job };
    this.cachedDm = { ...this.dm };
    this.rsj$.next(this.dm);
  }

  private buildJobTypesFilter(dto: Job[]): DropDownElement[] {
    return dto
      .map(job => job.type)
      .filter((value, index, currentValue) => currentValue.indexOf(value) === index)
      .map(x => ({ id: x, name: x }));
  }

  filterReadyToSchedule(): void {
    const selectedJobTypes: string[] = this.filtersForm.get('selectedJobTypes').value;
    const freeText: string = this.filtersForm.get('freeText').value;
    let filteredJobs = this.cachedDm?.jobs;

    if (selectedJobTypes?.length) {
      filteredJobs = this.cachedDm.jobs.filter((job: Job) => selectedJobTypes?.includes(job.type));
    }
    if (freeText) {
      filteredJobs = filteredJobs.filter((job: Job) => {
        return (
          `${job.firstName} ${job.lastName}`.toLowerCase().includes(freeText.toLowerCase()) ||
          job.address?.postCode?.toLowerCase()?.replace(/\s/g, '').includes(freeText?.toLowerCase()?.replace(/\s/g, ''))
        );
      });
    }
    this.dm = {
      loading: false,
      jobs: filteredJobs,
      filtersForm: this.filtersForm,
      jobTypes: this.cachedDm ? this.cachedDm.jobTypes : this.dm.jobTypes,
      selectedJob: this.cachedDm ? this.cachedDm.selectedJob : this.dm.selectedJob,
      i18ns: this.i18n
    };
    this.rsj$.next(this.dm);
  }

  public handleErrors(type: ErrorType | unknown, e): void {
    if (!environment.production) {
      console.log(e);
    }
    switch (type) {
      case ErrorType.fetchReadyToScheduleJobs:
        this.setError(ErrorType.fetchReadyToScheduleJobs);
        this.rsj$.next({ ...this.dm, jobs: [], loading: false, i18ns: this.i18n });
        break;
      case ErrorType.fetchEngineers:
        this.setError(ErrorType.fetchEngineers);
        break;
      case ErrorType.selectRescheduleJob:
        this.setError(ErrorType.selectRescheduleJob);
        break;
      case ErrorType.unknown:
        this.setError(ErrorType.unknown);
        break;
      case ErrorType.scheduleJob:
        this.setError(ErrorType.scheduleJob);
        this.selectedJobDetails$.next({ ...this.selectedJobDetailsDm, scheduleInProgress: false });
        break;
      default:
        this.setError(ErrorType.unknown);
    }
  }

  parseEngineers(dto): DropDownElement[] {
    const engineers = dto.map(x => ({ name: x.key, id: x.value.split('|')[1] }));
    if (AuthenticationService.getTier() === 'support') {
      const currentUser = this.userService.currentUser;
      engineers.push({ name: `${currentUser.label} (Support)`, id: currentUser.id });
    }
    return engineers;
  }

  async fetchEngineers(): Promise<void> {
    try {
      const dto = await this.gateway.get(`${environment.apiCustomRoot}/core/users/engineer`, {});
      this.allEngineersList = this.parseEngineers(dto);
    } catch (e) {
      this.handleErrors(ErrorType.fetchEngineers, e);
    }
  }

  listenForMoreDetailsSlotFormChanges(assignedTo: string, slotIdx: number, slot, cb, calEvent: EventInfo): void {
    // get form

    const slotForm = (this.selectedJobDetailsForm.get(assignedTo).get('slots') as FormArray).at(slotIdx);
    this.slotFormSubscriptions.push(
      slotForm.valueChanges.subscribe(change => {
        cb(change, calEvent, slot);
      })
    );
  }

  listenForMoreDetailsFormChanges(cb, calEvent: EventInfo): void {
    this.selectedJobDetailsFormSubscription?.unsubscribe();
    this.selectedJobDetailsFormSubscription = this.selectedJobDetailsForm
      .get('fullJob')
      .valueChanges.subscribe(change => {
        cb(change, calEvent, null, 'fullJobChange');
      });
  }

  private buildTradesPeopleFromAssignments(jobAssignments: JobAssignment[]): TradesPerson[] {
    const tradesPeople: TradesPerson[] = [];

    jobAssignments.forEach((ja: JobAssignment): void => {
      const tradesPerson: TradesPerson = {
        assignedTo: null,
        assignedToDisplayName: null,
        assignmentType: null,
        slots: []
      };
      // we don't have a tradesperson for this job assignment
      if (!tradesPeople.find(tp => tp.assignedTo === ja.assignedTo)) {
        tradesPerson.assignedTo = ja.assignedTo;
        tradesPerson.assignedToDisplayName = ja.assignedToDisplayName;
        tradesPerson.assignmentType = ja.assignmentType;
        // we don't have specific slot so add the assignment id
        if (!ja.startDate && !ja.endDate) {
          tradesPerson.assignmentId = ja.assignmentId;
        } else {
          tradesPerson.slots.push({
            assignmentId: ja.assignmentId,
            startDate: ja.startDate,
            endDate: ja.endDate
          });
        }
        tradesPeople.push(tradesPerson);
      } else {
        const existing = tradesPeople.find(tp => tp.assignedTo === ja.assignedTo);
        // add slot to existing
        existing.slots.push({
          assignmentId: ja.assignmentId,
          startDate: ja.startDate,
          endDate: ja.endDate
        });
      }
    });

    return tradesPeople;
  }

  public openJobDetails(jobInformation: JobInformation, cb, calEvent: EventInfo): void {
    this.filterEngineerOptions(jobInformation.jobAssignments);
    const tradesPeople = this.buildTradesPeopleFromAssignments(jobInformation.jobAssignments);
    jobInformation.tradesPeople = tradesPeople;

    this.initForms(jobInformation, false);

    if (cb && calEvent) {
      this.listenForMoreDetailsFormChanges(cb, calEvent);
    }

    this.slotFormSubscriptions?.forEach((sub: Subscription) => sub.unsubscribe());
    tradesPeople.forEach(tp => {
      tp.slots.forEach((slot, idx) => {
        // slot.assignmentId = t.assignmentId;
        this.listenForMoreDetailsSlotFormChanges(tp.assignedTo, idx, slot, cb, calEvent);
      });
    });

    if (jobInformation.tenantType) {
      this.readOnlyProjectService.next(jobInformation.tenantType);
    }

    this.selectedJobDetailsDm = {
      context: jobInformation.context,
      isReadonlyAggregator: this.isReadonlyAggregator,
      id: jobInformation.id,
      scheduleSuccess: false,
      type: jobInformation.type,
      projectId: jobInformation.projectId,
      firstName: jobInformation.customerFirstName,
      lastName: jobInformation.customerLastName,
      phoneNumber: jobInformation.contactInfo.telephoneNumber,
      email: jobInformation.contactInfo.email,
      address: jobInformation.address,
      startDate: jobInformation.startDateTimestamp,
      endDate: jobInformation.endDateTimestamp,
      engineers: this.filteredEngineerList,
      jobAssignments: jobInformation.jobAssignments,
      tradesPeople,
      collisions: [],
      form: this.selectedJobDetailsForm,
      addEngineerForm: this.addEngineerForm,
      isAddingEngineer: false,
      scheduleInProgress: false,
      isInitialSchedule: jobInformation.isInitialSchedule,
      enableCollisionCheck: false,
      collisionCheckInProgress: false,
      hidden: false,
      i18nLead: this.i18n.lead,
      i18nConfirmBtn: this.i18n.confirmBtn,
      i18nCancelBtn: this.i18n.cancelBtn,
      i18nCloseBtn: this.i18n.closeBtn,
      i18nStartDatePlaceholder: this.i18n.startDatePlaceholder,
      i18nTradesPeopleHeader: this.i18n.tradesPeopleHeader,
      i18nTradesPeopleSubHeader: this.i18n.tradesPeopleSubHeader,
      i18nTradesPeopleSlotHeader: this.i18n.tradesPeopleSlotHeader,
      i18nStartDateLabel: this.i18n.startDateLabel,
      i18nScheduleNowBtn: this.i18n.scheduleNowBtn,
      i18nGoToProjectBtn: this.i18n.goToProjectBtn,
      i18nStartDateRequired: this.i18n.startDateRequired,
      i18nInvalidDateFormat: this.i18n.invalidDateFormat,
      i18nStartDateAfterEnd: this.i18n.startDateAfterEnd,
      i18nStartTimeLabel: this.i18n.startTimeLabel,
      i18nEndDatePlaceholder: this.i18n.endDatePlaceholder,
      i18nEndDateLabel: this.i18n.endDateLabel,
      i18nEndDateRequired: this.i18n.endDateRequired,
      i18nEndDateBeforeStart: this.i18n.endDateBeforeStart,
      i18nEndTimeLabel: this.i18n.endTimeLabel,
      i18nDurationLabel: this.i18n.durationLabel,
      i18nDayLabel: this.i18n.dayLabel,
      i18nDaysLabel: this.i18n.daysLabel,
      i18nHourLabel: this.i18n.hourLabel,
      i18nHoursLabel: this.i18n.hoursLabel,
      i18nMinutesLabel: this.i18n.minutesLabel,
      i18nAddTradesPersonLabel: this.i18n.addTradesPersonLabel,
      i18nSelectEngineerPlaceholder: this.i18n.selectEngineerPlaceholder,
      i18nProvisionallyScheduleBtn: this.i18n.provisionallyScheduleBtn,
      i18nAddTradesPersonBtn: this.i18n.addTradesPersonBtn,
      i18nSetLeadEngineerBtn: this.i18n.setLeadEngineerBtn,
      i18nRemoveEngineerBtn: this.i18n.removeEngineerBtn,
      i18nRescheduleReasonInputLabel: this.i18n.rescheduleReasonInputLabel,
      i18nRescheduleReasonInputPlaceholder: this.i18n.rescheduleReasonInputPlaceholder,
      i18nEngineerRequiredError: this.i18n.engineerRequiredError,
      i18nTimeIsInvalid: this.i18n.timeIsInvalid,
      i18nTimeNotWithinJob: this.i18n.i18nTimeNotWithinJob,
      i18nCheckCollisionsBtn: this.i18n.checkCollisionsBtn,
      i18nAddSpecificTimeBtn: this.i18n.addSpecificTimeBtn
    };
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  private initI18ns(): void {
    this.i18n.projectType = this.i18nService.translate('common.projectType');
    this.i18n.jobType = this.i18nService.translate('common.jobType');
    this.i18n.lead = this.i18nService.translate('common.lead');
    this.i18n.nameLabel = this.i18nService.translate('common.name');
    this.i18n.contactInfoLabel = this.i18nService.translate('schedule.jobInformation.contactInfo');
    this.i18n.addressLabel = this.i18nService.translate('common.formFields.address');
    this.i18n.assignedTradespersonLabel = this.i18nService.translate('schedule.jobInformation.assignedTradesperson');
    this.i18n.freeTextFilterLabel = this.i18nService.translate('common.filter');
    this.i18n.jobTypesDropdownPlaceholder = this.i18nService.translate('common.showAll');
    this.i18n.titleJob = this.i18nService.translate('schedule.jobInformation.job');
    this.i18n.titlePostcode = this.i18nService.translate('common.postcode');
    this.i18n.buttonAssign = this.i18nService.translate('common.assign');
    this.i18n.buttonProject = this.i18nService.translate('common.project');
    this.i18n.buttonMoreDetails = this.i18nService.translate('schedule.jobInformation.buttons.moreDetails');
    this.i18n.titleJobsReadyToSchedule = this.i18nService.translate('schedule.jobDisplay.jobsReadyToSchedule');
    this.i18n.titleAllOtherJobs = this.i18nService.translate('schedule.jobDisplay.allOtherJobs');
    this.i18n.titleSelectedJobFromProject = this.i18nService.translate('schedule.jobDisplay.selectedJobFromProjects');
    this.i18n.startDatePlaceholder = this.i18nService.translate('schedule.formFields.startDate.placeholder');
    this.i18n.tradesPeopleHeader = this.i18nService.translate('schedule.moreInfo.tradesPeopleHeader');
    this.i18n.tradesPeopleSubHeader = this.i18nService.translate('schedule.moreInfo.tradesPeopleSubHeader');
    this.i18n.tradesPeopleSlotHeader = this.i18nService.translate('schedule.moreInfo.tradesPeopleSlotHeader');
    this.i18n.startDateLabel = this.i18nService.translate('schedule.formFields.startDate.label');
    this.i18n.scheduleNowBtn = this.i18nService.translate('schedule.moreInfo.scheduleNowBtn');
    this.i18n.goToProjectBtn = this.i18nService.translate('schedule.jobInformation.buttons.goToProject');
    this.i18n.startDateRequired = this.i18nService.translate('schedule.errors.startDateRequired');
    this.i18n.invalidDateFormat = this.i18nService.translate('schedule.errors.invalidDateFormat');
    this.i18n.startDateAfterEnd = this.i18nService.translate('schedule.errors.startDateAfterEnd');
    this.i18n.startTimeLabel = this.i18nService.translate('schedule.formFields.startTime.label');
    this.i18n.endDatePlaceholder = this.i18nService.translate('schedule.formFields.endDate.placeholder');
    this.i18n.endDateLabel = this.i18nService.translate('schedule.formFields.endDate.label');
    this.i18n.endDateRequired = this.i18nService.translate('schedule.errors.endDateRequired');
    this.i18n.endDateBeforeStart = this.i18nService.translate('schedule.errors.endDateBeforeStart');
    this.i18n.endTimeLabel = this.i18nService.translate('schedule.formFields.endTime.label');
    this.i18n.durationLabel = this.i18nService.translate('schedule.jobInformation.duration');
    this.i18n.dayLabel = this.i18nService.translate('schedule.moreInfo.dayLabel');
    this.i18n.daysLabel = this.i18nService.translate('schedule.moreInfo.daysLabel');
    this.i18n.hourLabel = this.i18nService.translate('schedule.moreInfo.hourLabel');
    this.i18n.hoursLabel = this.i18nService.translate('schedule.moreInfo.hoursLabel');
    this.i18n.minutesLabel = this.i18nService.translate('schedule.moreInfo.minutesLabel');
    this.i18n.addTradesPersonBtn = this.i18nService.translate('schedule.moreInfo.addTradesPersonBtn');
    this.i18n.selectEngineerPlaceholder = this.i18nService.translate('schedule.moreInfo.selectEngineerPlaceholder');
    this.i18n.confirmBtn = this.i18nService.translate('common.confirm');
    this.i18n.cancelBtn = this.i18nService.translate('common.cancel');
    this.i18n.closeBtn = this.i18nService.translate('common.close');
    this.i18n.provisionallyScheduleBtn = this.i18nService.translate('schedule.moreInfo.provisionallyScheduleBtn');
    this.i18n.scheduleJobLabel = this.i18nService.translate('schedule.moreInfo.scheduleJobLabel');
    this.i18n.jobHasBeenScheduledLabel = this.i18nService.translate('schedule.moreInfo.jobHasBeenScheduledLabel');
    this.i18n.setLeadEngineerBtn = this.i18nService.translate('schedule.moreInfo.setLeadEngineerBtn');
    this.i18n.removeEngineerBtn = this.i18nService.translate('schedule.moreInfo.removeEngineerBtn');
    this.i18n.freeTextFilterPlaceholder = this.i18nService.translate('schedule.jobDisplay.freeTextFilterPlaceholder');
    this.i18n.rescheduleReasonInputPlaceholder = this.i18nService.translate(
      'schedule.moreInfo.rescheduleReasonInputPlaceholder'
    );
    this.i18n.rescheduleReasonInputLabel = this.i18nService.translate('schedule.moreInfo.rescheduleReasonInputLabel');
    this.i18n.engineerRequiredError = this.i18nService.translate('schedule.moreInfo.engineerRequiredError');
    this.i18n.timeIsInvalid = this.i18nService.translate('schedule.moreInfo.timeIsInvalid');
    this.i18n.i18nTimeNotWithinJob = this.i18nService.translate('schedule.moreInfo.timeNotWithinJob');
    this.i18n.toggleSelectedJobHint = this.i18nService.translate('schedule.moreInfo.toggleHint');
    this.i18n.checkCollisionsBtn = this.i18nService.translate('schedule.moreInfo.checkCollisionsBtn');
    this.i18n.addSpecificTimeBtn = this.i18nService.translate('schedule.moreInfo.addSpecificTimeBtn');
  }

  private async initDataLists(): Promise<void> {
    await this.fetchEngineers();
  }

  isoStringToDateStruct(isoString: string): NgbDateStruct {
    if (isoString) {
      const date = new Date(isoString);
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      };
    }
  }

  isoStringToTime(isoString: string): string {
    if (isoString) {
      const date = new Date(isoString);
      const hrs = pad(date.getHours());
      const mins = pad(date.getMinutes());
      return this.timeSlots.find(slot => slot.id === hrs + mins).name;
    }
  }

  handleDateTimeChange(type: DateChangeType): void {
    if (this.selectedJobDetailsForm.get('fullJob').invalid) {
      return;
    }
    const fullJobForm = this.selectedJobDetailsForm.get('fullJob') as FormGroup;
    if (type === 'start') {
      const startDate = fullJobForm.get('startDate').value;
      const startTime = fullJobForm.get('startTime').value;
      const start = JumptechDate.from({
        year: startDate.year,
        month: startDate.month,
        day: startDate.day,
        hour: startTime.split(':')[0],
        minute: startTime.split(':')[1]
      }).toIso();
      fullJobForm.patchValue({ startIso: start, lastChange: type });
    } else {
      const endDate = fullJobForm.get('endDate').value;
      const endTime = fullJobForm.get('endTime').value;
      const end = JumptechDate.from({
        year: endDate.year,
        month: endDate.month,
        day: endDate.day,
        hour: endTime.split(':')[0],
        minute: endTime.split(':')[1]
      }).toIso();
      fullJobForm.patchValue({ endIso: end, lastChange: type });
    }
    // todo fix the below
    // if (!startDate || !endDate || !startTime || !endTime) {
    //   this.selectedJobDetailsForm.patchValue({ startIso: null, endIso: null });
    //   this.selectedJobDetails$.next(this.selectedJobDetailsDm);
    //   return;
    // }

    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  handleSlotDateTimeChange(slotDateChangeEvent: DateTimeChangeEvent): void {
    // todo maybe we can have a single dateTime change handler
    const slotForm = (
      this.selectedJobDetailsDm.form.get(slotDateChangeEvent.tradespersonId).get('slots') as FormArray
    ).at(slotDateChangeEvent.index);

    if (slotForm.invalid) {
      return;
    }

    const startDate = slotForm.get('startDate').value;
    const endDate = slotForm.get('endDate').value;
    const startTime = slotForm.get('startTime').value;
    const endTime = slotForm.get('endTime').value;

    const startIso = JumptechDate.from({
      year: startDate.year,
      month: startDate.month,
      day: startDate.day,
      hour: startTime.split(':')[0],
      minute: startTime.split(':')[1]
    }).toIso();
    const endIso = JumptechDate.from({
      year: endDate.year,
      month: endDate.month,
      day: endDate.day,
      hour: endTime.split(':')[0],
      minute: endTime.split(':')[1]
    }).toIso();

    slotForm.patchValue({ startIso, endIso, lastChange: slotDateChangeEvent.type });
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  checkCollisions(): void {
    this.selectedJobDetailsDm = { ...this.selectedJobDetailsDm, collisionCheckInProgress: true };
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);

    // mock collision check API call
    setTimeout(() => {
      // no collisions detected
      this.selectedJobDetailsDm = {
        ...this.selectedJobDetailsDm,
        collisionCheckInProgress: false,
        collisions: [],
        enableCollisionCheck: false
      };
      this.selectedJobDetails$.next(this.selectedJobDetailsDm);
    }, 2000); // todo
  }

  async scheduleJob(): Promise<void> {
    const payload: SchedulePayloadPostDto = {
      id: this.selectedJobDetailsForm.get('id').value,
      start: this.selectedJobDetailsForm.get('startIso').value,
      end: this.selectedJobDetailsForm.get('endIso').value,
      jobType: this.selectedJobDetailsDm.type,
      jobAssignments: this.selectedJobDetailsDm.jobAssignments,
      rescheduleReason: this.selectedJobDetailsForm.get('rescheduleReason')?.value ?? ''
    };

    this.selectedJobDetails$.next({ ...this.selectedJobDetailsDm, scheduleInProgress: true });
    try {
      const headers = {
        'x-jt-project-id': this.selectedJobDetailsDm.projectId
      };
      await this.gateway.post(
        `${environment.apiProjectUrl}/${this.selectedJobDetailsDm.projectId}/schedule`,
        payload,
        headers,
        { ignoreState: 'true' }
      );
      this.toasterService.pop('success', this.i18n.scheduleJobLabel, this.i18n.jobHasBeenScheduledLabel);
      await this.optimisticUpdateJobsList(this.selectedJobDetailsDm.id);
      this.closeJobDetails(true, { ...payload, projectId: this.selectedJobDetailsDm.projectId });
      this.modalService.dismissAll();
    } catch (e) {
      this.handleErrors(ErrorType.scheduleJob, e);
    }
  }

  async optimisticUpdateJobsList(jobId): Promise<void> {
    if (this.cachedDm.jobs && this.cachedDm.jobs.length) {
      const updated = { ...this.cachedDm, jobs: this.dm?.jobs?.filter(j => j.id !== jobId) };
      this.rsj$.next(updated);
      await this.loadReadyToScheduleJobs(false, jobId);
    }
  }

  goToProject(id?: string): void {
    const projectId: string = id ?? this.selectedJobDetailsDm.projectId;
    this.modalService.dismissAll();
    this.router.navigate([`/project/${projectId}`]).catch(console.log);
  }
}
