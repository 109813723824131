<div class="schedule-filters-modal-wrapper">
  <span>
    <i
      class="material-icons schedule-filters-modal-wrapper__close"
      [attr.data-qa]="'closeFilterBtn'"
      (click)="popoverClosed.emit()"
      >clear</i
    >
  </span>
  <div class="schedule-filters">
    <div class="schedule-filters__item">
      <label for="projectTypes" [attr.data-qa]="'projectTypesLabel'">{{ 'common.jobTypes' | transloco }}</label>
      <app-multi-select
        id="projectTypes"
        *ngIf="filterInfo.jobTypes?.length"
        [items]="filterInfo.jobTypes"
        [selectedItems]="filterInfo.selectedJobTypes"
        placeholder="{{ 'common.showAll' | transloco }}"
        [singleSelection]="false"
        [itemsShowLimit]="1"
        (itemsChanged)="jobTypesSelected.emit($event)"
        [attr.data-qa]="'projectTypes'"
      >
      </app-multi-select>
    </div>
    <div class="schedule-filters__item">
      <label for="scheduleEventTypeList" [attr.data-qa]="'scheduleEventTypesLabel'">{{
        'schedule.absenceTypes' | transloco
      }}</label>
      <app-multi-select
        id="scheduleEventTypeList"
        *ngIf="filterInfo.scheduleEventTypes?.length"
        [items]="filterInfo.scheduleEventTypes"
        [selectedItems]="filterInfo.selectedScheduleEventTypes"
        placeholder="{{ 'common.showAll' | transloco }}"
        [singleSelection]="false"
        [itemsShowLimit]="1"
        (itemsChanged)="absenceTypesSelected.emit($event)"
        [attr.data-qa]="'scheduleEventTypes'"
      >
      </app-multi-select>
    </div>
    <div class="schedule-filters__item">
      <label for="resources" [attr.data-qa]="'resourcesLabel'">{{ 'common.tradespeople' | transloco }}</label>
      <app-multi-select
        id="resources"
        *ngIf="filterInfo.tradesmen"
        [items]="filterInfo.tradesmen"
        [selectedItems]="filterInfo.selectedTradespeople"
        placeholder="{{ 'common.showAll' | transloco }}"
        [singleSelection]="false"
        [itemsShowLimit]="1"
        (itemsChanged)="engineersSelected.emit($event)"
        [attr.data-qa]="'resources'"
      >
      </app-multi-select>
    </div>
    <div class="schedule-filters__item">
      <label for="jobStatuses" [attr.data-qa]="'jobStatusesLabel'">{{ 'schedule.status' | transloco }}</label>

      <app-multi-select
        id="jobStatuses"
        *ngIf="filterInfo.jobStatuses"
        [items]="filterInfo.jobStatuses"
        [selectedItems]="filterInfo.selectedJobStatuses"
        placeholder="{{ 'common.showAll' | transloco }}"
        [singleSelection]="false"
        [itemsShowLimit]="1"
        (itemsChanged)="jobStatusesSelected.emit($event)"
        [attr.data-qa]="'jobStatuses'"
      >
      </app-multi-select>
    </div>
  </div>
</div>
