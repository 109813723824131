import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { IStandardDataVm } from '../../../domain';
import { StandardQuotesRepository } from '../../standard-quotes.repository';

@Injectable()
export class StandardQuotePresenter {
  constructor(private repository: StandardQuotesRepository, private translocoService: TranslocoService) {}

  async load(callback) {
    const vm: IStandardDataVm = {
      title: this.translocoService.translate('standardData.quotes.modalTitle'),
      nameLabel: this.translocoService.translate('standardData.quotes.name'),
      projectTypeLabel: this.translocoService.translate('standardData.quotes.projectType'),
      item: null,
      projectTypes: [],
      saveLabel: this.translocoService.translate('common.save'),
      closeLabel: this.translocoService.translate('common.cancel'),
      nameValid: true,
      projectTypeValid: true,
      saveDisabled: true,
      formStatus: 'VALID'
    };
    await this.repository.getStandardQuote(async result => {
      vm.item = result;
      this.validateModel(vm);
    });
    await this.repository.listProjectTypes(results => {
      vm.projectTypes = results.map(x => ({ id: x, name: x }));
    });
    await callback(vm);
  }

  changeName(name: string) {
    this.repository.changeStandardQuoteName(name);
  }

  async changeProjectType(projectType: string) {
    await this.repository.changeStandardQuoteProjectType(projectType);
  }

  async changeData(data) {
    await this.repository.changeData(data);
  }

  async actionClicked(vm: IStandardDataVm) {
    if (!vm.saveDisabled) {
      await this.repository.saveOrUpdateStandardQuote(vm.item);
    }
  }

  closeModal() {
    this.repository.closeModal();
  }

  private validateModel(vm: IStandardDataVm) {
    if (!vm?.item) {
      return;
    }
    vm.nameValid = !!vm.item.name;
    vm.projectTypeValid = !!vm.item.projectType;
    vm.saveDisabled = !vm.nameValid || !vm.projectTypeValid || vm.formStatus !== 'VALID';
  }
}
