import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { JumptechCardsLibModule } from '@jump-tech-frontend/cards';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ScheduleMoreInfoVm } from '../../schedule.model';

@Component({
  selector: 'schedule-event-more-details-reschedule-reason-form',
  templateUrl: 'schedule-event-more-details-reschedule-reason-form.component.html',
  styleUrls: ['../schedule-event-more-details/schedule-event-more-details.component.scss'],
  standalone: true,
  imports: [
    CoreComponentsAngularModule,
    JumptechCardsLibModule,
    NgIf,
    NgSelectModule,
    NgbDatepickerModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule
  ]
})
export class ScheduleEventMoreDetailsRescheduleReasonFormComponent {
  @Input() vm: ScheduleMoreInfoVm;
  constructor() {}
}
