import { Injectable } from '@angular/core';
import { EnaRepository } from '../ena.repository';
import { BehaviorSubject } from 'rxjs';
import { EnaError } from '../ena.model';

@Injectable()
export class EnaApplicationErrorsPresenter {
  constructor(private repository: EnaRepository) {}

  load(vmSubject$: BehaviorSubject<EnaError>): void {
    this.repository.getErrors((dataDm: EnaError) => {
      const viewModel = {
        ...dataDm
      };
      vmSubject$.next(viewModel);
    });
  }

  clearErrors(): void {
    this.repository.clearErrors();
  }
}
