import { Injectable } from '@angular/core';
import { TasksRepository } from '../../project-detail/tasks/tasks.repository';
import { ScheduleJobActionVm } from '../../schedule/utils/schedule-types';
import { TaskForm } from '../../project-detail/tasks/task.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ProjectScheduleActionsPresenter {
  constructor(private repository: TasksRepository) {}

  load(vmSubject$: BehaviorSubject<ScheduleJobActionVm[]>): void {
    this.repository.loadScheduleV2Actions((dm: TaskForm[]) => {
      let viewModel: ScheduleJobActionVm[] = null;
      if (dm) {
        viewModel = dm.map((tf): ScheduleJobActionVm => {
          const jobData = tf.model.project.jobs.find(j => j.type === tf.task.jobType);
          const jobAddress = tf.model.project.data.address;
          const isReschedule = tf.model.id.includes('_reschedule');
          return {
            actionId: tf.model.id ?? null,
            modalTitleActionLabel: `${tf.model.actionLabel}`,
            modalTitle: `${tf.task.jobType} | ${jobData?.firstName} ${jobData?.lastName}`,
            label: tf.task.label,
            isReschedule,
            visible: tf.model.visible,
            enabled: tf.model.enabled,
            qaButtonSchedule: isReschedule ? 'projectDetailsRescheduleButton' : 'projectDetailsScheduleButton',
            selectedJob: {
              customerFirstName: jobData?.firstName,
              customerLastName: jobData?.lastName,
              jobType: tf.task.jobType,
              label: tf.task.label,
              id: tf.task.id,
              ...jobData,
              projectId: tf.model.project.id,
              address: jobAddress,
              start: jobData?.startDate ?? '',
              end: jobData?.endDate ?? '',
              jobAssignments: jobData?.jobAssignments ?? [],
              defaultDuration: jobData?.defaultDuration ?? 2
            }
          };
        });
      }
      vmSubject$.next(viewModel);
    });
  }

  openScheduleModal(vm: ScheduleJobActionVm): void {
    this.repository.openScheduleV2ActionModal(vm);
  }
}
