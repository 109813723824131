<div class="card-wrapper">
  <div class="card__title-header">
    <div class="card__title">{{ card.label }}</div>
    <div class="card__step">{{ card.stepProgress }}</div>
  </div>

  <div class="card__description">
    <p class="card-text" [innerHTML]="card.description" [attr.data-qa]="'description'"></p>
    <div *ngIf="images && images[0] && images[0].type" [ngSwitch]="images[0].type">
      <div class="icon-speech-wrapper">
        <div *ngSwitchCase="imageAssetType.ICON">
          <i (click)="clickFile()" [class]="'material-icons ' + images[0].size">
            {{ images[0].getSrc() }}
          </i>
          <span class="speech-bubble">{{ i18ns.clickHere }}</span>
        </div>
        <img *ngSwitchCase="imageAssetType.S3" class="asset-image" [src]="images[0].getSrc()" />
        <img *ngSwitchCase="imageAssetType.S3_FORM" class="asset-image" [src]="images[0].getSrc()" />
        <img *ngSwitchCase="imageAssetType.LOCAL" class="section-image" [src]="images[0].getSrc()" />
      </div>
    </div>

    <input class="imageCapture" type="file" (change)="setFromImage($event)" accept="image/*" />

    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" type="line-scale" [attr.data-qa]="'spinner'">
    </ngx-spinner>
  </div>
</div>
<div class="form-wrapper">
  <form *ngIf="form" [formGroup]="form">
    <crds-questions [form]="form" [card]="card" [i18ns]="i18ns"></crds-questions>
  </form>
  <question-form-error
    *ngIf="uploadError"
    [message]="uploadError"
    [i18ns]="i18ns"
    class="file-upload-error"
  ></question-form-error>
</div>
