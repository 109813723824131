<ng-container *ngIf="modalPending$ | async">
  <ng-container *ngIf="vm$ | async as vm">
    <ng-container *ngIf="vm.form" [formGroup]="vm.form">
      <div class="question-modal">
        <div class="question-modal__body">
          <h4 *ngIf="vm.label" class="question-modal__title" [attr.for]="vm.key" [attr.data-qa]="vm.key">
            {{ vm.label }}
          </h4>
          <p class="question-modal__copy">
            {{ vm.content }}
          </p>
          <p class="question-modal__copy">
            {{ vm.hint }}
          </p>
          <jui-button size="xl" expand="true" (click)="acceptModal()" class="question-modal__action">
            {{ vm.actionLabel }}
          </jui-button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
