import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpAuthInterceptor } from './http-auth.interceptor.service';
import { HttpCancelInterceptor } from './http-cancel-pending.interceptor.service';
import { HttpErrorInterceptor } from './http-error.interceptor.service';
import { HttpBranchHeaderInterceptor } from './http-branch-header-interceptor.service';
import { HttpRetryBackoffInterceptor } from './http-retry-backoff.interceptor.service';

export const interceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpCancelInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpBranchHeaderInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpAuthInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpRetryBackoffInterceptor,
    multi: true
  }
];
