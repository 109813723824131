import { JumptechDateSettings, RendererInterface } from '@jump-tech-frontend/domain';
import { createRenderer, Renderer } from '@jump-tech/lib-mustache-renderer';

export class MoustacheRender implements RendererInterface {
  public constructor(
    private readonly moustacheRenderer: Renderer = createRenderer({
      timezone: JumptechDateSettings.defaultTimeZone,
      locale: JumptechDateSettings.defaultLocale
    })
  ) {}

  public render(template: string, context: unknown): string {
    return this.moustacheRenderer.render(template, context);
  }
}
