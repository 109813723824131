import { NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { FullCalendarComponent, FullCalendarModule } from '@fullcalendar/angular';
import { Calendar, CalendarOptions, EventInput, ToolbarInput, ViewMountArg } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import luxonPlugin from '@fullcalendar/luxon3';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import timeGridPlugin from '@fullcalendar/timegrid';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { JumptechDate } from '@jump-tech-frontend/domain';
import {
  NgbDatepickerModule,
  NgbDateStruct,
  NgbModal,
  NgbModalRef,
  NgbPopover,
  NgbPopoverModule
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, filter, Observable, Subscription, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as Analytics from '../../app.analytics';
import { UserService } from '../../auth/services/user.service';
import { ApiService } from '../../core/api.service';
import { Job, JobAssignment } from '../../core/domain/job';
import { User } from '../../core/domain/user';
import { FeatureFlagService } from '../../core/feature-flag/feature-flag.service';
import { HttpGateway } from '../../core/http-gateway.service';
import { JobTypeService } from '../../core/job-type.service';
import { LocalStorageGateway } from '../../core/local-storage-gateway.service';
import { IScheduleModalResult } from '../../feature-modules/planning-map/planning-map.model';
import { DropDownElement } from '../../shared/form-components/multiple-selection-dropdown.component';
import { ScheduleErrorsComponent } from './components/schedule-errors/schedule-errors.component';
import { ScheduleErrorsPresenter } from './components/schedule-errors/schedule-errors.presenter';
import { ScheduleEventModalComponent } from '../components/schedule-event-modal/schedule-event-modal.component';
import { ScheduleEventTooltipComponent } from './components/schedule-event-tooltip/schedule-event-tooltip.component';
import { ScheduleJobsDisplayComponent } from './components/schedule-jobs-display/schedule-jobs-display.component';
import { ScheduleJobsDisplayPresenter } from './components/schedule-jobs-display/schedule-jobs-display.presenter';
import { ScheduleKeyComponent } from '../components/schedule-key/schedule-key.component';
import { ErrorType } from '../schedule.model';
import {
  JOB_STATUSES_V2,
  JOB_STYLES,
  JT_EVENT,
  JT_EVENT_BG,
  JT_EVENT_PREASSIGNMENT,
  JT_EVENT_SELECTED,
  JT_EVENT_TEMP,
  SCHEDULE_VIEWS,
  SELECTABLE_CAL_TYPES,
  TEMP_EVENT_ID
} from '../utils/schedule-constants';
import { SchedulePresenter } from './schedule.presenter';
import { ScheduleService } from './services/schedule.service';
import {
  CalendarEventV3,
  EventInfo,
  JobEvent,
  JobInformation,
  Resource,
  ResourceInfo,
  ScheduleFilterInformation,
  ScheduleTypeListItem,
  SelectedEventType,
  SelectedScheduleFilters
} from './utils/schedule-types';
import {
  generateEventPreAssignment,
  generateScheduleEvent,
  getStyleForStatus,
  mapLegacyStatusesToNewStatuses,
  standardCalendarConfiguration
} from './utils/schedule.helper';
import { ScheduleFiltersPopoverComponent } from './components/schedule-filters-popover/schedule-filters-popover.component';
import { ScheduleEventSelectedJobDetailsComponent } from './components/schedule-event-selected-job-details/schedule-event-selected-job-details.component';
import { ScheduleEventSelectedJobDetailsToggleComponent } from './components/schedule-event-selected-job-details/components/schedule-event-selected-job-details-toggle/schedule-event-selected-job-details-toggle.component';
import { TradesPersonSlot } from './schedule.model';
import { JT_EVENT_PARTIAL } from './utils/schedule-constants';

const selectedFiltersKey = `selectedScheduleFilters-${environment.name}`;

interface ActiveCalendarElements {
  resourceId: string;
  eventId?: string;
  isPartial?: boolean;
  el: HTMLElement;
}

@Component({
  selector: 'schedule-v3',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  standalone: true,
  imports: [
    FullCalendarModule,
    NgxSpinnerModule,
    TranslocoModule,
    NgbDatepickerModule,
    NgbPopoverModule,
    ScheduleKeyComponent,
    ScheduleFiltersPopoverComponent,
    ScheduleEventModalComponent,
    ScheduleJobsDisplayComponent,
    ScheduleErrorsComponent,
    CoreComponentsAngularModule,
    NgSelectModule,
    FormsModule,
    NgIf,
    ScheduleErrorsComponent,
    ScheduleEventSelectedJobDetailsComponent,
    ScheduleEventSelectedJobDetailsToggleComponent
  ],
  providers: [ScheduleErrorsPresenter, SchedulePresenter]
})
export class ScheduleV3Component implements OnInit, OnDestroy {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild('eventTooltip') eventTooltip: ScheduleEventTooltipComponent;
  @ViewChild('p') filterPopover: NgbPopover;
  @ViewChild('viewSelect') viewSelectDropdown: NgSelectComponent;
  @Input() selectedJob: Job;
  @Input() actionId: string;
  @Input() context: string;
  @Output() scheduleSuccess: EventEmitter<IScheduleModalResult> = new EventEmitter<IScheduleModalResult>();

  calendarApi: Calendar;
  scheduleEventTypeList: ScheduleTypeListItem[];
  calendarEvents: EventInput[] = [];
  transientEvents: EventInput[] = [];
  activeScheduleEvent: EventInput = null;
  activeScheduleEventEl: ActiveCalendarElements[] = [];
  jobStatuses: DropDownElement[];
  jobStatusesV2: DropDownElement[];
  selectedEngineers: Resource[] = [];
  allEngineers: Resource[] = [];
  engineersForDropdown: DropDownElement[];
  scheduleViews: DropDownElement[] = [];
  defaultScheduleView: DropDownElement;
  selectedEventId: string;
  selectedEventType: SelectedEventType;
  selectedEventElements: Element[];
  eventStateBeforeEdit: EventInfo;
  // timers
  intervalEventRescheduleRetry: NodeJS.Timeout;
  timerRescheduleJob: NodeJS.Timeout;
  timerTooltipI18n: NodeJS.Timeout;
  timerRefreshSelectedJobDetails: NodeJS.Timeout;
  timerSelectedLinkedEvents: NodeJS.Timeout;
  timerLoadWeekView: NodeJS.Timeout;
  timerSelectedJobDetailsClosedSelectedEvent: NodeJS.Timeout;
  timerSelectedJobDetailsClosed: NodeJS.Timeout;
  timerSelectedJobDetailsSuccessClosed: NodeJS.Timeout;
  timerPopover: NodeJS.Timeout;

  CALENDAR_EVENT_REFRESH_DELAY = 4500;
  CALENDAR_EVENT_RESCHEDULE_SELECT_DELAY = 1000;
  CALENDAR_EVENT_RESCHEDULE_SELECT_RETRIES = 5;
  CALENDAR_EVENT_SELECT_LINKED_DELAY = 1500;
  selectedJobEventRendered = false;

  currentView = '';
  viewTitle = '';
  activeFilters = 0;
  loadReadyToScheduleJobs = false;
  provisionalSchedulingEnabled = false;
  calendarOptionsReady = false;
  tenantTimezone: string;
  tenant: string;
  isSelectable = false;
  selectLinkedEventsInProgress = false;
  calendarConfig: CalendarOptions = {
    ...JSON.parse(JSON.stringify(standardCalendarConfiguration)),
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin,
      resourceTimeGridPlugin,
      resourceTimelinePlugin,
      luxonPlugin
    ],
    droppable: true,
    schedulerLicenseKey: '0173554956-fcs-1657206500',
    initialView: 'resourceTimelineRollingMonth',
    resources: this.allEngineers,
    resourceAreaHeaderDidMount: this.handleResourceHeader.bind(this),
    resourceLabelDidMount: this.handleResourceRender.bind(this),
    viewDidMount: this.handleViewMount.bind(this),
    events: this.calendarEvents,
    height: 'auto',
    selectable: this.isSelectable,
    eventDidMount: this.handleEventRender.bind(this),
    eventResize: this.handleEventResize.bind(this),
    eventDrop: this.handleEventDrag.bind(this),
    eventDragStart: this.handleEventDragStart.bind(this),
    eventDragStop: this.handleEventDragStop.bind(this),
    eventReceive: this.handleEventReceive.bind(this),
    eventResizeStart: this.handleEventResizeStart.bind(this),
    eventResizeStop: this.handleEventResizeStop.bind(this),
    datesSet: this.handleDateChange.bind(this),
    dateClick: this.handleDateClick.bind(this),
    eventClick: this.handleEventClick.bind(this),
    moreLinkClick: this.handleMoreLinkClick.bind(this)
  };

  selectedJobStatuses: string[] = [];
  selectedAbsenceTypes: string[] = [];
  selectedResources: string[] = [];

  scheduleEventModalSubs: Subscription[] = [];
  pollingEvents: Subscription;
  userSub: Subscription;
  featuresSub: Subscription;
  filtersLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  filtersLoaded$ = this.filtersLoaded.asObservable();
  user$: Observable<User>;
  toolTipTranslations: { [key: string]: string };
  hasPromotedEvent = false;

  constructor(
    public jobTypeService: JobTypeService,
    private apiService: ApiService,
    private userService: UserService,
    private scheduleService: ScheduleService,
    private spinnerService: NgxSpinnerService,
    private cdRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private translateService: TranslocoService,
    private featureFlagService: FeatureFlagService,
    private localStorageGateway: LocalStorageGateway,
    private httpGateway: HttpGateway,
    private presenter: SchedulePresenter,
    private sjdPresenter: ScheduleJobsDisplayPresenter,
    // private sjvPresenter: ScheduleJobViewPresenter,
    protected router: Router
  ) {}

  /**
   * t(list.scheduleEventType.appointment, list.scheduleEventType.holiday, list.scheduleEventType.other, list.scheduleEventType.sickness)
   */
  async ngOnInit() {
    this.provisionalSchedulingEnabled = await this.featureFlagService.isFeatureEnabled('provisional-scheduling');
    this.setPreviouslySelectedFilters();

    this.loadReadyToScheduleJobs = this.context !== 'project';
    (this.calendarConfig.headerToolbar as ToolbarInput).right =
      'resourceTimelineRollingMonth,dayGridMonth,resourceTimelineWeek,resourceTimelineDay';
    this.calendarConfig.slotDuration = { days: 1 };
    this.calendarConfig.slotLabelInterval = { days: 1 };
    this.calendarOptionsReady = true;
    this.cdRef.detectChanges();

    if (this.isRescheduleJob()) {
      this.calendarConfig.initialView = 'resourceTimeLineWeek';
    }

    this.jobStatuses = Object.entries(JOB_STYLES).map(e => ({
      id: e[0],
      name: this.translateService.translate(e[1].key)
    }));
    this.jobStatusesV2 = this.provisionalSchedulingEnabled
      ? Object.entries(JOB_STATUSES_V2).map(status => ({
          id: status[0],
          name: this.translateService.translate(status[1].key)
        }))
      : Object.entries(JOB_STATUSES_V2)
          .filter(s => s[0] !== 'PROVISIONALLY_SCHEDULED')
          .map(status => ({
            id: status[0],
            name: this.translateService.translate(status[1].key)
          }));
    this.userSub = this.userService.userObservable.pipe(filter(user => user !== null)).subscribe(async user => {
      this.tenantTimezone = user.accessInfo?.configuration?.timezone;
      await this.fetchFilters(user.tenant);
    });
    this.featuresSub = this.featureFlagService.featureFlag$.pipe().subscribe(async () => {
      this.provisionalSchedulingEnabled = await this.featureFlagService.isFeatureEnabled('provisional-scheduling');
      this.loadReadyToScheduleJobs = this.context !== 'project';
    });

    await this.getSelectedJobInformation(this.selectedJob);
    this.getTranslationsForTooltip();
    this.calendarConfig.views.resourceTimelineWeek.buttonText = this.translateService.translate('common.week');
    this.calendarConfig.views.resourceTimelineRollingMonth.buttonText =
      this.translateService.translate('common.rollingMonth');
    this.calendarApi = this.calendarComponent.getApi();
    this.generateFilterData();
    this.updateActiveFilterAmount();
    this.setupViewDropdownElements();
    this.presenter.setSelectedJob(this.selectedJob);

    if (this.isRescheduleJob()) {
      await this.setupRescheduleJob();
    } else {
      this.calendarApi.gotoDate(JumptechDate.now().startOf('week').toIso());
    }
    this.viewTitle = this.calendarApi.view.title;
    this.overrideTodayButton();
  }

  isRescheduleJob(): boolean {
    return this.selectedJob?.jobAssignments?.length > 0 || !!this.selectedJob?.startDate;
  }

  private async setupRescheduleJob(): Promise<void> {
    this.defaultScheduleView = this.scheduleViews.find(view => view.id === 'week');
    this.loadWeekView(this.selectedJob.startDate);

    // build job information
    const jobInfo: JobInformation = {
      ...this.selectedJob,
      id: this.selectedJob.id,
      actionId: this.actionId ?? null,
      isInitialSchedule: this.selectedJob.isInitialSchedule,
      projectId: this.selectedJob.projectId,
      type: this.selectedJob.type,
      customerFirstName: this.selectedJob.firstName,
      customerLastName: this.selectedJob.lastName,
      contactInfo: {
        email: this.selectedJob.email,
        telephoneNumber: this.selectedJob.phoneNumber
      },
      jobAssignments: this.selectedJob.jobAssignments,
      startDateTimestamp: this.selectedJob.startDate,
      endDateTimestamp: this.selectedJob.endDate,
      tenantType: this.selectedJob.tenantType
    };
    let selectedEvent;

    if (this.selectedJob.jobAssignments.length > 1) {
      // build event id if we are have multiple assingments
      let eventId = `${this.selectedJob.linkId ?? this.selectedJob.id}--${
        this.selectedJob.jobAssignments[0].assignedTo
      }`;
      if (this.selectedJob.jobAssignments[0].assignmentId) {
        eventId = `${eventId}--${this.selectedJob.jobAssignments[0].assignmentId}`;
      }
      this.selectedJob.id = eventId;
    }

    if (this.selectedJobEventRendered) {
      selectedEvent = this.calendarApi.getEventById(this.selectedJob.id);
      await this.openEventForRescheduling(selectedEvent, jobInfo);
    } else {
      let retryAmount = 0;
      this.intervalEventRescheduleRetry = setInterval(async () => {
        if (this.selectedJobEventRendered) {
          selectedEvent = this.calendarApi.getEventById(this.selectedJob.id);
          await this.openEventForRescheduling(selectedEvent, jobInfo);
          clearInterval(this.intervalEventRescheduleRetry);
        } else {
          retryAmount++;
          if (retryAmount === this.CALENDAR_EVENT_RESCHEDULE_SELECT_RETRIES) {
            this.presenter.showError(
              ErrorType.selectRescheduleJob,
              new Error('Unable to select calendar event for reschedule')
            );
            clearInterval(this.intervalEventRescheduleRetry);
          }
        }
      }, this.CALENDAR_EVENT_RESCHEDULE_SELECT_DELAY);
    }
  }

  ngOnDestroy(): void {
    this.stopPolling();
    // timers
    clearInterval(this.intervalEventRescheduleRetry);
    clearTimeout(this.timerRescheduleJob);
    clearTimeout(this.timerTooltipI18n);
    clearTimeout(this.timerRefreshSelectedJobDetails);
    clearTimeout(this.timerLoadWeekView);
    clearTimeout(this.timerSelectedJobDetailsClosedSelectedEvent);
    clearTimeout(this.timerSelectedJobDetailsSuccessClosed);
    clearTimeout(this.timerSelectedJobDetailsClosed);
    clearTimeout(this.timerPopover);
    clearTimeout(this.timerSelectedLinkedEvents);
    // subscriptions
    this.scheduleEventModalSubs.map(x => x?.unsubscribe);
    this.userSub?.unsubscribe();
    this.featuresSub?.unsubscribe();
  }

  async openEventForRescheduling(event: EventImpl, jobInfo: JobInformation): Promise<void> {
    const evtInfo = { event } as unknown as EventInfo;

    await this.handleEventClick(evtInfo);
    // update jobInfo
    jobInfo.startDateTimestamp = evtInfo.event.extendedProps.fullJobStart ?? evtInfo.event.extendedProps.startDate;
    jobInfo.endDateTimestamp = evtInfo.event.extendedProps.fullJobEnd ?? evtInfo.event.extendedProps.endDate;
    jobInfo.jobAssignments = evtInfo.event.extendedProps.jobAssignments;
    jobInfo.context = this.context;
    this.presenter.openScheduleDetails(jobInfo, this.handleEventSelectedJobDetailsChange.bind(this), evtInfo);
  }

  async getSelectedJobInformation(selectedJob?: Job): Promise<void> {
    if (!selectedJob) {
      return;
    }

    try {
      const res = (
        await this.httpGateway.get(`${environment.apiJobsUrl}`, {
          jobId: selectedJob.id
        })
      ).results as Job[];
      this.selectedJob.defaultDuration = res[0].defaultDuration;
    } catch (e) {
      this.selectedJob.defaultDuration = 2;
    }
  }

  getTranslationsForTooltip() {
    this.timerTooltipI18n = setTimeout(() => {
      this.toolTipTranslations = {
        name: this.translateService.translate('common.name'),
        phone: this.translateService.translate('common.phone'),
        riskAssessor: this.translateService.translate('common.riskAssessor'),
        siteSupervisor: this.translateService.translate('common.siteSupervisor'),
        chargePoint: this.translateService.translate('common.chargePoint'),
        start: this.translateService.translate('common.start'),
        end: this.translateService.translate('common.end'),
        allDay: this.translateService.translate('common.allDay')
      };
    });
  }

  async fetchFilters(tenant: string): Promise<void> {
    const configCalls: [Promise<ScheduleTypeListItem[]>, Promise<void>, Promise<void>] = [
      this.scheduleService.getScheduleEventTypes(tenant),
      this.jobTypeService.fetchJobTypes(),
      this.fetchEngineers()
    ];
    const vals = await Promise.all(configCalls);
    this.scheduleEventTypeList = vals[0];
    this.filtersLoaded.next(true);
  }

  setJobTypes(selected: string[]) {
    this.jobTypeService.setSelectedJobTypes(selected);
    this.updateSelectedFilters('selectedJobTypes', selected);
    this.filterEvents();
    this.updateActiveFilterAmount();
    Analytics.logEvent('CalendarFilter', { jobTypes: selected.join(',') });
  }

  setAbsenceTypes(selected: string[]) {
    this.selectedAbsenceTypes = selected;
    this.updateSelectedFilters('selectedScheduleEventTypes', selected);
    this.filterEvents();
    this.updateActiveFilterAmount();
    Analytics.logEvent('CalendarFilter', { absenceTypes: selected.join(',') });
  }

  setJobStatuses(selected: string[]) {
    const selectedLegacyStatuses = [];
    selected.forEach(status => {
      selectedLegacyStatuses.push(...JOB_STATUSES_V2[status].legacyStatuses);
    });
    this.selectedJobStatuses = selectedLegacyStatuses;
    this.updateSelectedFilters('selectedJobStatuses', selectedLegacyStatuses);
    this.filterEvents();
    this.updateActiveFilterAmount();
    Analytics.logEvent('CalendarFilter', { jobStatus: selected.join(',') });
  }

  setEngineers(selected: string[]) {
    this.selectedResources = selected;
    this.updateSelectedFilters('selectedTradespeople', selected);
    this.selectedEngineers = this.allEngineers.filter(r => {
      return !selected.length ? true : selected.includes(r.id);
    });
    this.calendarConfig.resources = this.selectedEngineers;
    this.filterEvents();
    this.updateActiveFilterAmount();
    Analytics.logEvent('CalendarFilter', { leadEngineers: selected.join(',') });
  }

  addToSchedule() {
    this.openEventModal('add');
  }

  unSubModalEvents() {
    if (this.scheduleEventModalSubs.length) {
      this.scheduleEventModalSubs.forEach(sub => sub.unsubscribe());
      this.scheduleEventModalSubs = [];
    }
  }

  handleEventRender(evt: EventInfo) {
    if (evt.event.display === 'background') {
      this.scheduleService.handleBackgroundEventRender(evt);
      return;
    }

    if (evt.event.extendedProps.jobAssignments && evt.event.extendedProps.isPartial) {
      evt.el.style.backgroundColor = 'hotpink';
    }
    // Handle rendering of resized or dragged events separately
    if (evt.isResizing || evt.isDragging) {
      return;
    }
    if (!evt.event.id) {
      // we must have received an external event, so we need to set the end and temp ID
      this.setExternalEventProps(evt);
    }
    if (evt.event.id === TEMP_EVENT_ID) {
      this.addTempEventResourcesToTransientState(evt);
    }

    // Handle re-render of changed events whose changes we want to disregard
    if (this.eventStateBeforeEdit && this.eventStateBeforeEdit.event.id === evt.event.id) {
      evt.event.setExtendedProp('jobInformation', this.eventStateBeforeEdit.event.extendedProps.jobInformation);
    }
    this.scheduleService.handleEventRender(evt).then();
    if (evt.event.id === this.selectedJob?.id || evt.event.extendedProps?.linkId === this.selectedJob?.id) {
      if (evt.event.extendedProps.linkId) {
        this.selectedJob.linkId = evt.event.extendedProps?.linkId;
      }
      this.selectedJobEventRendered = true;
    }

    if (evt.event.classNames.includes(JT_EVENT_PREASSIGNMENT) && !evt.event.extendedProps.jobAssigned) {
      this.handlePreAssignmentEventRender(evt);
    }

    if (this.selectedEventId) {
      this.handlePreviouslySelectedEvent(evt);
    }
  }

  handlePreviouslySelectedEvent(evt: EventInfo): void {
    if (
      this.selectedEventId &&
      this.selectedEventId === evt.event.extendedProps.linkId &&
      this.selectedEventType === SelectedEventType.link
    ) {
      evt.el.classList.add(JT_EVENT_SELECTED);
      if (!this.selectLinkedEventsInProgress) {
        clearTimeout(this.timerSelectedLinkedEvents);
        this.timerSelectedLinkedEvents = setTimeout(() => {
          this.setSelectedEvent(evt);
          this.selectLinkedEventsInProgress = false;
        }, this.CALENDAR_EVENT_SELECT_LINKED_DELAY);
      }
      this.selectLinkedEventsInProgress = true;
    } else {
      if (this.selectedEventId && this.selectedEventId === evt.event.id) {
        evt.el.classList.add(JT_EVENT_SELECTED);
        this.selectedEventId = evt.event.id;
      }
    }
  }

  setExternalEventProps(evt: EventInfo): void {
    evt.event.setProp('id', TEMP_EVENT_ID);
    const defaultDuration = evt.event.extendedProps.jobInformation.defaultDuration;
    const endDate = JumptechDate.from(evt.event.start).plus({ hours: defaultDuration });
    evt.event.setEnd(endDate.toIso());
    this.addTempEventResourcesToTransientState(evt);
  }

  addTempEventResourcesToTransientState(evt: EventInfo): void {
    const resourcesIdsForEvent = evt.event.getResources().map(r => r.id);
    // todo better to be a hash map
    resourcesIdsForEvent.forEach(id => {
      const currentActiveIds = this.activeScheduleEventEl.map(x => x.resourceId);
      if (!currentActiveIds.includes(id)) {
        this.activeScheduleEventEl.push({ resourceId: id, el: evt.el });
      }
    });
  }

  refreshSelectedJobDetailsEvent(event: EventInput): void {
    // refresh more details pane with updated Event
    this.timerRefreshSelectedJobDetails = setTimeout((): void => {
      const currentEvent = this.calendarApi.getEventById(TEMP_EVENT_ID);
      this.stopPolling();
      // open the details
      this.presenter.openScheduleDetails(
        event.extendedProps.jobInformation,
        this.handleEventSelectedJobDetailsChange.bind(this),
        {
          event: currentEvent ?? this.calendarApi.getEventById(event.id)
        } as any
      );
      this.sjdPresenter.preventDraggableItems();
    });
  }

  /**
   * Called when an event is resized (duration changed)
   * @param evt
   */
  handleEventResize(evt: EventInfo): void {
    this.updateEventDataFromDragResize(evt);
  }

  assignTradespersonToJob(job: JobInformation): void {
    const event = generateEventPreAssignment(
      { ...job },
      this.selectedEngineers[0].id,
      this.currentView !== 'dayGridMonth'
    );
    this.calendarConfig.events = [...this.calendarEvents, ...this.transientEvents, event];
  }

  handlePreAssignmentEventRender(evt: EventInfo): void {
    if (this.selectedEventId) {
      this.unselectEvent();
    }

    // Only open More Details on first click of Assign button
    if (!this.activeScheduleEventEl.length) {
      const eventInput: EventInput = {
        ...evt.event.toPlainObject()
      };

      // Handle case where Month view doesn't have any event resources
      const eventResources = evt.event.getResources();
      if (eventResources.length) {
        eventInput.assignedToDisplayName = eventResources[0].title;
        eventInput.assignedTo = eventResources[0].id;
        eventInput.resourceIds = [eventResources[0].id];
      }

      this.persistTransientEventToCalendar(eventInput);
      const jobInfo: JobInformation = {
        ...eventInput.extendedProps.jobInformation,
        actionId: this.actionId ?? null,
        startDateTimestamp: '',
        endDateTimestamp: '',
        jobAssignments: [],
        setDefaultStartTime: true
      };

      if (evt.el) {
        this.activeScheduleEventEl.push({ resourceId: eventResources.length ? eventResources[0].id : '', el: evt.el });
      }
      this.presenter.openScheduleDetails(jobInfo, this.handleEventSelectedJobDetailsChange.bind(this), evt);
      this.sjdPresenter.preventDraggableItems();
    }
  }

  createBackgroundEvent(evt: any, draggingOrResizing: boolean): void {
    const existingBgEvents = this.calendarEvents.filter(evt => evt.display === 'background');
    let fullJobStart: string;
    let fullJobEnd: string;
    let idParts: string[];

    if (evt.event) {
      idParts = evt.event.id.split('--');
      fullJobStart = evt.event.extendedProps.fullJobStart;
      fullJobEnd = evt.event.extendedProps.fullJobEnd;
    } else {
      idParts = evt.id.split('--');
      fullJobStart = evt.extendedProps.fullJobStart;
      fullJobEnd = evt.extendedProps.fullJobEnd;
    }

    const resId = idParts[1];
    const evtId = idParts[0];

    const alreadyRenderedBg = existingBgEvents.find(existing => {
      return existing.resourceIds.indexOf(resId) !== -1 && existing.extendedProps.id === evtId;
    });

    if (!alreadyRenderedBg) {
      const bgEvent: EventInput = {
        display: 'background',
        resourceIds: [resId],
        start: JumptechDate.from(fullJobStart).toIso(),
        end: JumptechDate.from(fullJobEnd).toIso(),
        backgroundColor: getStyleForStatus('PROVISIONALLY_SCHEDULED').backgroundColor,
        classNames: [JT_EVENT_BG],
        extendedProps: {
          id: evtId,
          dragged: draggingOrResizing
        }
      };
      this.calendarEvents.push(bgEvent);
      this.calendarConfig.events = [...this.calendarEvents];
    }
  }

  moveLinkedJobs(evt: EventInfo): void {
    this.activeScheduleEventEl.forEach(eventEl => {
      const linkedEventId = eventEl.el.getAttribute(`data-eventid`);
      if (linkedEventId !== evt.event.id) {
        const linkedEvent = this.calendarApi.getEventById(linkedEventId);
        linkedEvent.moveDates(evt.delta);
        const update = { eventId: linkedEventId, start: linkedEvent.start, end: linkedEvent.end };
        const eventToUpdate = (this.calendarConfig.events as EventInput[]).find(x => x.id === linkedEventId);
        eventToUpdate.start = update.start;
        eventToUpdate.end = update.end;

        // update constraints for partials
        if (eventToUpdate.constraint) {
          const movedEvent = this.calendarApi.getEventById(evt.event.id);
          eventToUpdate.fullJobStart = movedEvent.startStr;
          eventToUpdate.fullJobEnd = movedEvent.endStr;
          eventToUpdate.constraint = { start: movedEvent.startStr, end: movedEvent.endStr };
        }

        this.calendarConfig.events = [
          ...(this.calendarConfig.events as EventInput[]).filter(x => x?.display !== 'background')
        ];
      }
    });
  }

  updateCalendarEventDateTime(evt: EventInfo): void {
    // find the calendar event and updates to the new date times
    if (evt.event.extendedProps.linkId && evt.delta) {
      this.updateActiveScheduleEventEl(evt.event.extendedProps.linkId);
      // move linked jobs by the same delta amount if we are not a partial
      if (!evt.event.extendedProps.isPartial) {
        this.moveLinkedJobs(evt);
      }
    }

    this.calendarConfig.events = [
      ...(this.calendarConfig.events as EventInput[]).filter(x => x?.display !== 'background')
    ];
    (this.calendarConfig.events as EventInput[]).forEach(event => {
      if (event.id === evt.event.id) {
        event.start = evt.event.start;
        event.end = evt.event.end;
        event.resourceIds = evt.event.getResources().map(x => x.id);
      }
    });
    this.calendarConfig.events = [...this.calendarConfig.events];
  }

  private removeBackgroundEvent(): void {
    // Remove any existing background events
    this.calendarConfig.events = [
      ...(this.calendarConfig.events as EventInput[]).filter(x => x?.display !== 'background')
    ];
    this.calendarEvents = this.calendarEvents.filter(x => x?.display !== 'background');
  }

  handleEventResizeStart(evt: EventInfo): void {
    this.removeBackgroundEvent();
    // Create new background event to be displayed while drag is active
    this.createBackgroundEvent(evt, true);
  }

  handleEventDragStart(evt: EventInfo): void {
    this.removeBackgroundEvent();

    // Create new background event to be displayed while drag is active
    if (evt.event.extendedProps.isPartial) {
      this.createBackgroundEvent(evt, true);
    }
  }

  handleEventResizeStop(evt: EventInfo): void {
    this.updateCalendarEventDateTime(evt);
  }

  handleEventDragStop(evt: EventInfo): void {
    this.updateCalendarEventDateTime(evt);
  }

  updateEventDataFromDragResize(evt: EventInfo): void {
    this.eventStateBeforeEdit = { ...evt, event: JSON.parse(JSON.stringify(evt.oldEvent)) };
    const eventToUpdate = evt.event.toPlainObject();
    const eventResources = evt.event.getResources();
    const assignedResources = eventResources.length ? eventResources : undefined;

    if (assignedResources && evt.newResource) {
      eventToUpdate.assignedToDisplayName = evt.newResource.title;
      eventToUpdate.assignedTo = evt.newResource.id;
      eventToUpdate.resourceIds = [eventResources.map(x => x.id)];
      eventToUpdate.extendedProps = {
        ...eventToUpdate.extendedProps,
        assignedTo: evt.newResource.id,
        assignedToDisplayName: evt.newResource.title
      };
      // update jobInformation for resource we are editing
      const updatedIndex = eventToUpdate.extendedProps.jobInformation.jobAssignments.findIndex(
        x => x.assignedTo === evt.oldResource.id
      );
      const updatedAssignment = eventToUpdate.extendedProps.jobInformation.jobAssignments[updatedIndex];

      updatedAssignment.assignedTo = evt.newResource.id;
      updatedAssignment.assignedToDisplayName = evt.newResource.title;

      eventToUpdate.extendedProps.jobInformation.jobAssignments = [
        ...eventToUpdate.extendedProps.jobInformation.jobAssignments
      ];
    }

    eventToUpdate.extendedProps.jobInformation.startDateTimestamp = JumptechDate.from(eventToUpdate.start).toIso();
    eventToUpdate.extendedProps.jobInformation.endDateTimestamp = JumptechDate.from(eventToUpdate.end).toIso();

    this.persistTransientEventToCalendar(eventToUpdate);
    this.refreshSelectedJobDetailsEvent(eventToUpdate);

    if (!evt.event.extendedProps.dragged) {
      this.activeScheduleEventEl = [];
      const elements: HTMLElement[] = this.updateActiveScheduleEventEl(evt.event.id);
      for (const element of elements) {
        const newEvent = { ...evt, el: element };
        this.scheduleService.handleEventRender(newEvent).then();
      }
    } else {
      this.scheduleService.handleEventRender(evt).then();
    }
  }

  /**
   * Called when an event in the calendar is dragged
   * @param evt
   */
  handleEventDrag(evt: EventInfo): void {
    this.updateEventDataFromDragResize(evt);
  }

  private getLinkedEventElements(eventId: string, isGroup = false): HTMLElement[] {
    return isGroup
      ? Array.from(document.querySelectorAll(`[data-linkid='${eventId}']`))
      : Array.from(document.querySelectorAll(`[data-eventid='${eventId}']`));
  }

  updateActiveScheduleEventEl(eventOrLinkId: string, isGroup = false): HTMLElement[] {
    const elements: HTMLElement[] = this.getLinkedEventElements(eventOrLinkId, isGroup);
    this.activeScheduleEventEl = [];
    // Ignore update of resource IDs in month view
    if (this.currentView === 'dayGridMonth') {
      elements.forEach(element => {
        this.activeScheduleEventEl.push({ resourceId: '', el: element });
      });
      return elements;
    }
    elements.forEach(element => {
      const elementResourceId = element.closest('td.fc-timeline-lane').getAttribute('data-resource-id');
      const eventId = element.getAttribute('data-eventid');
      const isPartial = element.classList.contains(JT_EVENT_PARTIAL);
      this.activeScheduleEventEl.push({ resourceId: elementResourceId, el: element, eventId, isPartial });
    });

    return elements;
  }

  persistTransientEventToCalendar(event: EventInput): void {
    event = { ...event, editable: true, resourceEditable: true };
    this.activeScheduleEvent = { ...event };
    if (event.extendedProps.dragged) {
      this.transientEvents = [event];
      this.calendarConfig.events = [...this.calendarEvents, ...this.transientEvents];
    }
  }

  private findAndRerenderChangedCalendarEvents(evt, eventId, start, end, lastChange: 'start' | 'end') {
    const calEntry = this.calendarApi.getEventById(eventId);
    let elem;
    if (eventId === TEMP_EVENT_ID) {
      const tempEl = document.querySelector(`.${JT_EVENT_TEMP}`) as HTMLElement;
      this.activeScheduleEventEl[0].eventId = TEMP_EVENT_ID;
      this.activeScheduleEventEl[0].el = tempEl;
      elem = this.activeScheduleEventEl.find(ae => ae.eventId === eventId).el;
    } else {
      elem = this.activeScheduleEventEl.find(ae => ae.eventId === eventId).el;
    }

    let parsedEvent;
    if (eventId === TEMP_EVENT_ID) {
      parsedEvent = evt.event;
      parsedEvent.setStart(start);
      parsedEvent.setEnd(end);
      parsedEvent.setExtendedProp('eventType', 'Job');
    } else {
      parsedEvent = JSON.parse(JSON.stringify(evt.event));
      parsedEvent.start = start;
      parsedEvent.end = end;
      parsedEvent.extendedProps.eventType = 'Job';
      parsedEvent.id = eventId;
    }

    if (calEntry) {
      setTimeout(() => {
        this.scheduleService
          .handleEventRender({
            event: parsedEvent,
            el: elem,
            view: { type: this.currentView }
          } as unknown as EventInfo)
          .then();
        if (lastChange === 'start') {
          // The order of operations matters depending on whether you're just updating the start or the end
          calEntry.setEnd(parsedEvent.end);
          calEntry.setStart(parsedEvent.start);
        } else {
          calEntry.setStart(parsedEvent.start);
          calEntry.setEnd(parsedEvent.end);
        }
      });
    }
  }

  handleEventSelectedJobDetailsChange(change, evt: EventInfo, slot?: TradesPersonSlot, changeType?: string): void {
    let eventId;

    if (changeType === 'fullJobChange') {
      // we are a full job change so update all linked events that don't have slots
      const fullJobEvents: ActiveCalendarElements[] = this.activeScheduleEventEl.filter(
        activeEl => !activeEl.isPartial
      );
      fullJobEvents.forEach(fe => {
        const isTempEvent = evt.event.id === TEMP_EVENT_ID;
        const evtId = isTempEvent ? TEMP_EVENT_ID : fe.eventId;
        let parsedEvent: JobEvent;
        if (isTempEvent) {
          parsedEvent = evt.event;
          parsedEvent.setStart(change.startIso);
          parsedEvent.setEnd(change.endIso);
        } else {
          parsedEvent = JSON.parse(JSON.stringify(evt.event));
          parsedEvent.start = change.startIso;
          parsedEvent.end = change.endIso;
        }
        this.findAndRerenderChangedCalendarEvents(evt, evtId, parsedEvent.start, parsedEvent.end, change.lastChange);
      });
    } else {
      if (evt.event.extendedProps.linkId) {
        eventId = `${evt.event.extendedProps.linkId}--${change.assignedTo}`;
        if (slot) {
          eventId = `${eventId}--${slot.assignmentId}`;
        }
      } else {
        eventId = evt.event.extendedProps.jobInformation.id;
      }
      this.findAndRerenderChangedCalendarEvents(evt, eventId, change.startIso, change.endIso, change.lastChange);
    }
  }

  rerenderActiveScheduleEvents(evt): void {
    this.activeScheduleEventEl.forEach(activeEl => {
      if (!evt.view) {
        evt.view = { type: this.currentView };
      }

      // Pre-assignment events will not have a resource id on Month view so we handle them separately
      const preAssignmentEvent = document.querySelector(`.${JT_EVENT_PREASSIGNMENT}`);
      if (preAssignmentEvent) {
        evt.el = preAssignmentEvent;
      } else {
        const elSelector =
          evt.event.extendedProps.dragged && !activeEl.resourceId
            ? `.${JT_EVENT_TEMP}`
            : `[data-resource-id='${activeEl.resourceId}'] .${JT_EVENT_TEMP}`;
        evt.el = !evt.event.extendedProps.dragged ? activeEl.el : document.querySelector(elSelector);
      }

      const leadResourceId = evt.event.extendedProps?.jobInformation?.jobAssignments.find(
        x => x.assignmentType === 'LEAD'
      )?.assignedTo;
      // todo: if we know here what the lead resource id is we can pass it down to the extended props -
      //  Product may want to indicate which engineer event is lead in the calendar view
      const isLead = activeEl.resourceId === leadResourceId;
      const thisEvent = { ...evt, isLead };
      this.scheduleService.handleEventRender(thisEvent).then();
    });
  }

  handleDateClick(dateClickInfo: DateClickArg): void {
    if (this.context !== 'project' || this.activeScheduleEvent || this.isRescheduleJob()) {
      return;
    }

    this.transientEvents = [];
    let transientDuration: number;
    // if we already have a transient event then lets keep the duration
    if (this.activeScheduleEvent) {
      const start = JumptechDate.from(this.activeScheduleEvent.start as string);
      const end = JumptechDate.from(this.activeScheduleEvent.end as string);
      const duration = end.diff(start, { units: 'minutes' });
      const transientDurationMinutes = duration.minutes;
      transientDuration = transientDurationMinutes / 60;
    }

    // build the event
    const viewWithoutTime = this.isViewWithoutTime();
    const defaultDuration = transientDuration ? transientDuration : this.selectedJob.defaultDuration;
    const { startDate, endDate } = this.calculateEventDateTimes(dateClickInfo.date, viewWithoutTime, defaultDuration);
    let resources = [];

    // if we have a resource defined grab it
    if (dateClickInfo.resource) {
      if (this.activeScheduleEvent) {
        const leadIndex = this.activeScheduleEvent.extendedProps.jobInformation.jobAssignments.findIndex(
          x => x.assignmentType === 'LEAD'
        );
        const leadAssignment = this.activeScheduleEvent.extendedProps.jobInformation.jobAssignments[leadIndex];

        leadAssignment.assignedTo = dateClickInfo.resource.id;
        leadAssignment.assignedToDisplayName = dateClickInfo.resource.title;
        resources = [...this.activeScheduleEvent.extendedProps.jobInformation.jobAssignments];
        // if lead is also a support engineer then remove it
        resources = resources.filter(r => r.assignedTo !== leadAssignment.assignedTo || r.assignmentType === 'LEAD');
      } else {
        resources = [
          {
            assignedToDisplayName: dateClickInfo.resource.title,
            assignedTo: dateClickInfo.resource.id,
            assignmentType: 'LEAD'
          }
        ];
      }
    }

    // build event input
    const eventToAdd: EventInput = {
      allDay: false,
      id: TEMP_EVENT_ID,
      start: '',
      end: '',
      resource: {
        ...dateClickInfo.resource
      },
      backgroundColor: 'var(--jds-theme-schedule-v2-job-status-color-provisionally-scheduled-bg)',
      classNames: [JT_EVENT],
      title: this.selectedJob.type,
      extendedProps: {
        eventType: 'Job',
        assignedToDisplayName: 'Unassigned', // todo i18n
        status: 'PROVISIONALLY_SCHEDULED',
        dragged: true,
        jobInformation: {
          ...this.selectedJob,
          contactInfo: {
            email: this.selectedJob.email,
            telephoneNumber: this.selectedJob.phoneNumber
          },
          startDateTimestamp: startDate,
          endDateTimestamp: endDate
        }
      },
      assignedToDisplayName: dateClickInfo.resource ? dateClickInfo.resource.title : '',
      assignedTo: dateClickInfo.resource ? dateClickInfo.resource.id : '',
      resourceIds: dateClickInfo.resource ? [dateClickInfo.resource.id] : []
    };

    eventToAdd.extendedProps.jobInformation.jobAssignments = resources;
    eventToAdd.start = startDate;
    eventToAdd.end = endDate;

    this.persistTransientEventToCalendar(eventToAdd);
    this.refreshSelectedJobDetailsEvent(eventToAdd);
  }

  /**
   * Called when an external event is dragged onto the calendar
   * @param evt
   */
  handleEventReceive(evt: EventInfo): void {
    const assignedResource = evt.event.getResources().length ? evt.event.getResources()[0] : undefined;
    const defaultDuration = evt.event.extendedProps?.jobInformation?.defaultDuration || 2;
    const viewWithoutTime = this.isViewWithoutTime();
    const { startDate, endDate } = this.calculateEventDateTimes(evt.event.start, viewWithoutTime, defaultDuration);
    // setDates only works correctly on views that do not display times
    if (viewWithoutTime) {
      evt.event.setDates(startDate, endDate, { allDay: false });
    } else {
      evt.event.setAllDay(false);
      evt.event.setEnd(endDate);
      evt.event.setStart(startDate);
    }
    evt.event.setProp('id', TEMP_EVENT_ID);

    const eventInput: EventInput = {
      ...evt.event.toPlainObject()
    };

    if (assignedResource) {
      eventInput.assignedToDisplayName = assignedResource.title;
      eventInput.assignedTo = assignedResource.id;
      eventInput.resourceIds = [assignedResource.id];
    }

    this.persistTransientEventToCalendar(eventInput);

    const jobInfo: JobInformation = {
      ...eventInput.extendedProps.jobInformation,
      actionId: this.actionId ?? null,
      jobAssignments: eventInput.assignedTo
        ? [
            {
              assignedTo: eventInput.assignedTo,
              assignedToDisplayName: eventInput.assignedToDisplayName,
              assignmentType: 'LEAD'
            }
          ]
        : [],
      startDateTimestamp: startDate,
      endDateTimestamp: endDate,
      setDefaultStartTime: viewWithoutTime
    };

    evt.event.extendedProps.jobInformation.jobAssignments = [...jobInfo.jobAssignments];
    if (evt.el) {
      this.activeScheduleEventEl.push({ resourceId: assignedResource.id, el: evt.el });
    }

    this.scheduleService.handleEventRender(evt).then();

    this.presenter.openScheduleDetails(jobInfo, this.handleEventSelectedJobDetailsChange.bind(this), evt);
    this.sjdPresenter.preventDraggableItems();
  }

  private calculateEventDateTimes(start: Date, viewWithoutTime: boolean, defaultDuration: number) {
    let startDate = JumptechDate.from(start).toIso();

    if (viewWithoutTime) {
      startDate = JumptechDate.from(start).plus({ hours: 9 }).toIso();
    }
    const endDate = JumptechDate.from(startDate).plus({ hours: defaultDuration }).toIso();
    return { startDate, endDate };
  }

  private isViewWithoutTime() {
    return ['resourceTimelineRollingMonth', 'dayGridMonth'].includes(this.currentView);
  }

  async onSelectedJobDetailsClosed(): Promise<void> {
    // first unselect the event;
    this.unselectEvent();
    if (this.transientEvents.length) {
      this.transientEvents = [];
      this.activeScheduleEvent = null;
      this.activeScheduleEventEl = [];
      this.eventStateBeforeEdit = null;

      this.filterEvents();
    } else if (this.eventStateBeforeEdit && this.eventStateBeforeEdit.event.id !== TEMP_EVENT_ID) {
      // Update and re-add the event under edit to force a re-render
      const foundEvent = (this.calendarConfig.events as EventInput[]).find(
        ev => ev.id === this.eventStateBeforeEdit.event.id
      );
      this.calendarApi.getEventById(this.eventStateBeforeEdit.event.id).remove();
      foundEvent.start = this.eventStateBeforeEdit.event.start;
      foundEvent.end = this.eventStateBeforeEdit.event.end;
      foundEvent.extendedProps = this.eventStateBeforeEdit.event.extendedProps;
      this.timerSelectedJobDetailsClosed = setTimeout(() => {
        this.calendarApi.addEvent(foundEvent);
      }, 0);

      // todo check this
      // Deal with the scenario when closing more details with a selected event still on the calendar
      this.timerSelectedJobDetailsClosedSelectedEvent = setTimeout(() => {
        // clear cache
        this.activeScheduleEvent = null;
        this.activeScheduleEventEl = [];
        if (this.eventStateBeforeEdit.event.extendedProps.dragged) {
          this.eventStateBeforeEdit = null;
        } else {
          this.unselectEvent();
        }
        this.transientEvents = [];
      }, 0);

      // restart polling
      if (this.eventStateBeforeEdit.event.extendedProps.dragged) {
        await this.handleDateChange(undefined, this.calendarApi, null);
      }
    }
  }

  notifySuccessToMap(data): void {
    const res: IScheduleModalResult = {
      data: { scheduleInfo: data },
      action: [],
      project: {
        id: data.projectId
      }
    };
    this.scheduleSuccess.emit(res);
  }

  async onSelectedJobDetailsSuccessClosed(data): Promise<void> {
    this.notifySuccessToMap(data);
    if (!this.activeScheduleEvent) {
      return;
    }
    await this.spinnerService.show('calendarSpinner');
    const isTempEventUpdate = this.transientEvents.length;
    this.transientEvents = [];
    this.eventStateBeforeEdit = null;
    this.sjdPresenter.initDraggableItems();

    this.timerSelectedJobDetailsSuccessClosed = setTimeout(
      async () => {
        await this.handleDateChange(undefined, this.calendarApi, data);
      },
      isTempEventUpdate ? this.CALENDAR_EVENT_REFRESH_DELAY : 0
    );
  }

  async handleEventClick(evt: EventInfo) {
    const { event } = evt;
    if (!event) {
      return;
    }
    if (event.extendedProps?.dragged) {
      return;
    }

    if (this.context === 'project' && evt.event.id !== this.selectedJob?.id) {
      return;
    }

    if (event.extendedProps.eventType === 'Job') {
      if (
        (evt.event.extendedProps.linkId && this.selectedEventId === evt.event.extendedProps.linkId) ||
        (!evt.event.extendedProps.linkId && this.selectedEventId === evt.event.id)
      ) {
        this.presenter.showSelectedJob();
        return;
      }
      this.setSelectedEvent(evt);
      const plainObjectEvent = evt.event.toPlainObject();
      this.activeScheduleEvent = { ...plainObjectEvent, editable: true, resourceEditable: true };

      if (evt.event.extendedProps.linkId) {
        this.updateActiveScheduleEventEl(evt.event.extendedProps.linkId, true);
      } else {
        this.updateActiveScheduleEventEl(evt.event.id);
      }

      if (!this.eventStateBeforeEdit || this.eventStateBeforeEdit.event.id !== evt.event.id) {
        this.eventStateBeforeEdit = JSON.parse(JSON.stringify(evt));
      }
      const jobInfo = this.parseJobInfo(event);
      this.presenter.openScheduleDetails(jobInfo, this.handleEventSelectedJobDetailsChange.bind(this), evt);
      this.stopPolling();
    } else {
      this.openEventModal('edit', evt, event.extendedProps);
    }
  }

  private parseJobInfo(event: JobEvent): JobInformation {
    return {
      id: event.id,
      address: event.extendedProps.address,
      type: event.extendedProps.type,
      customerFirstName: event.extendedProps.firstName,
      customerLastName: event.extendedProps.lastName,
      jobAssignments: event.extendedProps.jobInformation?.jobAssignments ?? event.extendedProps.jobAssignments,
      assignedTo: event.extendedProps.assignedTo,
      assignedToDisplayName: event.extendedProps.assignedToDisplayName,
      contactInfo: {
        email: event.extendedProps.email,
        telephoneNumber: event.extendedProps.phoneNumber
      },
      startDateTimestamp: event.extendedProps.jobInformation?.startDateTimestamp ?? event.extendedProps.startDate,
      endDateTimestamp: event.extendedProps.jobInformation?.endDateTimestamp ?? event.extendedProps.endDate,
      projectId: event.extendedProps.projectId,
      isInitialSchedule: event.extendedProps.isInitialSchedule,
      fullJobStart: event.extendedProps.fullJobStart ?? null,
      fullJobEnd: event.extendedProps.fullJobEnd ?? null
    };
  }

  handleMoreLinkClick(): void {
    clearTimeout(this.timerPopover);
    this.timerPopover = setTimeout((): void => {
      const popoverEL: HTMLElement = document.querySelector('.fc-popover');
      if (popoverEL) {
        const calendarEl = popoverEL.parentElement;
        const calendarHeight = calendarEl.clientHeight;
        const calendarWidth = calendarEl.clientWidth;
        const popoverHeight = popoverEL.clientHeight;
        const popoverWidth = popoverEL.clientWidth;
        const top = popoverEL.offsetTop;
        const left = popoverEL.offsetLeft;
        const nudgePadding = 10;

        const fitsInCalendarY = popoverHeight + top < calendarHeight;
        const fitsInCalendarX = popoverWidth + left < calendarWidth;

        const spaceReqY = top + popoverHeight;
        const spaceReqX = left + popoverWidth;
        const nudgeY = spaceReqY - calendarHeight + nudgePadding;
        const nudgeX = spaceReqX - calendarWidth + nudgePadding;

        if (!fitsInCalendarY && !fitsInCalendarX) {
          popoverEL.style.transform = `translate(-${nudgeX}px, -${nudgeY}px)`;
        } else {
          if (!fitsInCalendarY) {
            popoverEL.style.transform = `translateY(-${nudgeY}px)`;
          }
          if (!fitsInCalendarX) {
            popoverEL.style.transform = `translateX(-${nudgeX}px)`;
          }
        }
      }
    }, 5);
  }

  setSelectedEvent(event: EventInfo): void {
    if (this.selectedEventId) {
      this.unselectEvent();
    }

    if (event.event.extendedProps.linkId) {
      this.selectedEventId = event.event.extendedProps.linkId;
      this.selectedEventType = SelectedEventType.link;
    } else {
      this.selectedEventId = event.event.id;
      this.selectedEventType = SelectedEventType.normal;
    }

    // set selected style for events
    const isGroup = !!event.event.extendedProps.linkId;
    const eventId = isGroup ? event.event.extendedProps.linkId : event.event.id;
    const matchingEventElements: HTMLElement[] = this.getLinkedEventElements(eventId, isGroup);

    this.selectedEventElements = matchingEventElements;
    for (const element of matchingEventElements) {
      element.classList.add(JT_EVENT_SELECTED);
    }

    // render any bg events
    this.renderSelectedEventConstraints();
  }

  private renderSelectedEventConstraints(): void {
    if (this.selectedEventType === SelectedEventType.link && this.selectedEventElements.length) {
      this.removeBackgroundEvent();
      this.selectedEventElements.forEach(el => {
        const tempEvent = this.calendarApi.getEventById(el.getAttribute('data-eventid'));
        this.createBackgroundEvent(tempEvent, true);
      });
    }
  }

  unselectEvent() {
    // this.updateSelectedEventElements();
    this.selectedEventElements?.forEach(el => {
      el.classList.remove(JT_EVENT_SELECTED);
    });
    this.selectedEventId = '';
    this.selectedEventElements = [];
    this.activeScheduleEvent = null;
    this.activeScheduleEventEl = [];
    this.removeBackgroundEvent();
  }

  patchExistingEventIfNotUpdated(existing, updated) {
    // patches event with data from recent successful POST update so we don't have to wait for the API
    let updatedEvt = null;
    if (existing.projectId === updated.projectId && existing.type === updated.jobType) {
      let eventHasUpdated = true;
      if (existing.start !== updated.start || existing.end !== updated.end) {
        eventHasUpdated = false;
      }
      if (existing.resourceIds.length !== updated.jobAssignments.length) {
        eventHasUpdated = false;
      }
      if (existing.resourceIds.length === updated.jobAssignments.length) {
        const updatedAssignmentIds = updated.jobAssignments.map(a => a.assignedTo);
        if (JSON.stringify(updatedAssignmentIds) !== JSON.stringify(existing.resourceIds)) {
          eventHasUpdated = false;
        }
      }
      if (!eventHasUpdated) {
        updatedEvt = {
          ...existing,
          end: updated.end,
          start: updated.start,
          resourceIds: updated.jobAssignments.map(a => a.assignedTo),
          jobInformation: {
            ...existing.jobInformation,
            startDateTimestamp: updated.start,
            endDateTimestamp: updated.end,
            jobAssignments: updated.jobAssignments
          }
        };
      }
    }
    return updatedEvt;
  }

  async handleDateChange(evt: EventInfo, existingCalendarApi?: Calendar, updatedEvent?) {
    this.viewTitle = existingCalendarApi ? existingCalendarApi.view.title : evt.view.title;
    this.isSelectable = SELECTABLE_CAL_TYPES.includes(
      existingCalendarApi ? existingCalendarApi.view.type : evt.view.type
    );
    const start = existingCalendarApi
      ? existingCalendarApi.view.activeStart.toISOString()
      : evt.view.activeStart.toISOString();
    const end = existingCalendarApi
      ? existingCalendarApi.view.activeEnd.toISOString()
      : evt.view.activeEnd.toISOString();
    Analytics.logEvent('CalendarView', { start, end });
    try {
      // we don't want to trigger the spinner if we are just changing event details
      if (!this.activeScheduleEvent) {
        await this.spinnerService.show('calendarSpinner');
      }
      this.stopPolling();

      this.pollingEvents = this.filtersLoaded$
        .pipe(
          filter(x => x === true),
          switchMap(() => {
            return this.apiService.observeCalendarEvents(start, end);
          })
        )
        .subscribe((evts: CalendarEventV3[]) => {
          this.calendarEvents = evts
            .filter(event => {
              return this.scheduleService.isValidEventType(event.eventType);
            })
            .filter(event => {
              if (event.eventType === 'Job') {
                return this.allEngineers.find(e => e.id === event.job?.assignedTo);
              } else {
                return this.allEngineers.find(e => e.id === event.userIds[0]);
              }
            })
            .map(event => {
              return this.buildPartialEvents(event);
            })
            .flat()
            .map(event => {
              return { ...event, title: this.scheduleService.getEventTitleFromProps(event) } as CalendarEventV3;
            })
            .map(event => {
              const evType = this.scheduleService.getEventType(event.eventType);
              return generateScheduleEvent(event, evType?.textColour, this.context, this.selectedJob?.id);
            })
            .map((event: any) => {
              if (updatedEvent) {
                const patchedEvent = this.patchExistingEventIfNotUpdated(event, updatedEvent);
                return patchedEvent ?? event;
              }
              return event;
            });
          this.calendarConfig.events = [...this.calendarEvents, ...this.transientEvents];
          this.filterEvents();
          this.resizeCalendar();
          this.updateSelectedEventElements();
          this.spinnerService.hide('calendarSpinner').catch(console.log);
        });
    } catch (e) {
      console.error('Unable to access calendar events list', e);
      await this.spinnerService.hide('calendarSpinner');
    }
  }

  async saveEvent(modalRef: NgbModalRef, event: EventInfo, formData: any) {
    modalRef.componentInstance.savingEvent = true;
    const eventTo = this.eventTransform(formData);
    const { textColour } = this.scheduleService.getEventType(formData.type);
    const localEvent = generateScheduleEvent(eventTo, textColour, this.context, this.selectedJob?.id);
    try {
      if (!formData.id) {
        await this.insertEvent(eventTo, localEvent);
      } else {
        await this.updateEvent(eventTo, event, localEvent, formData.id);
      }
      this.scheduleService.showSuccessToast(formData);
      modalRef.componentInstance.savingEvent = false;
      modalRef.close();
    } catch (e) {
      console.log(`Save error:  ${e}`);
      this.scheduleService.showErrorToast();
      modalRef.componentInstance.savingEvent = false;
      modalRef.close();
    }
  }

  async deleteEvent(modalRef: NgbModalRef, formData: any) {
    modalRef.componentInstance.deletingEvent = true;
    try {
      await this.apiService.deleteCalendarEvent(formData.id);
      this.calendarConfig.events = [...(this.calendarConfig.events as EventInput[]).filter(x => x.id !== formData.id)];
      this.calendarConfig.events = [...this.calendarConfig.events];
      this.filterEvents();
      modalRef.componentInstance.deletingEvent = false;
      this.scheduleService.showDeleteSuccessToast(formData);
      modalRef.close();
    } catch (e) {
      console.log(`Delete error: ${e}`);
      this.scheduleService.showDeleteErrorToast();
      modalRef.componentInstance.deletingEvent = false;
      modalRef.close();
    }
  }

  async insertEvent(eventTo: CalendarEventV3, localEvent: EventInput) {
    this.cleanLocalDates(eventTo);
    const res = await this.apiService.createCalendarEvent(eventTo);
    if (res) {
      localEvent.id = res;
      localEvent.title = this.scheduleService.getEventTitleFromProps(localEvent);
      this.calendarEvents = [...this.calendarEvents, localEvent, ...this.transientEvents];
      this.filterEvents();
    }
  }

  async updateEvent(eventTo: CalendarEventV3, event: EventInfo, localEvent: EventInput, eventId: string) {
    this.cleanLocalDates(eventTo);
    const res = await this.apiService.updateCalendarEvent(eventTo);
    if (res) {
      const itemIndex = this.calendarEvents.findIndex(x => x.id === eventId);
      localEvent.title = this.scheduleService.getEventTitleFromProps(localEvent);
      this.calendarEvents[itemIndex] = localEvent;
      event.event.setProp('title', localEvent.title);
      event.event.setStart(localEvent.start);
      this.scheduleService.handleEventRender(event).then();
      this.filterEvents();
    }
  }

  overrideTodayButton() {
    (document.querySelector('.fc-today-button') as HTMLElement).onclick = () => {
      if (this.currentView === 'resourceTimelineRollingMonth') {
        this.calendarApi.gotoDate(JumptechDate.now().startOf('week').toIso());
      }
    };
  }

  cleanLocalDates(eventTo: CalendarEventV3) {
    delete eventTo.localStartDate;
    delete eventTo.localEndDate;
  }

  getIsoDateTime(date: NgbDateStruct, hour: string, minutes: string) {
    return JumptechDate.from({
      ...date,
      hour: parseInt(hour),
      minute: parseInt(minutes)
    }).toIso();
  }

  eventTransform(d: any): CalendarEventV3 {
    const startTime = d.startTime.name.split(':');
    const endTime = d.endTime.name.split(':');
    const start = this.getIsoDateTime(d.startDate, startTime[0], startTime[1]);
    const end = this.getIsoDateTime(d.endDate, endTime[0], endTime[1]);
    const localStartDate = this.getIsoDateTime(d.startDate, '0', '0');
    const localEndDate = this.getIsoDateTime(d.endDate, '0', '0');

    const calendarEvent: CalendarEventV3 = {
      tz: d.tz,
      startIso: start,
      endIso: end,
      localStartDate: localStartDate,
      localEndDate: localEndDate,
      eventType: d.type,
      userIds: [d.engineer],
      allDayEvent: d.allDayEvent,
      title: d.title,
      description: d.description ?? ''
    };
    if (d.id) {
      calendarEvent.id = d.id;
    }
    return calendarEvent;
  }

  handleViewMount(evt: ViewMountArg) {
    this.viewTitle = evt.view.title;
    this.currentView = evt.view.type;
  }

  handleViewChange(evt: DropDownElement) {
    switch (evt.id) {
      case 'month': {
        this.loadMonthView();
        break;
      }
      case 'week': {
        this.loadWeekView();
        break;
      }
      case 'day': {
        this.loadDayView();
        break;
      }
      case 'rollingMonth': {
        this.loadRollingView();
        break;
      }
      default: {
        return;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  resizeCalendar(): void {
    if (this.calendarApi) {
      const containerHeight = document.getElementsByClassName('main-calendar-container')[0]?.clientHeight;
      this.calendarApi.setOption('height', containerHeight);
      setTimeout(() => {
        // fixes issue with month view not resizing when toggling jobs list
        this.calendarApi.updateSize();
      });
    }
  }

  private buildPartialEvents(evt: CalendarEventV3): CalendarEventV3 | CalendarEventV3[] {
    //  this creates calendar items for each entry in job assignments and links
    //  them by a linkId (eventId). New eventIds are created to enable full calendar to work: <eventId>--<resourceId>--<assignmentId>
    //  if any assignments are partial slots then we add time constraints to restrict to total job length
    const hasMultipleAssignments = evt.job?.jobAssignments.length > 1;
    if (hasMultipleAssignments) {
      const calendarEvents: CalendarEventV3[] = [];
      evt.job.jobAssignments.forEach((ja: JobAssignment, i: number): void => {
        let calendarEvent: CalendarEventV3 = { ...evt };

        const assignmentConstraints = {
          start: evt.startIso,
          end: evt.endIso
        };
        // if we have specific slot data
        const isPartial = !!(ja.startDate && ja.endDate);

        calendarEvent = {
          ...calendarEvent,
          job: {
            ...evt.job,
            id: `${evt.id}--${ja.assignedTo}--${ja.assignmentId}`,
            fullJobStart: evt.startIso,
            fullJobEnd: evt.endIso,
            start: ja.startDate ?? evt.startIso,
            startDate: ja.startDate ?? evt.startIso,
            end: ja.endDate ?? evt.endIso,
            endDate: ja.endDate ?? evt.endIso
          },
          isPartial,
          linkId: evt.id,
          constraint: isPartial ? assignmentConstraints : null
        };
        calendarEvents.push(calendarEvent);
      });
      return calendarEvents;
    }
    return evt;
  }

  async fetchEngineers(): Promise<void> {
    const { dropdown, all } = await this.scheduleService.fetchEngineers();
    this.allEngineers = all;
    this.setEngineers(this.selectedResources);
    this.engineersForDropdown = dropdown;
  }

  handleResourceRender(evt: ResourceInfo): void {
    const { resource, el } = evt;
    const elements: HTMLCollection = el.getElementsByClassName('fc-datagrid-cell-main');
    const cellContents = elements[0];
    cellContents.outerHTML = `
      <div class="resource-cell">
        ${resource._resource.title}
      </div>`;
  }

  handleResourceHeader(evt: ResourceInfo) {
    const { el } = evt;
    const elements: HTMLCollection = el.getElementsByClassName('fc-datagrid-cell-main');
    const cellContents = elements[0];
    cellContents.outerHTML = `
      <div class="resource-header-cell">
        ${this.translateService.translate('common.tradespeople')}
      </div>`;
  }

  private filterEvents() {
    this.calendarConfig.events = [
      ...(this.calendarEvents as EventInput[]),
      ...(this.transientEvents as EventInput[])
    ].filter(e => {
      const eventJobAssignmentIds = e.eventType === 'Job' ? e.jobAssignments.map(ja => ja.assignedTo) : [];
      return (
        // filter jobs
        e.eventType === 'Job'
          ? this.jobTypeService.isSelected(e.title) &&
              (!this.selectedResources?.length ||
                this.selectedResources.some(sr => eventJobAssignmentIds.includes(sr))) &&
              (!this.selectedJobStatuses?.length || this.selectedJobStatuses.includes(e.status))
          : // filter absences
            (!this.selectedAbsenceTypes?.length || this.selectedAbsenceTypes.includes(e.eventType)) &&
              (!this.selectedResources?.length || this.selectedResources.includes(e.userIds[0]))
      );
    });
  }

  updateSelectedEventElements(): void {
    if (this.selectedEventId) {
      if (this.selectedEventType === SelectedEventType.link) {
        this.selectedEventElements = Array.from(document.querySelectorAll(`[data-linkid="${this.selectedEventId}"]`));
      } else {
        this.selectedEventElements = Array.from(document.querySelectorAll(`[data-eventid="${this.selectedEventId}"]`));
      }
    }
  }

  generateFilterData(): ScheduleFilterInformation {
    return {
      jobTypes: this.jobTypeService.jobTypes,
      jobStatuses: this.jobStatusesV2,
      scheduleEventTypes: this.scheduleEventTypeList,
      tradesmen: this.engineersForDropdown,
      selectedJobTypes: this.jobTypeService.getSelectedJobTypes(),
      selectedScheduleEventTypes: this.selectedAbsenceTypes,
      selectedTradespeople: this.selectedResources,
      selectedJobStatuses: mapLegacyStatusesToNewStatuses(this.selectedJobStatuses)
    };
  }

  setPreviouslySelectedFilters() {
    const savedScheduleFilters = this.localStorageGateway.getItem(selectedFiltersKey);
    if (savedScheduleFilters) {
      const parsedFilters = JSON.parse(savedScheduleFilters) as SelectedScheduleFilters;
      this.jobTypeService.setSelectedJobTypes(parsedFilters.selectedJobTypes);
      this.selectedAbsenceTypes = parsedFilters.selectedScheduleEventTypes;
      this.setEngineers(parsedFilters.selectedTradespeople);
      this.selectedJobStatuses = parsedFilters.selectedJobStatuses;
    }
  }

  updateSelectedFilters(filterKey: string, filterValues: string[]) {
    const existingScheduleFilters = this.localStorageGateway.getItem(selectedFiltersKey);
    if (existingScheduleFilters) {
      const parsedFilters = JSON.parse(existingScheduleFilters) as SelectedScheduleFilters;
      parsedFilters[filterKey] = filterValues;
      this.localStorageGateway.setItem(selectedFiltersKey, JSON.stringify(parsedFilters));
    } else {
      const filtersToSave: SelectedScheduleFilters = {
        selectedJobStatuses: [],
        selectedTradespeople: [],
        selectedScheduleEventTypes: [],
        selectedJobTypes: []
      };
      filtersToSave[filterKey] = filterValues;
      this.localStorageGateway.setItem(selectedFiltersKey, JSON.stringify(filtersToSave));
    }
  }

  closeFilterPopover() {
    this.filterPopover.close();
  }

  updateActiveFilterAmount() {
    this.activeFilters =
      this.jobTypeService.getSelectedJobTypes().length +
      this.selectedAbsenceTypes.length +
      this.selectedResources.length +
      mapLegacyStatusesToNewStatuses(this.selectedJobStatuses).length;
  }

  setupViewDropdownElements() {
    const scheduleViews: DropDownElement[] = JSON.parse(JSON.stringify(SCHEDULE_VIEWS));
    for (const view of scheduleViews) {
      view.label = this.translateService.translate(`common.${view.id}`);
    }
    this.scheduleViews = scheduleViews;
    this.defaultScheduleView = scheduleViews[0];
  }

  resetFilter() {
    this.jobTypeService.setSelectedJobTypes([]);
    this.setJobTypes([]);
    this.selectedAbsenceTypes = [];
    this.setAbsenceTypes([]);
    this.selectedResources = [];
    this.setEngineers([]);
    this.selectedJobStatuses = [];
    this.setJobStatuses([]);
    this.updateActiveFilterAmount();
  }

  openEventModal(mode: 'edit' | 'add' | string, eventData?: EventInfo, extendedProps?: CalendarEventV3 & Job) {
    const modalRef = this.modalService.open(ScheduleEventModalComponent, { backdrop: 'static' });
    modalRef.componentInstance.mode = mode;
    modalRef.componentInstance.engineerList = this.engineersForDropdown;
    modalRef.componentInstance.eventTypes = this.scheduleEventTypeList;
    modalRef.componentInstance.tz = this.tenantTimezone;
    if (extendedProps) {
      modalRef.componentInstance.currentEventTitle = this.scheduleService.getEventTitleFromProps(extendedProps);
    }
    if (eventData?.event) {
      modalRef.componentInstance.eventData = eventData.event;
    }

    this.scheduleEventModalSubs.push(
      modalRef.componentInstance.saveEvent.subscribe($event => {
        this.saveEvent(modalRef, eventData, $event).then();
      }),
      modalRef.componentInstance.deleteEvent.subscribe($event => {
        modalRef.componentInstance.isDeleting = true;
        this.deleteEvent(modalRef, $event).then();
      })
    );

    modalRef.result.then(
      () => this.unSubModalEvents(),
      () => this.unSubModalEvents()
    );
  }

  private loadMonthView(): void {
    this.calendarApi.changeView('dayGridMonth');
    this.calendarApi.setOption('dayHeaderFormat', { weekday: 'short' });
    this.calendarApi.gotoDate(JumptechDate.now().startOf('month').toIso());
    // Ensure that if an event is selected before changing views to Month view
    // we carry over the context so that editing may continue
    if (this.eventStateBeforeEdit) {
      const ev = this.calendarApi.getEventById(this.eventStateBeforeEdit.event.id);
      const evtInfo = { event: ev } as unknown as EventInfo;
      const plainObjectEvent = evtInfo.event.toPlainObject();
      this.activeScheduleEvent = { ...plainObjectEvent, editable: true, resourceEditable: true };
      this.eventStateBeforeEdit = JSON.parse(JSON.stringify(evtInfo));
      this.updateActiveScheduleEventEl(evtInfo.event.id);
    }
  }

  private loadWeekView(selectedDate: string = null): void {
    if (selectedDate) {
      this.calendarApi.gotoDate(selectedDate);
      this.calendarApi.changeView('resourceTimeline');
      this.timerLoadWeekView = setTimeout((): void => {
        this.calendarApi.scrollToTime(JumptechDate.from(selectedDate).toTimeFormat());
      });
    } else {
      this.calendarApi.changeView('dayGridMonth');
      this.calendarApi.changeView('resourceTimeline');
      this.calendarApi.gotoDate(JumptechDate.now().toIso());
      // Set the calendar to the start of the current day
      this.timerLoadWeekView = setTimeout((): void => {
        this.calendarApi.scrollToTime(JumptechDate.from(new Date(new Date().setHours(0, 0, 0, 0))).toTimeFormat());
      });
    }
    this.calendarApi.setOption('slotLabelInterval', { minutes: 60 });
    this.calendarApi.setOption('slotDuration', { minutes: 30 });
    this.calendarApi.setOption('duration', { days: 7 });
    this.calendarApi.setOption('eventMinWidth', 30);
    this.calendarApi.setOption('slotMinWidth', undefined);
    this.calendarApi.setOption('resourceAreaWidth', 200);
    this.calendarApi.setOption('slotLabelFormat', [
      { weekday: 'short', day: 'numeric' },
      { hour: 'numeric', minute: 'numeric' }
    ]);
    this.calendarApi.setOption('expandRows', true);
  }

  private loadDayView(): void {
    this.calendarApi.gotoDate(JumptechDate.now().toIso());
    this.calendarApi.changeView('dayGridMonth');
    this.calendarApi.changeView('resourceTimelineDay');
    this.calendarApi.setOption('resourceAreaWidth', 200);
    this.calendarApi.setOption('slotMinWidth', 30);
    this.calendarApi.setOption('slotLabelInterval', '01:00');
    this.calendarApi.setOption('slotLabelFormat', [{ hour: 'numeric', minute: 'numeric' }]);
  }

  private loadRollingView(): void {
    this.calendarApi.gotoDate(JumptechDate.now().startOf('week').toIso());
    this.calendarApi.setOption('slotDuration', { days: 1 });
    this.calendarApi.setOption('slotLabelInterval', { days: 1 });
    this.calendarApi.changeView('dayGridMonth');
    this.calendarApi.changeView('resourceTimelineRollingMonth');
    this.calendarApi.setOption('duration', { days: 30 });
    this.calendarApi.setOption('slotLabelFormat', [{ weekday: 'short', day: 'numeric' }]);
    this.calendarApi.setOption('eventMinWidth', 50);
    this.calendarApi.setOption('slotMinWidth', 50);
    this.calendarApi.setOption('resourceAreaWidth', 200);
    // Add class so that fc style overrides work
    this.calendarApi.el
      .querySelector('div.fc-resourceTimeline-view')
      ?.classList.add('fc-resourceTimelineRollingMonth-view');
  }

  private stopPolling() {
    if (this.pollingEvents) {
      this.pollingEvents.unsubscribe();
    }
  }
}
