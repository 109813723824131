import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe, NgIf } from '@angular/common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { DisableProjectAutoAssignmentActionPresenter } from './disable-project-auto-assignment-action.presenter';
import { BehaviorSubject } from 'rxjs';
import { ProjectAutoAssignmentRepository } from '../project-auto-assignment.repository';
import { IDisableProjectAutoAssignmentActionVm } from '../project-auto-assignment.model';
import { DisableProjectAutoAssignmentModalComponent } from '../disable-project-auto-assignment-modal/disable-project-auto-assignment-modal.component';
import { Project } from '../../../core/domain/project';
import { ReadOnlyDisableDirective } from '@jump-tech-frontend/angular-common';

@Component({
  selector: 'pathway-disable-project-auto-assignment-action',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<ng-container *ngIf="actionEnabled() && vm$ | async as vm">
    <div class="action-section">
      <jui-button
        readOnlyDisable
        size="sm"
        expand="true"
        [attr.data-qa]="'disableProjectAutoAssignment'"
        (click)="openModal()"
        [display]="activeTheme === 'dark' ? 'ghost' : 'contained'"
      >
        {{ vm.disableProjectAutoAssignment }}
      </jui-button>
    </div></ng-container
  >`,
  styles: [
    `
      .action-section {
        margin: var(--jds-space-stack-sm);
      }
    `
  ],
  imports: [NgIf, AsyncPipe, CoreComponentsAngularModule, ReadOnlyDisableDirective],
  providers: [DisableProjectAutoAssignmentActionPresenter, ProjectAutoAssignmentRepository]
})
export class DisableProjectAutoAssignmentActionComponent implements OnInit {
  @Input() project: Project;
  @Input() activeTheme: string;

  public vm$ = new BehaviorSubject<IDisableProjectAutoAssignmentActionVm>(null);
  constructor(private presenter: DisableProjectAutoAssignmentActionPresenter, private modalService: NgbModal) {}

  async ngOnInit(): Promise<void> {
    await this.presenter.load(this.vm$);
  }

  actionEnabled(): boolean {
    return this.presenter.actionEnabled(this.project);
  }

  openModal() {
    const modalRef = this.modalService.open(DisableProjectAutoAssignmentModalComponent, { size: 'md' });
    modalRef.result
      .then(() => {
        this.presenter.disableProjectAutoAssignment(this.project.id);
      })
      .catch(() => undefined);
  }
}
