import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-password-strength-checker',
  templateUrl: './password-strength-checker.component.html',
  styleUrls: ['../../login.component.scss'],
  standalone: true,
  imports: [NgIf, TranslocoModule]
})
export class PasswordStrengthCheckerComponent {
  @Input() form: UntypedFormGroup;

  checkFormError(errorType: string): boolean {
    return !this.form.controls['newPassword'].hasError(errorType) && this.form.controls['newPassword'].value;
  }
}
