import { inject, Injectable, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TRANSLATIONS_PROVIDER } from '../../translations.token';
import { VideoCaptureQuestionParams, VideoCaptureTranslations } from './video-capture.model';

@Injectable({ providedIn: 'root' })
export class VideoCapturePresenter {
  public readonly translationsProvider = inject(TRANSLATIONS_PROVIDER);

  load(params: VideoCaptureQuestionParams) {
    return signal({
      key: params.key,
      label: params.label,
      required: params.required ?? false,
      form: this.getForm(params),
      translations: this.getTranslations(),
      value: params?.value,
      videoSrc: params?.value?.[0]
    });
  }

  private getTranslations(): VideoCaptureTranslations {
    return {
      required: this.translationsProvider.getTranslation('required')
    };
  }

  private getForm(params: VideoCaptureQuestionParams): FormGroup {
    const { key, value, required } = params;
    return new FormGroup({
      [key]: new FormControl(value, required ? [Validators.required] : [])
    });
  }
}
