import { QuestionBase } from './question.base';

export interface RelayErrors {
  error: string;
  invalidForm: string;
  unsupportedBrowser: string;
  projectNotFound: string;
  alreadySubmitted?: string;
}

export type Card<T> = {
  type: string;
  key: string;
  label: string;
  description: string;
  questions?: QuestionBase<any>[];
  complete?: boolean;
  stepProgress?: string;
};

export interface Configuration {
  cards: Card<any>[];
  customer: string;
  customerLogo: string;
  useTenantBranding?: boolean;
  dontSubmitCards?: boolean;
  styles: string;
  font?: string;
  submissionMessage?: string;
  percentComplete?: string;
  saveToLocalStorage?: boolean;
  errors?: RelayErrors;
}

export interface HostConfiguration {
  host: string;
  configuration: Configuration;
  projectType: string;
  tenant: string;
  subTenant?: string;
  smsSenderId?: string;
  smsMessage?: string;
  errors?: RelayErrors;
}
