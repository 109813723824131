import { ProjectActionPayload } from './project-action.payload';

export interface TenantEnhancedEvent {
  event: {
    body: ProjectActionPayload<any> | any;
    path?: any;
    query?: any;
    principalId?: string;
    enhancedAuthContext: any;
  };
  userTenant: string;
  projectTenant?: string;
  defaultTenant: string;
  isOwner: boolean;
}
