import { Directive, ElementRef, Renderer2 } from '@angular/core';

export const uploadFileSeparator = '_ts_';

@Directive({
  selector: '.upload-file-input',
  standalone: true
})
export class UploadFileInput {
  constructor(private elRef: ElementRef, private renderer: Renderer2) {}
  reset() {
    const idRoot = this.elRef.nativeElement.id.split(uploadFileSeparator)[0];
    const time = new Date().getTime();
    this.renderer.setAttribute(this.elRef.nativeElement, 'id', `${idRoot}${uploadFileSeparator}${time}`);
    this.elRef.nativeElement.value = '';
  }
}
