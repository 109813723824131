import { BehaviorSubject } from 'rxjs';
import { DestroyRef, EventEmitter, inject, Injectable } from '@angular/core';
import { PhoneNumberQuestionParams, PhoneNumberQuestionViewModel } from './phone-number.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormUpdate } from '../question.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CountryISO } from 'ngx-intl-tel-input';
import { TenantConfig } from '../../../../../../apps/pathway/src/app/core/domain/user';
import { AccessService } from '../../../../../../apps/pathway/src/app/auth/services/access.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class PhoneNumberPresenter {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  /**
   * This is the default used if there is no cognitoCountryConfig in tenantConfig
   */
  defaultAllowedIsoCountry = CountryISO.UnitedKingdom;
  allowedCountries: CountryISO[] | null = null;
  preferredCountries: CountryISO[] = [];
  defaultCountry: CountryISO | null = null;
  tenantConfig: TenantConfig;
  vm: BehaviorSubject<PhoneNumberQuestionViewModel | null>;

  constructor(private featureAccessService: AccessService, private transloco: TranslocoService) {}

  async load(
    vm: BehaviorSubject<PhoneNumberQuestionViewModel | null>,
    params: PhoneNumberQuestionParams,
    formChange: EventEmitter<FormUpdate>
  ) {
    const form = this.getForm(params);
    this.subscribeToFormChanges(form, formChange, params);
    this.tenantConfig = await this.featureAccessService.getTenantConfig();
    this.setCognitoPhoneNumberPrefs();
    this.vm = vm;
    this.vm.next({
      key: `${params.key}__intlPhone`,
      form,
      label: `${params.label}${params.required ? ' *' : ''}`,
      allowedCountries: this.allowedCountries as CountryISO[],
      preferredCountries: this.preferredCountries,
      defaultCountry: this.defaultCountry as CountryISO,
      validationMessage: this.transloco.translate('common.validation.phoneNumberValidation')
    });
  }

  private getForm(params: PhoneNumberQuestionParams): FormGroup {
    const { key, value, required } = params;
    return new FormGroup(
      {
        [key]: new FormControl(value, {
          validators: required ? [Validators.required] : []
        }),
        [`${key}__intlPhone`]: new FormControl(value, {
          validators: required ? [Validators.required] : []
        })
      },
      { updateOn: 'blur' }
    );
  }

  private subscribeToFormChanges(
    form: FormGroup,
    formChange: EventEmitter<FormUpdate>,
    params: PhoneNumberQuestionParams
  ) {
    form
      .get(`${params.key}__intlPhone`)
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(change => {
        formChange.emit({
          key: params.key,
          value: change?.internationalNumber.replace(/ |-/g, '')
        });
        this.handleValidationMessage();
      });
  }

  private setCognitoPhoneNumberPrefs() {
    this.defaultCountry = this.getDefaultCountry();
    this.allowedCountries = this.getAllowedCountries();
    this.preferredCountries = this.getPreferredCountries();
  }

  private getAllowedCountries(): CountryISO[] {
    if (!this.tenantConfig.cognitoCountryConfig?.allowedCountries) {
      return [this.defaultAllowedIsoCountry];
    }

    const allowedCountries = this.tenantConfig.cognitoCountryConfig.allowedCountries as (keyof CountryISO)[];

    return allowedCountries.map(countryString => CountryISO[countryString]);
  }

  private getPreferredCountries(): CountryISO[] {
    if (!this.tenantConfig.cognitoCountryConfig) {
      return [];
    }

    return (this.tenantConfig.cognitoCountryConfig.preferredCountries || []).map(
      countryString => CountryISO[countryString]
    );
  }

  private getDefaultCountry(): CountryISO {
    if (!this.tenantConfig.cognitoCountryConfig?.defaultCountry) {
      return this.defaultAllowedIsoCountry;
    }

    return CountryISO[this.tenantConfig.cognitoCountryConfig.defaultCountry];
  }

  private handleValidationMessage() {
    const form = this.vm.value?.form;
    const intlPhone = form?.get(this.vm.value?.key as string);
    const controlValid = intlPhone && (intlPhone.touched || intlPhone.dirty) && intlPhone.valid;

    this.vm.next({
      ...(this.vm.value as PhoneNumberQuestionViewModel),
      showValidationMessage: !controlValid
    });
  }
}
