import { environment as previewBuildEnvironment } from './environment.preview';
import { environment as localBuildEnvironment } from './environment.local';
import { environment as devBuildEnvironment } from './environment.dev';
import { environment as stagingBuildEnvironment } from './environment.staging';
import { environment as productionBuildEnvironment } from './environment.prod';

interface BuildEnvironmentMap {
  [key: string]: Partial<PathwayApiEnvironment>;
}

export interface ApiEnvironmentListItem {
  id: string;
  label: string;
}

export const BuildEnvironmentMap: BuildEnvironmentMap = {
  local: localBuildEnvironment,
  dev: devBuildEnvironment,
  staging: stagingBuildEnvironment,
  prod: productionBuildEnvironment,
  preview: previewBuildEnvironment
};

export class PathwayApiEnvironment {
  readonly name: string;
  readonly dataEnv: string;
  readonly domain: string;
  readonly production: boolean;
  readonly api: string;
  readonly translationPath: string;
  readonly bucketSuffix: string;
  readonly analytics: boolean;
  readonly supportEmail: string;
  readonly pathwaySupportUrl: string;
  readonly w3wEndpoint: string;
  readonly w3wApiKey: string;
  readonly launchDarkly: LaunchDarklyConfiguration;
  readonly datadog: DatadogConfiguration;
  readonly httpRetryConfig: BackoffRetryConfig;
  readonly socketRetryConfig: BackoffRetryConfig;
  readonly appVersion: string;
  readonly staticDistribution: string;
  readonly apiRoot: string;

  readonly apiCoreRoot: string;
  readonly apiStoreRoot: string;
  readonly apiReportsRoot: string;
  readonly apiProjectsRoot: string;
  readonly apiSchedulerRoot: string;
  readonly apiCalendarRoot: string;
  readonly apiTenantSettingsRoot: string;
  readonly apiTenantsRoot: string;
  readonly apiRelayRoot: string;
  readonly apiDocumentPackRoot: string;
  readonly apiProjectUrl: string;
  readonly apiProjectCore: string;
  readonly apiJobsUrl: string;
  readonly apiMapActionsUrl: string;
  readonly apiDocumentPackUrl: string;
  readonly apiDocumentPackDefinitionUrl: string;
  readonly apiProjectsSearchUrl: string;
  readonly apiSearchProjectsUrl: string;
  readonly apiAuditLogsSearchUrl: string;
  readonly apiAuditLogsReadSearchUrl: string;
  readonly apiProjectDelegationUrl: string;
  readonly apiProjectUnDelegationUrl: string;
  readonly apiProjectGroups: string;
  readonly apiProjectGroupsSummary: string;
  readonly apiTenantConfigurationUrl: string;
  readonly apiProjectConfigurationUrl: string;
  readonly apiProjectConfigurationsUrl: string;
  readonly apiConfigurationUrl: string;
  readonly apiUsersUrl: string;
  readonly apiGlobalSettingsUrl: string;
  readonly apiTenantSettingsUrl: string;
  readonly apiTenantSettingsListUrl: string;
  readonly apiBulkUserUpdateUrl: string;
  readonly apiCalendarEntriesUrl: string;
  readonly apiEnaApplicationUrl: string;
  readonly apiEnaDeviceSearchUrl: string;
  readonly apiListJobsUrl: string;
  readonly apiUserUrl: string;
  readonly apiTaskWorkFlowUrl: string;
  readonly apiReportsUrl: string;
  readonly apiGetReportResultUrl: string;
  readonly apiRunReportUrl: string;
  readonly apiTaskManagementUrl: string;
  readonly apiSingleUseActionUrl: string;
  readonly apiCreateSingleUseActionUrl: string;
  readonly apiGetFormUrl: string;
  readonly apiCustomRoot: string;
  readonly apiAssignmentsUrl: string;
  readonly apiStandardDataUrl: string;
  readonly apiRelayUrl: string;
  readonly apiRelayParamsUrl: string;
  readonly apiRelayConfigurationsUrl: string;
  readonly apiCubeJsUrl: string;
  readonly apiGetTranslationUrl: string;
  readonly apiSignUrlForPostUrl: string;
  readonly apiSignedAttachmentUrl: string;
  readonly apiLeadManagement: string;
  readonly apiLeadManagementRegistration: string;
  readonly apiLeadManagementSignUp: string;
  readonly apiStaticMapSign: string;

  constructor(env: Partial<PathwayApiEnvironment>) {
    for (const prop in env) {
      this[prop] = env[prop];
    }

    this.apiCoreRoot = `${this.apiRoot}/core`;
    this.apiStoreRoot = `${this.apiRoot}/store`;
    this.apiReportsRoot = `${this.apiRoot}/reports`;
    this.apiProjectsRoot = `${this.apiRoot}/projects`;
    this.apiSchedulerRoot = `${this.apiRoot}/scheduler`;
    this.apiCalendarRoot = `${this.apiSchedulerRoot}/calendar`;
    this.apiTenantSettingsRoot = `${this.apiRoot}/tenant-settings`;
    this.apiTenantsRoot = `${this.apiRoot}/tenants`;
    this.apiRelayRoot = `${this.apiRoot}/relay`;
    this.apiDocumentPackRoot = `${this.apiRoot}/document-management/document-pack`;
    this.apiProjectUrl = `${this.apiProjectsRoot}`;
    this.apiProjectCore = `${this.apiCoreRoot}/project`;
    this.apiJobsUrl = `${this.apiRoot}/jobs`;
    this.apiMapActionsUrl = `${this.apiRoot}/actions`;
    this.apiDocumentPackUrl = `${this.apiDocumentPackRoot}`;
    this.apiDocumentPackDefinitionUrl = `${this.apiDocumentPackRoot}/definition`;
    this.apiProjectsSearchUrl = `${this.apiProjectsRoot}/search`;
    this.apiSearchProjectsUrl = `${this.apiProjectsRoot}/internal/searchProjects`;
    this.apiAuditLogsSearchUrl = `${this.apiProjectsRoot}/audit/search`;
    this.apiAuditLogsReadSearchUrl = `${this.apiRoot}/audit-logs-read/search`;
    this.apiProjectDelegationUrl = `${this.apiProjectsRoot}/delegate`;
    this.apiProjectUnDelegationUrl = `${this.apiProjectsRoot}/undelegate`;
    this.apiProjectGroups = `${this.apiProjectsRoot}/groups`;
    this.apiProjectGroupsSummary = `${this.apiRoot}/projects-read/summary`;
    this.apiTenantConfigurationUrl = `${this.apiCoreRoot}/tenant/configuration`;
    this.apiProjectConfigurationUrl = `${this.apiCoreRoot}/project/configuration`;
    this.apiProjectConfigurationsUrl = `${this.apiCoreRoot}/project/configurations`;
    this.apiConfigurationUrl = `${this.apiCoreRoot}/pathway/configuration`;
    this.apiUsersUrl = `${this.apiCoreRoot}/users`;
    this.apiGlobalSettingsUrl = `${this.apiTenantSettingsRoot}/settings/global`;
    this.apiTenantSettingsUrl = `${this.apiTenantSettingsRoot}/settings`;
    this.apiTenantSettingsListUrl = `${this.apiTenantSettingsRoot}/list`;
    this.apiBulkUserUpdateUrl = `${this.apiTenantSettingsRoot}/users`;
    this.apiEnaApplicationUrl = `${this.apiRoot}/ena/application`;
    this.apiEnaDeviceSearchUrl = `${this.apiRoot}/ena/device/search`;
    this.apiCalendarEntriesUrl = `${this.apiCalendarRoot}/entries`;
    this.apiListJobsUrl = `${this.apiSchedulerRoot}/jobs`;
    this.apiUserUrl = `${this.apiCoreRoot}/user`;
    this.apiTaskWorkFlowUrl = `${this.apiCoreRoot}/tasks/workflow`;
    this.apiReportsUrl = `${this.apiReportsRoot}/list`;
    this.apiGetReportResultUrl = `${this.apiReportsRoot}/report/result`;
    this.apiRunReportUrl = `${this.apiReportsRoot}/report`;
    this.apiTaskManagementUrl = `${this.apiRoot}/tasks`;
    this.apiSingleUseActionUrl = `${this.apiTenantsRoot}/singleUseAction`;
    this.apiCreateSingleUseActionUrl = `${this.apiTenantsRoot}/createSingleUseAction`;
    this.apiGetFormUrl = `${this.apiTenantsRoot}/form`;
    this.apiCustomRoot = this.apiRoot;
    this.apiAssignmentsUrl = `${this.apiRoot}/tenant-configuration/assignments`;
    this.apiStandardDataUrl = `${this.apiRoot}/tenant-configuration/standard-data`;
    this.apiRelayUrl = `${this.apiRelayRoot}`;
    this.apiRelayParamsUrl = `${this.apiRelayRoot}/params`;
    this.apiRelayConfigurationsUrl = `${this.apiRelayRoot}/forms`;
    this.apiCubeJsUrl = `${this.apiRoot}/cubejs-api/v1`;
    this.apiGetTranslationUrl = `${this.apiRoot}/i18n`;
    this.apiSignUrlForPostUrl = `${this.apiStoreRoot}/sign`;
    this.apiSignedAttachmentUrl = `${this.apiStoreRoot}/attachment`;
    this.apiLeadManagement = `${this.apiRoot}/todo`;
    this.apiLeadManagementRegistration = `${this.apiRoot}/leads/register`;
    this.apiLeadManagementSignUp = `${this.apiRoot}/leads/sign-up`;
    this.apiStaticMapSign = `${this.apiRoot}/maps/sign`;
  }
}

interface LaunchDarklyConfiguration {
  clientToken: string;
}

interface DatadogConfiguration {
  site: string;
  service: string;
  clientTokenLogging: string;
  clientToken: string;
  applicationId: string;
  sampleRate: number;
  replaySampleRate: number;
  actionNameAttribute: string;
  trackInteractions: boolean;
}

interface BackoffRetryConfig {
  initialInterval: number;
  maxRetries: number;
  resetOnSuccess: boolean;
}
