<div class="modal-wrapper">
  <div class="modal-header-wrapper">
    <div class="modal-header">
      <div>Project Structure Preview</div>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="jsonPreview"></div>
  </div>
</div>
