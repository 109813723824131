import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActionModel } from '../../task.model';
import { ActionModalComponent } from './action-modal.component';
import { ActionPresenter } from './action.presenter';

@Component({
  selector: 'task-action-component',
  template: `<div [attr.data-qa]="'actionPlaceholder'"></div>`,
  standalone: true,
  providers: [ActionPresenter]
})
export class ActionComponent implements OnInit {
  private modalRef?: NgbModalRef;

  constructor(private presenter: ActionPresenter, private modalService: NgbModal) {}

  ngOnInit() {
    this.presenter.load((vm: ActionModel) => {
      if (vm && !this.modalRef) {
        this.modalRef = this.modalService.open(ActionModalComponent, vm.options);
        this.modalRef.componentInstance.project = vm.project;
        this.modalRef.componentInstance.action = vm.projectAction;
        this.modalRef.componentInstance.resources = vm.resources;
        this.modalRef.componentInstance.activeTheme = vm.activeTheme;
        this.modalRef.componentInstance.submitEnabled = vm.enableSubmit;
        this.modalRef.componentInstance.fullScreen = vm.fullScreen;
        this.modalRef.result
          .then(data => {
            this.presenter.invokeAction(vm.projectAction, data);
            this.modalRef = null;
          })
          .catch(() => {
            this.presenter.clearAction();
            this.modalRef = null;
          });
      }
    });
  }
}
