import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';

import { NgxSpinnerModule } from 'ngx-spinner';
import { CoreModule } from './core/core.module';
import { OcrService } from './core/ocr.service';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { DefaultFormComponent } from './card/default/default-form.component';
import { OcrFormComponent } from './card/ocr/ocr-form.component';
import { ImageCaptureFormComponent } from './card/image-capture/image-capture-form.component';
import { CarouselMultipleFormComponent } from './card/carousel/carousel-multiple-form.component';
import { AddressFormComponent } from './card/address/address-form.component';
import { QuestionsComponent } from './core/questions.component';
import { NguCarouselModule } from '@ngu/carousel';
import { CardComponent } from './core/card.component';
import { InformationFormComponent } from './card/information/information-form.component';
import { InformationGroupsComponent } from './card/information/information-groups.component';
import { ConsentFormComponent } from './card/consent/consent-form.component';
import { CarouselFormComponent } from './card/carousel/carousel-form.component';
import { AddressMyLocationFormComponent } from './card/my-location/address-my-location-form.component';
import { ApiService } from './core/api.service';
import { PostCodeLookupModule } from '@jump-tech-frontend/address-lookup';
import { AddressLookupV2Component } from '@jump-tech-frontend/address-lookup-v2';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { SummaryFormComponent } from './card/summary/summary-form.component';
import { QuoteFormComponent } from './card/quote/quote-form.component';
import { VideoCaptureComponent } from '@jump-tech-frontend/question-components';
import { SignaturePadComponent } from './core/signature-pad.component';

@NgModule({
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    NguCarouselModule,
    CoreModule,
    DefaultFormComponent,
    OcrFormComponent,
    ImageCaptureFormComponent,
    CarouselFormComponent,
    CarouselMultipleFormComponent,
    AddressFormComponent,
    AddressMyLocationFormComponent,
    CardComponent,
    QuestionsComponent,
    InformationGroupsComponent,
    InformationFormComponent,
    ConsentFormComponent,
    SummaryFormComponent,
    QuoteFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CoreModule,
    NgxSpinnerModule,
    SignaturePadComponent,
    NguCarouselModule,
    PostCodeLookupModule,
    AddressLookupV2Component,
    CoreComponentsAngularModule,
    VideoCaptureComponent,
    DefaultFormComponent,
    OcrFormComponent,
    ImageCaptureFormComponent,
    CarouselFormComponent,
    CarouselMultipleFormComponent,
    AddressFormComponent,
    AddressMyLocationFormComponent,
    CardComponent,
    QuestionsComponent,
    InformationGroupsComponent,
    InformationFormComponent,
    ConsentFormComponent,
    SummaryFormComponent,
    QuoteFormComponent
  ],
  providers: [OcrService, ApiService, provideHttpClient(withInterceptorsFromDi())]
})
export class JumptechCardsLibModule {}
