import { NgIf } from '@angular/common';
import { Component, effect, input, OnDestroy, OnInit, signal } from '@angular/core';
import { IfDefinition, QuestionBase } from '@jump-tech-frontend/domain';
import { FormGroup } from '@angular/forms';
import { QuestionLabelContentComponent } from '../components/question-label-content.component';
import { checkIfExpression } from '@jump-tech-frontend/angular-common';
import { QuestionHintComponent } from '../components/question-hint.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'question-wrapper',
  standalone: true,
  imports: [NgIf, QuestionLabelContentComponent, QuestionHintComponent],
  template: ` @if (show()) {
    <label *ngIf="question().label" class="form-label" [attr.for]="question().key" [attr.data-qa]="question().key">
      <question-label-content [question]="question()"></question-label-content>
    </label>
    <ng-content #component></ng-content>
    <question-hint [question]="question()"></question-hint>
    }`
})
export class QuestionWrapperComponent implements OnInit, OnDestroy {
  question = input.required<QuestionBase<unknown>>();
  form = input.required<FormGroup>();
  show = signal(false);
  subscription: Subscription;

  constructor() {
    effect(() => {
      if (this.show()) {
        this.form().get(this.question().key)?.enable();
      } else {
        this.form().get(this.question().key)?.disable();
      }
    });
  }

  ngOnInit() {
    this.subscription = this.form().valueChanges.subscribe(() => {
      this.show.set(
        checkIfExpression(this.question().showIf as IfDefinition, this.form().getRawValue(), this.question().value)
      );
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
