<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngFor="let action of vm">
    <div class="project-schedule-actions" *ngIf="action.visible">
      <jui-button
        readOnlyDisable
        [readOnlyDisableIsDisabled]="!action.enabled"
        (click)="openScheduleModal(action)"
        size="sm"
        expand="true"
        [attr.data-qa]="action.qaButtonSchedule"
        [display]="activeTheme === 'dark' ? 'ghost' : 'contained'"
      >
        {{ action.label }}
      </jui-button>
    </div>
  </ng-container>
</ng-container>
