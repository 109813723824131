<ng-container *ngIf="vm">
  <div class="doc-manager-preview">
    <div class="doc-manager-preview__header">
      <button
        type="button"
        class="close doc-manager-preview__close"
        aria-describedby="modal-title"
        (click)="closeModal('Dismiss')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="doc-manager-preview__title">
        <div>
          {{ vm.title }}
          <span class="doc-manager-preview__state" [class.doc-manager-preview__state--done]="vm.stateDone">{{
            vm.stateLabel
          }}</span>
        </div>
      </div>
    </div>
    <div class="doc-manager-preview__banner" [class.doc-manager-preview__banner--updating]="vm.isUpdatingDocument">
      {{ vm.message }}
    </div>
    <div class="doc-manager-preview__content">
      <div class="doc-manager-preview__doc">
        <div class="doc-manager-preview__frame-loading" *ngIf="vm.isUpdatingDocument">{{ vm.message }}</div>
        <iframe class="doc-manager-preview__frame" [src]="safeUrl"></iframe>
      </div>
      <div class="doc-manager-preview__actions">
        <div class="doc-manager-preview__secondary-actions">
          <div *ngIf="vm.showUpload" class="doc-manager-preview__secondary-action">
            <span class="doc-manager-preview__hint">{{ vm.uploadHint }}</span>
            <jui-button
              readOnlyDisable
              size="xs"
              color="secondary"
              class="doc-manager-preview__upload-btn"
              (click)="upload()"
              [disabled]="!vm.actionEnabled"
              [loading]="vm.isUploading"
              [attr.data-qa]="vm.qaUpload"
            >
              {{ vm.uploadLabel }}
            </jui-button>
          </div>
          <div *ngIf="vm.showRegenerate" class="doc-manager-preview__secondary-action">
            <span class="doc-manager-preview__hint">{{ vm.uploadAlternativeHint }}</span>
            <jui-button
              readOnlyDisable
              [loading]="vm.isRegenerating"
              [color]="vm.hasMissingInfo ? 'danger' : 'secondary'"
              [ngbTooltip]="vm.regenerateHint"
              size="xs"
              class="doc-manager-preview__upload-btn"
              (click)="regenerate(vm)"
              [disabled]="!vm.actionEnabled"
              [attr.data-qa]="vm.qaRegenerate"
            >
              <span slot="icon" class="material-icons" *ngIf="vm.hasMissingInfo">warning</span>
              {{ vm.regenerateLabel }}
            </jui-button>
          </div>
          <div class="doc-manager-preview__secondary-action">
            <span class="doc-manager-preview__modified-lbl">{{ vm.lastModifiedLabel }}</span>
            <span class="doc-manager-preview__modified-date"> {{ vm.lastModifiedDate }}</span>
          </div>
        </div>
        <div class="doc-manager-preview__primary-actions">
          <jui-button
            size="sm"
            color="low"
            class="doc-manager-preview__action-btn"
            (click)="closeModal('Cancel')"
            [disabled]="!vm.actionEnabled"
            [attr.data-qa]="vm.qaCloseModal"
          >
            {{ vm.cancelLabel }}
          </jui-button>
          <jui-button
            readOnlyDisable
            size="sm"
            color="secondary"
            class="doc-manager-preview__action-btn"
            (click)="notRequired(vm)"
            *ngIf="vm.showNotRequired"
            [loading]="vm.isSettingNotRequired"
            [disabled]="!vm.actionEnabled"
            [attr.data-qa]="vm.qaNotRequired"
          >
            {{ vm.notRequiredLabel }}
          </jui-button>
          <jui-button
            readOnlyDisable
            size="sm"
            class="doc-manager-preview__action-btn"
            [disabled]="!vm.actionEnabled"
            *ngIf="!vm.actionHidden"
            (click)="actionClick(vm)"
            [attr.data-qa]="vm.qaMainAction"
          >
            {{ vm.actionSubmit }}
          </jui-button>
        </div>
      </div>
    </div>
    <app-document-pack-progress></app-document-pack-progress>
  </div>
</ng-container>
