<div class="modal-wrapper event-modal" [formGroup]="form" [attr.data-qa]="'scheduleModal'">
  <input type="hidden" formControlName="id" />
  <input type="hidden" formControlName="tz" />
  <div class="modal-header-wrapper">
    <div class="modal-header">
      <div *ngIf="mode === 'edit'">{{ 'schedule.eventModalTitle' | transloco }} {{ currentEventTitle }}</div>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.close()"
        [attr.data-qa]="'modalClose'"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div *ngIf="isDeleting" class="modal-banner modal-banner--danger">
      {{ 'schedule.deleteEventModalBanner' | transloco }}
    </div>
    <div *ngIf="!isDeleting" class="modal-banner">
      {{
        mode === 'edit'
          ? ('schedule.updateEventModalBanner' | transloco)
          : ('schedule.createEventModalBanner' | transloco)
      }}
    </div>
  </div>
  <div class="modal-body">
    <div *ngIf="isDeleting" class="event-modal__confirm-hint">
      <span class="event-modal__confirm-hint-title">{{ 'schedule.deleteConfirmTitle' | transloco }}</span>
      <span>{{ 'schedule.deleteConfirmHint' | transloco }}</span>
    </div>
    <ng-container *ngIf="savingEvent">
      <div class="event-modal__row" data-qa="scheduleModalNotifications">
        <div class="event-modal__notify a-fade-in-fast">{{ 'schedule.savingEvent' | transloco }}</div>
      </div>
    </ng-container>
    <div class="event-modal__row">
      <label for="type" class="event-modal__lbl">{{ 'schedule.formFields.eventType.label' | transloco }}</label>
      <ng-select
        class="w-100 event-modal__ctrl"
        id="type"
        formControlName="type"
        placeholder="{{ 'schedule.formFields.eventType.placeholder' | transloco }}"
        [items]="eventTypes"
        (change)="onEventTypeChange($event)"
        [clearable]="false"
        [searchable]="false"
        [attr.data-qa]="'typeSelect'"
        bindValue="id"
      >
        <ng-template ng-option-tmp let-item="item">
          <div class="event-modal__opt">
            <span
              *ngIf="!rollingMonthViewEnabled"
              class="event-modal__opt-icon"
              [ngStyle]="{ 'background-color': item.colour }"
            ></span>
            {{ item.name }}
          </div>
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
          <div class="event-modal__opt">
            <span
              *ngIf="!rollingMonthViewEnabled"
              class="event-modal__opt-icon"
              [ngStyle]="{ 'background-color': item.colour }"
            ></span>
            {{ item.name }}
          </div>
        </ng-template>
      </ng-select>
    </div>

    <div class="event-modal__row" *ngIf="titleRequired">
      <label for="title" class="event-modal__lbl">
        <span
          >{{ 'schedule.formFields.title.label' | transloco }} ({{ 'common.max' | transloco }}: {{ maxLenTitle }})</span
        >
        <span *ngIf="formControls.title.value" class="event-modal__validator-hint"
          >{{ 'schedule.charactersRemaining' | transloco }}
          <span class="event-modal__validator-hint-emphasis">{{
            maxLenTitle - formControls.title.value.length
          }}</span></span
        >
      </label>
      <input
        class="form-control event-modal__ctrl"
        type="text"
        id="title"
        formControlName="title"
        placeholder="{{ 'schedule.formFields.title.placeholder' | transloco }}"
        maxlength="{{ maxLenTitle }}"
        [attr.data-qa]="'titleInput'"
      />
    </div>

    <div class="event-modal__row">
      <label for="engineer" class="event-modal__lbl">{{ 'schedule.formFields.engineers.label' | transloco }}</label>
      <ng-select
        class="w-100 event-modal__ctrl"
        formControlName="engineer"
        id="engineer"
        placeholder="{{ 'schedule.formFields.engineers.placeholder' | transloco }}"
        bindLabel="name"
        bindValue="id"
        notFoundText="{{ 'schedule.formFields.engineers.notFound' | transloco }}"
        [items]="engineerList"
        [clearable]="false"
        [attr.data-qa]="'engineerSelect'"
      ></ng-select>
    </div>
    <div class="event-modal__row">
      <label for="description" class="event-modal__lbl">
        <span
          >{{ 'schedule.formFields.description.label' | transloco }} ({{ 'common.optional' | transloco }}) ({{
            'common.max' | transloco
          }}
          {{ maxLenDesc }})</span
        >
        <span *ngIf="formControls.description.value" class="event-modal__validator-hint"
          >{{ 'schedule.charactersRemaining' | transloco }}
          <span class="event-modal__validator-hint-emphasis">{{
            maxLenDesc - formControls.description.value.length
          }}</span></span
        >
      </label>
      <textarea
        class="form-control event-modal__ctrl"
        id="description"
        formControlName="description"
        placeholder="{{ 'schedule.formFields.description.placeholder' | transloco }}"
        rows="4"
        [maxlength]="maxLenDesc"
        [attr.data-qa]="'descriptionTextarea'"
      ></textarea>
    </div>

    <div class="event-modal__row-split">
      <div class="form-group event-modal__left">
        <label for="startDate" class="event-modal__lbl">{{ 'schedule.formFields.startDate.label' | transloco }}</label>
        <div class="input-group">
          <input
            class="form-control event-modal__ctrl"
            [placeholder]="datePickerPlaceholder"
            name="dp"
            id="startDate"
            formControlName="startDate"
            ngbDatepicker
            #startDate="ngbDatepicker"
            [maxDate]="getMaxStartDate()"
            [attr.data-qa]="'startDateInput'"
          />
          <div class="input-group-append">
            <button
              class="event-modal__input-btn calendar"
              (click)="startDate.toggle()"
              type="button"
              [attr.data-qa]="'startDateButton'"
            >
              <span class="event-modal__input-btn-icon material-icons">event</span>
            </button>
          </div>
        </div>
        <div *ngIf="formControls.startDate.errors && formControls.startDate.dirty" class="form-error">
          <div *ngIf="formControls.startDate.errors?.required">
            {{ 'schedule.errors.startDateRequired' | transloco }}
          </div>
          <div *ngIf="formControls.startDate.errors?.ngbDate?.invalid">
            {{ 'schedule.errors.invalidDateFormat' | transloco }}
          </div>
          <div *ngIf="formControls.startDate.errors?.ngbDate?.maxDate">
            {{ 'schedule.errors.startDateAfterEnd' | transloco }}
          </div>
        </div>
      </div>

      <div class="form-group event-modal__right">
        <label for="endDate" class="event-modal__lbl">{{ 'schedule.formFields.endDate.label' | transloco }}</label>
        <div class="input-group">
          <input
            class="form-control event-modal__ctrl"
            [placeholder]="datePickerPlaceholder"
            name="dp"
            id="endDate"
            formControlName="endDate"
            ngbDatepicker
            #endDate="ngbDatepicker"
            [minDate]="getMinEndDate()"
            [attr.data-qa]="'endDateInput'"
          />
          <div class="input-group-append">
            <button
              class="event-modal__input-btn calendar"
              (click)="endDate.toggle()"
              type="button"
              [attr.data-qa]="'endDateButton'"
            >
              <span class="event-modal__input-btn-icon material-icons">event</span>
            </button>
          </div>
        </div>
        <div *ngIf="formControls.endDate.errors && formControls.endDate.dirty" class="form-error">
          <div *ngIf="formControls.endDate.errors?.required">{{ 'schedule.errors.endDateRequired' | transloco }}</div>
          <div *ngIf="formControls.endDate.errors?.ngbDate?.invalid">
            {{ 'schedule.errors.invalidDateFormat' | transloco }}
          </div>
          <div *ngIf="formControls.endDate.errors?.ngbDate?.minDate">
            {{ 'schedule.errors.endDateBeforeStart' | transloco }}
          </div>
        </div>
      </div>
    </div>

    <div class="form-check form-check--lg mb-2">
      <input
        class="form-check-input"
        type="checkbox"
        formControlName="allDayEvent"
        id="allDayEventCheck"
        (change)="onAllDayEventChange()"
        [attr.data-qa]="'allDayCheckbox'"
      />
      <label class="form-check-label" for="allDayEventCheck">
        {{ 'schedule.formFields.allDay.label' | transloco }}
      </label>
    </div>

    <div class="event-modal__row" *ngIf="!isAllDayEvent">
      <div class="event-modal__row-split">
        <div class="form-group w-100 event-modal__left">
          <label for="startTime" class="event-modal__lbl">{{
            'schedule.formFields.startTime.label' | transloco
          }}</label>
          <ng-select
            class="w-100"
            formControlName="startTime"
            id="startTime"
            bindLabel="label"
            [readonly]="isAllDayEvent"
            [items]="timeSlots"
            [clearable]="false"
            [attr.data-qa]="'startTimeSelect'"
          ></ng-select>
        </div>
        <div class="form-group w-100 event-modal__right">
          <label for="endTime" class="event-modal__lbl">{{ 'schedule.formFields.endTime.label' | transloco }}</label>
          <ng-select
            class="w-100"
            formControlName="endTime"
            id="endTime"
            bindLabel="label"
            [readonly]="isAllDayEvent"
            [items]="timeSlots"
            [clearable]="false"
            [attr.data-qa]="'endTimeSelect'"
          ></ng-select>
        </div>
      </div>
      <div *ngIf="form.errors" class="form-error">
        <div *ngIf="form.errors?.eventEndTimeBeforeStartTime">
          {{ 'schedule.errors.eventEndTimeBeforeStartTime' | transloco }}
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <ng-container *ngIf="isDeleting">
      <jui-button
        [disabled]="deletingEvent"
        expand
        size="sm"
        color="low"
        class="event-modal__btn"
        (click)="this.isDeleting = false"
        [attr.data-qa]="'deleteCancelButton'"
      >
        {{ 'Buttons.cancel.text' | transloco }}
      </jui-button>
      <jui-button
        [disabled]="deletingEvent"
        size="sm"
        color="danger"
        expand
        class="event-modal__btn"
        (click)="onDeleteConfirm()"
        [attr.data-qa]="'deleteConfirmButton'"
        [loading]="deletingEvent"
      >
        <span *ngIf="!deletingEvent">{{ 'Buttons.confirmDelete.text' | transloco }}</span>
        <span *ngIf="deletingEvent">{{ 'Buttons.deleting.text' | transloco }}</span>
      </jui-button>
    </ng-container>

    <jui-button
      *ngIf="mode === 'edit' && !isDeleting"
      size="sm"
      color="low"
      class="event-modal__btn"
      [disabled]=""
      (click)="onDelete()"
      [attr.data-qa]="'deleteButton'"
    >
      <span>{{ 'Buttons.deleteEvent.text' | transloco }}</span>
    </jui-button>

    <jui-button
      *ngIf="!isDeleting"
      size="sm"
      class="event-modal__btn"
      [disabled]="form.invalid || isFormInvalid() || savingEvent"
      (click)="submit()"
      [attr.data-qa]="'submitButton'"
      [loading]="savingEvent"
    >
      <span *ngIf="!savingEvent">{{
        mode === 'edit' ? ('Buttons.update.text' | transloco) : ('Buttons.save.text' | transloco)
      }}</span>
      <span *ngIf="savingEvent">{{
        mode === 'edit' ? ('Buttons.updating.text' | transloco) : ('Buttons.saving.text' | transloco)
      }}</span>
    </jui-button>
  </div>
</div>
