import { Injectable } from '@angular/core';
import { ProjectAutoAssignmentRepository } from '../project-auto-assignment.repository';
import { BehaviorSubject } from 'rxjs';
import { IDisableProjectAutoAssignmentActionVm } from '../project-auto-assignment.model';
import { Project } from '../../../core/domain/project';
import { shouldShowPreAssignmentDetails } from '../project-auto-assignment.service';

@Injectable()
export class DisableProjectAutoAssignmentActionPresenter {
  constructor(private repository: ProjectAutoAssignmentRepository) {}

  async load(vm$: BehaviorSubject<IDisableProjectAutoAssignmentActionVm>) {
    const translations = this.repository.getActionTranslations();
    vm$.next({
      disableProjectAutoAssignment: translations.disableProjectAutoAssignment
    });
  }
  disableProjectAutoAssignment(projectId: string) {
    this.repository.disableProjectAutoAssignmentAndNotify(projectId);
  }

  actionEnabled(project: Project): boolean {
    return shouldShowPreAssignmentDetails(project);
  }
}
