import { SearchContext } from '../search-context';
import { ApiService } from '../../core/api.service';
import { Injectable } from '@angular/core';
import { NgxCsvWrapper } from './ngx-csv-wrapper';
import { TranslocoService } from '@ngneat/transloco';
import { SearchResult } from '../search-result';

@Injectable({ providedIn: 'root' })
export class ExportService {
  constructor(
    protected apiService: ApiService,
    protected ngxCsv: NgxCsvWrapper,
    private translocoService: TranslocoService,
    private searchContext: SearchContext
  ) {}

  private scroll(context: SearchContext, callback, user: any, projectConfiguration?: any) {
    this.apiService.search(context).subscribe(
      result => {
        context.postUpdate(this.translocoService, result, user, projectConfiguration?.states || [], true);
        callback(context);
      },
      err => {
        console.error('Export failed', err);
        context.handleError(err);
        throw err;
      }
    );
  }

  private doExport(exportConfiguration: any, results: SearchResult[], canDelegate?: boolean, eventFilterType?: string) {
    const prefix = exportConfiguration.prefix;
    const fieldList = exportConfiguration.fields;

    const opts = {
      headers: fieldList.map(field => field.header)
    };
    if (canDelegate) {
      opts.headers.push(this.translocoService.translate('common.assignedDate'));
      opts.headers.push(this.translocoService.translate('common.installer'));
    }

    if (eventFilterType) {
      opts.headers.push(this.translocoService.translate('common.eventFilterType'));
    }

    const data: any[] = results.map(item => {
      const result = {};

      for (const fieldItem of fieldList) {
        result[fieldItem.header] = this.escapeValue(item.getField(fieldItem.field, fieldItem.context));
      }

      if (canDelegate) {
        result[this.translocoService.translate('common.assignedDate')] = item.assigned_to_date;
        result[this.translocoService.translate('common.installer')] = item.installer;
      }

      if (eventFilterType) {
        result[this.translocoService.translate('common.eventFilterType')] = item[eventFilterType];
      }

      return result;
    });
    this.ngxCsv.export(data, `${prefix}_${new Date().getTime()}`, opts);
  }

  async export(
    exportConfiguration: any,
    searchContext: SearchContext,
    user?: any,
    projectConfiguration?: any,
    canDelegate?: boolean,
    eventFilterType?: string
  ): Promise<void> {
    const self = this;
    this.searchContext
      .withExportPage(1)
      .withExportPageSize(100)
      .withScroll('30s') // Should have made next query within 30s
      .withExport(true);

    let results: SearchResult[] = [];

    return new Promise((resolve, reject) => {
      this.scroll(
        this.searchContext,
        function getMoreUntilDone(context: SearchContext) {
          if (!context.hasExportResults()) {
            if (results.length > 0) {
              self.doExport(exportConfiguration, results, canDelegate, eventFilterType);
              resolve();
              return;
            } else {
              reject('No data to export');
              return;
            }
          }

          results = results.concat(context.getExportResults());
          self.scroll(context, getMoreUntilDone, user, projectConfiguration);
        },
        user,
        projectConfiguration
      );
    });
  }

  escapeValue(value: string): string {
    if (/^[+\-=@].*/.test(value)) {
      return `'${value}`;
    }
    return value;
  }
}
