import { Duration, EventApi, ViewApi } from '@fullcalendar/core';
import { ResourceApi } from '@fullcalendar/resource';
import { CalendarEvent } from '../../../core/domain/event';
import { Job, JobAssignment } from '../../../core/domain/job';
import { DropDownElement } from '../../../shared/form-components/multiple-selection-dropdown.component';
import { TradesPerson } from '../schedule.model';

export interface EventTypeDropDownElement {
  id: string;
  name?: string;
  label?: string;
  sticky?: boolean;
  busy?: boolean;
  title?: boolean;
}

export interface ScheduleQuestion {
  label: string;
  key: string;
  required?: boolean;
  /** This will have the currently assigned engineer in <name>|<id> format */
  assignedTo?: string;
  jobId?: string;
  jobType?: string;
  resources?: UserResource[];
  scheduleDate?: string;
  preferredDate?: string;
  preferredTimeslot?: string;
  defaultDuration?: number | string;
  eventConstraint?: { start: string; end: string } | string;
}

export interface TooltipInfo {
  title: string;
  assignedTo: string;
  showTitle?: boolean;
  status?: string;
  bodyItems?: string[];
  colour?: string;
  textColour?: string;
  icon?: string;
  isAbsence?: boolean;
}

export interface UserResource {
  value: string;
}

export interface Resource {
  id: string;
  title: string;
  tags?: string[];
}

export interface FullCalendarEvent extends EventApi {
  extendedProps: CalendarEventV3;
}

export interface JobEvent extends EventApi {
  extendedProps: CalendarEventV3 & Job;
}

export interface JobStyle {
  icon: string;
  color: string;
  border: string;
  outline: string;
  backgroundColor: string;
  label: string;
  key: string;
}

export interface ViewInfo {
  el: HTMLElement;
  view: ViewApi;
}

export interface EventInfo extends ViewInfo {
  event: JobEvent;
  oldEvent?: JobEvent;
  jsEvent: MouseEvent;
  isResizing: boolean;
  isDragging: boolean;
  newResource?: ResourceApi | Resource;
  oldResource?: ResourceApi | Resource;
  delta?: Duration;
}

export interface EventDropInfo extends EventInfo {
  newResource: Resource;
  oldResource: Resource;
}

export interface ResourceInfo extends ViewInfo {
  resource: {
    // TODO there must be a better way to refer to the resource??
    _resource: {
      id: string;
      title: string;
      extendedProps: Resource;
    };
  };
}

export interface DateRangeSelectInfo {
  start: Date;
  end: Date;
  resource: Resource;
  view?: ViewInfo;
}

export enum SelectedEventType {
  link = 'link',
  normal = 'normal'
}

export interface ScheduleTypeListItem {
  id: string;
  name: string;
  description: string;
  busy: boolean;
  colour: string;
  textColour: string;
  title: boolean;
  sort: number;
  icon: string;
  translation: string;
}

export interface JobInformation {
  id: string;
  address?: { [key: string]: string };
  assignedToDisplayName?: string;
  assignedTo?: string;
  type: string;
  customerFirstName: string;
  customerLastName: string;
  jobAssignments: JobAssignment[];
  tradesPeople?: TradesPerson[];
  contactInfo: {
    email?: string;
    telephoneNumber?: string;
  };
  startDateTimestamp: string;
  fullJobStart?: string;
  fullJobEnd?: string;
  endDateTimestamp: string;
  projectId: string;
  isInitialSchedule: boolean;
  defaultDuration?: number;
  tenantType?: string;
  setDefaultStartTime?: boolean;
  actionId?: string;
  context?: string;
}

export interface JobInformationEventContext {
  jobInformation: JobInformation;
  callback: any;
  event: EventInfo;
}

// do not remove because its currently unused - will be required once we migrate everyone over to v3
export interface ScheduleJobActionVm {
  modalTitleActionLabel: string;
  modalTitle: string;
  label: string;
  isReschedule: boolean;
  visible: boolean;
  enabled: boolean;
  selectedJob: Job;
  qaButtonSchedule: string;
  actionId?: string;
}

export interface ScheduleFilterInformation {
  jobTypes: DropDownElement[];
  scheduleEventTypes: DropDownElement[];
  tradesmen: DropDownElement[];
  jobStatuses: DropDownElement[];
  selectedJobTypes: string[];
  selectedScheduleEventTypes: string[];
  selectedTradespeople: string[];
  selectedJobStatuses: string[];
}

export interface JobDateTimeFormControls {
  scheduledStartDate: { year: number; month: number; day: number };
  scheduledEndDate: { year: number; month: number; day: number };
  scheduledStartTime: DropDownElement;
  scheduledEndTime: DropDownElement;
}

export interface SelectedScheduleFilters {
  selectedJobTypes: string[];
  selectedScheduleEventTypes: string[];
  selectedTradespeople: string[];
  selectedJobStatuses: string[];
}

// todo these are temporary interfaces and should be promoted back to core/domain once v1 + v2 is switched off!
export interface CalendarEventV3 extends CalendarEvent {
  isPartial?: boolean;
  linkId?: string;
  constraint?: JobConstraint;
  fullJobStart?: string;
  fullJobEnd?: string;
}

export interface JobConstraint {
  start: string;
  end: string;
}
