import { Component, Input } from '@angular/core';
import { MyOrdersVm, ProductLineItem } from '../../my-orders.model';
import { MyOrdersPresenter } from '../../my-orders.presenter';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIf } from '@angular/common';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'pathway-my-orders-products-selection',
  templateUrl: './products-selection.component.html',
  styleUrls: ['../../my-orders.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgSelectModule,
    NgxIntlTelInputModule,
    NgOptionHighlightModule,
    NgIf,
    NgbDatepickerModule
  ]
})
export class MyOrdersProductsSelectionComponent {
  @Input() vm: MyOrdersVm;

  constructor(private presenter: MyOrdersPresenter) {}

  markDateDisabled = date => {
    return this.presenter.isDateDisabled(date);
  };

  searchItems(term: string, item: ProductLineItem): boolean {
    term = term.toLowerCase();
    return (
      item.description?.toLowerCase().indexOf(term) > -1 ||
      item.description?.toLowerCase() === term ||
      item.name.toLowerCase().indexOf(term) > -1 ||
      item.name.toLowerCase() === term
    );
  }
}
