import { ExportConfiguration } from '../../../../apps/pathway/src/app/dashboards/abstract.dashboard';
import { CustomDashboardFilter } from '../../../../apps/pathway/src/app/dashboards/custom-dashboard-filters/custom-dashboard-filter.component';
import { IDocumentPackDefinition } from '../../../../apps/pathway/src/app/project-detail/document-pack/document-pack.model';
import { TriggerConfig } from './project';

export interface Delegate {
  tenant: string;
  delegationType: string;
  label?: string;
}

export interface AcceptedFileType {
  type: string;
  label: string;
}

export interface CustomAttachment {
  label: string;
  id: string;
  uploadAsFilename: string;
  acceptedFileTypes: AcceptedFileType[];
}

export interface SummaryConfigurationItem {
  label: string;
  value: string;
  default?: string;
}

export interface SummaryConfiguration {
  items?: SummaryConfigurationItem[];
  managerRole?: string;
}

export type Mappings = {
  [s: string]: string;
};

/**
 * This is the section from the project-configuration that controls job creation
 */
export interface JobTypeConfiguration {
  /** The job type to create, defaults to the same as the project type */
  jobType: string;
  /** The status the new job should have, defaults to CREATED if not specified */
  initialStatus?: string;
  /** Pass them to Mustache to read in the values from the project and payload and set fields on the job */
  jobMappings: Mappings;
  /** Pass these to Mustache to read in the values from the payload and set fields on the project */
  projectMappings: Mappings;
  /** Support a list of triggers from job status to project status */
  triggers: TriggerConfig[];
}

export interface ProjectConfiguration {
  projectType: string;
  configuration: ProjectConfigurationConfiguration;
  documentPackDefinition?: IDocumentPackDefinition;
  tenant: string;
  applicableTenants?: string[];
}

export interface ProjectConfigurationConfiguration {
  states: any[];
  actions: any[];
  layouts: any[];
  delegates?: Delegate[];
  eventActions?: any;
  outputs: any;
  jobTypes?: JobTypeConfiguration[];
  customAttachments?: CustomAttachment[];
  newProjectLayout?: { items?: any[] };
  summaryConfiguration?: SummaryConfiguration;
  customDashboardFields?: CustomDashboardFilter[];
  customDashboardFilters?: CustomDashboardFilter[];
  exportConfiguration?: ExportConfiguration;
  documentPackDefinition?: IDocumentPackDefinition;
  managerRole?: string;
}
