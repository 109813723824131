import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'underscore';
import { Project } from '../../core/domain/project';
import { User } from '../../core/domain/user';
import { checkIfExpression, IfDefinition } from '../../core/utils/filter';
import { AccessInfoHelper } from '../../shared/access-info.helper';
import { ProjectAction, RequiredDataType, Task, TaskForm, TaskShowIf } from './task.model';
import { diff } from 'deep-object-diff';

export function filterForUser<T>(tasks: T[], user: User) {
  return (tasks || []).filter(task => {
    return AccessInfoHelper.filterListForUser(task, user);
  });
}

export function shouldShow(task: Task, taskForms: TaskForm[], project: Project): boolean {
  if (task.show === false) {
    // Explicitly don't show
    return false;
  }

  const showCollection = task.show ? (Array.isArray(task.show) ? task.show : [task.show]) : [];

  if (!showCollection.length) {
    return true;
  }

  return (
    showCollection
      .map(show => {
        if (show.key) {
          const taskForm = taskForms.find(tg => tg.task.id === show.taskId);
          if (!taskForm) {
            return false;
          }
          return taskFilter(show, taskForm.model.form);
        }
        return taskProjectFilter(show, project);
      })
      .indexOf(false) === -1
  );
}

function taskFilter(show: TaskShowIf, form: UntypedFormGroup): boolean {
  return form && form.get(show.key).value === show.value;
}

function taskProjectFilter(show: IfDefinition, project: Project): boolean {
  const resources = {};
  for (const resource of project.resources) {
    if (resource && resource.summary && resource.type) {
      resources[resource.type] = {};
      for (const summaryKey of Object.keys(resource.summary)) {
        resources[resource.type][summaryKey] = resource.summary[summaryKey];
      }
    }
  }
  const resourceProject = Object.assign({}, project, { resources: resources });
  return checkIfExpression(show, resourceProject);
}

export function projectDifferences(originalProject: Project, updatedProject: Project) {
  const projectDiff: Partial<Project> = diff(originalProject, updatedProject);
  const filtered = Object.keys(projectDiff).filter(k => {
    const value = projectDiff[k];
    if (typeof value === 'undefined') {
      return false;
    }
    if (k === 'updated_on') {
      return false;
    }
    if (k === 'attachments') {
      return true;
    }

    function checkSubDiffs(value, parentKeys) {
      const subDiffs = Object.keys(value).filter(j => {
        const subValue = value[j];
        if (subValue && typeof subValue === 'object') {
          return checkSubDiffs(subValue, [...parentKeys, j]);
        }

        if (subValue?.toString().startsWith('https')) {
          const firstKey = parentKeys[0];
          let data = originalProject?.[firstKey];
          for (let i = 1; i < parentKeys.length; i++) {
            data = data?.[parentKeys[i]];
          }
          const imageUrl = data?.[j];
          return imageUrl?.replace(/\?.*/, '') !== subValue?.replace(/\?.*/, '');
        }
        return true;
      });

      return subDiffs.length;
    }

    if (value && typeof value === 'object') {
      return checkSubDiffs(value, [k]);
    }
    return true;
  });

  return filtered;
}

export function getInvalidDataLabels(action: ProjectAction, project: Project): { label: string; goto: string }[] {
  if (!action.requiredData || !action.requiredData.length) {
    return [];
  }

  let projectResource;
  let filter;
  return action.requiredData
    .filter(dataItem => {
      switch (dataItem.type) {
        case RequiredDataType.attachment:
        case RequiredDataType.ownerTeam:
          return false;
        case RequiredDataType.resource:
          if (!dataItem.resource) {
            return false;
          }
          projectResource = project.resources.find(r => r.type === dataItem.resource);
          return !(projectResource && projectResource.summary[dataItem.key]);
        default:
          filter = dataItem.filter || {};
          if (
            Object.prototype.hasOwnProperty.call(dataItem, 'key') &&
            !Object.prototype.hasOwnProperty.call(dataItem, 'value')
          ) {
            // Handle legacy
            filter[dataItem.key] = { $ne: '' };
          }
          return !checkIfExpression(filter, project);
      }
    })
    .map(dataItem => ({ label: dataItem.label, goto: dataItem?.goto }));
}

export function getInvalidAttachmentLabels(action: ProjectAction, project: Project): { label: string; goto: string }[] {
  if (!action.requiredData || !action.requiredData.length) {
    return [];
  }
  return action.requiredData
    .filter(dataItem => {
      return dataItem.type && dataItem.type == RequiredDataType.attachment;
    })
    .filter(dataItem => {
      if (!project.attachments || !project.attachments.length) {
        return true;
      }
      const filteredAttachments = project.attachments.filter(attachment => {
        return attachment.key == dataItem.key;
      });
      return !filteredAttachments.length;
    })
    .map(dataItem => ({ label: dataItem.label, goto: dataItem?.goto }));
}

export function getInvalidOwnerTeamLabels(action: ProjectAction, user?: User): { label: string; goto: string }[] {
  if (!action.requiredData || !action.requiredData.length) {
    return [];
  }

  const ownerUser: User = user;

  return action.requiredData
    .filter(dataItem => {
      return dataItem.type && dataItem.type == RequiredDataType.ownerTeam;
    })
    .filter(dataItem => {
      return (
        !ownerUser ||
        !ownerUser.accessInfo ||
        !AccessInfoHelper.accessInfoHasRoleOrTeam(ownerUser.accessInfo, { teamName: [dataItem.key] })
      );
    })
    .map(dataItem => ({ label: dataItem.label, goto: dataItem?.goto }));
}

export function getProjectResources(project: Project) {
  const resources = {};
  project.resources?.map(resource => {
    if (resource.summary) {
      resources[resource.type] = resource.summary;
    }
  });
  return resources;
}

export function isDifferent(prev: any, next: any): boolean {
  if (prev) {
    for (const key of Object.keys(prev)) {
      if (Object.prototype.hasOwnProperty.call(next, key) && !_.isEqual(prev[key], next[key])) {
        return true;
      }
    }
  }
  return false;
}
