import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  QueryList,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Card } from '@jump-tech-frontend/domain';
import { FormUpdate, IQuestionComponent } from '../questions/question.model';
import { CardToQuestionsComponent } from './card-to-questions.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf } from '@angular/common';

@Component({
  selector: 'question-group',
  template: `
    <div class="card-wrapper">
      <div class="card__title-header">
        <div class="card__title">{{ card.label }}</div>
        <div class="card__step" *ngIf="!card.complete">{{ card.stepProgress }}</div>
      </div>
      <question-card-to-questions #questions [card]="card"></question-card-to-questions>
    </div>
  `,
  standalone: true,
  imports: [CardToQuestionsComponent, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      question-card-to-questions {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
      }
      form-label {
        margin-bottom: 0.25rem;
      }
    `
  ],
  styleUrls: ['../sass/relay.scss']
})
export class QuestionGroupComponent implements AfterViewInit {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  @ViewChild('questions') questionsComponent: CardToQuestionsComponent;

  @Input() form: FormGroup;
  @Input() card: Card<string>;

  ngAfterViewInit() {
    this.questionsComponent.questions.changes.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((): void => {
      this.subscribeToQuestions(this.questionsComponent.questions);
    });
    this.subscribeToQuestions(this.questionsComponent.questions);
  }

  private subscribeToQuestions(questions: QueryList<IQuestionComponent>): void {
    questions.forEach(questionComponent => {
      this.subscribeToQuestion(questionComponent);
    });
  }

  private subscribeToQuestion(questionComponent: IQuestionComponent): void {
    questionComponent.formChange?.subscribe((formUpdate: FormUpdate) => {
      if (formUpdate) {
        this.form.controls[formUpdate.key]?.setValue(formUpdate.value);
      }
    });
  }
}
