<div class="modal-wrapper">
  <ngx-spinner
    bdColor="var(--jds-theme-spinner-bd-rgba)"
    size="small"
    type="line-scale"
    name="modalImageSpinner"
    [attr.data-qa]="'spinner'"
  ></ngx-spinner>
  <div class="modal-header-wrapper">
    <div class="modal-header">
      <div>{{ currentImageKey | camelToReadable }}</div>
      <jui-icon name="close" color="high" size="sm" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      </jui-icon>
    </div>
  </div>
  <div class="gallery-v2-modal">
    <div class="gallery-v2-modal__preview">
      @for (image of galleryImages; track image; let index = $index) {
      <crds-http-image
        [src]="getPreviewImageSrc(index)"
        [imageClass]="getImageStateClass(index)"
        [downloadUrl]="getPreviewImageSrc(index)"
        [attr.data-qa]="'previewImgSidebar_' + getPreviewImageKey(index)"
        (click)="setCurrentImage(index)"
        [allowDownload]="false"
        [skeletonLoaderHeight]="60"
      ></crds-http-image>
      }
    </div>
    <div class="gallery-v2-modal__main">
      <div class="gallery-v2-modal__nav-item">
        <jui-icon class="gallery-v2-modal__icon" name="keyboard_arrow_left" size="xl" color="high" (click)="galleryLeft()" title="{{ 'common.previous' | transloco }}"></jui-icon>
      </div>
      <div class="gallery-v2-modal__image-container">
        <pinch-zoom backgroundColor="transparent">
          <crds-http-image
            [src]="getSrc()"
            [downloadUrl]="getSrc()"
            imageClass="gallery-v2-modal__main-image"
            [attr.data-qa]="'previewImg_' + currentImageKey"
            [allowDownload]="true"
          ></crds-http-image>
        </pinch-zoom>
      </div>
      <div class="gallery-v2-modal__nav-item">
        <jui-icon class="gallery-v2-modal__icon" name="keyboard_arrow_right" size="xl" color="high" (click)="galleryRight()" title="{{ 'common.next' | transloco }}"></jui-icon>
      </div>

    </div>
  </div>
</div>
