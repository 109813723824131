import { IfDefinition } from './expression';

export interface Template {
  id?: string;
  tenant?: string;
  type?: string;
  name?: string;
  fromDisplayName?: string;
  from?: string;
  to?: string;
  subject?: string;
  template?: string;
  smsTemplate?: string;
  bcc?: string;
  includes?: string[];
  header?: string;
  footer?: string;
  replyTo?: { name: string; address: string };
  attachments?: S3EmailAttachment[];
  renderer?: 'MOUSTACHE' | null;
  primaryColour?: string;
  meta?: any;
}

export interface S3EmailAttachment {
  attachmentName?: string;
  name?: string;
  s3Bucket: string;
  s3Key?: string;
  s3KeyRoot?: string;
  optional?: boolean;
  conditions?: IfDefinition;
}
