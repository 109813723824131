@if (vm) {
    @if (control.type === 'text' || control.type === 'tel' || control.type === 'email') {
      <jui-input
        [label]="control.label"
        [formControl]="fc"
        [name]="control.name"
        [type]="control.type"
        [tooltip]="control.tooltip"
        size="sm"
        [required]="control.isRequired"
        [disable]="getDisabled(group, control, itemIdx)"
        [errors]="getErrors(group, control, itemIdx)"
        [messages]="control.validationMessages"
        [maxlength]="control.maxLength"
        [minlength]="control.minLength"
        [dataqastring]="control.qaFormFieldString"
      ></jui-input>
    } @else if (control.type === 'number') {
      <jui-number-input
        [label]="control.label"
        [formControl]="fc"
        [name]="control.name"
        [tooltip]="control.tooltip"
        size="sm"
        [required]="control.isRequired"
        [errors]="getErrors(group, control, itemIdx)"
        [disable]="getDisabled(group, control, itemIdx)"
        [min]="control.min"
        [max]="control.max"
        [messages]="control.validationMessages"
        [dataqastring]="control.qaFormFieldString"
      ></jui-number-input>
    } @else if (control.type === 'boolean') {
      <label
        class="jui-ng-select-label"
        [for]="control.name"
        [attr.data-qa]="control.qaEnaApplicationFormFieldLabel"
      >{{ control.label }}</label
      >
      <ng-select
        class="jui-ng-select"
        appendTo="body"
        [id]="control.name"
        [formControl]="fc"
        [items]="control.options"
        bindValue="value"
        bindLabel="label"
        [clearable]="false"
        [attr.data-qa]="control.qaEnaApplicationFormFieldInput"
      >
      </ng-select>
      @if (getErrors(group, control, itemIdx)) {
        <div class="ena-application__errors" [attr.data-qa]="control.qaEnaApplicationFormFieldError">
          {{ getValidationMessageForError(group, control, itemIdx) }}
        </div>
      } } @else if (control.type === 'select') {
      <label
        class="jui-ng-select-label"
        [for]="control.name"
        [attr.data-qa]="control.qaEnaApplicationFormFieldLabel"
      >{{ control.label }}</label
      >
      <ng-select
        class="jui-ng-select"
        appendTo="body"
        [id]="control.name"
        [formControl]="fc"
        [items]="control.options"
        bindValue="value"
        bindLabel="label"
        [clearable]="false"
        [attr.data-qa]="control.qaEnaApplicationFormFieldInput"
      >
      </ng-select>
      @if (getErrors(group, control, itemIdx)) {
        <div class="ena-application__errors" [attr.data-qa]="control.qaEnaApplicationFormFieldError">
          {{ getValidationMessageForError(group, control, itemIdx) }}
        </div>
      } } @else if (control.type === 'address') {
      <!-- address -->
      <jump-tech-frontend-address-lookup-v2
        [label]="control.label"
        context="desktop"
        [provider]="'getaddressio'"
        [disabled]="vm.inputsDisabled"
        [address]="group.form.get(control.name).value"
        (addressUpdate)="updateAddress($event, control.name, group.form)"
        [attr.data-qa]="control.qaEnaApplicationFormFieldInput"
      ></jump-tech-frontend-address-lookup-v2>
    } @else if (control.type === 'date') {
      <!-- date -->
      <div class="ena-application__date-input">
        <label
          class="jui-ng-select-label"
          [for]="control.name"
          [attr.data-qa]="control.qaEnaApplicationFormFieldLabel"
        >{{ control.label }}</label
        >
        <div class="input-group">
          <input
            class="form-control ena-application__calendar-input"
            [placeholder]="vm.i18nSelectDateMessage"
            name="dp"
            [id]="control.name"
            [formControl]="fc"
            ngbDatepicker
            #date="ngbDatepicker"
            container="body"
            [attr.data-qa]="control.qaEnaApplicationFormFieldInput"
          />
          <div class="input-group-append">
            <button class="ena-application__calendar-button" (click)="date.toggle()" type="button">
                  <span class="ena-application__calendar-icon">
                    <jui-icon name="event" size="sm" color="secondary"></jui-icon>
                  </span>
            </button>
          </div>
        </div>
      </div>
      @if (getErrors(group, control, itemIdx)) {
        <div class="ena-application__errors" [attr.data-qa]="control.qaEnaApplicationFormFieldError">
          {{ getValidationMessageForError(group, control, itemIdx) }}
        </div>
      } } @else if (control.type === 'image') {
      <!--  gallery image types-->
      <app-gallery-v2-layout
        [isInline]="true"
        [readOnly]="false"
        [project]="vm.project"
        [data]="vm.project.data"
        [galleryItem]="control.data"
        [useLayoutGroups]="false"
      >
      </app-gallery-v2-layout>
    } @else if (control.type === 'asyncDataLookup') {
      <div class="ena-application__device">
        <div class="ena-application__device-lookup">
          <label
            class="jui-ng-select-label"
            [for]="control.name"
            [attr.data-qa]="control.qaEnaApplicationFormFieldLabel"
          >{{ control.label }}</label
          >
          <ng-select
            [items]="control.options$ | async"
            [minTermLength]="3"
            [typeToSearchText]="control.typeToSearchLabel"
            [formControl]="fc"
            [loading]="control.optionLoading$ | async"
            (change)="setSelectedDevice($event, itemIdx)"
            appendTo="body"
            bindLabel="displayName"
            [typeahead]="control.optionInput$"
            [attr.data-qa]="control.qaEnaApplicationFormFieldInput"
          >
          </ng-select>
        </div>
      </div>
      @if (control.optionSelectedData) {
        <div [attr.data-qa]="control.qaEnaSelectedDeviceSearchOption" class="ena-application__device-selection">
          <div class="ena-application__device-data">
            <div class="ena-application__device-data-label">{{ vm.i18nDeviceIdLabel }}</div>
            {{ control.optionSelectedData.externalDeviceId }}
          </div>
          <div class="ena-application__device-data">
            <div class="ena-application__device-data-label">{{ vm.i18nDeviceTypeLabel }}</div>
            {{ control.optionSelectedData.deviceType }}
          </div>
          <div class="ena-application__device-data">
            <div class="ena-application__device-data-label">{{ vm.i18nManufacturerLabel }}</div>
            {{ control.optionSelectedData.manufacturerName }}
          </div>
          <div class="ena-application__device-data">
            <div class="ena-application__device-data-label">{{ vm.i18nModelLabel }}</div>
            {{ control.optionSelectedData.modelName }}
          </div>
          <div class="ena-application__device-data">
            <div class="ena-application__device-data-label">{{ vm.i18nPhaseLabel }}</div>
            {{ control.optionSelectedData.phase }}
          </div>
        </div>
      } }
}
