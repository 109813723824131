<div class="modal-wrapper">
  <div class="modal-header-wrapper">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-banner">{{ 'common.newProject' | transloco }}</div>
  </div>
  <div class="modal-body" *ngIf="!form || !formInitialised">
    <ngx-loading [show]="!form || !formInitialised" [attr.data-qa]="'loading'"></ngx-loading>
  </div>
  <form *ngIf="formInitialised" [formGroup]="form" (keyup.enter)="form.valid ? createProject() : false">
    <div class="modal-body">
      <div class="form-row mb-2">
        <div class="col-sm-12 col-md-12">
          <label for="projectType">{{ 'common.projectType' | transloco }} *</label>
          <app-form-error *ngIf="isInvalid('projectType')" field="Project Type"></app-form-error>
          <select class="form-control" id="projectType" formControlName="projectType" [attr.data-qa]="'projectType'">
            <option [value]="null">{{ 'common.pleaseSelect' | transloco }}</option>
            <option
              *ngFor="let projectConfigurationType of projectConfigurationTypes"
              [value]="projectConfigurationType.projectType"
            >
              {{ projectConfigurationType.projectType }}
            </option>
          </select>
        </div>
      </div>
      <div *ngIf="canDelegate && delegates.length">
        <label for="delegate">{{ 'common.installer' | transloco }} *</label>
        <app-form-error *ngIf="isInvalid('delegate') || isInvalid('subTenants')" field="Installer"></app-form-error>

        <div class="form-row mb-2">
          <div class="col-sm-12 col-md-6">
            <select class="form-control" id="delegate" formControlName="delegate" (change)="onDelegateChange()">
              <option [value]="null">{{ 'common.pleaseSelect' | transloco }}</option>
              <option *ngFor="let delegate of delegates" [value]="delegate.tenant">
                {{ delegate.tenant | uppercase }}
              </option>
            </select>
          </div>

          <div *ngIf="showSubTenants" class="col-sm-12 col-md-6">
            <app-multi-select
              *ngIf="selectSubTenants.length"
              [singleSelection]="true"
              [selectedItems]="[]"
              [items]="selectSubTenants"
              (itemsChanged)="setSubTenants($event)"
            ></app-multi-select>
          </div>
        </div>
      </div>
      <div *ngIf="canAssign && assignments.length">
        <label for="delegate">{{ 'common.assign' | transloco }} {{ assignmentLabel }}</label>
        <app-form-error *ngIf="isInvalid('team')" field="team"></app-form-error>

        <div class="form-row mb-2">
          <div class="col-sm-12 col-md-6">
            <select class="form-control" id="team" formControlName="team">
              <option [value]="null">{{ 'common.pleaseSelect' | transloco }}</option>
              <option *ngFor="let assignment of assignments" [value]="assignment.id">
                {{ assignment.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div *ngIf="newProjectLayout">
        <div *ngFor="let item of newProjectLayout.items">
          <div *ngIf="item['editConfig']">
            <div [ngSwitch]="item['editConfig']['key']" [attr.data-qa]="item['editConfig']['key']">
              <div *ngSwitchCase="'phoneNumber'">
                <question-phone-number
                  [params]="{
                    key: item['editConfig'].key,
                    label: item['editConfig'].label,
                    required: item['editConfig'].required,
                    value: item['editConfig'].value
                  }"
                  (formChange)="phoneNumberFieldChanged($event)"
                />
              </div>
              <div *ngSwitchDefault>
                <app-edit-layout-questions
                  *ngIf="item['editConfig'] && form"
                  [question]="item['editConfig']"
                  [form]="form"
                  [project]="partialProject"
                >
                </app-edit-layout-questions>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="form" class="modal-footer">
      <jui-button
        size="sm"
        type="submit"
        [disabled]="form.invalid"
        (click)="createProject()"
        [attr.data-qa]="'createButton'"
        [loading]="createInProgress"
      >
        {{ 'common.create' | transloco }}
      </jui-button>
    </div>
  </form>
  <ngx-spinner bdColor="var(--jds-theme-spinner-bd-rgba)" size="large" type="line-scale" [attr.data-qa]="'spinner'">
  </ngx-spinner>
</div>
