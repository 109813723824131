import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TradesPersonVm } from '../../../../schedule.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgForOf, NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReactiveFormsModule } from '@angular/forms';
import { JobAssignment } from '../../../../../../core/domain/job';

@Component({
  selector: 'schedule-event-selected-job-details-tradesperson',
  templateUrl: 'schedule-event-selected-job-details-tradesperson.component.html',
  styleUrls: ['../../../schedule-event-selected-job-details/schedule-event-selected-job-details.component.scss'],
  standalone: true,
  imports: [
    CoreComponentsAngularModule,
    NgIf,
    NgForOf,
    NgSelectModule,
    NgbDatepickerModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule
  ]
})
export class ScheduleEventSelectedJobDetailsTradespersonComponent {
  @Input() vm: TradesPersonVm;
  @Output() setLeadEngineer: EventEmitter<JobAssignment> = new EventEmitter<JobAssignment>();
  @Output() removeEngineer: EventEmitter<JobAssignment> = new EventEmitter<JobAssignment>();

  constructor() {}
}
