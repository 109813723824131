<ng-container *ngIf="vm$ | async as vm">
  <div cdkDrag class="schedule-more-details" [class.schedule-more-details--resheduling]="!vm.isInitialSchedule">
    <div class="schedule-more-details__drag-area" [attr.data-qa]="vm.qaDragHandle">
      <schedule-event-more-details-header
        [vm]="vm"
        (onCloseMoreDetails)="closeMoreDetails()"
        (onHandleGoToProject)="handleGoToProject()"
        (onHandleScheduleNow)="handleScheduleNow()"
      >
      </schedule-event-more-details-header>
    </div>
    <div class="schedule-more-details__content">
      <schedule-event-more-details-reschedule-reason-form
        [vm]="vm"
      ></schedule-event-more-details-reschedule-reason-form>
      <schedule-event-more-details-job-info [vm]="vm"></schedule-event-more-details-job-info>
      <schedule-event-more-details-schedule-date-time-form
        [vm]="vm"
        [timeSlots]="timeSlots"
        (onHandleDateTimeChange)="handleDateTimeChange()"
      >
      </schedule-event-more-details-schedule-date-time-form>
      <schedule-event-more-details-assigned-engineers
        [vm]="vm"
        (onRemoveEngineer)="removeEngineer($event)"
        (onSetLeadEngineer)="setLeadEngineer($event)"
      >
      </schedule-event-more-details-assigned-engineers>
      <schedule-event-more-details-add-engineer-form
        [vm]="vm"
        (onAddEngineer)="addEngineer($event)"
        (onConfirmAddEngineer)="confirmAddEngineer()"
        (onCancelAddEngineer)="cancelAddEngineer()"
      >
      </schedule-event-more-details-add-engineer-form>
    </div>
  </div>
</ng-container>
