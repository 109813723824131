<div class="pd-jobs">
  <div *ngFor="let job of jobs" class="pd-jobs__job">
    <div class="pd-jobs__title">{{ job.jobType }}</div>
    <div class="pd-jobs__row">
      <ng-container *ngIf="job.supportEngineers.length > 0; else singleEngineer">
        <span class="pd-jobs__label">{{ 'common.engineers' | transloco }}:</span>
        <span class="pd-jobs__lead-engineer">{{ job.assignedToDisplayName }} ({{ 'common.lead' | transloco }})</span>
        <span *ngFor="let supportEngineer of job.supportEngineers">
          {{ supportEngineer.assignedToDisplayName }}
        </span>
      </ng-container>
      <ng-template #singleEngineer>
        <span class="pd-jobs__label">{{ 'projectDetail.engineer' | transloco }}:</span>
        <span class="pd-jobs__lead-engineer">{{ job.assignedToDisplayName }}</span>
      </ng-template>
    </div>
    <div class="pd-jobs__row">
      <span class="pd-jobs__label">{{ 'projectDetail.scheduledDate' | transloco }}:</span>
      <span class="pd-jobs__val">{{ job.scheduledDate }}</span>
    </div>
    <div class="pd-jobs__row">
      <span class="pd-jobs__label">{{ 'common.status' | transloco }}:</span>
      <span class="pd-jobs__val" [attr.data-qa]="job.status"
        >{{ 'jobStatus.' + job.status.toLowerCase() | transloco }}
        <span *ngIf="job.statusLog.length" class="pd-jobs__logs-info">
          <span class="pd-jobs__logs-btn pr-0" (click)="job.showLogs = !job.showLogs">
            <span class="pd-jobs__logs-btn-icon material-icons" *ngIf="!job.showLogs">keyboard_arrow_down</span>
            <span class="pd-jobs__logs-btn-icon material-icons" *ngIf="job.showLogs">keyboard_arrow_up</span>
            <span>{{ 'projectDetail.statusLog' | transloco }}</span>
          </span>
          [{{ job.statusLog.length }}]
        </span>
      </span>
    </div>
    <div class="pd-jobs__logs" *ngIf="job.showLogs">
      <div *ngFor="let log of job.statusLog" class="pd-jobs__log">
        <div *ngIf="log.gps" class="pd-jobs__log-gps">
          <a
            target="_gps"
            href="https://www.google.com/maps/search/?api=1&amp;query={{ log.gps.lat }},{{ log.gps.lng }}"
          >
            <i class="material-icons pd-jobs__log-icon">room</i>
          </a>
        </div>
        <div class="pd-jobs__log-status">
          {{ 'jobStatus.' + log.status.toLowerCase() | transloco }}: {{ log.timestamp }}
        </div>
      </div>
    </div>
  </div>
</div>
