export interface JwtPayload {
  sub?: string;
  email_verified?: boolean;
  iss?: string;
  phone_number_verified?: boolean;
  'cognito:username'?: string;
  aud?: string;
  event_id?: string;
  token_use?: string;
  auth_time?: number;
  phone_number?: string;
  exp?: number;
  iat?: number;
  email?: string;
}
