import { Directive, HostBinding, inject, input } from '@angular/core';
import { IReadOnlyService, readOnlyServiceToken } from './read-only-service.token';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[readOnlyDisable]',
  standalone: true
})
export class ReadOnlyDisableDirective {
  private readOnlyService: IReadOnlyService = inject(readOnlyServiceToken);
  public readOnlyDisable = input<string | null>(null);
  public readOnlyDisableIsDisabled = input<boolean>(false);

  @HostBinding('disabled') get disabled() {
    const isReadonlyByDirective = this.readOnlyService.isReadOnly(this.readOnlyDisable());
    return isReadonlyByDirective || this.readOnlyDisableIsDisabled();
  }
}
