import { Component } from '@angular/core';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

@Component({
  selector: 'app-jobs-loader',
  template: `
    <jui-skeleton-loader width="70%" color="primary"></jui-skeleton-loader>
    <div class="job-loaders">
      <div class="job-loaders__item">
        <jui-skeleton-loader width="60px"></jui-skeleton-loader>
      </div>
      <div class="job-loaders__item">
        <jui-skeleton-loader width="100px" height="20px" color="primary"></jui-skeleton-loader>
      </div>
    </div>
    <jui-skeleton-loader count="2" random="true"></jui-skeleton-loader>
  `,
  styleUrls: ['./jobs.component.scss'],
  standalone: true,
  imports: [CoreComponentsAngularModule]
})
export class JobsLoaderComponent {}
