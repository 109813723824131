<ng-container *ngIf="vm">
  <div class="page-summary-wrapper">
    <div class="progress-wrapper">
      <div *ngIf="vm.showProgress" class="progress">
        <div
          class="progress-bar progress-primary"
          role="progressbar"
          [style.width]="vm.progress"
          [attr.aria-valuenow]="vm.progress"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  </div>
</ng-container>
