import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Project } from '../../core/domain/project';
import { ActionSubmitted, ITasksVm } from './task.model';
import { TasksPresenter } from './tasks.presenter';
import { CONTACT_LOGGING_LD_FEATURE_KEY } from '../../feature-modules/contact-logging/contact-log.model';
import { TranslocoModule } from '@ngneat/transloco';
import { ErrorComponent } from './components/error/error.component';
import { ActionComponent } from './components/action/action.component';
import { InvalidItemsComponent } from './components/invalid-items/invalid-items.component';
import { DisableProjectAutoAssignmentActionComponent } from '../../feature-modules/project-auto-assignment/disable-project-auto-assignment-action/disable-project-auto-assignment-action.component';
import { ContactLogActionComponent } from '../../feature-modules/contact-logging/contact-log-action/contact-log-action.component';
import { FeatureFlagDirective } from '../../core/feature-flag/featureFlagDirective';
import { TaskComponent } from './components/task/task.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragHandle } from '@angular/cdk/drag-drop';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf, NgFor } from '@angular/common';
import { ProjectScheduleActionsComponent } from '../../core/project-schedule-actions/project-schedule-actions.component';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    CoreComponentsAngularModule,
    CdkDragHandle,
    NgbTooltipModule,
    NgFor,
    TaskComponent,
    FeatureFlagDirective,
    ContactLogActionComponent,
    DisableProjectAutoAssignmentActionComponent,
    InvalidItemsComponent,
    ActionComponent,
    ErrorComponent,
    TranslocoModule,
    ProjectScheduleActionsComponent
  ],
  providers: [TasksPresenter]
})
export class TasksComponent implements OnInit, OnChanges, OnDestroy {
  @Input() project: Project;
  @Input() qaIndex: number;

  @Output() stateUpdate: EventEmitter<void> = new EventEmitter();
  @Output() infoMessage: EventEmitter<string> = new EventEmitter();
  @Output() goto: EventEmitter<string> = new EventEmitter();
  @Output() delegated: EventEmitter<void> = new EventEmitter();
  @Output() actioned: EventEmitter<ActionSubmitted> = new EventEmitter();

  constructor(private presenter: TasksPresenter) {}

  vm: ITasksVm;
  CONTACT_LOGGING_LD_FEATURE_KEY = CONTACT_LOGGING_LD_FEATURE_KEY;
  SCHEDULE_V2_PHASE_2_FEATURE_KEY = 'scheduler-v2-phase-2';

  async ngOnInit() {
    await this.presenter.load(this.project, vm => {
      this.vm = vm;
    });

    this.presenter.getStateUpdates(() => {
      if (this.stateUpdate) {
        this.stateUpdate.emit();
      }
    });

    this.presenter.getInfoMessage(message => {
      if (this.infoMessage) {
        this.infoMessage.emit(message);
      }
    });

    this.presenter.getGoto(goto => {
      if (this.goto) {
        this.goto.emit(goto);
      }
    });

    this.presenter.getDelegated(() => {
      if (this.delegated) {
        this.delegated.emit();
      }
    });

    this.presenter.getActioned(action => {
      if (this.actioned) {
        this.actioned.emit(action);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.project && this.project) {
      this.presenter.updateProject(this.project);
    }
  }

  ngOnDestroy(): void {
    this.presenter.cleanUp();
  }

  toggle(id: string) {
    this.presenter.toggleTaskStatus(id);
  }

  action(id: string) {
    this.presenter.actionTask(id);
  }
}
