import {Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { JumptechDate } from '@jump-tech-frontend/domain';
import { Note } from './note';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddNoteModalComponent } from './add-note-modal/add-note-modal.component';
import { User } from '../../core/domain/user';
import { ConfirmModalComponent } from '../../shared/modals/confirm-modal.component';
import { UserService } from '../../auth/services/user.service';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { ReadOnlyDisableDirective } from '@jump-tech-frontend/angular-common';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss', '../styled-table.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [NgIf, NgFor, CoreComponentsAngularModule, DatePipe, TranslocoModule, ReadOnlyDisableDirective]
})
export class NotesComponent implements OnInit {
  private _notes: Note[] = [];
  @Input() set notes(notes: Note[]) {
    this._notes = notes || [];
  }
  @Input() projectId!: string;
  @Input() readOnly?: boolean = false;
  @Output() onNotesUpdate: EventEmitter<{ notes: Note[]; projectId: string }> = new EventEmitter<{
    notes: Note[];
    projectId: string;
  }>();

  get notes(): Note[] {
    return this._notes;
  }

  user: User;

  constructor(private modal: NgbModal, private userService: UserService, private translocoService: TranslocoService) {}

  ngOnInit() {
    this.userService.userObservable.subscribe(user => {
      this.user = user;
      this.setNotePermissions();
    });
  }

  ngOnDestroy() {
    this.notes = [];
  }

  private setNotePermissions() {
    for (const note of this.notes) {
      note.canEdit = !this.readOnly && (this.user.id === note.created_by || this.user.isAdmin);
    }
  }

  addNote() {
    const modalRef = this.modal.open(AddNoteModalComponent);
    modalRef.componentInstance.note = {
      created_by: this.user.id,
      created_by_name: this.user.label,
      canEdit: true
    } as Note;
    modalRef.result.then(
      (note: Note) => {
        note.created_on = new Date().toJSON();
        this.notes.push(note);
        this.onNotesUpdate.emit({ notes: this.notes, projectId: this.projectId });
      },
      () => {}
    );
  }

  editNote(note: Note, noteIndex: number) {
    const modalRef = this.modal.open(AddNoteModalComponent);
    modalRef.componentInstance.note = note;
    modalRef.result.then(
      (note: Note) => {
        note.edited_on = new Date().toJSON();
        note.edited_by = this.user.id;
        note.edited_by_name = this.user.label;
        note.canEdit = true;
        this.notes[noteIndex] = note;
        this.onNotesUpdate.emit({ notes: this.notes, projectId: this.projectId });
      },
      () => {}
    );
  }

  deleteNote(noteIndex: number) {
    const modalRef = this.modal.open(ConfirmModalComponent, { size: 'sm' });
    modalRef.componentInstance.config = {
      title: this.translocoService.translate('project.modals.deleteNote.title'),
      messages: [this.translocoService.translate('project.modals.deleteNote.messages.confirmation')]
    };
    modalRef.result.then(
      () => {
        this.notes.splice(noteIndex, 1);
        this.onNotesUpdate.emit({ notes: this.notes, projectId: this.projectId });
      },
      () => {}
    );
  }

  getDate(date: string): string {
    return JumptechDate.from(date).toDateTimeFormat();
  }
  renderCreateBy(note: Note) {
    if (note.created_by_name) {
      return note.created_by_name;
    }
    return this.translocoService.translate('notes.createdByApi');
  }
  renderEditedBy(note: Note) {
    if (note.edited_by_name) {
      return note.edited_by_name;
    }
    return this.translocoService.translate('notes.editedByApi');
  }
}
