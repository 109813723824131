import { Injectable } from '@angular/core';
import { JumptechDateSettings } from '@jump-tech-frontend/domain';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  weekdayShortNames;
  monthShortNames;
  monthFullNames;

  constructor(private dateParser: NgbDateParserFormatter) {
    super();
    this.weekdayShortNames = JumptechDateSettings.WeekdaysShort;
    this.monthShortNames = JumptechDateSettings.MonthsShort;
    this.monthFullNames = JumptechDateSettings.MonthsLong;
  }

  getWeekdayShortName(weekday: number): string {
    return this.weekdayShortNames[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return this.monthShortNames[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.monthFullNames[month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return this.dateParser.format(date);
  }

  getWeekdayLabel(weekday: number): string {
    return this.weekdayShortNames[weekday - 1];
  }
}
