import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { AttachmentsHelper } from '../../shared/attachments.helper';
import { Question } from '../domain/question';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../../error/logger.service';

import { ProjectAttachment } from '../../project-detail/project-attachments/project-attachments.component';
import { User } from '../domain/user';
import { ApiService } from '../api.service';
import { Project } from '../domain/project';
import * as XRegExp from 'xregexp';
import { UserService } from '../../auth/services/user.service';
import { firstValueFrom } from 'rxjs';
import { PresignedPost } from 'aws-sdk/clients/s3';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ProgressBarComponent } from '../../progress-bar/progress-bar.component';
import { NgIf } from '@angular/common';
import { UploadFileInput } from '../directives/upload-file-input';

@Component({
  selector: 'app-attachment-question',
  template: `
    <div>
      <input
        class="upload-file-input"
        [id]="question.key"
        (change)="onFileChange($event)"
        style="display:none;"
        type="file"
        [accept]="attachmentsHelper.getAcceptedMimeTypes(question.customAttachment?.acceptedFileTypes)"
        #attachmentElement
      />

      <div class="attachment-filename pt-2">{{ question.label }}{{ question.required ? ' *' : '' }}</div>

      <i class="material-icons material-icons-lg pt-2" (click)="attachmentElement.click()">cloud_upload</i>
      <div *ngIf="fileName">
        <span class="file-details">
          ({{ fileName }})
          <i class="material-icons material-icons-lg" (click)="reset()">delete_forever</i>
        </span>
      </div>
    </div>
    <app-progress-bar
      *ngIf="uploadInProgress"
      class="attachments-table pb-2"
      [fileName]="getSanitisedFilename(uploadInProgress.fileName)"
      [progress]="uploadInProgress.progress"
    >
    </app-progress-bar>
  `,
  styles: [
    `
      .material-icons {
        cursor: pointer;
      }
      .file-details {
        display: inline-flex;
      }
    `
  ],
  standalone: true,
  imports: [UploadFileInput, NgIf, ProgressBarComponent]
})
export class AttachmentQuestionComponent implements AfterViewInit {
  @Input() form;
  @Input() question: Question;
  @Input() project!: Project;
  /**
   * If true, we'll call valueUpdated with the current attachments list
   * If false, we'll call the api to immediately update the project
   */
  @Input() notify = false;
  @Output() valueUpdated: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('attachmentElement') attachmentElement: ElementRef;

  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  fileName: string = null;
  user: User;
  uploadInProgress: { fileName: string; progress: number } = null;
  bucketName = `pathway-attachments-${environment.bucketSuffix}`;
  attachments: any[] = [];

  constructor(
    public attachmentsHelper: AttachmentsHelper,
    private loggerService: LoggerService,
    private userService: UserService,
    private apiService: ApiService
  ) {}

  async ngAfterViewInit(): Promise<void> {
    this.userService.userObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => {
      this.user = user;
    });
  }

  reset() {
    const fileNameToDelete = this.fileName;
    this.fileName = null;
    this.attachmentsHelper.s3.remove(`${this.project.id}/${fileNameToDelete}`, this.bucketName).then(() => {
      this.attachments = this.attachments.filter(a => a.key !== fileNameToDelete);
      this.notifyOrUpdate();
      if (this.question.required) {
        this.form.get(this.question.key).setErrors({ required: true });
      }
    });
  }

  private notifyOrUpdate() {
    if (this.notify) {
      this.valueUpdated.next(this.attachments);
    } else {
      this.updateAttachments(this.attachments);
    }
  }

  async onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (
        !this.attachmentsHelper.checkIsFileTypeAndSizeOK(
          file,
          this.question.acceptedFileTypes || this.question.customAttachment?.acceptedFileTypes
        )
      ) {
        return;
      }

      const { uploadDate, key, fileName, fileType } = this.attachmentsHelper.getFileUploadDetail(
        file,
        this.project.id,
        this.question.key
      );
      this.uploadInProgress = { fileName: fileName, progress: 0 };
      const presignedPost: PresignedPost = await firstValueFrom(
        this.apiService.getSignedPostUrl(key, this.bucketName, fileType)
      );
      this.attachmentsHelper.postAndTrackProgress(presignedPost, file, this.uploadInProgress).subscribe(() => {
        this.addOrUpdateAttachment(fileName, uploadDate);
        this.notifyOrUpdate();
        this.uploadInProgress = null;
        const mimeType = XRegExp('.[0-9a-z]+$', 'i').exec(file.name);
        this.fileName = mimeType.length ? `${this.question.key}${mimeType[0]}` : file.name;
        if (this.question.required) {
          this.form.get(this.question.key).setErrors(null);
        }
        // }
        event.target.value = null;
      });
    }
  }

  private addOrUpdateAttachment(fileName, uploadDate) {
    const index = this.attachments.findIndex(attachment => attachment.key === fileName);
    const projectAttachment = {
      key: fileName,
      uploadDate: uploadDate,
      uploadedBy: this.user.id,
      uploadedByLabel: this.user.label,
      uploadedByTenant: this.user.tenant
    };
    if (index > -1) {
      this.attachments[index] = projectAttachment;
    } else {
      this.attachments.push(projectAttachment);
    }
  }

  getSanitisedFilename(fileName: string) {
    return this.attachmentsHelper.getSanitisedFilename(fileName);
  }

  updateAttachments($event: ProjectAttachment[]) {
    const update = { attachments: $event };
    this.project = { ...this.project, ...update };
    this.apiService.updateProject(this.project, update).toPromise().catch(this.loggerService.error);
  }
}
