import { User } from '../../core/domain/user';
import { AcceptedFileType } from '../../core/domain/project-configuration';

export enum ErrorType {
  fetchDocumentPackById = 'documentManager.errors.fetchDocumentPackById',
  addDocumentPack = 'documentManager.errors.addDocumentPack',
  removeDocumentPack = 'documentManager.errors.removeDocumentPack',
  uploadDocument = 'documentManager.errors.uploadDocument',
  regenerateDocument = 'documentManager.errors.regenerateDocument',
  approveDocument = 'documentManager.errors.approveDocument',
  withdrawApproveDocument = 'documentManager.errors.withdrawApproveDocument',
  requiredDocument = 'documentManager.errors.requiredDocument',
  unknown = 'documentManager.errors.unknown',
  previewDocument = 'documentManager.errors.previewDocument',
  reviewMissingInfo = 'documentManager.errors.reviewMissingInfo',
  autoGenDocReviewed = 'documentManager.errors.autoGenDocReviewed',
  provideResponse = 'documentManager.errors.provideResponse',
  reviseDocument = 'documentManager.errors.reviseDocument'
}

export interface IDocumentPackManagerConfig {
  projectId: string;
  projectStates: any[];
  currentProjectState: string;
  documentPackDefinition: IDocumentPackDefinition;
  documentPackId?: string;
  user: User;
  tenant: string;
  autoAddInProgress?: boolean;
  showLoading: boolean;
  isRefreshing: boolean;
}

export interface IDocumentPackDefinition {
  id: string;
  version: number;
}

export interface IDocumentModel {
  modelData: {
    [key: string]: unknown;
  };
  missingInformation: IDocumentMissingInfo[];
  infoBox: string;
  documentSuppressed: boolean;
}

interface IDocumentMissingInfo {
  [key: string]: string;
}

export interface IDocumentPackDefinitionDetails {
  id: string;
  version: number;
  name: string;
  documents: Array<IDocumentDefinition>;
}

export interface IDocumentPackManagerDm {
  projectId: string;
  hasDocumentPack: boolean;
  canRemoveDocumentPack: boolean;
  addDocumentPackLabel: string;
  removeDocumentPackLabel: string;
  stageLabel: string;
  addDocumentPackInProgress: boolean;
  autoAddDocumentPackInProgress: boolean;
  removeDocumentPackInProgress: boolean;
  documentPack: IDocumentPackDm;
  fetchingDocumentPack: boolean;
  allowRetry: boolean;
  retryLabel: string;
  acceptedFileTypes: AcceptedFileType[];
  uploadInProgress: boolean;
  isPreviewing: boolean;
  stateChangeInProgress: boolean;
  isRefreshing: boolean;
}

export interface IDocumentPackManagerVm {
  projectId: string;
  showAddDocumentPack: boolean;
  addDocumentPackLabel: string;
  addDocumentPackInProgress: boolean;
  showRemoveDocumentPack: boolean;
  removeDocumentPackLabel: string;
  removeDocumentPackInProgress: boolean;
  documents: Array<IDocumentVm>;
  stages: Array<IStageVm>;
  showMainSpinner: boolean;
  showRetryButton: boolean;
  retryButtonLabel: string;
  acceptedFileTypes: string;
  actionButtonDisabled: boolean;
  inProgress: boolean;

  qaComponent: string;
  qaRetry: string;
  qaAddDocPack: string;
  qaRemoveDocPack: string;
  qaMainSpinner: string;
}

export interface IGetDocumentPackDto {
  documentPack: IDocumentPackDm;
}

export interface IAddDocumentPackDto {
  documentPackId: string;
}

export interface IDocumentPackDm {
  id: string;
  documentPackDefinitionReference: IDocumentPackDefinitionReference;
  projectId: string;
  documents: Array<IDocumentDm>;
  stages: Array<IStageDm>;
  deletable: boolean;
}

export interface IDocumentDm {
  id: string;
  documentDefinition: IDocumentDefinition;
  state: {
    currentState: DocumentState;
    allowedTransitions: DocumentStateTransition[];
    displayStateTranslationKey: string;
    explicitNotRequired: boolean;
    documentLocked: boolean;
  };
  manuallyOverwritten?: boolean;
  url: string;
  stateLabel: string;
  stateTransition: string;
  allowRequiredTransition: boolean;
  actionLabel: string;
  actionType: DocumentActionType;
  documentModel?: IDocumentModel;
  allowModelRegeneration?: boolean;
}

export interface IStageDm {
  stageNumber: number;
  name: string;
  isOpen: boolean;
  state: DocumentStageState;
  documents: Array<IDocumentDm>;
  outstandingDocuments?: number;
  metaStatus: string;
  metaStatusIndex: number;
}

export interface IStageVm {
  displayName: string;
  name: string;
  isOpen: boolean;
  toggleIcon: string;
  isCurrentStage: boolean;
  state: string;
  isCompleted: boolean;
  isInactive: boolean;
  outstandingDocuments?: number;
  documents: Array<IDocumentVm>;
  qaStageName: string;
  qaStageOutstandingDocCount: string;
}

export interface IDocumentVm {
  id: string;
  name: string;
  documentType: string;
  state: string;
  stateLabel: string;
  stateTransition: string;
  actionType: DocumentActionType;
  actionLabel: string;
  actionEnabled: boolean;
  actionDone: boolean;
  noAction: boolean;
  missingInfo: IDocumentMissingInfo[];
  infoBox: string;
  isAutoActionState: boolean;
  isMissingInfo: boolean;
  requiredDisabled: boolean;
  showNameInactive: boolean;
  isRequired: boolean;
  s3Url: string;
  fileName: string;
  isSendingEmailOnApproval: boolean;

  qaRequiredToggle: string;
  qaDocumentLocked: string;
  qaDocAction: string;
  qaDocState: string;
  qaDocUploadInput: string;
  qaMainAction: string;
  qaNotRequired: string;
  qaCloseModal: string;
  qaUpload: string;
  qaRegenerate: string;
}

interface IDocumentDefinition {
  id: string;
  displayName: string;
  documentType: DocumentType;
  modelDefinition?: ModelDefinition;
  requiredProjectMetaStatus?: string;
  requiredByProjectMetaStatus?: string;
}

interface ModelDefinition {
  modelGenerator: string;
}

interface IDocumentPackDefinitionReference {
  id: string;
  version: number;
}

export interface IDocumentPackI18n {
  [key: string]: string;
}

export interface IDocumentProgress {
  inProgress: boolean;
  progress: number;
  fileName: string;
  uploadingLabel: string;
}

export interface IDocumentProgressVm {
  label: string;
  inProgress: boolean;
  isComplete: boolean;
  percentageValue: string;
  qaProgressLbl: string;
}

export interface IMessageDm {
  message: string;
  showLoader: boolean;
}

export interface IMessageVm {
  message: string;
  showLoader: boolean;
  qaMessage: string;
  qaSpinner: string;
}

export interface IPreviewDm {
  id: string;
  name: string;
  documentType: string;
  message: string;
  state: string;
  stateLabel: string;
  stateTransition: DocumentStateTransition;
  cancelLabel: string;
  actionLabel: string;
  uploadLabel: string;
  actionSubmitLabel: string;
  actionEnabled: boolean;
  actionHidden: boolean;
  fileName: string;
  isUploading: boolean;
  uploadHint: string;
  uploadAlternativeHint: string;
  regenerateHint: string;
  regenerateLabel: string;
  canRegenerate: boolean;
  isRegenerating: boolean;
  hasMissingInfo: boolean;
  lastModifiedDate: string;
  lastModifiedLabel: string;
  canSetNotRequired: boolean;
  notRequiredLabel: string;
  isSettingNotRequired: boolean;
  isUpdatingState: boolean;
  qaMainAction: string;
  qaNotRequired: string;
  qaCloseModal: string;
  qaUpload: string;
  qaRegenerate: string;
}

export interface IMissingInfoDm {
  missing: IMissingInfoSectionVm[];
  documentName: string;
  label: string;
  closeLabel: string;
  uploadHint: string;
  uploadLabel: string;
  message: string;
  isUploading: boolean;
  qaCloseModal: string;
  qaUpload: string;
}

export interface IResponseDm {
  id: string;
  documentName: string;
  mainDocumentFileName: string;
  message: string;
  closeLabel: string;
  approvedLabel: string;
  rejectedLabel: string;
  reviseLabel: string;
  uploadLabel: string;
  completeLabel: string;
  completeHint: string;
  responseLabel: string;
  stateLabel: string;
  actionType: DocumentActionType;
  acceptedFileTypes: AcceptedFileType[];
  isUploading: boolean;
  isRevising: boolean;
  isCompleting: boolean;
}

export interface IResponseVm {
  id: string;
  title: string;
  stateLabel: string;
  name: string;
  sentDocumentName: string;
  message: string;
  closeLabel: string;
  approvedLabel: string;
  rejectedLabel: string;
  reviseLabel: string;
  uploadLabel: string;
  completeLabel: string;
  completeHint: string;
  actionType: DocumentActionType;
  accept: string;
  isUpdating: boolean;
  isRevising: boolean;
  isCompleting: boolean;

  qaCloseModal: string;
  qaComplete: string;
  qaRevise: string;
  qaUpload: string;
}

export interface IPreviewVm {
  id: string;
  name: string;
  title: string;
  message: string;
  state: string;
  stateLabel: string;
  stateTransition: DocumentStateTransition;
  stateDone: boolean;
  cancelLabel: string;
  actionSubmit: string;
  actionEnabled: boolean;
  actionHidden: boolean;
  fileName: string;
  isUploading: boolean;
  showUpload: boolean;
  regenerateHint: string;
  uploadHint: string;
  uploadAlternativeHint: string;
  uploadLabel: string;
  showRegenerate: boolean;
  regenerateLabel: string;
  isRegenerating: boolean;
  isUpdatingDocument: boolean;
  hasMissingInfo: boolean;
  lastModifiedDate: string;
  lastModifiedLabel: string;
  showNotRequired: boolean;
  notRequiredLabel: string;
  isSettingNotRequired: boolean;

  qaMainAction: string;
  qaNotRequired: string;
  qaCloseModal: string;
  qaUpload: string;
  qaRegenerate: string;
}

export interface IMissingInfoVm {
  title: string;
  state: string;
  data: IMissingInfoSectionVm[];
  closeBtnLabel: string;
  uploadBtnLabel: string;
  uploadBtnHint: string;
  message: string;
  isUploadingFile: boolean;
  qaClose: string;
  qaUpload: string;
}

export interface IMissingInfoSectionVm {
  section: string;
  fields: string[];
}

export enum DocumentStateTransition {
  UPLOADED = 'UPLOADED',
  REUPLOADED = 'REUPLOADED',
  NOT_REQUIRED = 'NOT_REQUIRED',
  APPROVED = 'APPROVED',
  REVIEWED = 'REVIEWED',
  WITHDRAW_APPROVAL = 'WITHDRAW_APPROVAL',
  REQUIRED = 'NOW_REQUIRED',
  SEND_EMAIL = 'SEND_EMAIL',
  NONE = 'NONE',
  AUTOMATIC_UPDATE = 'AUTOMATIC_UPDATE',
  COMPLETED = 'COMPLETED',
  REVISE = 'REVISE'
}

export enum DocumentState {
  INITIAL = 'INITIAL',
  AWAITING_DOCUMENT = 'AWAITING_DOCUMENT',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  APPROVED = 'APPROVED',
  NOT_REQUIRED = 'NOT_REQUIRED',
  MISSING_INFO = 'MISSING_INFO',
  COMPLETED = 'COMPLETED',
  PENDING_INSTALL = 'PENDING_INSTALL',
  PENDING_REVIEWING = 'PENDING_REVIEWING',
  READY_TO_REVIEW = 'READY_TO_REVIEW',
  AWAITING_RESPONSE = 'AWAITING_RESPONSE',
  PENDING_DNO = 'PENDING_DNO',
  GENERATING = 'GENERATING'
}

export enum ApiDocumentState {
  READY_TO_REVIEW = 'readyToReview',
  NOT_REQUIRED = 'notRequired',
  AWAITING_APPROVAL = 'awaitingApproval',
  APPROVED = 'approved',
  AWAITING_RESPONSE = 'awaitingResponse',
  COMPLETED = 'completed'
}

export enum DocumentStateTranslationSuffix {
  AWAITING_DOCUMENT = 'awaitingDocument',
  NOT_REQUIRED = 'notRequired',
  NOW_REQUIRED = 'required',
  MISSING_INFO = 'missingInformation',
  AWAITING_APPROVAL = 'awaitingApproval',
  APPROVED = 'approved',
  COMPLETED = 'completed',
  PENDING_INSTALL = 'pending.installed',
  PENDING_REVIEWING = 'pending.reviewing',
  READY_TO_REVIEW = 'readyToReview',
  READY_FOR_DNO = 'pending.readyForDno',
  AWAITING_RESPONSE = 'awaitingResponse',
  SENT = 'sent',
  GENERATING = 'generating'
}

export enum DocumentType {
  MANUAL = 'MANUAL',
  AUTO = 'AUTOGENERATABLE'
}

export enum DocumentActionType {
  UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
  PREVIEW_DOCUMENT = 'PREVIEW_DOCUMENT',
  REVIEW_MISSING_INFO = 'REVIEW_MISSING_INFO',
  REQUIRES_RESPONSE = 'REQUIRES_RESPONSE',
  UNKNOWN = 'UNKNOWN'
}

export enum DocumentStageState {
  PREVIOUS = 'PREVIOUS',
  ACTIVE = 'ACTIVE',
  FUTURE = 'FUTURE'
}

export const DOCUMENT_STATE_KEY_PREFIX = 'documentManager.state';

export const OUTSTANDING_DOCUMENT_STATES = [
  DocumentState.MISSING_INFO,
  DocumentState.AWAITING_DOCUMENT,
  DocumentState.AWAITING_APPROVAL
];

export const VALID_GROUPED_DOCUMENT_STATES = [
  DocumentState.APPROVED,
  DocumentState.COMPLETED,
  DocumentState.NOT_REQUIRED
];

export const AUTO_GENERATE_STATES = [DocumentState.INITIAL];

export const NO_ACTION_DOCUMENT_STATES = [DocumentState.NOT_REQUIRED, DocumentState.INITIAL, DocumentState.GENERATING];

export const TOGGLE_REQUIRED_TRANSITION_STATES = [
  DocumentStateTransition.NOT_REQUIRED,
  DocumentStateTransition.REQUIRED
];

export const DOC_PACK_MANAGER_LD_FEATURE_KEY = 'document-pack-manager';

export const AUTO_ADD_DOC_PACK_FEATURE_KEY = 'automatically-create-document-pack';

export const LINKED_PROJECTS_LD_FEATURE_KEY = 'linked-projects-component';

export const ACCEPTED_FILE_TYPES: AcceptedFileType[] = [
  {
    label: 'PDF',
    type: '.pdf'
  }
];

export interface UpdateDocumentDocumentRequest {
  documentPackId: string;
  documentId: string;
  url?: string;
  stateTransition?: DocumentStateTransition;
}
