import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnaAttachmentInputs } from '../ena.model';
import { ProjectAttachment } from '../../project-attachments/project-attachments.component';

@Injectable({ providedIn: 'root' })
export class EnaAttachmentsService {
  private attachmentSettingsSubject: BehaviorSubject<EnaAttachmentInputs> = new BehaviorSubject<EnaAttachmentInputs>(
    null
  );

  private modifiedAttachmentsSubject: BehaviorSubject<ProjectAttachment[]> = new BehaviorSubject<ProjectAttachment[]>(
    null
  );

  public attachmentSettings$: Observable<EnaAttachmentInputs> = this.attachmentSettingsSubject.asObservable();

  public modifiedAttachments$: Observable<ProjectAttachment[]> = this.modifiedAttachmentsSubject.asObservable();

  public notifyAttachmentSettingSubs(attachmentInputs: EnaAttachmentInputs) {
    this.attachmentSettingsSubject.next(attachmentInputs);
  }

  public notifyModifiedAttachmentSubs(attachments: ProjectAttachment[]) {
    this.modifiedAttachmentsSubject.next(attachments);
  }
}
