import { Injectable } from '@angular/core';
import { ScheduleRepository } from '../schedule.repository';
import { Job } from '../../core/domain/job';
import { EventInfo, JobInformation } from '../utils/schedule-types';
import { ErrorType } from '../schedule.model';

@Injectable()
export class SchedulePresenter {
  constructor(private repository: ScheduleRepository) {}

  setSelectedJob(job: Job): void {
    this.repository.setSelectedJob(job);
  }

  public openScheduleDetails(jobInformation: JobInformation, cb, calEvent: EventInfo): void {
    this.repository.openMoreDetails(jobInformation, cb, calEvent);
  }

  public showError(type: ErrorType, e): void {
    this.repository.handleErrors(type, e);
  }
}
