import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { Card, ImageAsset, ImageAssetFactory, ImageAssetType } from '../../domain/card';
import { I18nKeys } from '../../domain/i18n-keys';
import { NgIf } from '@angular/common';
import { QuestionsComponent } from '../../core/questions.component';
import { ImageAssetComponent } from '../../core/image-asset.component';

@Component({
  selector: `crds-default-form`,
  template: `
    <div class="card-wrapper">
      <div class="card__title-header">
        <div class="card__title">{{ card.label }}</div>
        <div class="card__step">{{ card.stepProgress }}</div>
      </div>

      <div class="card__description">
        <p class="card-text" [innerHTML]="card.description" [attr.data-qa]="'description'"></p>
        <crds-image-asset [images]="images" [key]="'default'"></crds-image-asset>
      </div>
    </div>
    <div class="form-wrapper">
      <form [formGroup]="form.get(formKey)">
        <crds-questions [form]="form.get(formKey)" [card]="card" [i18ns]="i18ns"></crds-questions>
      </form>
    </div>
    <div *ngIf="card.description2" class="card-wrapper">
      <div class="card">
        <div class="card-body">
          <p class="card-text" [innerHTML]="card.description2"></p>
        </div>
      </div>
    </div>
  `,
  standalone: true,
  imports: [ImageAssetComponent, ReactiveFormsModule, QuestionsComponent, NgIf]
})
export class DefaultFormComponent implements OnChanges {
  @Input() card: Card<null>;
  @Input() form: UntypedFormGroup;
  @Input() formKey: string;
  @Input() i18ns: I18nKeys;
  action: any;
  images: ImageAsset[];
  imageAssetType = ImageAssetType;

  constructor(private imageAssetFactory: ImageAssetFactory) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.card || !Object.prototype.hasOwnProperty.call(changes, 'card')) {
      return;
    }

    this.action = this.card.action;
    this.images = this.imageAssetFactory.create(this.card.image, this.form);
  }
}
