<div class="modal-wrapper">
  <div class="modal-header">
    <div>{{ config.title || ('submit.confirm' | transloco) }}</div>
  </div>
  <div class="modal-body" *ngIf="config.messages && !config.requires?.failMessage">
    <p *ngFor="let message of config.messages">{{ message }}</p>
  </div>
  <div class="modal-body" *ngIf="!config.messages && !config.requires?.failMessage">
    <p>{{ 'submit.message' | transloco }}</p>
  </div>
  <div class="modal-body" *ngIf="config.requires?.failMessage">
    <div>{{ config.requires?.failMessage }}</div>
  </div>
  <div class="modal-footer text-center">
    <jui-button
      size="xl"
      title="{{ config.cancel || ('submit.no' | transloco) }}"
      expand
      display="ghost"
      color="secondary"
      class="modal__action"
      (click)="cancel($event)"
      [attr.data-qa]="'confirmNoButton'"
    >
      {{ config.cancel || ('submit.no' | transloco) }}
    </jui-button>
    <jui-button
      size="xl"
      title="{{ config.confirm || ('submit.yes' | transloco) }}"
      expand
      color="primary"
      class="modal__action"
      (click)="confirm($event)"
      [attr.data-qa]="'confirmYesButton'"
    >
      {{ config.confirm || ('submit.yes' | transloco) }}
    </jui-button>
  </div>
</div>
