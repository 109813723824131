@if (vm.statusChangeForm) {
  <div class="status-change-popover">
    <div class="status-change-popover__content" [formGroup]="vm.statusChangeForm">
      <label for="statusOptions" class="status-change-label">Project Status</label>
      <ng-select
        id="statusOptions"
        [items]="vm.statusOptions"
        [multiple]="false"
        [clearable]="false"
        [closeOnSelect]="true"
        placeholder="{{ vm.statusOptionsDropdownPlaceholder }}"
        formControlName="selectedStatus"
        bindValue="id"
        bindLabel="label"
        (change)="projectStatusChanged.emit()"
      ></ng-select>
    </div>
    <div class="status-change-popover__footer">
      <jui-button
        size="xs"
        [disabled]="vm.changeStatusButtonDisabled"
        (click)="changeStatusButtonClicked.emit()"
      >{{ vm.statusChangeButtonText }}</jui-button>
    </div>
    @if (vm.errorMessage) {
      <span class="status-change-popover__error">{{ vm.errorMessage }}</span>
    }
  </div>
}
