import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssignmentTarget, DelegationShare, DelegationShareSubTenant } from '../../admin/user-management/domain/types';
import { TranslocoModule } from '@ngneat/transloco';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { MultipleSelectionDropdownComponent } from '../../shared/form-components/multiple-selection-dropdown.component';
import { NgIf } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';

@Component({
  selector: 'app-delegate-project-modal',
  templateUrl: './delegate-project-modal.component.html',
  styleUrls: ['../../project-detail/project-detail.component.scss'],
  standalone: true,
  imports: [NgxSpinnerModule, NgIf, MultipleSelectionDropdownComponent, CoreComponentsAngularModule, TranslocoModule]
})
export class DelegateProjectModalComponent implements OnInit {
  @Input() delegates: DelegationShare[];
  @Input() preAssignment: AssignmentTarget;

  delegate: string = null;

  selectSubTenantItems: DelegationShareSubTenant[] = [];
  selectDelegateItems: any[] = [];

  selectedSubTenantIds: string[] = [];
  selectedDelegateIds: string[] = [];

  fullSubTenants: {
    [key: string]: DelegationShareSubTenant[];
  } = {};

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.selectDelegateItems = Object.assign(
      [],
      this.delegates.map(delegate => {
        return { id: delegate.tenant, name: delegate.tenant };
      })
    );
    for (const delegate of this.delegates) {
      this.fullSubTenants[delegate.tenant] = delegate.subTenants;
    }
    if (this.preAssignment?.delegate) {
      this.setDelegate([this.preAssignment.delegate]);
    }
    if (this.preAssignment?.subTenant) {
      this.setSubTenants([this.preAssignment.subTenant.id]);
    }
  }

  submit() {
    this.activeModal.close({
      delegate: this.selectedDelegateIds[0],
      subTenants: this.selectSubTenantItems?.filter(ssti => this.selectedSubTenantIds.includes(ssti.id)) || undefined
    });
  }

  setDelegate(selectedDelegateIds: string[]) {
    this.delegate = selectedDelegateIds.length ? selectedDelegateIds[0] : null;
    this.selectedDelegateIds = selectedDelegateIds;
    if (!this.delegate) {
      this.selectedSubTenantIds = [];
    }
    this.selectSubTenantItems = [];
    setTimeout(() => {
      this.selectSubTenantItems = this.delegate ? this.fullSubTenants[this.delegate] : [];
    });
  }

  setSubTenants(selectedSubTenantIds: any[]) {
    this.selectedSubTenantIds = selectedSubTenantIds;
  }

  disableSubmit() {
    return this.delegate === null || (this.selectSubTenantItems?.length && !this.selectedSubTenantIds?.length);
  }
}
