import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpGateway } from '../../../../core/http-gateway.service';
import { ProjectConfigurationService } from '../../../../core/project-configuration.service';
import { AlertService } from '../../alert.service';
import { StandardData } from '../domain';
import { StandardQuoteComponent } from './components/modal/standard-quote.component';

const skipHeader = {
  skip: 'true'
};

@Injectable({
  providedIn: 'root'
})
export class StandardQuotesRepository {
  private type = 'Quote';

  private subscriptionList: Subscription[] = [];

  private standardQuotes$: Subject<StandardData[] | null> = new Subject<StandardData[] | null>();

  private projectTypes$: Subject<string[] | null> = new Subject<string[] | null>();

  private standardQuote$: Subject<StandardData | null> = new Subject<StandardData | null>();
  private standardQuoteSubscription: Subscription;

  private modalRef: NgbModalRef;

  private currentFilter: string;

  private cachedResults: StandardData[] = [];
  private cachedStandardQuote: StandardData;
  public listSpinnerName = 'standardQuoteListSpinner';
  public spinnerName = 'standardQuoteSpinner';

  public constructor(
    private spinnerService: NgxSpinnerService,
    private projectConfigurationService: ProjectConfigurationService,
    private alertService: AlertService,
    private translocoService: TranslocoService,
    private httpGateway: HttpGateway,
    private modalService: NgbModal
  ) {}

  public async listStandardQuotes(callback, projectType?: string) {
    this.spinnerService.show(this.listSpinnerName).then();
    try {
      this.subscriptionList.push(this.standardQuotes$.subscribe(callback));
      await this.hydrateStandardQuotes(projectType);
    } finally {
      this.spinnerService.hide(this.listSpinnerName).then();
    }
  }

  async filterStandardQuotes(filter: string): Promise<void> {
    if (!this.cachedResults) {
      await this.hydrateStandardQuotes();
    }
    this.currentFilter = filter?.toLocaleLowerCase();
    if (!this.currentFilter) {
      this.standardQuotes$.next(this.cachedResults);
      return;
    }
    const results = this.cachedResults.filter(
      m =>
        m.name.toLocaleLowerCase().includes(this.currentFilter) ||
        m.projectType.toLocaleLowerCase().includes(this.currentFilter)
    );
    this.standardQuotes$.next(results);
  }

  private async hydrateStandardQuotes(projectType?: string): Promise<void> {
    let url = `${environment.apiStandardDataUrl}?type=${this.type}`;
    if (projectType) {
      url = `${url}&projectType=${projectType}`;
    }
    this.cachedResults = await this.httpGateway.get(url, {});
    this.standardQuotes$.next(this.cachedResults);
  }

  public async listProjectTypes(callback) {
    this.spinnerService.show(this.spinnerName).then();
    try {
      this.subscriptionList.push(this.projectTypes$.subscribe(callback));
      await this.hydrateProjectTypes();
    } finally {
      this.spinnerService.hide(this.spinnerName).then();
    }
  }

  private async hydrateProjectTypes(): Promise<void> {
    const projectTypeTypes = await this.projectConfigurationService.getProjectTypes();
    const projectTypes = projectTypeTypes.map(x => x.projectType);
    this.projectTypes$.next(projectTypes);
  }

  public createStandardQuoteModal() {
    this.updateStandardQuoteModal({
      type: this.type,
      projectType: null,
      name: null,
      data: {}
    });
  }

  public updateStandardQuoteModal(standardQuote: StandardData) {
    const clone = JSON.parse(JSON.stringify(standardQuote));
    const modelSub = this.subscribeAndNotifyActiveModal(clone);
    this.modalRef = this.modalService.open(StandardQuoteComponent, {
      windowClass: 'standard-quote-modal'
    });
    this.modalRef.result.finally(() => {
      modelSub.unsubscribe();
      this.standardQuoteSubscription.unsubscribe();
    });
  }

  private subscribeAndNotifyActiveModal(standardQuote: StandardData): Subscription {
    return this.modalService.activeInstances.subscribe(x => {
      if (x.length) {
        setTimeout(() => {
          this.cachedStandardQuote = standardQuote;
          this.changeStandardQuoteProjectType(standardQuote.projectType).then(() => {
            this.standardQuote$.next(this.cachedStandardQuote);
          });
        });
      }
    });
  }

  public async getStandardQuote(callback) {
    this.standardQuoteSubscription = this.standardQuote$.subscribe(callback);
  }

  public changeStandardQuoteName(name: string) {
    this.cachedStandardQuote.name = name;
    this.standardQuote$.next(this.cachedStandardQuote);
  }

  public async changeStandardQuoteProjectType(projectType: string) {
    this.cachedStandardQuote.projectType = projectType;
    if (!projectType) {
      return;
    }
    this.cachedStandardQuote.layout = await this.getLayout(projectType);
    this.standardQuote$.next(this.cachedStandardQuote);
  }

  public async changeData(data?: Record<string, string>) {
    this.cachedStandardQuote.data = data;
    this.standardQuote$.next(this.cachedStandardQuote);
  }

  public async saveOrUpdateStandardQuote(standardQuote: StandardData) {
    if (standardQuote.id) {
      alert(this.translocoService.translate('standardData.quotes.affect'));
    }
    this.spinnerService.show(this.spinnerName).then();
    try {
      const value = this.cleanData(standardQuote);
      await this.httpGateway.post(`${environment.apiStandardDataUrl}`, value, skipHeader);
      this.closeModal();
      await this.hydrateStandardQuotes();
    } finally {
      this.spinnerService.hide(this.spinnerName).then();
    }
  }

  public async deleteStandardQuote(id: string) {
    const result = await this.alertService.alert(
      'standardData.quotes.confirmation',
      'standardData.quotes.confirmDelete',
      'standardData.quotes.confirm',
      true
    );
    if (result) {
      this.spinnerService.show(this.listSpinnerName).then();
      try {
        await this.httpGateway.delete(`${environment.apiStandardDataUrl}/${id}`, {});
        await this.hydrateStandardQuotes();
      } finally {
        this.spinnerService.hide(this.listSpinnerName).then();
      }
    }
  }

  public closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  public cleanup(): void {
    this.subscriptionList.forEach(sub => sub.unsubscribe());
    this.subscriptionList = [];
  }

  private async getLayout(projectType: string) {
    this.spinnerService.show(this.spinnerName).then();
    try {
      const projectConfiguration = await this.projectConfigurationService.getProjectConfiguration(projectType, true);
      if (!projectConfiguration?.layouts) {
        return null;
      }
      const tabs = projectConfiguration?.layouts;
      for (const tab of tabs) {
        if (!tab?.layouts?.length) {
          continue;
        }
        for (const layout of tab.layouts) {
          if (layout.type !== this.type) {
            continue;
          }

          layout.edit = true;
          for (const item of layout.items) {
            if (item.editConfig) {
              item.editConfig.required = false;
              if (item.editConfig.pattern) {
                item.editConfig.pattern = `^$|${item.editConfig.pattern}`;
              }
              if (item.editConfig.type === 'number' || item.editConfig?.controlType === 'MultiDropdownQuestion') {
                item.editConfig.min = 0;
              }
            }
          }

          return layout;
        }
      }
      return null;
    } finally {
      this.spinnerService.hide(this.spinnerName).then();
    }
  }

  private cleanData(obj: StandardData): StandardData {
    const clone = JSON.parse(JSON.stringify(obj));
    delete clone.layout;
    const keys = Object.keys(clone.data);
    for (const key of keys) {
      if (!clone.data[key]) {
        clone.data[key] = undefined;
      }
    }
    return clone;
  }
}
