export type SupportedDates = string | number | Date | DateObject;

export interface JumptechDuration {
  years?: number | undefined;
  quarters?: number | undefined;
  months?: number | undefined;
  weeks?: number | undefined;
  days?: number | undefined;
  hours?: number | undefined;
  minutes?: number | undefined;
  seconds?: number | undefined;
  milliseconds?: number | undefined;
}

export type JumptechDurationExtended = JumptechDuration & {
  year?: number | undefined;
  quarter?: number | undefined;
  month?: number | undefined;
  week?: number | undefined;
  day?: number | undefined;
  hour?: number | undefined;
  minute?: number | undefined;
  second?: number | undefined;
  millisecond?: number | undefined;
};

export interface JumptechDateArithmeticOptions {
  businessDays?: boolean;
}

export type JumptechDateUnit = 'year' | 'quarter' | 'month' | 'day' | 'hour' | 'minute' | 'second' | 'millisecond';
export type JumptechDateOfUnit = 'week' | JumptechDateUnit;
export type JumptechDurationUnit =
  | 'years'
  | 'quarters'
  | 'months'
  | 'days'
  | 'hours'
  | 'minutes'
  | 'seconds'
  | 'milliseconds';

export interface JumptechDateEqualityOptions {
  unit?: 'timestamp' | JumptechDateUnit;
}

export interface JumptechDateDiffOptions {
  units: JumptechDurationUnit | JumptechDurationUnit[];
}

export interface JumptechDateUntilOptions {
  units: JumptechDurationUnit | JumptechDurationUnit[];
}

export interface DateObject {
  year?: number | undefined;
  month?: number | undefined;
  day?: number | undefined;
  hour?: number | undefined;
  minute?: number | undefined;
  second?: number | undefined;
  millisecond?: number | undefined;
}

export interface JumptechDateFromFormatOptions {
  sourceFormat?: string;
  sourceZone?: string;
  sourceLocale?: string;
  targetZone?: string;
  targetLocale?: string;
}

export function isDate(val: unknown): val is Date {
  return !!val && Object.prototype.toString.call(val) === '[object Date]' && !isNaN(val as number);
}

export function isDateObject(val: unknown): val is DateObject {
  if (!val || typeof val !== 'object') {
    return false;
  }
  return ['year', 'month', 'day', 'hour', 'minute', 'second', 'millisecond'].some(x => x in val);
}

export type SupportedDateFormats = 'Date' | 'Time' | 'DateTime' | 'Timestamp';

export type SupportedExportDateFormats = 'ExportDate' | 'ExportTime' | 'ExportDateTime';
