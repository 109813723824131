export interface ProjectTypeDataItem {
  name: string;
  label: string;
  data: any;
}

export interface ProjectTypeData {
  tenant: string;
  projectType: string;
  data: ProjectTypeDataItem[];
  version?: number;
}
