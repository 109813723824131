import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MyOrdersHistoryPresenter } from './order-history.presenter';
import { OrderHistoryVm } from '../my-orders.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
  selector: 'pathway-my-orders-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor, CoreComponentsAngularModule, AsyncPipe],
  providers: [MyOrdersHistoryPresenter]
})
export class MyOrdersHistoryComponent implements OnChanges {
  @Input() refresh = false;
  vm$: BehaviorSubject<OrderHistoryVm> = new BehaviorSubject<OrderHistoryVm>(null);

  constructor(private presenter: MyOrdersHistoryPresenter) {}

  viewOrder(order): void {
    this.presenter.viewOrder(order);
  }

  ngOnChanges() {
    this.presenter.load(this.vm$);
  }
}
