import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { VideoPlayerParams, VideoPlayerViewModel } from './video-player.model';
import { VideoPlayerPresenter } from './video-player.presenter';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { VideoPlayerControlsComponent } from './video-player-controls/video-player-controls.component';
import { VideoPlayerTimerComponent } from './video-player-timer/video-player-timer.component';
import { VideoPlayerRepository } from './video-player.repository';
import { VideoRecorderService } from './video-recorder.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'question-video-player',
  templateUrl: './video-player.component.html',
  standalone: true,
  imports: [NgIf, AsyncPipe, CoreComponentsAngularModule, VideoPlayerControlsComponent, VideoPlayerTimerComponent],
  providers: [VideoPlayerPresenter, VideoPlayerRepository, VideoRecorderService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  private readonly presenter = inject(VideoPlayerPresenter);
  private readonly videoId = uuidv4();

  public readonly vm$: BehaviorSubject<VideoPlayerViewModel | null> = new BehaviorSubject<VideoPlayerViewModel | null>({
    canDelete: false,
    canRecord: false,
    showRecordControls: false,
    showVideo: false,
    id: this.videoId,
    videoSource: null,
    readOnly: true
  });

  @Input() params: VideoPlayerParams;
  @Output() recordingUpdated: EventEmitter<string | null> = new EventEmitter<string | null>();
  public loading = false;

  async ngOnInit() {
    this.loading = true;
    const videoPlayerParams = { ...this.params, id: this.videoId };
    await this.presenter.load(this.vm$, videoPlayerParams, this.recordingUpdated);
    this.loading = false;
  }

  public async startRecording() {
    await this.presenter.startRecording();
  }

  public stopRecording() {
    this.presenter.stopRecording();
  }

  public pauseRecording() {
    this.presenter.pauseRecording();
  }

  public resumeRecording() {
    this.presenter.resumeRecording();
  }

  public async removeRecording() {
    await this.presenter.removeRecording();
  }
}
