import { ProjectType } from './project-type';
import { ProjectStatus } from './project-status.interface';
import { Address } from './address';
import { AsyncAction } from './project-action';
import { DelegationShareSubTenant } from './role';

export type TriggerConfig = {
  jobStatus: string;
  projectStatus: string;
  asyncActions?: AsyncAction[];
};

export interface ExternalId {
  id: string;
}

export type ExternalIdMap = { [key: string]: ExternalId };

export interface Job {
  id: string;
  projectId: string;
  type: ProjectType | string;
  assignedTo: string;
  assignedToDisplayName: string;
  status: string;
  statusLog: any[];
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  scheduledDate?: string;
  scheduledDisplayDate?: string;
  address?: Address;
  triggers?: TriggerConfig[];
}

export interface RelayProject {
  tenant: string;
  id: string;
  message: any;
  updated_on?: string;
  updated?: number;
  expires?: number; // Used for dynamo auto expiry
}

export interface Project {
  tenant: string;
  id: string;
  externalIds?: ExternalIdMap;
  primaryOwner?: string;
  type: ProjectType | string;
  status: ProjectStatus;
  data?: any;
  jobs?: Job[];
  owner?: string;
  owner_name?: string;
  attachments?: any[];
  resources?: any[];
  created_on?: string;
  created_by?: string;
  created_by_name?: string;
  created_by_team?: string;
  created_by_teams?: string[];
  created_by_team_name?: string;
  created_by_roles?: string[];
  updated_on?: string;
  updated_by?: string;
  relaySubmissionDate?: string;
  delegationChain?: string;
  subTenants?: DelegationShareSubTenant[];
  // Below is to facilitate DynamoDb query filter
  subTenantId?: string;
  assigned_to_dates?: { from: string; to: string; date: string }[];
  // Assignments
  assigned_to_team?: string;
  assigned_to_team_name?: string;
  notes?: string[];
  isAggregator?: boolean;
}
