import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DateChangeType, DateTimeChangeEvent, SelectedJobDetailsVm } from '../../schedule.model';
import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { ScheduleEventSelectedJobDetailsPresenter } from './schedule-event-selected-job-details.presenter';
import { ScheduleEventSelectedJobDetailsJobInfoComponent } from './components/schedule-event-selected-job-details-job-info/schedule-event-selected-job-details-job-info.component';
import { ScheduleEventSelectedJobDetailsDateTimeFormComponent } from './components/schedule-event-selected-job-details-date-time-form/schedule-event-selected-job-details-date-time-form.component';
import { ScheduleEventSelectedJobDetailsAssignedEngineersComponent } from './components/schedule-event-selected-job-details-assigned-engineers/schedule-event-selected-job-details-assigned-engineers.component';
import { TIME_SLOTS } from '../../utils/schedule-constants';
import { JobAssignment } from '../../../../core/domain/job';
import { JumptechDate } from '@jump-tech-frontend/domain';
import { ScheduleEventSelectedJobDetailsActionsComponent } from './components/schedule-event-selected-job-details-actions/schedule-event-selected-job-details-actions.component';

@Component({
  selector: 'schedule-event-selected-job-details',
  templateUrl: 'schedule-event-selected-job-details.component.html',
  styleUrls: ['schedule-event-selected-job-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CoreComponentsAngularModule,
    ScheduleEventSelectedJobDetailsJobInfoComponent,
    ScheduleEventSelectedJobDetailsDateTimeFormComponent,
    ScheduleEventSelectedJobDetailsAssignedEngineersComponent,
    ScheduleEventSelectedJobDetailsActionsComponent
  ],
  providers: [ScheduleEventSelectedJobDetailsPresenter]
})
export class ScheduleEventSelectedJobDetailsComponent implements OnInit {
  @Output() closedOnSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private presenter: ScheduleEventSelectedJobDetailsPresenter) {}
  public vm$: BehaviorSubject<SelectedJobDetailsVm> = new BehaviorSubject<SelectedJobDetailsVm>(null);
  timeSlots = TIME_SLOTS;

  ngOnInit() {
    this.presenter.load(this.vm$, this.closedOnSuccess, 'schedule');
    this.localiseTimeSlotLabels();
  }

  handleHide(): void {
    this.presenter.hideDisplay();
  }

  handleClose(): void {
    this.presenter.closeMoreDetails();
    this.closed.emit();
  }

  addEngineer(el): void {
    this.presenter.addEngineer(el);
  }

  setLeadEngineer(engineer: JobAssignment): void {
    this.presenter.setLeadEngineer(engineer);
  }

  removeEngineer(engineer: JobAssignment): void {
    this.presenter.deleteEngineer(engineer);
  }

  confirmAddEngineer(): void {
    this.presenter.confirmAddEngineer();
  }

  cancelAddEngineer(): void {
    this.presenter.cancelAddEngineer();
  }

  localiseTimeSlotLabels(): void {
    this.timeSlots = TIME_SLOTS.map(slot => {
      const date = JumptechDate.now().set({
        hour: Number(slot.label.split(':')[0]),
        minute: Number(slot.label.split(':')[1])
      });
      return {
        ...slot,
        label: date.toTimeFormat(false)
      };
    });
  }

  handleDateTimeChange(type: DateChangeType): void {
    this.presenter.handleDateTimeChange(type);
  }

  handleSlotDateTimeChange(slotDateChangeEvent: DateTimeChangeEvent): void {
    this.presenter.handleSlotDateTimeChange(slotDateChangeEvent);
  }

  handleScheduleNow(): void {
    this.presenter.scheduleJob();
  }

  handleGoToProject(): void {
    this.presenter.goToProject();
  }

  handleCheckCollisions(): void {
    this.presenter.checkCollisions();
  }
}
