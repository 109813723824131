export interface StatusLogEntry {
  gps: {
    lng: number;
    lat: number;
  };
  status: string;
  timestamp: string;
}

export type JobAssignmentType = 'LEAD' | 'SUPPORT';

export interface JobAssignment {
  assignedTo: string;
  assignedToDisplayName?: string;
  assignmentType: JobAssignmentType;
  color?: string;
  type?: string;
  titleText?: string;
}

export interface Job {
  id?: string;
  projectId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  address?: {
    line1?: string;
    line2?: string;
    line3?: string;
    line4?: string;
    town?: string;
    locality?: string;
    county?: string;
    country?: string;
    postCode?: string;
  };
  startDate?: string;
  endDate?: string;
  statusLog?: StatusLogEntry[];
  scheduledDate?: string;
  riskAssessor?: string;
  gps?: {
    lng?: string;
    lat?: string;
  };
  type?: string;
  jobAssignments?: JobAssignment[];
  installChargePoint?: string;
  assignedToDisplayName?: string;
  assignedTo?: string;
  siteSupervisor?: string;
  phoneNumber?: string;
  natureOfWorks?: string;
  tenant?: string;
  status?: string;
  projectStatus?: string;
  completedDate?: string;
  abortedDate?: string;
  readyToSchedule?: boolean;
  isInitialSchedule?: boolean;
  geometry?: {
    type: string;
    coordinates: number[];
  };
  defaultDuration?: number;
  tenantType?: string;
  rescheduleReason?: string;
}

export function isJobArray(val: unknown): val is Job[] {
  const isArray = val && typeof val === 'object' && Array.isArray(val);
  if (isArray) {
    const arr = val as unknown[];
    if (arr.length === 0) {
      return true;
    }
    return (arr[0] as unknown as Job).id !== undefined;
  }
}
