import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ScheduleMoreInfoVm } from '../../schedule.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { ReadOnlyDisableDirective } from '@jump-tech-frontend/angular-common';

@Component({
  selector: 'schedule-event-more-details-header',
  templateUrl: 'schedule-event-more-details-header.component.html',
  styleUrls: ['../schedule-event-more-details/schedule-event-more-details.component.scss'],
  standalone: true,
  imports: [CoreComponentsAngularModule, ReadOnlyDisableDirective]
})
export class ScheduleEventMoreDetailsHeaderComponent {
  @Input() vm: ScheduleMoreInfoVm;

  @Output() onCloseMoreDetails: EventEmitter<void> = new EventEmitter<void>();
  @Output() onHandleGoToProject: EventEmitter<void> = new EventEmitter<void>();
  @Output() onHandleScheduleNow: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}
}
