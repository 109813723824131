import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IAddressV2 } from '../../domain/address-v2';
import { getAddressLinesForCountry } from '../address.utils';
import { ControlContext } from '../../address-lookup-v2.vm';

@Component({
  selector: 'jump-tech-frontend-address-result',
  standalone: true,
  template: `
    <div class="address-result" *ngIf="addressLines.length">
      <div class="address-result__result" *ngFor="let addressLine of addressLines" [innerHTML]="addressLine"></div>
    </div>
  `,
  imports: [NgIf, NgForOf, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['address-result.component.scss']
})
export class AddressResultComponent {
  public addressLines: string[] = [];

  @Input() set address(address: IAddressV2) {
    if (address?.line1) {
      this.addressLines = address?.line1 ? getAddressLinesForCountry(this.countryCodes, address) : [];
    }
  }
  @Input() context: ControlContext;

  @Input() countryCodes: string[];
}
