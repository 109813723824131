export interface ApiKeyAccessRestrictions {
  endpoints: string[];
}

export interface ApiKeyAccess {
  tenant: string;
  id: string;
  restrictions?: ApiKeyAccessRestrictions;
  ipAddresses?: string[];
  user?: any;
}
