import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstChars',
  standalone: true
})
export class FirstCharsPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value
      .split(' ')
      .map(valuePart => valuePart.charAt(0))
      .join('')
      .toUpperCase();
  }
}
