import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, Subject, Subscription, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { UserService } from '../../auth/services/user.service';
import { Project } from '../../core/domain/project';
import { User } from '../../core/domain/user';
import { HttpGateway } from '../../core/http-gateway.service';
import { ProjectConfigurationService } from '../../core/project-configuration.service';
import { DropDownElement } from '../../shared/form-components/multiple-selection-dropdown.component';
import { ProjectState, SupportBarDataDm } from './support-bar.model';

@Injectable({ providedIn: 'root' })
export class SupportBarRepository {
  private supportBarDataSubscription: Subscription;
  public supportBarData$: Subject<SupportBarDataDm>;
  private statusChangeForm: FormGroup;
  private supportBarDataDm: SupportBarDataDm | null = {} as SupportBarDataDm;
  public projectId$: BehaviorSubject<string>;
  public user$: BehaviorSubject<User>;
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    private gateway: HttpGateway,
    private projectConfigurationService: ProjectConfigurationService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {
    this.init();
  }

  init(): void {
    this.supportBarData$ = new Subject<SupportBarDataDm>();
    this.projectId$ = new BehaviorSubject<string>(null);
    this.user$ = new BehaviorSubject<User>(null);
    this.statusChangeForm = this.fb.group({
      selectedStatus: []
    });
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(e => e instanceof NavigationEnd),
        switchMap(() => this.activatedRoute.firstChild.params)
      )
      .subscribe(params => {
        this.projectId$.next(params?.['projectId']);
      });
    this.userService.userObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(user => {
      this.user$.next(user);
    });
    this.projectId$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((projectId?: string) => {
      this.loadData(projectId).then();
    });
  }

  load(cb): void {
    this.supportBarDataSubscription?.unsubscribe();
    this.supportBarDataSubscription = this.supportBarData$.subscribe(cb);
    this.loadData(this.projectId$.value).then();
  }

  handleStatusFormUpdate(): void {
    const updatedDm = {
      ...this.supportBarDataDm,
      statusChangeForm: this.statusChangeForm
    };
    this.supportBarDataDm = { ...updatedDm };
    this.supportBarData$.next(updatedDm);
  }

  async updateProjectStatus(projectId: string): Promise<void> {
    if (!projectId) {
      return;
    }
    this.triggerTransientState();

    const selectedStatus = this.statusChangeForm.get('selectedStatus').value;
    console.log(`Updating status of project ${projectId} to ${selectedStatus}`);

    try {
      await this.gateway.post(
        `${environment.apiProjectsRoot}/admin/${projectId}/status`,
        {
          status: selectedStatus,
          force: true
        },
        {}
      );
      this.setProjectStatusUpdateTimeout();
    } catch (e) {
      console.log(e);
      this.handleProjectStatusUpdateError();
    }
  }

  private triggerTransientState(): void {
    const updatedDm: SupportBarDataDm = {
      ...this.supportBarDataDm,
      state: 'transient',
      statusChangeButtonText: 'Updating status...',
      errorMessage: ''
    };
    this.supportBarDataDm = updatedDm;
    this.statusChangeForm.get('selectedStatus').disable();
    this.supportBarData$.next(updatedDm);
  }

  private setProjectStatusUpdateTimeout(): void {
    // Check if we are still in a transient state after 20 seconds
    setTimeout(() => {
      if (this.supportBarDataDm.state === 'transient') {
        this.handleProjectStatusUpdateError();
      }
    }, 20000);
  }

  private handleProjectStatusUpdateError(): void {
    const updatedDm: SupportBarDataDm = {
      ...this.supportBarDataDm,
      state: 'ready',
      statusChangeButtonText: 'Change status',
      errorMessage: 'Failed to update project status. Please try again.'
    };
    this.statusChangeForm.get('selectedStatus').enable();
    this.supportBarData$.next(updatedDm);
  }

  public async loadData(projectId?: string): Promise<void> {
    this.supportBarDataDm = {
      show: false,
      instanceInformation: undefined,
      loading: false,
      statusOptions: [],
      projectStructure: undefined,
      projectStructureButtonText: '',
      changeProjectStatusButtonText: '',
      statusChangeForm: this.statusChangeForm,
      statusOptionsDropdownPlaceholder: '',
      statusChangeButtonText: '',
      state: 'ready',
      errorMessage: ''
    };
    this.supportBarDataDm.show = AuthenticationService.getTier() === 'support' && !!this.user$.value;
    this.supportBarDataDm.instanceInformation = this.user$.value && {
      userName: this.user$.value.label,
      tenant: this.user$.value.subTenant?.name ?? this.user$.value.tenant,
      team: this.user$.value.team_name ?? this.user$.value.accessInfo?.teams?.[0]?.name,
      isAdmin: this.user$.value.isAdmin,
      isSuperAdmin: this.user$.value.isSuperAdmin
    };
    this.statusChangeForm.get('selectedStatus').enable();
    this.supportBarDataDm.loading = !!projectId;
    this.supportBarData$.next(this.supportBarDataDm);

    if (projectId) {
      const project: Project = await this.gateway.get(`${environment.apiProjectUrl}/${projectId}`, {});
      const projectConfiguration = await this.projectConfigurationService.getProjectConfiguration(project.type);
      const projectStates: ProjectState[] = projectConfiguration.states;
      this.parseAndNotify(project, projectStates);
    }
  }

  private parseAndNotify(project: Project, projectStates: ProjectState[]): void {
    const projectStateDropdownElements: DropDownElement[] = projectStates.map(state => {
      return {
        id: state.status,
        label: state.label
      };
    });
    const updatedDm: SupportBarDataDm = {
      ...this.supportBarDataDm,
      loading: false,
      projectStructure: project,
      statusOptions: projectStateDropdownElements,
      projectStructureButtonText: 'View JSON',
      changeProjectStatusButtonText: 'Change Project Status',
      statusChangeButtonText: 'Change status',
      statusChangeForm: this.statusChangeForm,
      statusOptionsDropdownPlaceholder: 'Select status'
    };
    this.supportBarDataDm = { ...updatedDm };
    this.supportBarData$.next(updatedDm);
  }
}
