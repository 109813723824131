import { Component, computed, input } from '@angular/core';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf } from '@angular/common';

@Component({
  selector: 'question-hint',
  template: `
    <div *ngIf="question().hint && question().permanentHint">
      <jui-alert-block size="md" color="low" [iconName]="icon()">
        <span [innerHtml]="question().hint"></span>
      </jui-alert-block>
    </div>
  `,
  standalone: true,
  imports: [NgIf, CoreComponentsAngularModule]
})
export class QuestionHintComponent {
  question = input.required<QuestionBase<string>>();
  icon = computed(() => this.question().icon || 'info');
}
